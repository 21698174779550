import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const Question = (props: IconProps) => (
  <Icon
    width='12'
    height='19'
    viewBox='0 0 12 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M6.08052 15.9778C6.84792 15.976 7.47256 16.6015 7.46802 17.368C7.46347 18.1227 6.83973 18.7473 6.0887 18.7501C5.32949 18.7528 4.69575 18.1154 4.70121 17.3553C4.70575 16.5952 5.32222 15.9805 6.08052 15.9787V15.9778Z'
      fill='#08101D'
    />
    <path
      d='M1.68359 5.60935C1.68359 4.21011 2.75232 1.25 5.90171 1.25C9.08458 1.25 10.3164 3.55198 10.3164 5.08663C10.3164 6.85716 9.43694 7.7599 8.17894 9.06013C6.56129 10.7317 5.89006 11.5543 5.89006 13.3787'
      stroke='#08101D'
      strokeWidth={props.strokeWidth || '1.4'}
      strokeMiterlimit='10'
      strokeLinecap='round'
    />
  </Icon>
);
