import { Box, IconButton, Image, SimpleGrid, Text } from '@chakra-ui/react';
import { Eye } from '../../Icons/Eye';
import { useEffect, useState } from 'react';
import React from 'react';
import { Like } from '../../Icons/Like';
import { Link } from 'react-router-dom';
import configureAxios from '../../axiosClient';
import { CommunityExperienceListItem } from '../../interfaces';
// import { Clone } from '../../Icons/Clone';
import emperiaLogo from '../../assets/image/EmperiavrTransparentLogo.png';

function SampleExperienceItems() {
  const [hoveredId, setHoveredId] = useState<string | null>(null);
  const [sampleExperiences, setSampleExperiences] = useState([]);

  const axiosClient = configureAxios();

  const fetchHeroSectionExperiences = async () => {
    try {
      const res = await axiosClient.get(`/experience/hero-featured`);
      const experiencesData = res.data.data;
      setSampleExperiences(experiencesData);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchHeroSectionExperiences();
  }, []);

  return (
    <SimpleGrid columns={[3]} spacing={[4]} mt='60px'>
      {sampleExperiences
        .slice(0, 3)
        .map((sampleExperience: CommunityExperienceListItem) => {
          const isHovered = hoveredId === sampleExperience.id;
          return (
            <Box
              key={sampleExperience.id}
              minWidth={['320px']}
              borderRadius='16px'
              bg='#1D2531'
              position='relative'
              onMouseEnter={() => setHoveredId(sampleExperience.id)}
              onMouseLeave={() => setHoveredId(null)}
              cursor='pointer'
            >
              <Box
                overflow='hidden'
                width={['100%']}
                height={['140px']}
                borderTopRadius='16px'
              >
                {/* src={
            communityExperienceListItem.community_url
              ? communityExperienceListItem.community_url.replace(
                  'index.html',
                  'browser_icon.jpg'
                )
              : thumbnail
          } */}
                <Image
                  src={sampleExperience.community_url.replace(
                    'index.html',
                    'browser_icon.jpg'
                  )}
                  objectFit='cover'
                  width={['100%']}
                  height={['100%']}
                  transform={isHovered ? 'scale(1.2)' : 'scale(1)'}
                  transition='0.2s all'
                  cursor='pointer'
                />
              </Box>

              <Box
                opacity={isHovered ? 1 : 0}
                visibility={isHovered ? 'visible' : 'hidden'}
                backgroundColor='rgba(0,0,0,0.5)'
                transition='0.2s all'
                background='rgba(8, 16, 29, 0.60)'
                backdropFilter='blur(4px)'
                position='absolute'
                zIndex='2'
                top='0px'
                width={['100%']}
                height={'140px'}
                borderTopRadius='16px'
                display='flex'
                justifyContent='center'
                alignItems='center'
              >
                <Box
                  height={['auto']}
                  width={['90px']}
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                >
                  <Link
                    to={`${sampleExperience.community_url}`}
                    target='_blank'
                  >
                    <IconButton
                      bg='white'
                      _hover={{
                        bg: '#D3BB8A',
                      }}
                      borderRadius='100%'
                      icon={<Eye stroke='#08101D' boxSize={[5]} />}
                      aria-label='View Experience'
                    />
                  </Link>
                </Box>
              </Box>

              <Box
                display='flex'
                width={['100%']}
                padding='16px 20px 16px 20px'
                justifyContent='space-between'
                alignItems='center'
                gap={3}
              >
                <Image
                  src={emperiaLogo}
                  alt='user'
                  width='34px'
                  height='34px'
                />
                <Box display='flex' flexDir='column' marginRight='auto'>
                  <Text
                    fontSize='14px'
                    fontFamily='Normal'
                    whiteSpace='nowrap'
                    overflow='hidden'
                    textOverflow='ellipsis'
                    textAlign='start'
                  >
                    {sampleExperience.name}
                  </Text>
                  <Box display='flex' alignItems='center' gap={2}>
                    <Text fontFamily='Normal' fontSize='11px'>
                      {sampleExperience.organisation_name}
                    </Text>
                  </Box>
                </Box>
                <Box display='flex' alignItems='center' gap={4}>
                  <Box display='flex' flexDirection='column' gap={2}>
                    <Like boxSize={5} />
                    <Text fontSize='11px' fontFamily='Normal'>
                      {sampleExperience.like_count}
                    </Text>
                  </Box>
                  {/* <Box display='flex' flexDirection='column' gap={2}>
                    <Clone boxSize={6} />
                    <Text fontSize='11px' fontFamily='Normal'>
                      {sampleExperiences.templateTotalLikes}
                    </Text>
                  </Box> */}
                </Box>
              </Box>
            </Box>
          );
        })}
    </SimpleGrid>
  );
}

export default SampleExperienceItems;
