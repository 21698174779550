import { Box, Text } from '@chakra-ui/react';
import QuestionnaireForm from '../forms/QuestionnaireForm';
import { Fragment } from 'react';
import { useUserStore } from '../../store/user';
import React from 'react';

function Questionnaire() {
  const user = useUserStore((state) => state.user);

  return (
    <Fragment>
      <Text
        textAlign='center'
        color='#D3BB8A'
        fontFamily='Normal'
        fontSize={['14px', '14px', '14px']}
      >
        {`Hi ${user.firstName}, Help us personalize your experience.`}
      </Text>
      <Box
        mt={[5, 5, 5, 5, 5]}
        w={['100%', '380px', '480px']}
        height={['auto']}
        p='30px'
        borderRadius='16px'
        background='#1D2531'
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        alignItems='center'
        gap={5}
      >
        <QuestionnaireForm />
      </Box>
    </Fragment>
  );
}

export default Questionnaire;
