import { Box, Text, Button } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { usePlanStore } from '../../store/plan';
import React from 'react';

type Plan = {
  planType: string;
  charges: string;
  btnText: string;
  targetPeople: string;
  planDuration: string;
};

function SuggestedPlanCard() {
  const { setShowPlans, suggestedPlan, currentPlan } = usePlanStore();
  const [upgradeToPlan, setUpgradeToPlan] = useState<Plan>();

  const plans: Plan[] = [
    {
      planType: 'Free',
      charges: '$0',
      btnText: !currentPlan.name.includes('Free')
        ? 'Cancel Paid Plan'
        : 'Start For Free',
      targetPeople: 'for students & personal projects',
      planDuration: '/ Month',
    },
    {
      planType: 'Pro',
      charges: '$45',
      btnText: !currentPlan.name.includes('Free')
        ? 'Shift To Pro'
        : 'Start Free Trial',
      targetPeople: 'for Freelancers',
      planDuration: '/ Month',
    },
    {
      planType: 'Studio',
      charges: '$199',
      btnText: !currentPlan.name.includes('Free')
        ? 'Shift To Studio'
        : 'Start Free Trial',
      targetPeople: 'for Studios & Start-Ups',
      planDuration: '/ Month',
    },
    {
      planType: 'Enterprise',
      charges: 'Custom Pricing',
      btnText: 'Contact Sales',
      targetPeople: 'for retailers & large organisations',
      planDuration: '',
    },
  ];

  useEffect(() => {
    setUpgradeToPlan(
      plans.find((plan: Plan) => plan.planType === suggestedPlan.plan)
    );
  }, [suggestedPlan]);

  return (
    <Box
      borderRadius='30px'
      border='1px solid rgba(211, 187, 138, 1)'
      width={['100%']}
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
      alignItems='flex-start'
    >
      <Box
        padding={['12px 24px']}
        width={['100%']}
        display='flex'
        justifyContent='center'
        alignItems='center'
        background='linear-gradient(255.12deg, #D3BB8A -1.47%, rgba(211, 187, 138, 0) 100%),
    linear-gradient(0deg, rgba(242, 243, 245, 0.14), rgba(242, 243, 245, 0.14))'
        borderTopRadius='30px'
      >
        <Text fontFamily='Metal' textTransform='uppercase' fontSize={['14px']}>
          Suggested For You
        </Text>
      </Box>

      <Box
        p={['30px']}
        width={['inherit']}
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='flex-start'
        gap={5}
      >
        <Box display='flex' flexDirection='column'>
          <Text fontFamily='Metal' fontSize={['35px']} lineHeight={['35px']}>
            {upgradeToPlan?.planType}
          </Text>
          <Text color='#D6DBE4' fontFamily='Normal' fontSize={['12px']}>
            {upgradeToPlan?.targetPeople}
          </Text>
        </Box>

        <Button
          textAlign='left'
          whiteSpace='normal'
          textTransform='uppercase'
          width='100%'
          variant='outline'
          color='white'
          background='#1D2531'
          border='none'
          fontFamily='Metal'
          fontSize={['14px', '14px', '15px']}
          borderRadius='30px'
          _hover={{
            bg: 'white',
            color: '#08101D',
          }}
          onClick={() => setShowPlans(true)}
        >
          {upgradeToPlan?.btnText}
        </Button>
      </Box>
    </Box>
  );
}

export default SuggestedPlanCard;
