import {
  Box,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import Toggle from '../../Icons/Toggle';
import { Experiences } from '../../Icons/Experiences';
import { Globe } from '../../Icons/Globe';
import { Private } from '../../Icons/Private';

interface ExperienceDropdownProps {
  selectedExperienceFilter: string;
  setSelectedExperienceFilter: (state: string) => void;
}

function ExperienceDropdown({
  selectedExperienceFilter,
  setSelectedExperienceFilter,
}: ExperienceDropdownProps) {
  return (
    <Popover
      placement='bottom'
      returnFocusOnClose={false}
      closeOnBlur={true}
      isLazy
    >
      <PopoverTrigger>
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          bg='#1D2531'
          maxWidth={['250px']}
          padding='8px 16px 8px 16px'
          borderRadius='16px'
          cursor='pointer'
        >
          <Text fontFamily='Normal' fontSize='14px'>
            {selectedExperienceFilter}
          </Text>
          <Toggle stroke='white' boxSize={4} />
        </Box>
      </PopoverTrigger>
      <PopoverContent
        borderRadius='16px'
        background='#FFF'
        boxShadow='0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
        backdropFilter='blur(12px)'
        width={['250px']}
        height={['auto']}
      >
        <PopoverBody
          height={['inherit']}
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          padding={['20px']}
          gap={4}
        >
          <Box
            display='flex'
            gap={4}
            alignItems='center'
            cursor='pointer'
            onClick={() => setSelectedExperienceFilter('All')}
            p='5px'
            _hover={{
              backgroundColor: '#D6DBE4',
              borderRadius: '16px',
            }}
          >
            <Experiences boxSize={5} stroke='#08101D' />
            <Text fontSize='14px' color='#08101D' fontFamily='Normal'>
              All Experiences
            </Text>
          </Box>
          <Box
            display='flex'
            gap={4}
            alignItems='center'
            cursor='pointer'
            onClick={() => setSelectedExperienceFilter('Shared With Community')}
            p='5px'
            _hover={{
              backgroundColor: '#D6DBE4',
              borderRadius: '16px',
            }}
          >
            <Globe boxSize={5} stroke='#08101D' />
            <Text fontSize='14px' color='#08101D' fontFamily='Normal'>
              Shared With Community
            </Text>
          </Box>
          <Box
            display='flex'
            gap={4}
            alignItems='center'
            cursor='pointer'
            onClick={() => setSelectedExperienceFilter('Private')}
            p='5px'
            _hover={{
              backgroundColor: '#D6DBE4',
              borderRadius: '16px',
            }}
          >
            <Private boxSize={5} stroke='#08101D' />
            <Text fontSize='14px' color='#08101D' fontFamily='Normal'>
              Private
            </Text>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default ExperienceDropdown;
