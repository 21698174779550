import React from 'react';
import { motion } from 'framer-motion';
import { Text } from '@chakra-ui/react';
import { UserCircle } from '../../Icons/UserCircle';
import { ExperienceListItem } from '../../interfaces';

interface ExperienceDetailedInfoProps {
  experienceHovered: boolean;
  experienceListItem: ExperienceListItem;
}

const ExperienceDetailedInfo = ({
  experienceHovered,
  experienceListItem,
}: ExperienceDetailedInfoProps) => {
  return (
    <motion.div
      initial={{ opacity: 0, height: 0 }}
      animate={{
        opacity: experienceHovered ? 1 : 0,
        height: experienceHovered ? 'auto' : 0,
      }}
      transition={{ duration: 0.2, ease: 'easeInOut' }}
      style={{ overflow: 'hidden' }}
    >
      {experienceHovered && (
        <>
          <Text fontFamily='Normal' fontSize={['11px']} fontWeight={'bold'}>
            <UserCircle boxSize={[4]} /> Created by:{' '}
            <Text as={'span'} color={'#ACBBD3'}>
              {experienceListItem?.user?.name}
            </Text>
          </Text>
        </>
      )}
    </motion.div>
  );
};

export default ExperienceDetailedInfo;
