import { useCallback } from 'react';
import posthog from 'posthog-js';

const usePostHog = () => {
  const identifyUser = useCallback(
    (userId: string, userProperties: Record<string, unknown> = {}) => {
      posthog.identify(userId, userProperties);
    },
    []
  );

  return { identifyUser };
};

export default usePostHog;
