import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  Spinner,
  useToast,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Select,
  Tag,
  Text,
  IconButton,
  FormLabel,
} from '@chakra-ui/react';
import FormWrapper from '../common/FormWrapper';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useAuthStore } from '../../store/auth';
import configureAxios from '../../axiosClient';
import { AddUser } from '../../Icons/AddUser';
import { useUserStore } from '../../store/user';
import { CloseIcon } from '@chakra-ui/icons';
import React from 'react';
import { dispatchOrganisationToAnalytics } from '../../analytics';
import { usePlanStore } from '../../store/plan';

type UserInviteFormValues = {
  email: string;
  role: string;
};

type EnteredEmail = { email: string; role: string };

interface UserInviteFormProps {
  flow: 'login' | 'postLogin';
  theme?: 'white' | 'black';
  showSkip: boolean;
  btnText: string;
  close: () => void;
}

const axiosClient = configureAxios();

function UserInviteForm({
  flow,
  theme = 'black',
  showSkip,
  btnText,
  close,
}: UserInviteFormProps) {
  const whiteTheme = theme === 'white';

  const { setLoggedIn, setOfferUserInvites, activeLoginView, setFirstTime } =
    useAuthStore();
  const { organisation, getOrganizations, user } = useUserStore();

  useEffect(() => {
    activeLoginView === 'userInvites' && getOrganizations();
  }, [activeLoginView]);

  const {
    register,
    handleSubmit,
    getValues,
    trigger,
    setValue,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm<UserInviteFormValues>({
    defaultValues: {
      role: 'Member',
    },
  });

  const toast = useToast({
    position: 'top',
    variant: 'top-accent',
  });
  const [enteredEmails, setEnteredEmails] = useState<EnteredEmail[]>([]);
  const [createAccountLoading, setUserInviteFormLoading] = useState(false);
  const { currentPlan } = usePlanStore();

  async function sendRequest(enteredEmails: EnteredEmail[]) {
    try {
      const response = await axiosClient.post(
        `org/${organisation.organizationId}/invites/create`,
        {
          invitees: enteredEmails,
        }
      );
      const isStatusOk = response.data.status === 'OK';
      return { success: isStatusOk, response };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      toast({
        title: error.response.data.data,
        status: 'error',
      });
      return { success: false, error };
    }
  }

  const onSubmit: SubmitHandler<UserInviteFormValues> = async (data) => {
    setUserInviteFormLoading(true);
    if (currentPlan.name === 'Free' || currentPlan.name === 'Pro') {
      toast({
        title: 'Please upgrade to Studio or Enterprise Plan to invite members',
        status: 'warning',
      });
      setUserInviteFormLoading(false);
    } else {
      if (data) {
        const result = await sendRequest(enteredEmails);
        if (result?.response?.data?.status === 'OK') {
          if (flow === 'login') {
            setLoggedIn(true);
            setFirstTime(true);
            setOfferUserInvites(false);
          } else {
            close();
          }
          if (result.response?.data.data === 'Invite sent.') {
            dispatchOrganisationToAnalytics('invite_user', {
              organisation_id: organisation.organizationId,
              organisation_user_id: user.id,
            });
          }
          toast({
            title: result.response?.data.data,
            status: 'success',
          });
          setTimeout(() => {
            setValue('email', '');
            setEnteredEmails([]);
          }, 1000);
        }
        setUserInviteFormLoading(false);
      } else {
        const result = await sendRequest(enteredEmails);
        if (result?.response?.data?.status === 'OK') {
          if (flow === 'login') {
            setLoggedIn(true);
            setOfferUserInvites(false);
          } else {
            close();
          }
          toast({
            title: `The invitations were sent successfully`,
            status: 'success',
          });
          setTimeout(() => {
            setValue('email', '');
            setEnteredEmails([]);
          }, 1000);
        } else {
          toast({
            title: `There was an error inviting users`,
            status: 'error',
          });
        }
        setUserInviteFormLoading(false);
      }
    }
  };

  const updateEnteredEmails = () => {
    const values = getValues();
    const role = values.role.toLowerCase();

    const finalRole = role === 'owner' ? 'member admin owner' : role;

    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      const alreadyExists = enteredEmails.some(
        (enteredEmail: EnteredEmail) => enteredEmail.email === values.email
      );
      if (!alreadyExists) {
        const emails = [
          ...enteredEmails,
          { email: values.email, role: finalRole },
        ];
        setEnteredEmails(emails);
      }
      setValue('email', '');
      clearErrors('email');
    } else {
      trigger('email');
    }
  };

  const removeEmail = (email: string) => {
    const updatedEmails = enteredEmails.filter(
      (enteredEmail: EnteredEmail) => enteredEmail.email !== email
    );
    if (updatedEmails.length === 0) {
      trigger('email');
      setEnteredEmails(updatedEmails);
    } else {
      setEnteredEmails(updatedEmails);
    }
  };

  return (
    <FormWrapper onSubmit={handleSubmit(onSubmit)} name='invite_user'>
      {createAccountLoading ? (
        <Box
          w={['100%']}
          height={['100px']}
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <Spinner size='md' color={whiteTheme ? '#08101D' : '#7F8B9E'} />
        </Box>
      ) : (
        <Box
          w={['100%']}
          height={['auto']}
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          alignItems='center'
          gap={5}
        >
          <FormControl isInvalid={errors.email ? true : false}>
            <FormLabel
              color={whiteTheme ? '#08101D' : 'white'}
              fontFamily='Normal'
              fontSize='12px'
              margin='0px'
            >
              Email
            </FormLabel>
            <InputGroup color={whiteTheme ? '#08101D' : 'white'}>
              <InputLeftElement pointerEvents='none'>
                <AddUser stroke='currentColor' boxSize={[5]} />
              </InputLeftElement>
              <Input
                color={whiteTheme ? '#08101D' : '#7F8B9E'}
                id='email'
                type='email'
                placeholder='enter email to invite'
                borderBottom='1px solid #D6DBE4'
                variant='flushed'
                fontFamily='Normal'
                fontSize={['14px', '14px', '14px']}
                size='md'
                _placeholder={{ color: '#7F8B9E' }}
                errorBorderColor='#EA1A4C'
                {...register('email', {
                  required: {
                    value: enteredEmails.length === 0,
                    message: 'This is required',
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Entered value does not match email format',
                  },
                })}
              />

              <InputRightElement width='auto'>
                <Select
                  variant='unstyled'
                  size='sm'
                  id='role'
                  {...register('role')}
                >
                  <option>Owner</option>
                  <option>Member</option>
                </Select>

                <Button
                  textTransform='uppercase'
                  size='sm'
                  variant='outline'
                  color='#08101D'
                  background='white'
                  fontFamily='Metal'
                  fontSize={['14px', '14px', '14px']}
                  borderRadius='30px'
                  border='1px solid #D6DBE4'
                  _hover={{
                    bg: '#D3BB8A',
                    borderColor: '#D3BB8A',
                  }}
                  onClick={updateEnteredEmails}
                >
                  Add
                </Button>
              </InputRightElement>
            </InputGroup>

            <FormErrorMessage
              color='#EA1A4C'
              mt={['4px']}
              fontSize='13px'
              fontFamily='Normal'
            >
              {errors.email && errors.email.message}
            </FormErrorMessage>

            {enteredEmails.length > 0 ? (
              <Box
                display='flex'
                alignItems='center'
                gap={2}
                flexWrap='wrap'
                mt='20px'
              >
                {enteredEmails.map((enteredEmail: EnteredEmail) => (
                  <Box
                    key={enteredEmail.email}
                    bg={whiteTheme ? 'white' : '#08101D'}
                    display='flex'
                    flexDirection='column'
                    padding={['10px']}
                    borderRadius='10px'
                    gap={2}
                    maxWidth='100px'
                    position='relative'
                    boxShadow='rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
                  >
                    <IconButton
                      borderRadius='100%'
                      size='xs'
                      position='absolute'
                      bg='#1D2531'
                      color='white'
                      _hover={{
                        color: 'grey',
                        bg: '#1D2531',
                      }}
                      icon={<CloseIcon color='currentColor' boxSize={[2]} />}
                      aria-label='Remove Email'
                      top='-10px'
                      right='-10px'
                      onClick={() => removeEmail(enteredEmail.email)}
                    />

                    <Tag
                      fontSize={['12px']}
                      size='sm'
                      fontFamily='Normal'
                      bg='#1D2531'
                      color='white'
                      width='auto'
                      justifyContent='center'
                    >
                      {enteredEmail.role}
                    </Tag>
                    <Text
                      fontSize={['12px']}
                      fontFamily='Normal'
                      noOfLines={1}
                      color={whiteTheme ? '#08101D' : 'white'}
                    >
                      {enteredEmail.email}
                    </Text>
                  </Box>
                ))}
              </Box>
            ) : (
              <Box display='flex' justifyContent='center' mt='20px'>
                <Text
                  fontSize={['12px']}
                  fontFamily='Normal'
                  color={whiteTheme ? '#08101D' : '#7F8B9E'}
                >
                  Send multiple using the add button.
                </Text>
              </Box>
            )}
          </FormControl>

          <Button
            textTransform='uppercase'
            width={['80%', '80%', '300px']}
            variant='outline'
            color={whiteTheme ? '#08101D' : '#7F8B9E'}
            background={whiteTheme ? 'white' : '#1D2531'}
            border={
              whiteTheme
                ? '1px solid #D6DBE4'
                : '1px solid rgba(242, 243, 245, 0.14)'
            }
            fontFamily='Metal'
            fontSize={['14px', '14px', '15px']}
            borderRadius='30px'
            _hover={{
              bg: 'white',
              color: '#08101D',
            }}
            isLoading={isSubmitting}
            type='submit'
          >
            {btnText}
          </Button>

          {showSkip && (
            <Button
              fontSize={['11px']}
              variant='link'
              fontFamily='Normal'
              textAlign='center'
              mt={['15px']}
              onClick={() => {
                setValue('email', '');
                setEnteredEmails([]);
                setLoggedIn(true);
                setOfferUserInvites(false);
              }}
            >
              Skip
            </Button>
          )}
        </Box>
      )}
    </FormWrapper>
  );
}

export default UserInviteForm;
