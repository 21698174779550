import { Box } from '@chakra-ui/react';
import React from 'react';
// import { PluginList } from '../../Icons/PluginList';

function PluginsBarActions() {
  return (
    <Box
      width={['auto']}
      display='flex'
      justifyContent='space-between'
      alignItems='center'
    >
      {/* <PluginList
        stroke="white"
        _hover={{
          stroke: '#D3BB8A',
        }}
        transition="0.2s all"
        boxSize={[5]}
        cursor="pointer"
      /> */}
    </Box>
  );
}

export default PluginsBarActions;
