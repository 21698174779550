import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const AddUser = (props: IconProps) => (
  <Icon
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M12.9311 11.0609C13.8012 10.3914 14.3256 9.29275 14.1852 8.08153C14.0123 6.5884 12.8069 5.38878 11.3126 5.22404C9.34149 5.00592 7.672 6.54315 7.672 8.47135C7.672 9.52479 8.17088 10.4622 8.94587 11.0585C9.17326 11.2337 9.12918 11.5818 8.86582 11.6931C7.15225 12.4159 5.96191 14.0054 5.96191 15.85V17.9441C5.96191 18.1472 6.12666 18.3119 6.32969 18.3119H15.5507C15.7537 18.3119 15.9185 18.1472 15.9185 17.9441V15.85C15.9185 14.0042 14.7282 12.4148 13.0146 11.692C12.7524 11.5818 12.7048 11.2337 12.9311 11.0597V11.0609Z'
      strokeWidth='1.4'
      strokeMiterlimit='10'
    />
    <path
      d='M17.6853 14.6948H18.386C18.5879 14.6948 18.7503 14.5313 18.7503 14.3306V12.2539C18.7503 10.4231 17.5693 8.84527 15.8685 8.12829C15.6074 8.01807 15.5622 7.6735 15.7861 7.50063C16.6504 6.83586 17.169 5.74646 17.031 4.54452C16.8593 3.06298 15.6631 1.87381 14.1804 1.70907C12.9576 1.57449 11.8531 2.11861 11.1953 3.01426'
      strokeWidth='1.4'
      strokeMiterlimit='10'
      strokeLinecap='round'
    />
    <path
      d='M3.60547 1.68799V6.39828'
      strokeWidth='1.4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.96029 4.04321H1.25'
      strokeWidth='1.4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Icon>
);
