import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const RightArrow = (props: IconProps) => (
  <Icon
    width='5'
    height='7'
    viewBox='0 0 5 7'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M0.5 0.5L3.5 3.5L0.5 6.5' strokeLinecap='round' />
  </Icon>
);
