import { Component, ErrorInfo, ReactNode } from 'react';
import posthog from 'posthog-js';

interface ErrorBoundaryProps {
  children: ReactNode;
}

class ErrorBoundary extends Component<ErrorBoundaryProps> {
  componentDidCatch(error: Error, info: ErrorInfo) {
    posthog.capture('error_occurred', {
      error: error.toString(),
      info: info.componentStack,
    });
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
