import {
  Box,
  Checkbox,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import Toggle from '../../Icons/Toggle';
import { useCommunityStore } from '../../store/community';

function CommunityDropdown() {
  const { likes, setLikes, mostRecent, setMostRecent } = useCommunityStore();

  const handleFilterChange = (filterType: string) => {
    if (filterType === 'likes') setLikes(!likes);
    if (filterType === 'mostRecent') setMostRecent(!mostRecent);
  };

  const handleFilterClick = (filterType: string) => {
    handleFilterChange(filterType);
  };

  return (
    <Popover
      placement='bottom'
      returnFocusOnClose={false}
      closeOnBlur={true}
      isLazy
    >
      <PopoverTrigger>
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          bg='#1D2531'
          width='250px'
          padding='8px 16px 8px 16px'
          borderRadius='16px'
          cursor='pointer'
        >
          <Text fontFamily='Normal' fontSize='14px'>
            Sort By
          </Text>
          <Toggle stroke='white' boxSize={4} />
        </Box>
      </PopoverTrigger>
      <PopoverContent
        borderRadius='16px'
        background='#FFF'
        boxShadow='0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
        backdropFilter='blur(12px)'
        width={['250px']}
        height={['auto']}
      >
        <PopoverBody
          height={['inherit']}
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          padding={['20px']}
          gap={4}
        >
          <Box
            display='flex'
            gap={4}
            alignItems='center'
            cursor='pointer'
            p='5px'
            _hover={{ backgroundColor: '#D6DBE4', borderRadius: '16px' }}
          >
            <Checkbox
              isChecked={likes}
              onChange={() => {
                handleFilterClick('likes');
              }}
            >
              <Text fontSize='14px' color='#08101D' fontFamily='Normal'>
                Most Likes
              </Text>
            </Checkbox>
          </Box>
          <Box
            display='flex'
            gap={4}
            alignItems='center'
            cursor='pointer'
            p='5px'
            _hover={{ backgroundColor: '#D6DBE4', borderRadius: '16px' }}
          >
            <Checkbox
              isChecked={mostRecent}
              onChange={() => handleFilterClick('mostRecent')}
            >
              <Text fontSize='14px' color='#08101D' fontFamily='Normal'>
                Most Recent
              </Text>
            </Checkbox>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default CommunityDropdown;
