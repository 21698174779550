import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import CreateOrganisationForm from '../forms/CreateOrganisationForm';

interface CreateNewOrganisationModalProps {
  createNewOrganisationModalActive: boolean;
  setCreateNewOrganisationModalActive: (state: boolean) => void;
}

function CreateNewOrganisationModal({
  createNewOrganisationModalActive,
  setCreateNewOrganisationModalActive,
}: CreateNewOrganisationModalProps) {
  return (
    <Modal
      isOpen={createNewOrganisationModalActive}
      onClose={() => setCreateNewOrganisationModalActive(false)}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        minW={['500px']}
        height={['auto']}
        padding={['20px']}
        borderRadius='16px'
        boxShadow='0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
      >
        <CloseIcon
          position='absolute'
          boxSize={[4]}
          cursor='pointer'
          color='#D6DBE4'
          _hover={{ color: 'black' }}
          transition='0.2s all'
          right={['20px']}
          top={['20px']}
          onClick={() => setCreateNewOrganisationModalActive(false)}
        />
        <ModalBody
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          alignItems='center'
          gap={5}
        >
          <Text
            color='#08101D'
            fontFamily='Metal'
            fontSize={['35px']}
            lineHeight={['35px']}
            textAlign='center'
            textTransform='uppercase'
          >
            Create New Organisation
          </Text>

          <CreateOrganisationForm
            flow='postLogin'
            theme='white'
            close={() => setCreateNewOrganisationModalActive(false)}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default CreateNewOrganisationModal;
