import {
  Box,
  Fade,
  Text,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Process } from '../interfaces';
import { Processes } from '../Icons/Processes';
import TopActionBar from '../components/common/TopActionBar';
import ProcessesBarActions from '../components/barActions/ProcessesBarActions';
import ProcessItem from '../components/processesView/ProcessItem';
import { useUserStore } from '../store/user';
import configureAxios from '../axiosClient';
import React from 'react';

const axiosClient = configureAxios();

function ProcessesView() {
  const { organisation } = useUserStore();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [cool, setCool] = useState<boolean>(false);
  const [processes, setProcesses] = useState<Process[]>([]);

  useEffect(() => {
    const userOrgId = organisation.organizationId;

    axiosClient
      .get(`org/${userOrgId}/processes`)
      .then((res) => {
        const isStatusOk = res.data.status === 'OK';
        if (isStatusOk) {
          const processes = res.data.data;
          const sortedProcesses = processes.sort(
            (a: Process, b: Process) =>
              new Date(b.created).getTime() - new Date(a.created).getTime()
          );
          setProcesses(sortedProcesses);
        }
      })
      .catch((err: unknown) => {
        console.log(err);
      });
  }, [refresh]);

  const handleRefresh = () => {
    if (!cool) {
      setRefresh(!refresh);
      setCool(true);
      setTimeout(() => {
        setCool(false);
      }, 5000);
    }
  };

  return (
    <Fade in={true} transition={{ enter: { duration: 0.2 } }}>
      <TopActionBar
        icon={<Processes stroke='white' boxSize={[5]} />}
        title='Processes'
        showSearchBar={false}
        rightActions={
          <ProcessesBarActions refreshProcessesList={handleRefresh} />
        }
      />
      <Box p={['40px']}>
        <TableContainer>
          <Table variant='simple' size='lg'>
            <Thead height={['auto']}>
              <Tr>
                <Th borderBottom='2px solid #D3BB8A' padding={['16px']}>
                  <Text
                    fontFamily='Normal'
                    textTransform='capitalize'
                    fontSize={['15px']}
                    color='#D3BB8A'
                  >
                    Experience Name
                  </Text>
                </Th>
                <Th borderBottom='2px solid #D3BB8A' padding={['16px']}>
                  <Text
                    fontFamily='Normal'
                    textTransform='capitalize'
                    fontSize={['15px']}
                    lineHeight={['25px']}
                  >
                    Action
                  </Text>
                  <Text
                    fontFamily='Normal'
                    textTransform='capitalize'
                    fontSize={['15px']}
                    lineHeight={['25px']}
                  >
                    Type
                  </Text>
                </Th>
                <Th borderBottom='2px solid #D3BB8A' padding={['16px']}>
                  <Text
                    fontFamily='Normal'
                    textTransform='capitalize'
                    fontSize={['15px']}
                    lineHeight={['25px']}
                  >
                    Status
                  </Text>
                </Th>
                <Th borderBottom='2px solid #D3BB8A' padding={['16px']}>
                  <Text
                    fontFamily='Normal'
                    textTransform='capitalize'
                    fontSize={['15px']}
                  >
                    Date
                  </Text>
                </Th>
                <Th borderBottom='2px solid #D3BB8A' padding={['16px']}>
                  <Text
                    fontFamily='Normal'
                    textTransform='capitalize'
                    fontSize={['15px']}
                  >
                    Time Elapsed
                  </Text>
                </Th>
                <Th borderBottom='2px solid #D3BB8A' padding={['16px']}>
                  <Text
                    fontFamily='Normal'
                    textTransform='capitalize'
                    fontSize={['15px']}
                    lineHeight={['25px']}
                  >
                    User
                  </Text>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {processes.map((process: Process) => (
                <ProcessItem key={process.id} process={process} />
              ))}
            </Tbody>
          </Table>

          {processes.length === 0 && (
            <Box
              height={['60px']}
              width={['100%']}
              display='flex'
              justifyContent='center'
              alignItems='center'
              fontFamily='Normal'
              fontSize={['14px']}
            >
              No Processes Yet
            </Box>
          )}
        </TableContainer>
      </Box>
    </Fade>
  );
}

export default ProcessesView;
