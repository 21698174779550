import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const CopyText = (props: IconProps) => (
  <Icon
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M9.21867 4.87183H4.00763C3.63019 4.87183 3.32422 5.1778 3.32422 5.55524V12.8165C3.32422 13.194 3.63019 13.4999 4.00763 13.4999H9.21867C9.59611 13.4999 9.90208 13.194 9.90208 12.8165V5.55524C9.90208 5.1778 9.59611 4.87183 9.21867 4.87183Z'
      stroke='#08101D'
      strokeMiterlimit='10'
      strokeLinecap='round'
    />
    <path
      d='M6.09766 3.18341C6.09766 2.80653 6.40419 2.5 6.78107 2.5H11.9921C12.369 2.5 12.6755 2.80653 12.6755 3.18341V10.4497C12.6755 10.8266 12.369 11.1331 11.9921 11.1331H11.6001'
      stroke='#08101D'
      strokeMiterlimit='10'
      strokeLinecap='round'
    />
  </Icon>
);
