import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const Iframe = (props: IconProps) => (
  <Icon
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M6.56074 2.92529H11.6001C12.3521 2.92529 12.9584 3.53159 12.9584 4.28356V9.25206C12.9584 10.004 12.3521 10.6103 11.6001 10.6103H4.2694C3.51743 10.6103 2.91113 10.004 2.91113 9.25206V6.67726'
      stroke='#08101D'
      strokeMiterlimit='10'
      strokeLinecap='round'
    />
    <path
      d='M2.97372 1L2.96191 4.8622'
      stroke='#08101D'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.89931 2.9371L1.03711 2.92529'
      stroke='#08101D'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.91113 9.25586H12.9623'
      stroke='#08101D'
      strokeWidth='0.6'
      strokeMiterlimit='10'
      strokeLinecap='round'
    />
    <path
      d='M9.90304 11.9331H5.97391C5.6782 11.9331 5.43848 12.1719 5.43848 12.4666C5.43848 12.7612 5.6782 13 5.97391 13H9.90304C10.1988 13 10.4385 12.7612 10.4385 12.4666C10.4385 12.1719 10.1988 11.9331 9.90304 11.9331Z'
      stroke='#08101D'
      strokeWidth='0.6'
      strokeMiterlimit='10'
      strokeLinecap='round'
    />
    <path
      d='M6.41122 7.53542L4.89941 6.36219L6.41122 5.18896'
      stroke='#08101D'
      strokeWidth='0.6'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.37207 7.99198L8.47837 4.86206'
      stroke='#08101D'
      strokeWidth='0.6'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.57227 7.53542L11.088 6.36219L9.57227 5.18896'
      stroke='#08101D'
      strokeWidth='0.6'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Icon>
);
