import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const Open = (props: IconProps) => (
  <Icon
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M5.85958 3.75761L12.242 3.75761L12.242 10.14'
      stroke='#08101D'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.75691 12.2431L12.2422 3.75781'
      stroke='#08101D'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Icon>
);
