import { AlertProps, createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { alertAnatomy } from '@chakra-ui/anatomy';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(alertAnatomy.keys);

const baseStyle = definePartsStyle((props: AlertProps) => {
  const { status } = props;

  const successBase = status === 'success' && {
    container: {
      background: 'white',
      color: '#2F855A',
    },
    title: {
      color: '#2F855A',
    },
    description: {
      color: '#2F855A',
    },
    icon: {
      color: '#2F855A',
    },
  };

  const warningBase = status === 'warning' && {
    container: {
      background: 'white',
      color: '#C05621',
    },
    title: {
      color: '#C05621',
    },
    description: {
      color: '#C05621',
    },
    icon: {
      color: '#C05621',
    },
  };

  const errorBase = status === 'error' && {
    container: {
      background: 'white',
      color: '#EA1A4C',
    },
    title: {
      color: '#EA1A4C',
    },
    description: {
      color: '#EA1A4C',
    },
    icon: {
      color: '#EA1A4C',
    },
  };

  const infoBase = status === 'info' && {
    container: {
      background: 'white',
      color: 'blue.400',
    },
    title: {
      color: 'blue.400',
    },
    description: {
      color: 'blue.400',
    },
    icon: {
      color: 'blue.400',
    },
  };

  return {
    ...successBase,
    ...warningBase,
    ...errorBase,
    ...infoBase,
  };
});

export const AlertTheme = defineMultiStyleConfig({ baseStyle });
