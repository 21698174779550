import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const Experiences = (props: IconProps) => (
  <Icon
    width='22'
    height='20'
    viewBox='0 0 22 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M10.9967 8.07727C5.8077 8.07727 1.60059 6.41558 1.60059 4.37622V14.8374C1.60059 16.9976 5.8077 18.7499 10.9967 18.7499C16.1858 18.7499 20.3929 16.9976 20.3929 14.8374V4.37622C20.3929 6.42313 16.1858 8.07727 10.9967 8.07727Z'
      strokeWidth='1.4'
      strokeMiterlimit='10'
    />
    <path
      d='M1.60814 4.21001C1.60814 4.26288 1.60059 4.31575 1.60059 4.36863C1.60059 5.76596 3.57196 6.98202 6.47238 7.61649V1.74768C6.47238 1.43044 6.17025 1.19629 5.86813 1.27183C3.40579 1.90629 1.7441 2.97884 1.60814 4.21001Z'
      strokeWidth='1.4'
      strokeMiterlimit='10'
    />
    <path
      d='M20.3926 4.20999C20.3926 4.26287 20.4001 4.31574 20.4001 4.36861C20.4001 5.76595 18.4287 6.98201 15.5283 7.61647V4.20999V1.74011C15.5283 1.42287 15.8304 1.18873 16.1326 1.26426C18.5949 1.89872 20.2566 2.97127 20.3926 4.20244V4.20999Z'
      strokeWidth='1.4'
      strokeMiterlimit='10'
    />
    <path
      d='M1.60059 13.5459L4.92398 10.6379C5.33185 10.3434 5.73972 10.2905 6.14004 10.6681L12.817 18.75'
      strokeWidth='1.4'
      strokeMiterlimit='10'
    />
    <path
      d='M18.6251 17.1185L15.4603 13.3873C15.1128 12.9794 14.4859 12.9567 14.1158 13.3419L11.0039 16.5445'
      strokeWidth='1.4'
      strokeMiterlimit='10'
    />
    <path
      d='M11.2841 12.1409C11.914 12.1409 12.4246 11.6303 12.4246 11.0004C12.4246 10.3705 11.914 9.85986 11.2841 9.85986C10.6542 9.85986 10.1436 10.3705 10.1436 11.0004C10.1436 11.6303 10.6542 12.1409 11.2841 12.1409Z'
      strokeWidth='1.4'
      strokeMiterlimit='10'
    />
  </Icon>
);
