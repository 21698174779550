import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import React from 'react';
import ReactPlayer from 'react-player';
import TutorialVideo from '../../assets/videos/Tutorial.mp4';
import { useAuthStore } from '../../store/auth';

interface GettingStartedModalProps {
  gettingStartedActive: boolean;
  setGettingStartedActive: (state: boolean) => void;
}

function GettingStartedModal({
  gettingStartedActive,
  setGettingStartedActive,
}: GettingStartedModalProps) {
  const { setFirstTime } = useAuthStore();

  const close = () => {
    setFirstTime(false);
    setGettingStartedActive(false);
  };

  return (
    <Modal
      isOpen={gettingStartedActive}
      onClose={close}
      isCentered
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent
        minW={['600px']}
        height={['auto']}
        margin='auto'
        maxHeight='80vh'
        overflow='auto'
        padding={['20px']}
        borderRadius='16px'
        boxShadow='0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
      >
        <CloseIcon
          position='absolute'
          boxSize={[4]}
          cursor='pointer'
          color='#D6DBE4'
          _hover={{ color: 'black' }}
          transition='0.2s all'
          right={['20px']}
          top={['20px']}
          onClick={close}
        />
        <ModalBody
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          alignItems='center'
          gap={5}
          pl='0px'
          pr='0px'
        >
          <Text
            color='#08101D'
            fontFamily='Metal'
            fontSize={['35px']}
            lineHeight={['35px']}
            textAlign='center'
            textTransform='uppercase'
          >
            WELCOME !
          </Text>

          <Box>
            <Text
              fontFamily='Normal'
              fontSize='14px'
              color='rgba(8, 16, 29, 1)'
              textAlign='center'
            >
              Start working with Creator Tools by downloading the plugin.
            </Text>
            <Text
              fontFamily='Normal'
              fontSize='14px'
              color='rgba(8, 16, 29, 1)'
              textAlign='center'
            >
              Then follow setup instructions in Unreal Engine.
            </Text>
          </Box>

          <Box width='100%' height='100%' borderRadius='10px' overflow='hidden'>
            <ReactPlayer
              id='instructions-screen-video'
              height='inherit'
              width='100%'
              loop={true}
              muted
              controls={true}
              playing={true}
              url={TutorialVideo}
              playsinline
            />
          </Box>

          <Box
            display='flex'
            flexDirection='column'
            justifyContent='space-between'
            alignItems='center'
          >
            <Button
              fontSize={['11px']}
              variant='link'
              fontFamily='Normal'
              textAlign='center'
              onClick={close}
            >
              Close
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default GettingStartedModal;
