import { UNSUBSCRIBE_REASON } from './enums';

export const UNSUBSCRIBE_REASON_OPTIONS = [
  {
    key: UNSUBSCRIBE_REASON.TOO_MANY_EMAILS,
    value: 'Too many emails',
  },
  {
    key: UNSUBSCRIBE_REASON.NOT_RELEVANT,
    value: 'Content is not relevant to me',
  },
  {
    key: UNSUBSCRIBE_REASON.NEVER_SIGNED_UP,
    value: 'I never signed up for this',
  },
  {
    key: UNSUBSCRIBE_REASON.OTHER,
    value: 'Other (please specify)',
  },
];
