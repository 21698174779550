import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import { useCommunityStore } from '../../store/community';
import { Globe } from '../../Icons/Globe';
import UpdateCommunityProfileForm from '../forms/UpdateCommunityProfileForm';

function UpdateCommunityProfileModal() {
  const {
    updateCommunityProfileModalActive,
    setUpdateCommunityProfileModalActive,
  } = useCommunityStore();
  return (
    <Modal
      isOpen={updateCommunityProfileModalActive}
      onClose={() => setUpdateCommunityProfileModalActive(false)}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        minW={['500px']}
        height={['auto']}
        padding={['20px']}
        borderRadius='16px'
        boxShadow='0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
      >
        <CloseIcon
          position='absolute'
          boxSize={[4]}
          cursor='pointer'
          color='#D6DBE4'
          _hover={{ color: 'black' }}
          transition='0.2s all'
          right={['20px']}
          top={['20px']}
          onClick={() => setUpdateCommunityProfileModalActive(false)}
        />
        <ModalBody
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          alignItems='center'
          gap={5}
        >
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            gap={2}
          >
            <Globe boxSize={6} />
            <Text
              color='#08101D'
              fontFamily='Metal'
              fontSize={['36px']}
              lineHeight={['36px']}
              textAlign='center'
              textTransform='uppercase'
            >
              Community Profile
            </Text>
          </Box>

          <UpdateCommunityProfileForm />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default UpdateCommunityProfileModal;
