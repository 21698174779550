import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const TwitterX = (props: IconProps) => (
  <Icon
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M8.15281 5.92812L13.2525 0H12.0439L7.616 5.14719L4.07925 0H0L5.34822 7.78356L0 14H1.20859L5.88481 8.56439L9.61975 14H13.699L8.15248 5.92812H8.15281ZM6.49753 7.85203L5.95558 7.077L1.64402 0.909781H3.50033L6.97966 5.887L7.5215 6.66203L12.0445 13.1316H10.1884L6.49753 7.85236V7.85203Z'
      fill='#08101D'
    />
  </Icon>
);
