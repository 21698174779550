import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const Mail = (props: IconProps) => (
  <Icon
    width='14'
    height='12'
    viewBox='0 0 14 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M13.6693 2.00033C13.6693 1.26699 13.0693 0.666992 12.3359 0.666992H1.66927C0.935938 0.666992 0.335938 1.26699 0.335938 2.00033V10.0003C0.335938 10.7337 0.935938 11.3337 1.66927 11.3337H12.3359C13.0693 11.3337 13.6693 10.7337 13.6693 10.0003V2.00033ZM12.3359 2.00033L7.0026 5.33366L1.66927 2.00033H12.3359ZM12.3359 10.0003H1.66927V3.33366L7.0026 6.66699L12.3359 3.33366V10.0003Z'
      fill='#08101D'
    />
  </Icon>
);
