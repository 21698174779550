import { Box, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import React, { useState } from 'react';
import { MagnifyingGlass } from '../../Icons/MagnifyingGlass';
import CommunityDropdown from './CommunityDropdown';
import { useCommunityStore } from '../../store/community';
import CommunityTagsDropdown from './CommunityTagsDropdown';

const CommunityFilters = () => {
  const { setQueryName } = useCommunityStore();

  const [searchBarText, setSearchBarText] = useState('');

  const onSearchBarTextChange = (expName: string) => {
    setSearchBarText(expName);
    setQueryName(expName);
  };

  return (
    <Box display='flex' gap={5} width={['100%']}>
      <InputGroup
        borderRadius='35px'
        border='1px solid rgba(242, 243, 245, 0.14)'
        background='#1D2531'
        width={['300px']}
      >
        <Input
          value={searchBarText}
          borderRadius='35px'
          placeholder='Search Community Experiences...'
          _placeholder={{ textTransform: 'capitalize' }}
          fontFamily='Normal'
          fontSize={['12px']}
          onChange={(e) => onSearchBarTextChange(e.target.value)}
        />
        <InputRightElement>
          <MagnifyingGlass boxSize={[5]} />
        </InputRightElement>
      </InputGroup>
      <CommunityDropdown />
      <CommunityTagsDropdown />
    </Box>
  );
};

export default CommunityFilters;
