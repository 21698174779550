import { Box, Button } from '@chakra-ui/react';
import { AddUser } from '../../Icons/AddUser';
import React from 'react';

interface BillingBarActionsProps {
  openUserInvitesModal: () => void;
}

function BillingBarActions({ openUserInvitesModal }: BillingBarActionsProps) {
  return (
    <Box
      width={['auto']}
      display='flex'
      justifyContent='space-between'
      alignItems='center'
    >
      <Button
        leftIcon={<AddUser stroke='#08101D' boxSize={[4]} />}
        textTransform='uppercase'
        minW={['100px', '100px', 'auto']}
        variant='outline'
        color='#08101D'
        background='white'
        border='1px solid rgba(242, 243, 245, 0.14)'
        fontFamily='Metal'
        fontSize={['14px', '14px', '14px']}
        borderRadius='30px'
        _hover={{
          bg: '#D3BB8A',
        }}
        onClick={openUserInvitesModal}
      >
        Invite Members
      </Button>
    </Box>
  );
}

export default BillingBarActions;
