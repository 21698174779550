import {
  CommunityExperienceEvent,
  CommunityExperienceItem,
} from './@types/analytics/community_experience.types';
import {
  ExperienceEvent,
  ExperienceItem,
} from './@types/analytics/experience.types';
import {
  OrganisationEvent,
  OrganisationItem,
} from './@types/analytics/organisation.types';
import { PluginEvent, PluginItem } from './@types/analytics/plugin.types';
import {
  SubscriptionEvent,
  SubscriptionItem,
} from './@types/analytics/subscription.types';
import { User, UserEvents } from './@types/analytics/user.types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: any[];
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function pushToAnalytics(object: any) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(object);
}

export const dispatchShareToAnalytics = (
  content_type: string,
  item_name: string
) => {
  pushToAnalytics({
    event: 'share',
    content_type: content_type,
    item_name: item_name,
  });
};

export const dispatchUserToAnalytics = (event: UserEvents, user: User) => {
  pushToAnalytics({
    ...user,
    event,
  });
};

export const dispatchExperienceToAnalytics = (
  event: ExperienceEvent,
  experience: ExperienceItem
) => {
  pushToAnalytics({
    ...experience,
    event: event,
  });
};

export const dispatchCommunityExperienceToAnalytics = (
  event: ExperienceEvent,
  experience: ExperienceItem
) => {
  pushToAnalytics({
    ...experience,
    event: event,
  });
};

export const dispatchCommunityExperienceLikeToAnalytics = (
  event: CommunityExperienceEvent,
  experience: CommunityExperienceItem
) => {
  pushToAnalytics({
    ...experience,
    event: event,
  });
};

export const dispatchSubscriptionToAnalytics = (
  event: SubscriptionEvent,
  subscription: SubscriptionItem
) => {
  pushToAnalytics({
    ...subscription,
    event: event,
  });
};

export const dispatchOrganisationToAnalytics = (
  event: OrganisationEvent,
  organisation: OrganisationItem
) => {
  pushToAnalytics({
    ...organisation,
    event: event,
  });
};

export const dispatchPluginEventToAnalytics = (
  event: PluginEvent,
  plugin: PluginItem
) => {
  pushToAnalytics({
    ...plugin,
    event,
  });
};

export const dispatchUnsubscribeEmailToAnalytics = (
  event: string,
  user: string
) => {
  pushToAnalytics({
    event,
    user,
  });
};
