import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { CommunityExperienceListItem } from '../interfaces';
import configureAxios from '../axiosClient';

const axiosClient = configureAxios();

const defaultCommunityListItem: CommunityExperienceListItem = {
  id: '',
  name: '',
  description: '',
  like_count: 0,
  community_shared_at: '',
  clone_count: 1,
  community_url: '',
  organisation_name: '',
  tags: [],
  organisation_id: '',
  is_liked: false,
  is_owner: false,
};

interface CommunityState {
  updateCommunityProfileModalActive: boolean;
  listUnListCommunityExperienceModalActive: boolean;
  listedUnlistedCommunityExperienceProcessing: boolean;
  unlistingProcessingActive: boolean;
  communityExperiencesLoaded: boolean;
  queryName: string;
  likes: boolean;
  mostRecent: boolean;
  tags: string[];
  totalCommunityExperiencesPages: number;
  isFeaturedExperiences: boolean;
  isExperienceDelisted: boolean;
  communityExperiences: CommunityExperienceListItem[];
  featuredExperiences: CommunityExperienceListItem[];
  listedUnlistedCommunityExperience: CommunityExperienceListItem;
  setUpdateCommunityProfileModalActive: (state: boolean) => void;
  setListUnListCommunityExperienceModalActive: (state: boolean) => void;
  setCommunityExperiences: (
    communityExperiences: CommunityExperienceListItem[]
  ) => void;
  setListedUnlistedCommunityExperienceProcessing: (state: boolean) => void;
  setListedUnlistedCommunityExperience: (
    state: CommunityExperienceListItem
  ) => void;
  resetListedUnlistedCommunityExperience: () => void;
  setUnlistingProcessActive: (state: boolean) => void;
  fetchCommunityExperiences: (
    size: string,
    page: number,
    isFeaturedExperiences: boolean
  ) => Promise<CommunityExperienceListItem[]>;
  setTotalCommunityExperiencesPages: (state: number) => void;
  setIsExperienceDelisted: (state: boolean) => void;
  setCommunityExperiencesLoaded: (state: boolean) => void;
  ManageCommunityExperiences: (commExpIdToManage: string) => void;
  setQueryName: (name: string) => void;
  setTags: (tags: string[]) => void;
  setLikes: (likes: boolean) => void;
  setMostRecent: (mostRecent: boolean) => void;
  setIsFeaturedExperience: (isFeaturedExperiences: boolean) => void;
  resetQuery: () => void;
}

export const useCommunityStore = create<CommunityState>()(
  devtools(
    persist(
      (set) => ({
        updateCommunityProfileModalActive: false,
        listUnListCommunityExperienceModalActive: false,
        listedUnlistedCommunityExperienceProcessing: false,
        unlistingProcessingActive: false,
        communityExperiencesLoaded: false,
        queryName: '',
        likes: false,
        mostRecent: false,
        isFeaturedExperiences: false,
        isExperienceDelisted: false,
        tags: [],
        communityExperiences: [],
        featuredExperiences: [],
        totalCommunityExperiencesPages: 0,
        listedUnlistedCommunityExperience: defaultCommunityListItem,
        setTotalCommunityExperiencesPages: (totalPages: number) => {
          set(() => ({ totalCommunityExperiencesPages: totalPages }));
        },
        setQueryName: (name: string) => {
          set(() => ({ queryName: name }));
        },

        setTags: (tags: string[]) => {
          set(() => ({ tags }));
        },

        setLikes: (likes: boolean) => {
          set(() => ({ likes }));
        },

        setMostRecent: (mostRecent: boolean) => {
          set(() => ({ mostRecent }));
        },
        setIsFeaturedExperience: (state: boolean) => {
          set(() => ({ isFeaturedExperiences: state }));
        },
        setUpdateCommunityProfileModalActive: (state: boolean) => {
          set(() => ({ updateCommunityProfileModalActive: state }));
        },
        setListUnListCommunityExperienceModalActive: (state: boolean) => {
          set(() => ({ listUnListCommunityExperienceModalActive: state }));
        },
        setIsExperienceDelisted: (state: boolean) => {
          set(() => ({ isExperienceDelisted: state }));
        },
        setCommunityExperiences: (
          communityExperiences: CommunityExperienceListItem[]
        ) => {
          set(() => ({ communityExperiences: communityExperiences }));
        },
        setListedUnlistedCommunityExperienceProcessing: (state: boolean) => {
          set(() => ({ listedUnlistedCommunityExperienceProcessing: state }));
        },
        setListedUnlistedCommunityExperience: (
          state: CommunityExperienceListItem
        ) => {
          set(() => ({ listedUnlistedCommunityExperience: state }));
        },
        setUnlistingProcessActive: (state: boolean) => {
          set(() => ({ unlistingProcessingActive: state }));
        },
        resetListedUnlistedCommunityExperience: () => {
          set(() => ({
            listedUnlistedCommunityExperience: defaultCommunityListItem,
          }));
        },
        setCommunityExperiencesLoaded: (state: boolean) => {
          set(() => ({ communityExperiencesLoaded: state }));
        },
        ManageCommunityExperiences: (commExpIdToManage: string) =>
          set((state) => ({
            communityExperiences: state.communityExperiences.filter(
              (communityExperiencesListItem: CommunityExperienceListItem) =>
                communityExperiencesListItem.id !== commExpIdToManage
            ),
          })),
        fetchCommunityExperiences: async (
          size: string,
          page: number,
          isFeaturedExperiences: boolean
        ) => {
          set(() => ({ communityExperiencesLoaded: true }));

          const { queryName, tags, likes, mostRecent } =
            useCommunityStore.getState();

          let queryParams = `page=${page}&size=${size}`;

          if (queryName) {
            queryParams += `&query=${encodeURIComponent(queryName)}`;
          }

          if (tags.length > 0) {
            tags.forEach((tag: string) => {
              queryParams += `&tags=${encodeURIComponent(tag)}`;
            });
          }

          if (likes) {
            queryParams += `&likes=true`;
          }

          if (mostRecent) {
            queryParams += `&most_recent=true`;
          }

          try {
            const res = await axiosClient.get(
              `experience/community?${queryParams}&featured=${isFeaturedExperiences}`
            );
            const communityExperiencesData = res.data.data.experiences;
            const totalPages = res.data.data.total_pages;
            if (!isFeaturedExperiences) {
              set(() => ({
                communityExperiences: communityExperiencesData,
                communityExperiencesLoaded: false,
              }));
            }
            if (isFeaturedExperiences) {
              set(() => ({
                featuredExperiences: communityExperiencesData,
              }));
            }
            set(() => ({
              totalCommunityExperiencesPages: totalPages,
            }));
            return communityExperiencesData;
          } catch (err) {
            console.log(err);
            set(() => ({
              communityExperiencesLoaded: false,
            }));
          }
        },
        resetQuery: () => {
          set(() => ({
            queryName: '',
            tags: [],
            likes: false,
            mostRecent: false,
          }));
        },
      }),
      {
        name: 'community-storage',
      }
    )
  )
);
