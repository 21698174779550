import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const Eye = (props: IconProps) => (
  <Icon
    width='22'
    height='14'
    viewBox='0 0 22 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M1.83737 7.79651C2.46251 8.55249 3.77095 9.81732 4.6287 10.4715C8.6267 13.51 13.3589 13.4737 17.3787 10.4715C18.3891 9.71555 19.5594 8.56703 20.17 7.79651C20.5916 7.26586 20.628 6.54622 20.17 5.95743C19.574 5.19417 18.4182 4.05293 17.4078 3.35509C13.2862 0.505608 8.83751 0.629183 4.6287 3.34782C3.74914 3.91481 2.48432 5.13602 1.84464 5.95016C1.40849 6.50988 1.36488 7.22225 1.83737 7.79651Z'
      strokeWidth='1.4'
      strokeMiterlimit='10'
    />
    <path
      d='M11.0039 10.8642C13.1396 10.8642 14.871 9.13281 14.871 6.99704C14.871 4.86127 13.1396 3.12988 11.0039 3.12988C8.8681 3.12988 7.13672 4.86127 7.13672 6.99704C7.13672 9.13281 8.8681 10.8642 11.0039 10.8642Z'
      strokeWidth='1.4'
      strokeMiterlimit='10'
    />
  </Icon>
);
