import { Box, Image, Input, Text } from '@chakra-ui/react';
import DummyExperienceImage from '../../assets/image/DummyExperienceImage.jpeg';
import React from 'react';
import { useExperiencesStore } from '../../store/experiences';

interface PublishExperienceModalProps {
  publishExperienceModalActive: boolean;
  selectedExperienceName?: string;
  setSelectedExperienceName?: (state: string) => void;
}

const PublishExperienceForm = ({
  setSelectedExperienceName,
}: PublishExperienceModalProps) => {
  const { selectedExperience } = useExperiencesStore();
  return (
    <Box display='flex' flexDir='column' justifyContent='center'>
      <Text
        fontFamily='Metal'
        fontSize='36px'
        fontWeight='500'
        textTransform='uppercase'
        textAlign='center'
        lineHeight='36px'
        mt='20px'
      >
        Share With Community
      </Text>
      <Box
        height={['220px']}
        width={['full']}
        borderRadius='16px'
        bg='#1D2531'
        position='relative'
        margin='0 auto'
        mt={['15px']}
      >
        <Image
          src={
            selectedExperience.preview_url
              ? selectedExperience.preview_url.replace(
                  'index.html',
                  'browser_icon.jpg'
                )
              : DummyExperienceImage
          }
          objectFit='cover'
          width={['100%']}
          height={['140px']}
          borderTopRadius='16px'
          cursor='pointer'
        />
        <Box
          width={['100%']}
          height={['80px']}
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='flex-start'
          padding={['0px 20px']}
        >
          <Text fontFamily='Normal' color='white' fontSize={['15px']}>
            {selectedExperience?.name}
          </Text>

          {selectedExperience.published_url &&
          selectedExperience.published_url?.length > 0 ? (
            <Box
              width='100%'
              display='flex'
              justifyContent='space-between'
              alignItems='center'
            >
              <Text
                fontFamily='Normal'
                fontSize={['11px']}
                color='#4DFF7B'
                maxW='100%'
                noOfLines={1}
              >
                {selectedExperience?.published_url}
              </Text>
            </Box>
          ) : (
            <Text fontFamily='Normal' fontSize={['11px']}>
              Not Published
            </Text>
          )}
        </Box>
      </Box>
      <Box
        display='flex'
        justifyContent='center'
        flexDirection='column'
        mt='20px'
        width={['100%']}
      >
        <Input
          color={'#08101D'}
          id='experience-name'
          required
          type='text'
          defaultValue={selectedExperience.name}
          placeholder='Experience Name'
          borderBottom='1px solid #D6DBE4'
          variant='flushed'
          fontFamily='Normal'
          fontSize={['14px', '14px', '14px']}
          size='md'
          _placeholder={{ color: '#7F8B9E' }}
          errorBorderColor='#EA1A4C'
          mb='5px'
          onChange={(e) => {
            setSelectedExperienceName &&
              setSelectedExperienceName(e.target.value);
          }}
        />
      </Box>
    </Box>
  );
};

export default PublishExperienceForm;
