import { Box, Button, Text } from '@chakra-ui/react';
import { getNextBillDate } from '../../utils/helper';
import { usePlanStore } from '../../store/plan';
import React from 'react';

function CurrentPlanCard() {
  const { currentPlan, setShowCancelPlans } = usePlanStore();

  return (
    <Box
      borderRadius='30px'
      border='1px solid rgba(242, 243, 245, 0.14)'
      width={['100%']}
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
      alignItems='flex-start'
    >
      <Box
        padding={['12px 24px']}
        width={['100%']}
        display='flex'
        justifyContent='center'
        alignItems='center'
        background='#F2F3F524'
        borderTopRadius='30px'
      >
        <Text fontFamily='Metal' textTransform='uppercase' fontSize={['14px']}>
          Current plan
        </Text>
      </Box>

      {/* {loadingPlan ? (
        <Box padding="30px" width="100%">
          <SkeletonText startColor="#29303C" endColor="transparent" noOfLines={1} spacing="4" skeletonHeight="5" />
          <SkeletonText
            startColor="#29303C"
            endColor="transparent"
            mt="20px"
            noOfLines={4}
            spacing="2"
            skeletonHeight="2"
          />
        </Box>
      ) : ( */}
      <Box
        p={['30px']}
        width={['inherit']}
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='flex-start'
        gap={5}
      >
        <Box display='flex' flexDirection='column'>
          <Text fontFamily='Metal' fontSize={['35px']} lineHeight={['35px']}>
            {currentPlan?.name?.split('_')[0]}
          </Text>
          <Box display='flex' alignItems='flex-end' gap={2}>
            <Text
              fontFamily='Metal'
              textTransform='capitalize'
              fontSize={['25px']}
              lineHeight={['25px']}
              color='white'
            >
              {`$${currentPlan.standingCharge}.00`}
            </Text>
            <Text
              fontFamily='Metal'
              textTransform='lowercase'
              fontSize={['15px']}
              color='white'
            >
              / month
            </Text>
          </Box>
        </Box>

        {!currentPlan.name.includes('Free') && (
          <Box display='flex' flexDirection='column'>
            <Text
              color='#D6DBE4'
              fontFamily='Normal'
              fontSize={['12px']}
              textTransform='capitalize'
            >
              {`Billed ${currentPlan?.name?.split('_')[1]}`}
            </Text>
            <Box display='flex' alignItems='center' gap={2}>
              <Text color='#D6DBE4' fontFamily='Normal' fontSize={['12px']}>
                Next bill on:
              </Text>
              <Text color='#D6DBE4' fontFamily='Normal' fontSize={['12px']}>
                {getNextBillDate(
                  currentPlan.startDate,
                  currentPlan?.name?.split('_')[1]
                )}
              </Text>
            </Box>
          </Box>
        )}

        {!currentPlan.name.includes('Free') && (
          <Button
            variant='link'
            textAlign='center'
            color='white'
            border='none'
            fontFamily='Normal'
            fontSize={['12px', '12px', '12px']}
            borderRadius='30px'
            onClick={() => setShowCancelPlans(true)}
          >
            cancel plan
          </Button>
        )}
      </Box>
      {/* )} */}
    </Box>
  );
}

export default CurrentPlanCard;
