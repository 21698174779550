import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import UserInviteForm from '../forms/UserInviteForm';
import React from 'react';
import { CloseIcon } from '@chakra-ui/icons';

interface UserInvitesModalProps {
  userInvitesModalActive: boolean;
  setUserInvitesModalActive: (state: boolean) => void;
}

function UserInvitesModal({
  userInvitesModalActive,
  setUserInvitesModalActive,
}: UserInvitesModalProps) {
  return (
    <Modal
      isOpen={userInvitesModalActive}
      onClose={() => setUserInvitesModalActive(false)}
      isCentered
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent
        minW={['500px']}
        height={['auto']}
        margin='auto'
        maxHeight='80vh'
        overflow='auto'
        padding={['20px']}
        borderRadius='16px'
        boxShadow='0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
      >
        <CloseIcon
          position='absolute'
          boxSize={[4]}
          cursor='pointer'
          color='#D6DBE4'
          _hover={{ color: 'black' }}
          transition='0.2s all'
          right={['20px']}
          top={['20px']}
          onClick={() => setUserInvitesModalActive(false)}
        />
        <ModalBody
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          alignItems='center'
          gap={5}
        >
          <Text
            color='#08101D'
            fontFamily='Metal'
            fontSize={['35px']}
            lineHeight={['35px']}
            textAlign='center'
            textTransform='uppercase'
          >
            Invite Your Team
          </Text>

          <UserInviteForm
            flow='postLogin'
            theme='white'
            showSkip={false}
            btnText='Invite'
            close={() => setUserInvitesModalActive(false)}
          />

          <Box
            display='flex'
            flexDirection='column'
            justifyContent='space-between'
            alignItems='center'
          >
            <Button
              fontSize={['11px']}
              variant='link'
              fontFamily='Normal'
              textAlign='center'
              mt={['15px']}
              onClick={() => setUserInvitesModalActive(false)}
            >
              Cancel
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default UserInvitesModal;
