import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CloseButton,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import FormWrapper from '../common/FormWrapper';
import { useForm, useFieldArray } from 'react-hook-form';
import { Linkedin } from '../../Icons/Linkedin';
import { Instagram } from '../../Icons/Instagram';
import Toggle from '../../Icons/Toggle';
import { Mail } from '../../Icons/Mail';
import { TwitterX } from '../../Icons/TwitterX';
import { Copy } from '../../Icons/Copy';
import configureAxios from '../../axiosClient';
import { useUserStore } from '../../store/user';
import { useCommunityStore } from '../../store/community';

type SocialLink = {
  platform: string;
  link: string;
};

type UpdateCommunityProfileFormValues = {
  organizationBio: string;
  socialLinks: SocialLink[];
};

function UpdateCommunityProfileForm() {
  const { organisation, getOrganizations } = useUserStore();
  const { setUpdateCommunityProfileModalActive } = useCommunityStore();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<UpdateCommunityProfileFormValues>({
    defaultValues: {
      organizationBio: organisation.community_short_bio || '',
      socialLinks: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'socialLinks',
  });

  const [selectedPlatform, setSelectedPlatform] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [inputError, setInputError] = useState<string | null>(null);

  const axiosClient = configureAxios();
  const organizationId = organisation.organizationId;
  const toast = useToast();

  const platformUrls: { [key: string]: string } = {
    linkedin: 'https://www.linkedin.com/in/',
    instagram: 'https://www.instagram.com/',
    mail: 'mailto:',
    twitter: 'https://www.twitter.com/',
    other: '',
  };

  const iconMap: { [key: string]: JSX.Element } = {
    linkedin: <Linkedin boxSize={4} />,
    instagram: <Instagram boxSize={4} />,
    mail: <Mail boxSize={4} />,
    twitter: <TwitterX boxSize={4} />,
    other: <Copy stroke='black' boxSize={4} />,
  };

  const validateBio = (value: string) => {
    const wordCount = value.trim().split(/\s+/).length;
    return wordCount <= 15 || 'Bio must not exceed 15 words';
  };

  const validateInput = (platform: string, value: string) => {
    if (platform === 'mail') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(value) ? null : 'Invalid email address';
    } else if (['linkedin', 'instagram', 'twitter'].includes(platform)) {
      return value.includes('@') || value.includes('/')
        ? 'Please enter only your username without @ or URL'
        : null;
    } else if (platform === 'other') {
      const urlRegex =
        /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
      return urlRegex.test(value) ? null : 'Invalid URL';
    }
    return null;
  };

  const handleAddLink = () => {
    if (!selectedPlatform || !inputValue) {
      return;
    }

    if (fields.length >= 5) {
      toast({
        title: "Can't add more links",
        description: "You've reached the maximum limit of 5 contact links.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const error = validateInput(selectedPlatform, inputValue);
    if (error) {
      setInputError(error);
      return;
    }

    const concatenatedValue = `${platformUrls[selectedPlatform]}${inputValue}`;

    append({
      platform: selectedPlatform,
      link: concatenatedValue,
    });

    setSelectedPlatform(null);
    setInputValue('');
    setInputError(null);
  };

  const onSubmit = (data: UpdateCommunityProfileFormValues) => {
    axiosClient
      .put(`org/${organizationId}/profile/community-profile`, {
        community_short_bio: data.organizationBio,
        community_contact_links: JSON.stringify(data.socialLinks),
      })
      .then((res) => {
        if (res.status === 200) {
          getOrganizations();
          setUpdateCommunityProfileModalActive(false);
        }
      })
      .catch((error) => {
        console.log('Error ', error);
        setUpdateCommunityProfileModalActive(false);
      });
  };

  useEffect(() => {
    if (organisation?.community_contact_links) {
      try {
        const parsedLinks = JSON.parse(organisation.community_contact_links);
        if (Array.isArray(parsedLinks)) {
          setValue('socialLinks', parsedLinks);
        }
      } catch (error) {
        console.error('Failed to parse community_contact_links:', error);
      }
    }
  }, [organisation, setValue]);

  return (
    <FormWrapper
      onSubmit={handleSubmit(onSubmit)}
      name='update_community_profile'
    >
      <Box
        w={['100%']}
        height={['auto']}
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        alignItems='center'
        gap={5}
      >
        <FormControl isInvalid={!!errors.organizationBio}>
          <FormLabel
            color='#08101D'
            fontFamily='Normal'
            fontSize='12px'
            marginBottom='5px'
          >
            Short Bio (max 15 words)
          </FormLabel>
          <Textarea
            color={'#7F8B9E'}
            padding='15px'
            borderRadius='20px'
            border='1px solid #D6DBE4'
            fontFamily='Normal'
            fontSize={['14px', '14px', '14px']}
            overflow='hidden'
            _placeholder={{ color: '#7F8B9E' }}
            errorBorderColor='#EA1A4C'
            {...register('organizationBio', {
              validate: validateBio,
            })}
          />
          <FormErrorMessage
            color='#EA1A4C'
            mt={['4px']}
            fontSize='13px'
            fontFamily='Normal'
          >
            {errors.organizationBio && errors.organizationBio.message}
          </FormErrorMessage>
        </FormControl>

        {fields.map((field, index) => (
          <Box
            key={field.id}
            width={['100%']}
            display='flex'
            marginRight='auto'
            alignItems='center'
            gap={5}
          >
            <HStack
              spacing={3}
              border='1px solid #D6DBE4'
              p={3}
              borderRadius='16px'
            >
              {iconMap[field.platform]}
              <Text fontSize='14px' fontFamily='Normal' color='black'>
                {field.link}
              </Text>
            </HStack>
            <CloseButton
              marginLeft='auto'
              color='black'
              size='lg'
              onClick={() => remove(index)}
            />
          </Box>
        ))}

        <FormControl isInvalid={!!inputError}>
          <FormLabel
            color='#08101D'
            fontFamily='Normal'
            fontSize='12px'
            marginBottom='5px'
          >
            Contact links (max 5)
          </FormLabel>
          <Box borderRadius='32px' border='1px solid #D6DBE4'>
            <InputGroup>
              <InputLeftAddon border='none' bgColor='transparent'>
                <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label='Options'
                    icon={
                      <HStack spacing={4}>
                        {selectedPlatform && iconMap[selectedPlatform]}
                        <Toggle stroke='black' boxSize={4} />
                      </HStack>
                    }
                    border='none'
                    variant='unstyled'
                    size='xs'
                    color='#08101D'
                  >
                    Select Platform
                  </MenuButton>
                  <MenuList
                    width='120px'
                    maxWidth='120px'
                    backdropFilter='blur(40px)'
                    boxShadow='0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
                    fontFamily='Normal'
                    color='#08101D'
                    p='5px'
                    mt='15px'
                  >
                    {Object.entries(iconMap).map(([platform, icon]) => (
                      <MenuItem
                        key={platform}
                        icon={icon}
                        onClick={() => {
                          setSelectedPlatform(platform);
                          setInputValue('');
                          setInputError(null);
                        }}
                      >
                        {platform.charAt(0).toUpperCase() + platform.slice(1)}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </InputLeftAddon>
              <Input
                color={'#7F8B9E'}
                padding={'0px 15px'}
                border='none'
                fontFamily='Normal'
                fontSize={['14px', '14px', '14px']}
                _placeholder={{ color: '#7F8B9E' }}
                errorBorderColor='#EA1A4C'
                variant='unstyled'
                mr='15px'
                value={inputValue}
                onChange={(e) => {
                  setInputValue(e.target.value);
                  if (selectedPlatform) {
                    setInputError(
                      validateInput(selectedPlatform, e.target.value)
                    );
                  }
                }}
                placeholder={
                  selectedPlatform === 'mail'
                    ? 'Enter email'
                    : 'Enter link or username'
                }
              />
              <InputRightAddon border='none' bgColor='transparent'>
                <Button
                  onClick={handleAddLink}
                  textTransform='uppercase'
                  size='sm'
                  variant='outline'
                  color='#08101D'
                  background='white'
                  fontFamily='Metal'
                  fontSize={['14px', '14px', '14px']}
                  borderRadius='30px'
                  borderColor='transparent'
                  borderWidth='1px'
                  _hover={{ borderColor: 'transparent', bgColor: '#D6DBE4' }}
                >
                  Add
                </Button>
              </InputRightAddon>
            </InputGroup>
          </Box>
          <FormErrorMessage
            color='#EA1A4C'
            mt={['4px']}
            fontSize='13px'
            fontFamily='Normal'
          >
            {inputError}
          </FormErrorMessage>
        </FormControl>

        <Button
          textTransform='uppercase'
          width={['80%', '80%', '300px']}
          variant='outline'
          color='#08101D'
          background='white'
          border='1px solid #D6DBE4'
          fontFamily='Metal'
          fontSize={['14px', '14px', '15px']}
          borderRadius='30px'
          _hover={{
            bg: 'white',
            color: '#08101D',
          }}
          type='submit'
        >
          Save Changes
        </Button>
      </Box>
    </FormWrapper>
  );
}

export default UpdateCommunityProfileForm;
