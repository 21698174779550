import React, { useEffect, useRef } from 'react';
import {
  Box,
  Button,
  Text,
  VStack,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { ExperienceSceneItem } from '../../interfaces';
import { CopyText } from '../../Icons/CopyText';
import { motion } from 'framer-motion';

interface SceneDropDownProps {
  selectedScene: string;
  setSelectedScene: (scene: string) => void;
  scenes: ExperienceSceneItem[];
  handleCopy: (text: string, toastMessage?: string) => void;
}

const SceneDropDown = ({
  selectedScene,
  setSelectedScene,
  scenes,
  handleCopy,
}: SceneDropDownProps) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const onSceneSelect = (scene: string) => {
    const iframe = document.getElementById(
      'experience-container'
    ) as HTMLIFrameElement; // Get reference to the iframe
    console.log('scene', scene);
    iframe.contentWindow?.window.postMessage(
      {
        type: 'loadScene',
        scene: scene + '_0',
      },
      '*'
    );
    setSelectedScene(scene);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <Box position='relative' width='fit-content' ref={dropdownRef}>
      <Button
        width='230px'
        rightIcon={<ChevronDownIcon />}
        background='transparent'
        border='1px solid #fff'
        borderRadius='30px'
        cursor='pointer'
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        gap={2}
        onClick={onToggle}
        _hover={{
          background: 'transparent',
        }}
        _active={{
          background: 'transparent',
          border: '1px solid #D3BB8A',
        }}
        _focus={{
          background: 'transparent',
          border: '1px solid #D3BB8A',
        }}
      >
        <Text fontSize='14px' fontWeight='normal' fontFamily='Normal'>
          {selectedScene}
        </Text>
      </Button>

      <motion.div
        initial={{ opacity: 0, height: 0 }}
        animate={{
          opacity: isOpen ? 1 : 0,
          height: isOpen ? 'auto' : 0,
        }}
        transition={{ duration: 0.3, ease: 'easeInOut' }}
        style={{ overflow: 'hidden' }}
      >
        <VStack
          position='absolute'
          top='100%'
          left={0}
          mt={2}
          background='white'
          borderRadius='16px'
          boxShadow='md'
          zIndex='dropdown'
        >
          {scenes.map((item: ExperienceSceneItem, index) => (
            <Box
              key={index}
              width='full'
              border='1px solid transparent'
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'flex-start'}
              padding={'12px 16px'}
              cursor='pointer'
              _hover={{
                border: '1px solid #D3BB8A',
                borderTopLeftRadius: index === 0 ? '16px' : '0px',
                borderTopRightRadius: index === 0 ? '16px' : '0px',
                borderBottomLeftRadius:
                  index === scenes.length - 1 ? '16px' : '0px',
                borderBottomRightRadius:
                  index === scenes.length - 1 ? '16px' : '0px',
              }}
              onClick={() => {
                onSceneSelect(item.name);
                onToggle();
              }}
            >
              <Text fontWeight='400' fontSize='14px' color={'#08101D'}>
                {item.name}
              </Text>
              <Text
                width={'max-content'}
                fontWeight='500'
                fontSize='12px'
                color={'#08101D'}
              >
                ID:{' '}
                <Text as={'span'} fontWeight='400' color='#58677E'>
                  {item.uuid.substring(0, 20)}...
                </Text>
                <IconButton
                  icon={<CopyText stroke={'#58677E'} boxSize={[5]} />}
                  size='xs'
                  variant='ghost'
                  aria-label='Copy ID'
                  ml={2}
                  onClick={() => {
                    handleCopy(item.uuid, 'Scene ID copied to clipboard');
                  }}
                />
              </Text>
            </Box>
          ))}
        </VStack>
      </motion.div>
    </Box>
  );
};

export default SceneDropDown;
