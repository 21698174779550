import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PostHogProvider } from 'posthog-js/react';
import { HashRouter } from 'react-router-dom';
import ErrorBoundary from './components/errorBoundary/ErrorBoundary';
import { customDashTheme } from './theme';
import { ChakraProvider } from '@chakra-ui/react';

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

const root = ReactDOM.createRoot(
  document.getElementById('dashboard-root') as HTMLElement
);

root.render(
  <ChakraProvider theme={customDashTheme} cssVarsRoot='#dashboard-root'>
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}
    >
      <ErrorBoundary>
        <HashRouter>
          <App />
        </HashRouter>
      </ErrorBoundary>
    </PostHogProvider>
  </ChakraProvider>
);

reportWebVitals();
