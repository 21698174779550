import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const Disable = (props: IconProps) => (
  <Icon
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M9.25056 16.547C13.557 16.547 17.048 13.056 17.048 8.74958C17.048 4.44318 13.557 0.952148 9.25056 0.952148C4.94416 0.952148 1.45312 4.44318 1.45312 8.74958C1.45312 13.056 4.94416 16.547 9.25056 16.547Z'
      stroke='black'
      strokeWidth='1.4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.75 14.2473L14.7453 3.25195'
      stroke='black'
      strokeWidth='1.4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Icon>
);
