import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { Organisation, User } from '../interfaces';
import configureAxios from '../axiosClient';

const axiosClient = configureAxios();

interface UserState {
  user: User;
  organisation: Organisation;
  organisationList: Organisation[];
  inactiveOrganisation: Organisation;
  roles: string;
  userCreateNewOrganisationView: boolean;
  setUser: (user: User) => void;
  getUser: () => void;
  setUserCreateNewOrganisationView: (state: boolean) => void;
  setOrganisation: (value: Organisation) => void;
  setInactiveOrganisation: (value: Organisation) => void;
  getOrganizations: () => void;
  resetUser: () => void;
}

const defaultUser = {
  id: '',
  image: '',
  firstName: '',
  lastName: '',
  email: '',
  dateOfBirth: '',
  roles: '',
};

const defaultOrganisation = {
  organizationId: '',
  alias: '',
  roles: '',
  community_short_bio: '',
  community_contact_links: '',
};

const defaultOrganisationList: Organisation[] = [];

export const useUserStore = create<UserState>()(
  devtools(
    persist(
      (set) => ({
        user: defaultUser,
        organisation: defaultOrganisation,
        inactiveOrganisation: defaultOrganisation,
        organisationList: defaultOrganisationList,
        roles: '',
        userCreateNewOrganisationView: false,
        setUser: (user: User) => set(() => ({ user: user })),
        getUser: () => {
          axiosClient
            .get(`user/profile`)
            .then((res) => {
              set(() => ({
                user: {
                  id: res.data.data.uuid,
                  firstName: res.data.data.name,
                  lastName: res.data.data.surname,
                  email: res.data.data.email,
                  dateOfBirth: '',
                  roles: '',
                },
              }));
            })
            .catch((err: unknown) => {
              console.log(err);
            });
        },
        getOrganizations: () => {
          axiosClient
            .get(`user/organisations`)
            .then((res) => {
              const { data } = res.data;
              const org =
                data?.find((x: Organisation) => x?.is_default === true) ||
                data[0];
              set((state) => ({
                roles: org?.roles,
                organisation: {
                  ...state.organisation,
                  organizationId: org.org_id,
                  alias: org.alias,
                  community_contact_links: org.community_contact_links,
                  community_short_bio: org.community_short_bio,
                },
                organisationList: data,
              }));
            })
            .catch((err: unknown) => {
              console.log(err);
            });
        },
        resetUser: () =>
          set(() => ({
            user: defaultUser,
            organisation: defaultOrganisation,
            roles: '',
            community_short_bio: '',
            community_contact_links: '',
          })),
        setOrganisation: (organisation: Organisation) =>
          set(() => ({
            organisation: { ...organisation },
          })),
        setInactiveOrganisation: (organisation: Organisation) =>
          set(() => ({
            inactiveOrganisation: { ...organisation },
          })),
        setUserCreateNewOrganisationView: (state: boolean) =>
          set(() => ({
            userCreateNewOrganisationView: state,
          })),
      }),
      {
        name: 'user-storage',
      }
    )
  )
);
