import thumbnail from '../../assets/image/PluginDashboard.jpg';
import dummyUser from '../../assets/image/DummyUser.png';
import {
  Box,
  IconButton,
  Image,
  Text,
  Tooltip,
  // useToast,
} from '@chakra-ui/react';
import { Eye } from '../../Icons/Eye';
import { useState } from 'react';
import React from 'react';
import { Like } from '../../Icons/Like';
import { CommunityExperienceListItem } from '../../interfaces';
// import { Clone } from '../../Icons/Clone';
import configureAxios from '../../axiosClient';
import { useCommunityStore } from '../../store/community';
// import { useUserStore } from '../../store/user';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CommunityExperienceItemAdjustMenu from './CommunityExperienceItemsAdjustMenu';
import CommunityExperienceDetailedInfo from './CommunityExperienceDetailedInfo';
import { FilledHeart } from '../../Icons/FilledHeart';
import { dispatchCommunityExperienceLikeToAnalytics } from '../../analytics';
import { useUserStore } from '../../store/user';
import { useExperiencesStore } from '../../store/experiences';
// import DeleteExperienceModal from '../modals/DeleteExperienceModal';

interface CommunityItemProps {
  communityExperienceListItem: CommunityExperienceListItem;
  handleLikeBtnTriggered?: () => void;
}

function CommunityItem({
  communityExperienceListItem,
  handleLikeBtnTriggered,
}: CommunityItemProps) {
  const navigate = useNavigate();
  // const toast = useToast({
  //   position: 'top',
  //   variant: 'top-accent',
  // });
  const location = useLocation();
  const [isOpen, setOpen] = useState(false);
  const [experienceHovered, setExperienceHovered] = useState(false);
  const {
    fetchCommunityExperiences,
    setListUnListCommunityExperienceModalActive,
  } = useCommunityStore();
  const { setSelectedExperience } = useExperiencesStore();
  const { organisation } = useUserStore();

  const axiosClient = configureAxios();

  const likeUnlikeCommunityExperience = async () => {
    try {
      const res = await axiosClient.post('experience/like/community', {
        exp_id: communityExperienceListItem.id,
      });
      if (res.status === 200) {
        handleLikeBtnTriggered && handleLikeBtnTriggered();
        if (res.data.data === 'Experience liked.') {
          dispatchCommunityExperienceLikeToAnalytics('like_experience', {
            experience_id: communityExperienceListItem.id,
            organisation_id: communityExperienceListItem.organisation_id,
            experience_name: communityExperienceListItem.name,
            experience_community_url: communityExperienceListItem.community_url,
          });
        }
        location.pathname === '/community'
          ? fetchCommunityExperiences('12', 0, false)
          : fetchCommunityExperiences('4', 0, false);
      }
    } catch (error) {
      console.log('Error ', error);
    }
  };

  // const cloneCommunityExperience = async () => {
  //   try {
  //     const res = await axiosClient.post(
  //       `experience/clone/${communityExperienceListItem.id}`,
  //       {
  //         org_id: organisation.organizationId,
  //       }
  //     );
  //     if (res.status === 200) {
  //       fetchCommunityExperiences('4', 0);
  //       toast({
  //         title: res.data.data,
  //         status: 'success',
  //       });
  //     }
  //   } catch (error) {
  //     console.log('Error ', error);
  //   }
  // };

  const OpenManageExperienceModal = () => {
    setSelectedExperience(communityExperienceListItem);
    setListUnListCommunityExperienceModalActive(true);
  };

  const countCommunityExperienceView = async (experienceId: string) => {
    try {
      await axiosClient.put(`experience/${experienceId}/viewed`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box
      width={['100%']}
      height={['235px']}
      borderRadius='16px'
      bg='#1D2531'
      position='relative'
      onMouseEnter={() => setExperienceHovered(true)}
      onMouseLeave={() => {
        setExperienceHovered(false);
        setOpen(false);
      }}
      cursor='pointer'
    >
      <Box
        overflow='hidden'
        width={['100%']}
        height={experienceHovered ? '100px' : '140px'}
        borderTopRadius='16px'
      >
        <Image
          src={
            communityExperienceListItem.community_url
              ? communityExperienceListItem.community_url.replace(
                  'index.html',
                  'browser_icon.jpg'
                )
              : thumbnail
          }
          objectFit='cover'
          width={['100%']}
          height={['100%']}
          transform={experienceHovered ? 'scale(1.2)' : 'scale(1)'}
          transition='0.2s all'
          cursor='pointer'
        />
      </Box>

      <Box
        opacity={experienceHovered ? 1 : 0}
        visibility={experienceHovered ? 'visible' : 'hidden'}
        backgroundColor='rgba(0,0,0,0.5)'
        transition='0.2s all'
        background='rgba(8, 16, 29, 0.60)'
        backdropFilter='blur(4px)'
        position='absolute'
        zIndex='2'
        top='0px'
        width={['100%']}
        height={experienceHovered ? '100px' : '140px'}
        borderTopRadius='16px'
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        <Box
          // height={['auto']}
          width={['90px']}
          display='flex'
          justifyContent={
            communityExperienceListItem.is_owner &&
            communityExperienceListItem.organisation_id ===
              organisation.organizationId
              ? 'space-between'
              : 'center'
          }
          alignItems='flex-end'
        >
          {communityExperienceListItem.is_owner &&
          communityExperienceListItem.organisation_id ===
            organisation.organizationId ? (
            <Link
              to={`/viewer/${
                communityExperienceListItem.id
              }?isCommunity=${true}`}
              target='_blank'
            >
              <IconButton
                bg='white'
                _hover={{
                  bg: '#D3BB8A',
                }}
                borderRadius='100%'
                icon={<Eye stroke='#08101D' boxSize={[5]} />}
                aria-label='View Experience'
                onClick={() => {
                  countCommunityExperienceView(communityExperienceListItem.id);
                  setSelectedExperience(communityExperienceListItem);
                }}
              />
            </Link>
          ) : (
            <Link
              to={`${communityExperienceListItem.community_url}`}
              target='_blank'
            >
              <IconButton
                bg='white'
                _hover={{
                  bg: '#D3BB8A',
                }}
                borderRadius='100%'
                icon={<Eye stroke='#08101D' boxSize={[5]} />}
                aria-label='View Experience'
                onClick={() => {
                  countCommunityExperienceView(communityExperienceListItem.id);
                }}
              />
            </Link>
          )}
          {communityExperienceListItem.is_owner &&
            communityExperienceListItem.organisation_id ===
              organisation.organizationId && (
              <CommunityExperienceItemAdjustMenu
                isOpen={isOpen}
                ManageExperiences={OpenManageExperienceModal}
                setOpen={(state: boolean) => {
                  setOpen(state);
                }}
              />
            )}
        </Box>
      </Box>

      <Box
        width={['100%']}
        height={['auto']}
        display='flex'
        flexDirection='column'
        alignItems='flex-start'
        padding={'12px 16px'}
        gap={2}
      >
        <Box
          display='flex'
          width={['100%']}
          justifyContent='space-between'
          alignItems='center'
          gap={4}
        >
          <Image
            src={dummyUser}
            alt='user'
            width='34px'
            height='34px'
            onClick={() =>
              navigate(
                `/community/community-profile?id=${communityExperienceListItem.organisation_id}&org=${communityExperienceListItem.organisation_name}`
              )
            }
          />
          <Box
            display='flex'
            flexDir='column'
            maxWidth={['220px']}
            marginRight='auto'
            gap={2}
          >
            <Text
              fontSize='14px'
              fontFamily='Normal'
              whiteSpace='nowrap'
              overflow='hidden'
              textOverflow='ellipsis'
              height='20px'
            >
              {communityExperienceListItem.name}
            </Text>
            <Box
              display='flex'
              alignItems='center'
              gap={2}
              onClick={() =>
                navigate(
                  `/community/community-profile?id=${communityExperienceListItem.organisation_id}&org=${communityExperienceListItem.organisation_name}`
                )
              }
            >
              <Text fontFamily='Normal' fontSize='11px'>
                {communityExperienceListItem.organisation_name}
              </Text>
            </Box>
          </Box>
          <Box display='flex' alignItems='center' gap={4}>
            <Tooltip hasArrow label='Like' closeOnClick={false}>
              <Box
                display='flex'
                flexDirection='column'
                gap={2}
                mt='auto'
                onClick={() => likeUnlikeCommunityExperience()}
              >
                {communityExperienceListItem.is_liked ? (
                  <FilledHeart boxSize={6} />
                ) : (
                  <Like boxSize={6} />
                )}
                <Text fontSize='11px' fontFamily='Normal' textAlign='center'>
                  {communityExperienceListItem.like_count}
                </Text>
              </Box>
            </Tooltip>
            {/* {!communityExperienceListItem.is_owner && (
              <Tooltip hasArrow label='Clone' closeOnClick={false}>
                <Box
                  display='flex'
                  flexDirection='column'
                  gap={2}
                  onClick={() => cloneCommunityExperience()}
                >
                  <Clone boxSize={6} />
                  <Text fontSize='11px' fontFamily='Normal' textAlign='center'>
                    {communityExperienceListItem.clone_count}
                  </Text>
                </Box>
              </Tooltip>
            )} */}
          </Box>
        </Box>

        <CommunityExperienceDetailedInfo
          experienceHovered={experienceHovered}
          communityExperienceListItem={communityExperienceListItem}
        />
      </Box>
    </Box>
  );
}

export default CommunityItem;
