import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const Fullscreen = (props: IconProps) => (
  <Icon
    width='26'
    height='26'
    viewBox='0 0 26 26'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M16.1167 5.00073L21.2661 5.00073L21.2661 10.1501'
      stroke='white'
      strokeWidth='1.4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.3159 9.95024L21.2656 5.00049'
      stroke='white'
      strokeWidth='1.4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.8872 20.9995L4.7378 20.9995L4.7378 15.8501'
      stroke='white'
      strokeWidth='1.4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.68803 16.05L4.73828 20.9998'
      stroke='white'
      strokeWidth='1.4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Icon>
);
