import { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Text,
  Textarea,
  VStack,
  useToast,
} from '@chakra-ui/react';
import FormWrapper from '../common/FormWrapper';
import React from 'react';
import { UNSUBSCRIBE_REASON } from '../../enums';
import configureAxios from '../../axiosClient';
import { dispatchUnsubscribeEmailToAnalytics } from '../../analytics';
import { UNSUBSCRIBE_REASON_OPTIONS } from '../../constants';

interface UnsubscribeEmailFormProps {
  userId: string;
}

const axiosClient = configureAxios();

function UnsubscribeEmailForm({ userId }: UnsubscribeEmailFormProps) {
  const toast = useToast({
    position: 'top',
    variant: 'top-accent',
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [selectedReason, setSelectedReason] = useState<{
    key: string;
    value: string;
  }>({
    key: '',
    value: '',
  });
  const [additionalComments, setAdditionalComments] = useState<string>('');

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (!selectedReason.key) {
      toast({
        title: 'Please select a reason for unsubscribing.',
        status: 'error',
      });
      setIsSubmitting(false);
      return;
    }

    axiosClient
      .post(
        process.env.REACT_APP_API_URL + `user/${userId}/unsubscribe-email`,
        {
          reason: additionalComments || selectedReason.value,
        }
      )
      .then((res) => {
        const isStatusOk = res.data.status === 'OK';
        setIsSubmitting(false);

        if (isStatusOk) {
          dispatchUnsubscribeEmailToAnalytics('Unsubscribe Email', userId);
          toast({
            title: res.data.data,
            status: 'success',
          });
          setTimeout(() => {
            window.location.href = '/#/';
          }, 1200);
        } else {
          toast({
            title: res.data.data,
            status: 'error',
          });
        }
      })
      .catch((err: unknown) => {
        toast({
          title: `An error occurred. Please try again later.`,
          status: 'error',
        });
        setIsSubmitting(false);
        console.log(err);
      });
  };

  const isOtherSelected = selectedReason.key === UNSUBSCRIBE_REASON.OTHER;

  return (
    <FormWrapper onSubmit={onSubmit} name='unsubscribe_email'>
      <Box
        margin={['20px 0px']}
        w={['100%']}
        height={['auto']}
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        alignItems='center'
        gap={6}
      >
        <Box
          w={['100%']}
          height='auto'
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          alignItems='center'
          gap={5}
        >
          <Text
            color={'#08101D'}
            fontFamily='normal'
            fontSize={['12px']}
            fontWeight={400}
            lineHeight={['16px']}
          >{`Please tell us why you're unsubscribing:`}</Text>

          <FormControl w={['100%']} height='auto'>
            <VStack spacing={4} mb={4}>
              {UNSUBSCRIBE_REASON_OPTIONS.map(
                (reason: { key: string; value: string }) => (
                  <Box
                    key={reason.key}
                    width='100%'
                    px={'15px'}
                    py={'10px'}
                    fontSize={['14px']}
                    lineHeight={['16px']}
                    fontFamily={'normal'}
                    borderRadius='50px'
                    color={'#08101D'}
                    border={
                      selectedReason.key === reason.key
                        ? '1px solid #D3BB8A'
                        : '1px solid #D6DBE4'
                    }
                    cursor='pointer'
                    onClick={() => setSelectedReason(reason)}
                    _hover={{
                      border: '1px solid #D3BB8A',
                    }}
                  >
                    {reason.value}
                  </Box>
                )
              )}
            </VStack>

            <Textarea
              borderRadius={'30px'}
              fontSize={['12px']}
              lineHeight={['16px']}
              fontFamily={'normal'}
              placeholder='Please specify your reason'
              value={additionalComments}
              onChange={(e) => setAdditionalComments(e.target.value)}
              isDisabled={!isOtherSelected}
              bg={isOtherSelected ? 'white' : 'gray.100'}
              color={isOtherSelected ? 'black' : 'gray.600'}
              _focusVisible={{
                boxShadow: 'none',
                borderColor: isOtherSelected ? '#D3BB8A' : '#D6DBE4',
              }}
            />
          </FormControl>
        </Box>

        <Button
          textTransform='uppercase'
          width={['80%', '80%', '300px']}
          variant='outline'
          color='#08101D'
          background='white'
          fontFamily='Metal'
          fontSize={['14px', '14px', '14px']}
          borderRadius='30px'
          border='1px solid #D6DBE4'
          _hover={{
            bg: '#D3BB8A',
            borderColor: '#D3BB8A',
          }}
          isLoading={isSubmitting}
          type='submit'
        >
          Unsubscribe
        </Button>
      </Box>
    </FormWrapper>
  );
}

export default UnsubscribeEmailForm;
