import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import UnsubscribeEmailForm from '../forms/UnsubscribeEmailForm';

interface UnsubscribeEmailModalProps {
  modalActive: boolean;
  userId: string;
}

function UnsubscribeEmailModal({
  modalActive,
  userId,
}: UnsubscribeEmailModalProps) {
  const onClose = () => {
    window.location.href = '/#/';
  };

  return (
    <Modal isOpen={modalActive} onClose={onClose} isCentered autoFocus={false}>
      <ModalOverlay background={'rgb(8, 16, 29)'} />
      <ModalContent
        minW={['500px']}
        height={['auto']}
        margin='auto'
        maxHeight='80vh'
        overflow='auto'
        padding={['20px']}
        borderRadius='16px'
        boxShadow='0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
      >
        <CloseIcon
          position='absolute'
          boxSize={[4]}
          cursor='pointer'
          color='#D6DBE4'
          _hover={{ color: 'black' }}
          transition='0.2s all'
          right={['20px']}
          top={['20px']}
          onClick={onClose}
        />
        <ModalBody
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          alignItems='center'
        >
          <Box>
            <Text
              color='#08101D'
              fontFamily='Metal'
              fontSize={['35px']}
              lineHeight={['35px']}
              textAlign='center'
              textTransform='uppercase'
            >
              {`we're sorry`}
            </Text>
            <Text
              color='#08101D'
              fontFamily='Metal'
              fontSize={['35px']}
              lineHeight={['35px']}
              textAlign='center'
              textTransform='uppercase'
            >
              to see you leave
            </Text>
          </Box>

          <UnsubscribeEmailForm userId={userId} />

          <Button
            fontSize={['11px']}
            variant='link'
            fontFamily='Normal'
            textAlign='center'
            onClick={onClose}
          >
            Cancel
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default UnsubscribeEmailModal;
