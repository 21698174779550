import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const SampleFile = (props: IconProps) => (
  <Icon
    width='27'
    height='28'
    viewBox='0 0 27 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#clip0_5025_260261)'>
      <path
        d='M24.1709 4.84182L18.953 0.223633H3.58594V13.3756H24.1709V4.84182Z'
        stroke='#1D2531'
        strokeLinejoin='round'
      />
      <path
        d='M18.9531 4.84182H24.171L18.9531 0.223633V4.84182Z'
        stroke='#1D2531'
        strokeLinejoin='round'
      />
      <path
        d='M24.1709 22.885H3.58594V27.7762H24.1709V22.885Z'
        stroke='#1D2531'
        strokeLinejoin='round'
      />
      <path
        d='M26.7782 13.3757H24.1738H3.58882H0.984375V22.8851H3.58882H24.1738H26.7782V13.3757Z'
        stroke='#1D2531'
        strokeLinejoin='round'
      />
      <path
        d='M3.47323 18.6429H3.95652C3.95652 19.0635 4.4801 19.1933 4.9634 19.1933C5.76442 19.1933 6.02397 19.068 6.02397 18.7592C6.02397 18.5086 5.84944 18.437 5.59437 18.4191L4.4443 18.3296C3.782 18.2804 3.53587 18.0298 3.53587 17.4973C3.53587 16.8842 4.08182 16.6426 4.99025 16.6426C5.89867 16.6426 6.42672 17.0319 6.42672 17.645H5.94342C5.94342 17.2199 5.47802 17.0856 4.99025 17.0856C4.2653 17.0856 4.01917 17.2512 4.01917 17.4973C4.01917 17.7434 4.1758 17.824 4.4443 17.8464L5.59437 17.9359C6.31932 17.9896 6.50279 18.2983 6.50279 18.795C6.50279 19.4573 5.92104 19.6363 4.95892 19.6363C3.9968 19.6363 3.46875 19.256 3.46875 18.6384L3.47323 18.6429Z'
        fill='#1D2531'
      />
      <path
        d='M9.35118 18.9338H7.54329L7.23004 19.5871H6.71094L8.14741 16.7097H8.75153L10.188 19.5871H9.6689L9.35566 18.9338H9.35118ZM9.13638 18.4863L8.44723 17.0588L7.75808 18.4863H9.13638Z'
        fill='#1D2531'
      />
      <path
        d='M13.9859 16.7098V19.5872H13.5026V17.1841L12.5629 18.813H11.9498L11.0145 17.2468V19.5827H10.5312V16.7053H11.2114L12.2496 18.455L13.2655 16.7053H13.977L13.9859 16.7098Z'
        fill='#1D2531'
      />
      <path
        d='M17.3749 17.7032C17.3749 18.4147 17.0303 18.7011 16.1622 18.7011H15.0927V19.5871H14.6094V16.7097H16.1711C17.0348 16.7097 17.3794 16.9961 17.3794 17.7032H17.3749ZM16.8916 17.7032C16.8916 17.3138 16.735 17.1572 16.2696 17.1572H15.0882V18.2581H16.2696C16.735 18.2581 16.8916 18.1014 16.8916 17.7076V17.7032Z'
        fill='#1D2531'
      />
      <path
        d='M17.8203 16.7097H18.3036V19.1396H20.2368V19.5871H17.8203V16.7097Z'
        fill='#1D2531'
      />
      <path
        d='M20.6484 16.7097H23.1455V17.1572H21.1317V17.8643H23.0336V18.3118H21.1317V19.1441H23.1723V19.5916H20.6484V16.7142V16.7097Z'
        fill='#1D2531'
      />
    </g>
    <defs>
      <clipPath id='clip0_5025_260261'>
        <rect
          width='26.2413'
          height='28'
          fill='white'
          transform='translate(0.757812)'
        />
      </clipPath>
    </defs>
  </Icon>
);
