import { Box, Text, useToast } from '@chakra-ui/react';
import BillingHistoryTable from '../tables/BillingHistoryTable';
import BillingOption from './BillingOption';
import React, { useEffect, useState } from 'react';
import LeftInfoPanel from './LeftInfoPanel';
import { useUserStore } from '../../store/user';
import configureAxios from '../../axiosClient';

const axiosClient = configureAxios();

interface BillingProps {
  numberOfMembers: string;
  setBillingModalActive: (state: boolean) => void;
}

type Invoice = {
  name: string;
  due_date: number;
  paid: boolean;
  total: number;
  url: string;
};

function Billing({ numberOfMembers, setBillingModalActive }: BillingProps) {
  const toast = useToast({
    position: 'top',
    variant: 'top-accent',
  });
  const organisation = useUserStore((state) => state.organisation);
  const [invoices, setInvoices] = useState<Invoice[]>([]);

  useEffect(() => {
    const userOrgId = organisation.organizationId;

    axiosClient
      .get(`org/${userOrgId}/invoices/read`)
      .then((res) => {
        const isStatusOk = res.data.status === 'OK';
        if (isStatusOk) {
          const invoices = res.data.data.map((invoice: Invoice) => {
            const milliseconds = invoice.due_date * 1000;
            const dateObject = new Date(milliseconds);
            const year = dateObject.getFullYear();
            const month = ('0' + (dateObject.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based months
            const day = ('0' + dateObject.getDate()).slice(-2);
            const formattedDate = year + '/' + month + '/' + day;
            return {
              name: 'Invoice',
              due_date: formattedDate,
              paid: invoice.paid,
              total: invoice.total / 100,
              url: invoice.url,
            };
          });
          setInvoices(invoices);
        } else {
          toast({
            title: `There was an error fetching client invoices`,
            status: 'error',
          });
        }
      })
      .catch((err: unknown) => {
        console.log(err);
      });
  }, [organisation.organizationId]);

  return (
    <Box flex='1' display='flex' padding='40px' gap={8}>
      <LeftInfoPanel numberOfMembers={numberOfMembers} />

      <Box flex='1'>
        <Box
          w={['100%']}
          height={['auto']}
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          alignItems='center'
        >
          <Box
            borderBottom='2px solid #D3BB8A'
            width={['inherit']}
            height={['50px']}
            padding={['16px']}
            display='flex'
            justifyContent='flex-start'
            alignItems='center'
            mb={5}
          >
            <Text
              fontFamily='Normal'
              fontSize={['15px']}
              textTransform='capitalize'
              fontWeight='900'
              color='#D3BB8A'
            >
              Settings
            </Text>
          </Box>

          <Box
            width={['inherit']}
            height={['auto']}
            display='flex'
            flexDirection='column'
            alignItems='center'
            gap={5}
          >
            <BillingOption
              title='Billing details'
              description='Manage payment methods'
              onClick={() => setBillingModalActive(true)}
            />
          </Box>
        </Box>

        <BillingHistoryTable invoices={invoices} />
      </Box>
    </Box>
  );
}

export default Billing;
