import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const Globe = (props: IconProps) => (
  <Icon
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#clip0_5391_40228)'>
      <path
        d='M10.0073 19.0996C15.0294 19.0996 19.1006 15.0284 19.1006 10.0064C19.1006 4.98429 15.0294 0.913086 10.0073 0.913086C4.98526 0.913086 0.914062 4.98429 0.914062 10.0064C0.914062 15.0284 4.98526 19.0996 10.0073 19.0996Z'
        // stroke='#08101D'
        strokeMiterlimit='10'
      />
      <path
        d='M10.0088 0.913086C8.11713 2.16553 6.8125 6.31426 6.8125 10.0064C6.8125 13.3593 7.64746 17.1036 10.0088 19.0996'
        // stroke='#08101D'
        strokeMiterlimit='10'
      />
      <path
        d='M10.0078 0.913086C11.8995 2.16553 13.2042 6.31426 13.2042 10.0064C13.2042 13.3593 12.3692 17.1036 10.0078 19.0996'
        // stroke='#08101D'
        strokeMiterlimit='10'
      />
      <path
        d='M18.4366 12.9287H1.55469'
        // stroke='#08101D'
        strokeMiterlimit='10'
      />
      <path
        d='M1.55469 7.07129H18.4366'
        // stroke='#08101D'
        strokeMiterlimit='10'
      />
    </g>
    <defs>
      <clipPath id='clip0_5391_40228'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </Icon>
);
