import { Box, Button } from '@chakra-ui/react';
import React from 'react';

interface SidebarItemProps {
  sideBarItemKey: string;
  sideBarIcon: React.ReactElement;
  text: string;
  sideBarOpen: boolean;
  active: boolean;
  setActiveView: (view: string) => void;
}

function SidebarItem({
  sideBarItemKey,
  sideBarIcon,
  text,
  sideBarOpen,
  active,
  setActiveView,
}: SidebarItemProps) {
  return (
    <Box width={['100%']} height={['50px']} display='flex' alignItems='center'>
      <Button
        leftIcon={sideBarIcon}
        iconSpacing={sideBarOpen ? 2 : 0}
        variant='ghost'
        background='transparent'
        _hover={{
          bg: 'transparent',
          color: '#D3BB8A',
        }}
        _active={{
          bg: 'transparent',
        }}
        _focus={{
          bg: 'transparent',
        }}
        color={active ? '#D3BB8A' : 'white'}
        textTransform='uppercase'
        fontFamily='Metal'
        fontSize={['14px']}
        onClick={() => !active && setActiveView(sideBarItemKey)}
      >
        {sideBarOpen && text}
      </Button>
    </Box>
  );
}

export default SidebarItem;
