import { CloseIcon } from '@chakra-ui/icons';
import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react';
import React from 'react';
import NearingUsageLimit from '../common/NearingUsageLimit';

interface NearingUsageLimitModalProps {
  nearingUsageLimitActive: boolean;
  setContactModalActive: (state: boolean) => void;
  setNearingUsageLimitActive: (state: boolean) => void;
}

function NearingUsageLimitModal({
  nearingUsageLimitActive,
  setContactModalActive,
  setNearingUsageLimitActive,
}: NearingUsageLimitModalProps) {
  return (
    <Modal
      isOpen={nearingUsageLimitActive}
      onClose={() => {
        setNearingUsageLimitActive(false);
      }}
      size={'lg'}
      isCentered
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent
        // minW={['700px']}
        height={['auto']}
        margin='auto'
        maxHeight='80vh'
        overflow='auto'
        padding={['20px']}
        borderRadius='16px'
        boxShadow='0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
      >
        <CloseIcon
          position='absolute'
          boxSize={[4]}
          cursor='pointer'
          color='#D6DBE4'
          _hover={{ color: 'black' }}
          transition='0.2s all'
          right={['20px']}
          top={['20px']}
          onClick={() => {
            setNearingUsageLimitActive(false);
          }}
        />
        <ModalBody
          height='auto'
          width='auto'
          gap={5}
          display='flex'
          flexDirection='column'
          alignItems='center'
        >
          <NearingUsageLimit
            setContactModalActive={setContactModalActive}
            setNearingUsageLimitActive={setNearingUsageLimitActive}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default NearingUsageLimitModal;
