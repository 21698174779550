import {
  Box,
  Fade,
  SimpleGrid,
  Skeleton,
  Button,
  VStack,
  Text,
} from '@chakra-ui/react';
import React, { Fragment, useEffect, useState } from 'react';
import TopActionBar from '../components/common/TopActionBar';
import { Experiences } from '../Icons/Experiences';
import { CommunityExperienceListItem } from '../interfaces';
import { useCommunityStore } from '../store/community';
import CommunityItem from '../components/communityView/CommunityItem';
import CommunityBarActions from '../components/barActions/CommunityBarActions';
import CommunityFilters from '../components/communityView/CommunityFilters';
import { Star } from '../Icons/Star';

const CommunityExperiencesView = () => {
  const {
    queryName,
    totalCommunityExperiencesPages,
    fetchCommunityExperiences,
    likes,
    tags,
    mostRecent,
    isFeaturedExperiences,
    setIsExperienceDelisted,
    isExperienceDelisted,
  } = useCommunityStore();

  const [localCommunityExperiences, setLocalCommunityExperiences] = useState<
    CommunityExperienceListItem[]
  >([]);
  const [communityExperiencesLoaded, setCommunityExperiencesLoaded] =
    useState(false);
  const [page, setPage] = useState(0);
  const [loader, setLoader] = useState(false);

  const loadMoreExperiences = () => {
    setLoader(true);
    const nextPage = page + 1;
    fetchCommunityExperiences('12', nextPage, isFeaturedExperiences)
      .then((newExperiences) => {
        setLocalCommunityExperiences((prevExperiences) => [
          ...prevExperiences,
          ...newExperiences,
        ]);
        setPage(nextPage);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    setPage(0);
    fetchCommunityExperiences('12', 0, isFeaturedExperiences).then(
      (initialExperiences) => {
        setLocalCommunityExperiences(initialExperiences);
        setCommunityExperiencesLoaded(true);
      }
    );
    setIsExperienceDelisted(false);

    const intervalId = setInterval(() => {
      fetchCommunityExperiences('12', 0, isFeaturedExperiences).then(
        (refreshedExperiences) => {
          setLocalCommunityExperiences((prevExperiences) => {
            const newExperienceIds = new Set(
              refreshedExperiences.map((exp) => exp.id)
            );
            const filteredPrevExperiences = prevExperiences.filter(
              (exp) => !newExperienceIds.has(exp.id)
            );
            return [...refreshedExperiences, ...filteredPrevExperiences];
          });
        }
      );
    }, 5000);

    return () => clearInterval(intervalId);
  }, [
    queryName,
    likes,
    tags,
    mostRecent,
    isFeaturedExperiences,
    isExperienceDelisted,
  ]);

  return (
    <Fade in={true} transition={{ enter: { duration: 0.2 } }}>
      <VStack spacing={0} align='stretch' minHeight='90vh'>
        <TopActionBar
          featureIcon={
            <Star
              stroke={isFeaturedExperiences ? '#d3bb8a' : 'white'}
              boxSize={[5]}
            />
          }
          featureTitle='Featured Experiences'
          icon={
            <Experiences
              stroke={!isFeaturedExperiences ? '#d3bb8a' : 'white'}
              boxSize={[5]}
            />
          }
          title='Community Experiences'
          showSearchBar={false}
          searchBarPlaceholder='Search Community Experience Name...'
          rightActions={<CommunityBarActions />}
        />
        <Box p={['40px 40px 0px 40px']}>
          <CommunityFilters />
        </Box>
        {isFeaturedExperiences ? (
          <Box p={['40px 40px 0px 40px']}>
            {localCommunityExperiences.length > 0 ? (
              <SimpleGrid columns={[3, 3, 3, 3, 4]} spacing={[5]}>
                {localCommunityExperiences.map(
                  (localCommunityExperience: CommunityExperienceListItem) => (
                    <Skeleton
                      key={localCommunityExperience.id}
                      isLoaded={communityExperiencesLoaded}
                      startColor='#29303C'
                      endColor='transparent'
                      borderRadius='16px'
                    >
                      <CommunityItem
                        communityExperienceListItem={localCommunityExperience}
                      />
                    </Skeleton>
                  )
                )}
              </SimpleGrid>
            ) : (
              <Text fontFamily='Normal' color='white' fontWeight='600'>
                No Featured Experience Found
              </Text>
            )}
          </Box>
        ) : (
          <Fragment>
            <Box p={['40px']} flex='1'>
              {localCommunityExperiences.length > 0 ? (
                <SimpleGrid columns={[3, 3, 3, 3, 4]} spacing={[5]}>
                  {localCommunityExperiences.map(
                    (communityExperience: CommunityExperienceListItem) => (
                      <Skeleton
                        key={communityExperience.id}
                        isLoaded={communityExperiencesLoaded}
                        startColor='#29303C'
                        endColor='transparent'
                        borderRadius='16px'
                      >
                        <CommunityItem
                          communityExperienceListItem={communityExperience}
                        />
                      </Skeleton>
                    )
                  )}
                </SimpleGrid>
              ) : (
                <Text fontFamily='Normal' color='white' fontWeight='600'>
                  No Community Experience Found
                </Text>
              )}
            </Box>
            {localCommunityExperiences.length > 0 &&
              page !== totalCommunityExperiencesPages - 1 && (
                <Box textAlign='center' py={6}>
                  <Button
                    backgroundColor='transparent'
                    border='1px solid #D3BB8A'
                    borderRadius='25px'
                    fontSize='20px'
                    size='lg'
                    padding={['11px 16px']}
                    onClick={loadMoreExperiences}
                    _hover={{
                      backgroundColor: 'white',
                      color: 'black',
                    }}
                    isLoading={loader}
                  >
                    Load More
                  </Button>
                </Box>
              )}
          </Fragment>
        )}
      </VStack>
    </Fade>
  );
};

export default CommunityExperiencesView;
