import {
  Box,
  Button,
  Checkbox,
  Image,
  Input,
  Spinner,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { Disable } from '../../Icons/Disable';
import { Controller, useForm } from 'react-hook-form';
import DummyExperienceImage from '../../assets/image/DummyExperienceImage.jpeg';
import { CloseIcon } from '@chakra-ui/icons';
import { useUserStore } from '../../store/user';
import { useExperiencesStore } from '../../store/experiences';
import configureAxios from '../../axiosClient';
import { useCommunityStore } from '../../store/community';

interface ListUnListCommunityExperienceFormProps {
  setListUnListCommunityExperienceModalActive: (state: boolean) => void;
}

interface FormData {
  experienceName: string;
  tags: string[];
}

const ListUnlistCommunityExperienceForm = ({
  setListUnListCommunityExperienceModalActive,
}: ListUnListCommunityExperienceFormProps) => {
  const toast = useToast({
    position: 'top',
    variant: 'top-accent',
  });
  const { organisation } = useUserStore();
  const { selectedExperience } = useExperiencesStore();
  const {
    listedUnlistedCommunityExperience,
    resetListedUnlistedCommunityExperience,
    setListedUnlistedCommunityExperienceProcessing,
    setUnlistingProcessActive,
    setIsExperienceDelisted,
    isExperienceDelisted,
  } = useCommunityStore();

  const axiosClient = configureAxios();
  const organizationId = organisation.organizationId;

  const [unListingLoader, setUnlistingLoader] = useState(false);
  const [suggestedTags, setSuggestedTags] = useState<string[]>([]);
  const [tagInputValue, setTagInputValue] = useState('');

  const { control, handleSubmit, setValue, watch } = useForm<FormData>({
    defaultValues: {
      experienceName: selectedExperience.name,
      tags: selectedExperience.tags,
    },
  });

  const tags = watch('tags');

  const DelistCommunityExperience = async () => {
    setUnlistingLoader(true);
    setListedUnlistedCommunityExperienceProcessing(true);
    setUnlistingProcessActive(true);
    try {
      const res = await axiosClient.delete(
        `experience/delist/${organizationId}/${selectedExperience.id}`
      );
      toast({
        title: res.data.data,
        status: 'success',
      });
      setUnlistingLoader(false);
      setListUnListCommunityExperienceModalActive(false);
      setIsExperienceDelisted(!isExperienceDelisted);
      resetListedUnlistedCommunityExperience();
    } catch (error) {
      setUnlistingLoader(false);
      setListedUnlistedCommunityExperienceProcessing(false);
      resetListedUnlistedCommunityExperience();
      console.log('Error ', error);
    }
  };

  const handleTags = async (inputTag: string) => {
    setTagInputValue(inputTag);
    if (inputTag.trim()) {
      try {
        const res = await axiosClient.get(
          `experience/tags?tag=${inputTag.trim()}`
        );
        setSuggestedTags(res.data.data);
      } catch (error) {
        console.log('Error fetching tags:', error);
      }
    } else {
      setSuggestedTags([]);
    }
  };

  const addTag = (newTag: string) => {
    const tag = newTag.trim().replace(/\s+/g, '');
    if (tag && !tags.includes(tag)) {
      setValue('tags', [...tags, tag]);
    }
  };

  const removeTag = (index: number) => {
    setValue(
      'tags',
      tags.filter((_, i) => i !== index)
    );
  };

  const toggleTag = (tag: string) => {
    if (tags.includes(tag)) {
      setValue(
        'tags',
        tags.filter((t) => t !== tag)
      );
    } else {
      setValue('tags', [...tags, tag]);
      setTagInputValue('');
      setSuggestedTags([]);
    }
  };

  const onSubmit = async (data: FormData) => {
    try {
      const res = await axiosClient.put(
        `experience/update/community/${organizationId}/${selectedExperience.id}`,
        {
          name: data.experienceName,
          tag_list: data.tags,
        }
      );
      if (res.status === 200) {
        setListUnListCommunityExperienceModalActive(false);
        toast({
          title: 'Experience updated successfully',
          status: 'success',
        });
        resetListedUnlistedCommunityExperience();
      }
    } catch (error) {
      console.log('Error', error);
      toast({
        title: 'Failed to update experience',
        status: 'error',
      });
      resetListedUnlistedCommunityExperience();
    }
  };
  return (
    <Box display='flex' flexDirection='column'>
      {(listedUnlistedCommunityExperience?.community_url ||
        selectedExperience?.community_url) && (
        <Box
          width='100%'
          display='flex'
          justifyContent='center'
          border='1px solid #D6DBE4'
          padding='12px 24px 12px 24px'
          borderRadius='30px'
          gap={5}
          mt='40px'
          cursor={unListingLoader ? 'not-allowed' : 'pointer'}
          _hover={{
            backgroundColor: unListingLoader ? 'initial' : '#D3BB8A',
          }}
          onClick={() => {
            if (!unListingLoader) {
              DelistCommunityExperience();
            }
          }}
        >
          <Disable boxSize={5} />
          <Text
            fontSize='14px'
            fontFamily='Metal'
            color='#08101D'
            textTransform='uppercase'
          >
            Unlist from community
          </Text>
          {unListingLoader && <Spinner color='black' size='sm' />}
        </Box>
      )}
      <Box width='100%'>
        <Box
          height={['220px']}
          width={['full']}
          borderRadius='16px'
          bg='#1D2531'
          position='relative'
          margin='0 auto'
          mt={['15px']}
        >
          <Image
            src={
              selectedExperience?.preview_url
                ? selectedExperience.preview_url.replace(
                    'index.html',
                    'browser_icon.jpg'
                  )
                : DummyExperienceImage
            }
            objectFit='cover'
            width={['100%']}
            height={['140px']}
            borderTopRadius='16px'
            cursor='pointer'
          />
          <Box
            width={['100%']}
            height={['80px']}
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='flex-start'
            padding={['0px 20px']}
          >
            <Text fontFamily='Normal' color='white' fontSize={['15px']}>
              {selectedExperience.name}
            </Text>

            {selectedExperience.published_url &&
            selectedExperience.published_url?.length > 0 ? (
              <Box
                width='100%'
                display='flex'
                justifyContent='space-between'
                alignItems='center'
              >
                <Text
                  fontFamily='Normal'
                  fontSize={['11px']}
                  color='#4DFF7B'
                  maxW='100%'
                  noOfLines={1}
                >
                  {selectedExperience?.published_url}
                </Text>
              </Box>
            ) : (
              <Text fontFamily='Normal' fontSize={['11px']}>
                Not Published
              </Text>
            )}
          </Box>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            display='flex'
            justifyContent='center'
            flexDirection='column'
            mt='20px'
            width={['100%']}
          >
            <Controller
              name='experienceName'
              control={control}
              rules={{ required: 'Experience name is required' }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <Input
                    {...field}
                    color={'#08101D'}
                    placeholder='Experience Name'
                    borderBottom='1px solid #D6DBE4'
                    variant='flushed'
                    fontFamily='Normal'
                    fontSize={['14px', '14px', '14px']}
                    size='md'
                    _placeholder={{ color: '#7F8B9E' }}
                    errorBorderColor='#EA1A4C'
                    isInvalid={!!error}
                    mb='5px'
                    isDisabled={unListingLoader}
                  />
                  {error && (
                    <Text color='red' fontSize='12px'>
                      {error.message}
                    </Text>
                  )}
                </>
              )}
            />

            {Array.isArray(tags) && tags.length > 0 && (
              <Box
                display='flex'
                flexWrap='wrap'
                maxWidth='400px'
                gap={3}
                mt='15px'
              >
                {tags.map((tag, index) => (
                  <Box
                    key={index}
                    display='flex'
                    flexDirection='row'
                    maxWidth={['100px']}
                    justifyContent='space-between'
                    alignItems='center'
                    border='1px solid #D6DBE4'
                    borderRadius='30px'
                    gap={3}
                    p={'10px'}
                  >
                    <Text
                      width='auto'
                      color='#08101D'
                      fontFamily='Normal'
                      fontSize={['14px']}
                      display='block'
                      maxWidth={'60px'}
                      textOverflow='ellipsis'
                      whiteSpace='nowrap'
                      overflow='hidden'
                      title={tag}
                    >
                      {tag}
                    </Text>
                    <CloseIcon
                      boxSize={3}
                      color='black'
                      cursor={unListingLoader ? 'disabled' : 'pointer'}
                      onClick={() => removeTag(index)}
                    />
                  </Box>
                ))}
              </Box>
            )}

            <Controller
              name='tags'
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  value={tagInputValue}
                  color={'#08101D'}
                  id='tags'
                  type='text'
                  mt='15px'
                  placeholder='Add tags...'
                  borderBottom='1px solid #D6DBE4'
                  variant='flushed'
                  fontFamily='Normal'
                  fontSize={['14px', '14px', '14px']}
                  size='md'
                  _placeholder={{ color: '#7F8B9E' }}
                  errorBorderColor='#EA1A4C'
                  onChange={(e) => {
                    handleTags(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === ',' || e.key === 'Enter') {
                      e.preventDefault();
                      addTag(tagInputValue);
                      setTagInputValue('');
                    }
                  }}
                  isDisabled={unListingLoader}
                />
              )}
            />

            {suggestedTags.length > 0 && (
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-end'
                width='200px'
                height={['auto']}
                maxHeight='200px'
                overflowY='auto'
                borderRadius='16px'
                padding='20px'
                backdropFilter='blur(40px)'
                border='1px solid gold'
                boxShadow='0px 4px 4px 0px #00000040'
                m='15px 0px'
                gap={5}
              >
                {suggestedTags.map((tag, index) => (
                  <Box display='flex' key={index} gap={5} alignItems='center'>
                    <Checkbox
                      borderRadius='16px'
                      color='gold'
                      isChecked={tags && tags.includes(tag)}
                      onChange={() => toggleTag(tag)}
                    >
                      <Text
                        color='#08101D'
                        fontSize='16px'
                        fontFamily='Normal'
                        display='block'
                        maxWidth={['120px']}
                        textOverflow='ellipsis'
                        whiteSpace='nowrap'
                        overflow='hidden'
                        title={tag}
                      >
                        {tag}
                      </Text>
                    </Checkbox>
                  </Box>
                ))}
              </Box>
            )}
          </Box>

          <Box
            display='flex'
            flexDirection='column'
            justifyContent='space-between'
            alignItems='center'
            mt='40px'
          >
            <Button
              type='submit'
              textTransform='uppercase'
              width={['80%', '80%', '300px']}
              variant='outline'
              color='#08101D'
              background='white'
              fontFamily='Metal'
              fontSize={['14px', '14px', '15px']}
              borderRadius='30px'
              border='1px solid #D6DBE4'
              _hover={{
                bg: '#D3BB8A',
                borderColor: '#D3BB8A',
              }}
              isDisabled={unListingLoader}
            >
              Save
            </Button>

            <Button
              variant='link'
              fontFamily='Normal'
              textAlign='center'
              mt={['15px']}
              fontSize={['11px']}
              onClick={() => {
                resetListedUnlistedCommunityExperience();
                setListUnListCommunityExperienceModalActive(false);
              }}
            >
              Cancel
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default ListUnlistCommunityExperienceForm;
