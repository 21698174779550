import {
  Box,
  Button,
  Center,
  Divider,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { GoldStar } from '../../Icons/GoldStar';
import { AddUser } from '../../Icons/AddUser';
import UserDropdown from './UserDropdown';
import EmperiaCreatorLogo from '../../assets/image/EmperiaCreatorLogo.png';
import { Folder } from '../../Icons/Folder';
import { Play } from '../../Icons/Play';
import { Question } from '../../Icons/Question';
import { NavLink } from 'react-router-dom';
import useRoleAuthorization from '../../utils/useRoleAuthorization';
import React from 'react';
import { EmailIcon } from '@chakra-ui/icons';
import { usePlanStore } from '../../store/plan';
import NotificationDropdown from './NotificationDropdown';

interface NavbarProps {
  sideBarOpen: boolean;
  setShowPlans: (state: boolean) => void;
  setUserInvitesModalActive: (state: boolean) => void;
}

function Navbar({
  sideBarOpen,
  setShowPlans,
  setUserInvitesModalActive,
}: NavbarProps) {
  const hasAccess = useRoleAuthorization();
  const { setShowContactModal, setShowGettingStartedModal } = usePlanStore();

  return (
    <Box
      minW={
        sideBarOpen
          ? ['1250px', '1250px', '1250px', 'unset']
          : ['1070px', '1070px', '1070px', 'unset']
      }
      height={['65px']}
      w={['100%']}
      padding={['0px 40px']}
      borderBottom='1px solid rgba(242, 243, 245, 0.14)'
      display='flex'
      position='fixed'
      top='0px'
      bg='#08101D'
      zIndex='99'
    >
      <Box
        width={['auto']}
        height={['inherit']}
        display='flex'
        alignItems='center'
        gap={10}
      >
        <NavLink to={'/'}>
          <Image
            src={EmperiaCreatorLogo}
            w={['130px']}
            alt='Emperia Creator Tools'
          />
        </NavLink>

        <Menu placement='bottom'>
          <MenuButton
            as={Button}
            textTransform='uppercase'
            fontSize={['14px']}
            fontFamily='Metal'
            variant='link'
            color='white'
          >
            Resources
          </MenuButton>
          <MenuList
            padding={['16px']}
            borderRadius='30px'
            width={['100px']}
            zIndex='999'
            gap={2}
            display='flex'
            flexDirection='column'
          >
            <MenuItem
              display='flex'
              justifyContent='flex-start'
              alignItems='center'
              padding={['8px 12px']}
              borderRadius='30px'
              border='1px solid #D6DBE4'
              background='FFF'
              _hover={{
                background: 'rgba(29, 37, 49, 0.05)',
              }}
              transition='0.2s all'
              onClick={() => setShowGettingStartedModal(true)}
            >
              <Play boxSize={[4]} />
              <Text
                ml={['10px']}
                textTransform='uppercase'
                fontSize={['14px']}
                fontWeight='900'
                color='#08101D'
                fontFamily='Metal'
              >
                Getting Started
              </Text>
            </MenuItem>

            <Link
              href={process.env.REACT_APP_DOCUMENTATION_URL}
              target='_blank'
            >
              <MenuItem
                display='flex'
                justifyContent='flex-start'
                alignItems='center'
                padding={['8px 12px']}
                borderRadius='30px'
                border='1px solid #D6DBE4'
                background='FFF'
                _hover={{
                  background: 'rgba(29, 37, 49, 0.05)',
                }}
                transition='0.2s all'
              >
                <Folder boxSize={[4]} />
                <Text
                  ml={['10px']}
                  textTransform='uppercase'
                  fontSize={['14px']}
                  fontWeight='900'
                  color='#08101D'
                  fontFamily='Metal'
                >
                  DOCUMENTATION
                </Text>
              </MenuItem>
            </Link>

            <Link
              href={process.env.REACT_APP_VIDEO_TUTORIALS_URL}
              target='_blank'
            >
              <MenuItem
                display='flex'
                justifyContent='flex-start'
                alignItems='center'
                padding={['8px 12px']}
                borderRadius='30px'
                border='1px solid #D6DBE4'
                background='FFF'
                _hover={{
                  background: 'rgba(29, 37, 49, 0.05)',
                }}
                transition='0.2s all'
              >
                <Play boxSize={[4]} />
                <Text
                  ml={['10px']}
                  textTransform='uppercase'
                  fontSize={['14px']}
                  fontWeight='900'
                  color='#08101D'
                  fontFamily='Metal'
                >
                  video TUTORIALS
                </Text>
              </MenuItem>
            </Link>

            <Link href={process.env.REACT_APP_FAQ_URL} target='_blank'>
              <MenuItem
                display='flex'
                justifyContent='flex-start'
                alignItems='center'
                padding={['8px 12px']}
                borderRadius='30px'
                border='1px solid #D6DBE4'
                background='FFF'
                _hover={{
                  background: 'rgba(29, 37, 49, 0.05)',
                }}
                transition='0.2s all'
              >
                <Question boxSize={[4]} />
                <Text
                  ml={['10px']}
                  textTransform='uppercase'
                  fontSize={['14px']}
                  fontWeight='900'
                  color='#08101D'
                  fontFamily='Metal'
                >
                  FAQ
                </Text>
              </MenuItem>
            </Link>

            <MenuItem
              display='flex'
              justifyContent='flex-start'
              alignItems='center'
              padding={['8px 12px']}
              borderRadius='30px'
              border='1px solid #D6DBE4'
              background='FFF'
              _hover={{
                background: 'rgba(29, 37, 49, 0.05)',
              }}
              transition='0.2s all'
              onClick={() => setShowContactModal(true)}
            >
              <EmailIcon color='#08101D' boxSize={[4]} />
              <Text
                ml={['10px']}
                textTransform='uppercase'
                fontSize={['14px']}
                fontWeight='900'
                color='#08101D'
                fontFamily='Metal'
              >
                Contact Us
              </Text>
            </MenuItem>
          </MenuList>
        </Menu>

        {hasAccess && (
          <Button
            leftIcon={
              <GoldStar
                stroke='currentColor'
                fill='transparent'
                boxSize={[5]}
              />
            }
            variant='link'
            background='transparent'
            _hover={{
              bg: 'transparent',
            }}
            color='#D3BB8A'
            textTransform='uppercase'
            fontFamily='Metal'
            fontSize={['14px']}
            onClick={() => setShowPlans(true)}
          >
            Upgrade
          </Button>
        )}
      </Box>

      <Box
        height={['inherit']}
        flex='1'
        display='flex'
        justifyContent='flex-end'
        alignItems='center'
      >
        <Box
          width={['auto']}
          height={['inherit']}
          display='flex'
          justifyContent='space-evenly'
          alignItems='center'
          gap={5}
        >
          <Box cursor='pointer' position='relative'>
            <NotificationDropdown />
          </Box>
          {hasAccess && (
            <AddUser
              stroke='white'
              _hover={{ stroke: '#D3BB8A' }}
              cursor='pointer'
              transition='0.2s all'
              boxSize={[5]}
              onClick={() => setUserInvitesModalActive(true)}
            />
          )}

          {hasAccess && (
            <Center height='30px' margin={['0px 16px']}>
              <Divider
                orientation='vertical'
                borderColor='rgba(242, 243, 245, 0.14)'
              />
            </Center>
          )}

          <UserDropdown />
        </Box>
      </Box>
    </Box>
  );
}

export default Navbar;
