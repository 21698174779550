import { Box, Text } from '@chakra-ui/react';
import React from 'react';

export interface BillingOptionProps {
  title: string;
  description: string;
  onClick?: () => void;
}

function BillingOption({ title, description, onClick }: BillingOptionProps) {
  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      p={['16px']}
      width={['inherit']}
      borderRadius='16px'
      border='1px solid rgba(242, 243, 245, 0.14)'
      _hover={{
        bg: 'rgba(0,0,0,0.2)',
      }}
      cursor='pointer'
      transition='0.2s all'
      onClick={onClick}
    >
      <Text fontSize={['15px']} fontFamily='Normal' textTransform='capitalize'>
        {title}
      </Text>
      <Box fontSize={['11px']} fontFamily='Normal'>
        {description}
      </Box>
    </Box>
  );
}

export default BillingOption;
