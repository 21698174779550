import { Box } from '@chakra-ui/react';
import React from 'react';

interface BillingTabItemProps {
  text: string;
  active: boolean;
  setActive: () => void;
}

function BillingTabItem({ text, active, setActive }: BillingTabItemProps) {
  return (
    <Box
      padding={['10px 30px']}
      bg={active ? '#1D2531' : 'unset'}
      _hover={{
        bg: '#1D2531',
        color: 'white',
      }}
      color={active ? 'white' : '#58677e'}
      textTransform='capitalize'
      borderRadius='30px'
      transition='0.2s'
      fontSize={['15px']}
      fontFamily='Normal'
      fontWeight='900'
      cursor='pointer'
      onClick={setActive}
    >
      {text}
    </Box>
  );
}

export default BillingTabItem;
