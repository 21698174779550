import {
  Box,
  Button,
  Code,
  Divider,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import { RightStemArrow } from '../../Icons/RightStemArrow';
import { useUserStore } from '../../store/user';
import { CopyText } from '../../Icons/CopyText';
import { useExperiencesStore } from '../../store/experiences';

interface EmbedIframeModalProps {
  embedIframeModalActive: boolean;
  setEmbedIframeModalActive: (state: boolean) => void;
}

function EmbedIframeModal({
  embedIframeModalActive,
  setEmbedIframeModalActive,
}: EmbedIframeModalProps) {
  const { organisation } = useUserStore();
  const { selectedExperience } = useExperiencesStore();
  const toast = useToast({
    position: 'top',
    variant: 'top-accent',
  });

  const handleCopy = async (text: string) => {
    try {
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(text);
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand('copy');
        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err);
        }
        document.body.removeChild(textArea);
      }
      if (!toast.isActive('codeCopy')) {
        toast({
          id: 'codeCopy',
          title: `Code Copied`,
          status: 'success',
        });
      }
    } catch (err) {
      console.error('Async: Could not copy text', err);
    }
  };

  const getCode = (code: string) => {
    return (
      <Box
        height='auto'
        width='100%'
        borderTop='1px solid rgba(214, 219, 228, 1)'
        boxShadow='0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
        borderRadius='16px'
        position='relative'
        overflowX='hidden'
        style={{ scrollbarWidth: 'none' }}
      >
        <IconButton
          position='absolute'
          borderRadius='100%'
          border='1px solid #D6DBE4'
          top='10px'
          right='10px'
          icon={<CopyText boxSize={4} stroke='rgba(8, 16, 29, 1)' />}
          size='sm'
          aria-label={'Copy Text'}
          onClick={() => handleCopy(code)}
        />
        <Code
          minHeight='120px'
          fontSize='12px'
          display='flex'
          alignItems='center'
          whiteSpace='pre'
          fontFamily='Normal'
          color='#58677E'
          bg='transparent'
        >
          {code}
        </Code>
      </Box>
    );
  };

  const codeOne = `
    <script type="module" async
    src="https://sdk.emperiavr.com/Production/latest/emperia-sdk.min.js">
    </script>
              `;

  const codeTwo = `
    <script>
        window.emperiaAsyncInit = function () {
            emperia.init({
                id: "experience-root",
                organization_id: ${organisation.organizationId},
                experience_url: ${selectedExperience?.published_url?.replace(
                  'index.html',
                  'experience.html'
                )},
                ui_url: ${process.env.REACT_APP_STANDARD_UI_URL},
                attach_ui: true,
                debug: false,
            });
        }
    </script>
              `;

  const codeThree = `
    <div id="experience-root" style="
      position: absolute;
      height: 100vh;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      overflow: hidden;">
    </div>
              `;

  return (
    <Modal
      isOpen={embedIframeModalActive}
      onClose={() => setEmbedIframeModalActive(false)}
      isCentered
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent
        minW={['600px']}
        height={['auto']}
        margin='auto'
        maxHeight='80vh'
        overflow='auto'
        padding={['20px']}
        borderRadius='16px'
        boxShadow='0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
      >
        <CloseIcon
          position='absolute'
          boxSize={[4]}
          cursor='pointer'
          color='#D6DBE4'
          _hover={{ color: 'black' }}
          transition='0.2s all'
          right={['20px']}
          top={['20px']}
          onClick={() => setEmbedIframeModalActive(false)}
        />
        <ModalBody
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          alignItems='center'
          gap={5}
        >
          <Text
            color='#08101D'
            fontFamily='Metal'
            fontSize={['35px']}
            lineHeight={['35px']}
            textAlign='center'
            textTransform='uppercase'
          >
            EMBED EXPERIENCE
          </Text>

          <Box>
            <Text
              fontFamily='Normal'
              fontSize='15px'
              color='rgba(8, 16, 29, 1)'
              textAlign='center'
            >
              Embedding the virtual experience into Your website
            </Text>
            <Text
              fontFamily='Normal'
              fontSize='12px'
              color='rgba(8, 16, 29, 1)'
              textAlign='center'
            >
              Copy the code below and paste it onlto every page of your website.
            </Text>
            <Text
              fontFamily='Normal'
              fontSize='12px'
              color='rgba(8, 16, 29, 1)'
              textAlign='center'
            >
              To learn more visit:
            </Text>

            <Link
              href={process.env.REACT_APP_DOCUMENTATION_URL}
              target='_blank'
            >
              <Button
                rightIcon={
                  <RightStemArrow
                    stroke='currentColor'
                    fill='currentColor'
                    boxSize={[3]}
                    transform='rotate(-45deg)'
                  />
                }
                fontSize={['11px']}
                variant='link'
                color='#08101D'
                fontFamily='Normal'
                textAlign='center'
              >
                Documentation/ Embedding the virtual experience into Your
                website
              </Button>
            </Link>
          </Box>

          <Box width='100%'>
            <Tabs
              variant='soft-rounded'
              colorScheme='gray'
              border='1px solid lightgrey'
              borderRadius='16px'
              padding='10px'
            >
              <TabList>
                <Tab color='#08101D' fontFamily='Normal' fontSize={['15px']}>
                  Step One
                </Tab>
                <Tab color='#08101D' fontFamily='Normal' fontSize={['15px']}>
                  Step Two
                </Tab>
                <Tab color='#08101D' fontFamily='Normal' fontSize={['15px']}>
                  Step Three
                </Tab>
              </TabList>
              <Divider width='100%' mt='10px' />
              <TabPanels>
                <TabPanel>
                  <Box display='flex' flexDirection='column' gap={2}>
                    <Text
                      fontFamily='Normal'
                      fontSize='12px'
                      color='rgba(8, 16, 29, 1)'
                    >
                      Paste this code as high in the&nbsp;
                      <span style={{ fontFamily: 'Medium', fontWeight: 900 }}>
                        {'<head>'}
                      </span>{' '}
                      of the page as possible:
                    </Text>
                    {getCode(codeOne)}
                  </Box>
                </TabPanel>
                <TabPanel>
                  <Box display='flex' flexDirection='column' gap={2}>
                    <Text
                      fontFamily='Normal'
                      fontSize='12px'
                      color='rgba(8, 16, 29, 1)'
                    >
                      Paste this code immediately after the above:
                    </Text>
                    {getCode(codeTwo)}
                  </Box>
                </TabPanel>
                <TabPanel>
                  <Box display='flex' flexDirection='column' gap={2}>
                    <Text
                      fontFamily='Normal'
                      fontSize='12px'
                      color='rgba(8, 16, 29, 1)'
                    >
                      Paste this code immediately after the&nbsp;
                      <span style={{ fontFamily: 'Medium', fontWeight: 900 }}>
                        {'<body>'}
                      </span>{' '}
                      tag:
                    </Text>
                    {getCode(codeThree)}
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>

            {/* <Box display="flex" flexDirection="column" gap={5}> */}
            {/* <Box display="flex" flexDirection="column" gap={2}>
                <Box display="flex" alignItems="center" gap={2}>
                  <Text fontFamily="Medium" fontWeight={900} fontSize="12px" color="rgba(8, 16, 29, 1)">
                    1.
                  </Text>
                  <Text fontFamily="Normal" fontSize="12px" color="rgba(8, 16, 29, 1)">
                    Paste this code as high in the&nbsp;
                    <span style={{ fontFamily: 'Medium', fontWeight: 900 }}>{'<head>'}</span> of the page as possible
                  </Text>
                </Box>
                {getCode(codeOne)}
              </Box> */}

            {/* <Box display="flex" flexDirection="column" gap={2}>
                <Box display="flex" alignItems="center" gap={2}>
                  <Text fontFamily="Medium" fontWeight={900} fontSize="12px" color="rgba(8, 16, 29, 1)">
                    2.
                  </Text>
                  <Text fontFamily="Normal" fontSize="12px" color="rgba(8, 16, 29, 1)">
                    Paste this code immediately after the above
                  </Text>
                </Box>
                {getCode(codeTwo)}
              </Box> */}

            {/* <Box display="flex" flexDirection="column" gap={2}>
                <Box display="flex" alignItems="center" gap={2}>
                  <Text fontFamily="Medium" fontWeight={900} fontSize="12px" color="rgba(8, 16, 29, 1)">
                    3.
                  </Text>
                  <Text fontFamily="Normal" fontSize="12px" color="rgba(8, 16, 29, 1)">
                    Paste this code immediately after the&nbsp;
                    <span style={{ fontFamily: 'Medium', fontWeight: 900 }}>{'<body>'}</span> tag
                  </Text>
                </Box>
                {getCode(codeThree)}
              </Box> */}
            {/* </Box> */}
          </Box>

          <Box
            display='flex'
            flexDirection='column'
            justifyContent='space-between'
            alignItems='center'
          >
            <Button
              textTransform='uppercase'
              width={['80%', '80%', '300px']}
              variant='outline'
              color='#08101D'
              background='white'
              fontFamily='Metal'
              fontSize={['14px', '14px', '14px']}
              borderRadius='30px'
              border='1px solid #D6DBE4'
              _hover={{
                bg: '#D3BB8A',
                borderColor: '#D3BB8A',
              }}
              onClick={() => setEmbedIframeModalActive(false)}
            >
              Close
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default EmbedIframeModal;
