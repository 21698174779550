import { Box } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';
import { SidebarOption } from '../../interfaces';
import { Experiences } from '../../Icons/Experiences';
import { Plugins } from '../../Icons/Plugins';
import { Processes } from '../../Icons/Processes';
import Plans from './Plans';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import { useUserStore } from '../../store/user';
import UserInvitesModal from '../modals/UserInvitesModal';
import CancelContactModal from '../modals/CancelContactModal';
import { usePlanStore } from '../../store/plan';
import { useAuthStore } from '../../store/auth';
import React from 'react';
import { useExperiencesStore } from '../../store/experiences';
import EnterpriseContactModal from '../modals/EnterpriseContactModal';
import configureAxios from '../../axiosClient';
import EmbedIframeModal from '../modals/EmbedIframeModal';
import ContactModal from '../modals/ContactModal';
import GettingStartedModal from '../modals/GettingStartedModal';
import NearingUsageLimitModal from '../modals/NearingUsageLimitModal';
import LimitReachedModal from '../modals/LimitReachedModal';
import ViewerNavbar from './ViewerNavbar';
import UnPublishExperienceModal from '../modals/UnPublishExperienceModal';
import ShareCommunityExperienceModal from '../modals/ShareCommunityExperienceModal';
import { useCommunityStore } from '../../store/community';
import ListUnListCommunityExperienceModal from '../modals/ListUnListCommunityExperienceModal';
import { PublishExperienceModal } from '../modals/PublishExperienceModal';

interface OverlayProps {
  children: React.ReactNode;
}

const axiosClient = configureAxios();

function Overlay({ children }: OverlayProps) {
  const location = useLocation();

  const [userInvitesModalActive, setUserInvitesModalActive] = useState(false);
  const [viewerActive, setViewerActive] = useState(false);

  const [activeView, setActiveView] = useState('experiences');
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const { organisation, getUser, getOrganizations } = useUserStore();
  const {
    showGettingStartedModal,
    setShowPlans,
    showCancelPlan,
    showContactModal,
    setShowContactModal,
    setShowCancelPlans,
    showEnterpriseForm,
    setShowEnterpriseForm,
    setShowGettingStartedModal,
  } = usePlanStore();
  const { isLoggedIn } = useAuthStore();

  const {
    showEmbedModal,
    setShowEmbedModal,
    unPublishExperience,
    viewerUnPublishExperienceModalActive,
    showNearingUsageLimitActive,
    shareCommunityExperience,
    showLimitReachedModalActive,
    publishExperienceModalActive,
    setNearingUsageLimitActive,
    setLimitReachedModalActive,
    shareCommunityExperienceModalActive,
    setShareCommunityExperienceModalActive,
    setViewerUnPublishExperienceModalActive,
    setPublishExperienceModalActive,
  } = useExperiencesStore();
  const {
    listUnListCommunityExperienceModalActive,
    setListUnListCommunityExperienceModalActive,
  } = useCommunityStore();

  useEffect(() => {
    isLoggedIn && getUser();
    isLoggedIn && getOrganizations();
  }, [isLoggedIn]);

  useEffect(() => {
    const userOrgId = organisation.organizationId;
    const getUsageStatus = async () => {
      if (userOrgId?.length > 0) {
        try {
          const res = await axiosClient.get(`org/${userOrgId}/usage/status`);
          if (res.data.status === 'OK') {
            res.data = res.data.data;
            if (
              res.data.is_organisation_blocked_due_to_bandwidth ||
              res.data.is_organisation_blocked_due_to_storage
            ) {
              if (
                res.data.is_bandwidth_usage_high ||
                res.data.is_storage_usage_high
              ) {
                setLimitReachedModalActive(true);
              }
            } else {
              if (
                res.data.is_bandwidth_usage_high ||
                res.data.is_storage_usage_high
              ) {
                setNearingUsageLimitActive(true);
              }
            }
          }
        } catch (err) {
          console.log('Error', err);
        }
      }
    };
    getUsageStatus();
  }, [organisation.organizationId]);

  useEffect(() => {
    if (location.pathname.includes('viewer')) {
      setViewerActive(true);
    } else {
      setViewerActive(false);
      const activeSidebarOption = sidebarOptions.find(
        (sidebarOption: SidebarOption) =>
          sidebarOption.href === location.pathname
      );
      activeSidebarOption
        ? setActiveView(activeSidebarOption.key)
        : setActiveView('');
    }
  }, [location.pathname]);

  const sidebarOptions: SidebarOption[] = [
    {
      href: '/',
      key: 'experiences',
      text: 'Experiences',
      sideBarIcon: <Experiences stroke='currentColor' boxSize={[5]} />,
    },
    {
      href: '/plugins',
      key: 'plugins',
      text: 'Plugins',
      sideBarIcon: <Plugins stroke='currentColor' boxSize={[5]} />,
    },
    {
      href: '/processes',
      key: 'processes',
      text: 'Processes',
      sideBarIcon: <Processes stroke='currentColor' boxSize={[5]} />,
    },
  ];

  return (
    <Fragment>
      <Plans />

      <GettingStartedModal
        gettingStartedActive={showGettingStartedModal}
        setGettingStartedActive={(state) => setShowGettingStartedModal(state)}
      />

      <UserInvitesModal
        userInvitesModalActive={userInvitesModalActive}
        setUserInvitesModalActive={(state) => setUserInvitesModalActive(state)}
      />

      <CancelContactModal
        cancelPlanModalActive={showCancelPlan}
        setCancelPlanModalActive={(state) => setShowCancelPlans(state)}
      />

      <ContactModal
        contactModalActive={showContactModal}
        setContactModalActive={(state) => setShowContactModal(state)}
      />

      <EnterpriseContactModal
        enterpriseContactActive={showEnterpriseForm}
        setEnterpriseContactActive={(state) => setShowEnterpriseForm(state)}
      />

      <EmbedIframeModal
        embedIframeModalActive={showEmbedModal}
        setEmbedIframeModalActive={(state) => setShowEmbedModal(state)}
      />

      <NearingUsageLimitModal
        nearingUsageLimitActive={showNearingUsageLimitActive}
        setContactModalActive={setShowContactModal}
        setNearingUsageLimitActive={setNearingUsageLimitActive}
      />
      <LimitReachedModal
        limitReachedModalActive={showLimitReachedModalActive}
        setContactModalActive={setShowContactModal}
        setLimitReachedModalActive={setLimitReachedModalActive}
      />

      <UnPublishExperienceModal
        unPublishExperienceModalActive={viewerUnPublishExperienceModalActive}
        setUnPublishExperienceModalActive={(state: boolean) =>
          setViewerUnPublishExperienceModalActive(state)
        }
        unPublishExperience={(experienceId: string) => {
          unPublishExperience(experienceId);
          setViewerUnPublishExperienceModalActive(false);
        }}
      />

      <PublishExperienceModal
        publishExperienceModalActive={publishExperienceModalActive}
        setPublishExperienceModalActive={setPublishExperienceModalActive}
        shareCommunityExperience={(experienceId: string) => {
          shareCommunityExperience(experienceId);
        }}
      />

      <ShareCommunityExperienceModal
        shareCommunityExperienceModalActive={
          shareCommunityExperienceModalActive
        }
        setShareCommunityExperienceModalActive={
          setShareCommunityExperienceModalActive
        }
        shareCommunityExperience={(experienceId: string) => {
          shareCommunityExperience(experienceId);
          setShareCommunityExperienceModalActive(false);
        }}
      />

      <ListUnListCommunityExperienceModal
        listUnlistCommunityExperienceModalActive={
          listUnListCommunityExperienceModalActive
        }
        setListUnListCommunityExperienceModalActive={
          setListUnListCommunityExperienceModalActive
        }
      />

      <Box
        w='100%'
        h={viewerActive ? 'calc(100vh - 15px)' : '100vh'}
        display='flex'
        flexDirection='column'
        bg='#08101D'
        color='white'
      >
        {viewerActive ? (
          <ViewerNavbar />
        ) : (
          <Fragment>
            <Navbar
              sideBarOpen={sideBarOpen}
              setShowPlans={(state) => setShowPlans(state)}
              setUserInvitesModalActive={(state) =>
                setUserInvitesModalActive(state)
              }
            />

            <Sidebar
              sideBarOpen={sideBarOpen}
              activeView={activeView}
              sidebarOptions={sidebarOptions}
              setActiveView={(view) => setActiveView(view)}
              setSideBarOpen={(state) => setSideBarOpen(state)}
            />
          </Fragment>
        )}

        <Box
          display='flex'
          flex='1'
          bg='#08101D'
          color='white'
          mt={viewerActive ? 0 : ['65px']}
          transition={!viewerActive ? '0.2s all' : 'unset'}
          ml={viewerActive ? 0 : sideBarOpen ? '220px' : '60px'}
          height={viewerActive ? '100%' : ['calc(100vh - 65px)']}
          zIndex={viewerActive ? 'auto' : '50'}
          overflow={viewerActive ? 'hidden' : 'visible'}
        >
          <Box flex='1' w='100%' h='max-content' bg='#08101D' color='white'>
            {children}
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
}

export default Overlay;
