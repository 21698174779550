import { Box, Button, Switch, Text, useToast } from '@chakra-ui/react';
import React, { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { dispatchExperienceToAnalytics } from '../../analytics';
import { Copy } from '../../Icons/Copy';
import { useExperiencesStore } from '../../store/experiences';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useUserStore } from '../../store/user';
import configureAxios from '../../axiosClient';
import { Eye } from '../../Icons/Eye';
import { Fullscreen } from '../../Icons/Fullscreen';
import { ArrowLeftIcon } from '@chakra-ui/icons';
import SceneDropDown from './SceneDropDown';
import { useCommunityStore } from '../../store/community';

const axiosClient = configureAxios();

function ViewerNavbar() {
  const toast = useToast({
    position: 'top',
    variant: 'top-accent',
  });
  const navigate = useNavigate();
  const [param] = useSearchParams();
  const isCommunityExperience = param.get('isCommunity');
  const {
    selectedExperience,
    selectedExperienceScenes,
    // setSelectedExperience,
    setViewerUnPublishExperienceModalActive,
    setPublishExperienceModalActive,
  } = useExperiencesStore();
  const { setIsFeaturedExperience } = useCommunityStore();
  const { organisation, user } = useUserStore();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSharingToCommunity, setIsSharingToCommunity] = useState(false);
  const [debugActive, setDebugActive] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [showSceneChanger, setShowSceneChanger] = useState(false);
  const [selectedScene, setSelectedScene] = useState('');

  // Reference date: 3 PM on August 30th, 2024
  const referenceDate = new Date('2024-08-30T00:00:00');

  useEffect(() => {
    if (selectedExperience && selectedExperience.created) {
      const experienceCreatedDate = new Date(selectedExperience.created);

      if (
        process.env.NODE_ENV === 'production' //prod & staging
      ) {
        if (experienceCreatedDate.getTime() > referenceDate.getTime()) {
          setShowSceneChanger(true);
        } else {
          setShowSceneChanger(false);
        }
      } else {
        //local
        setShowSceneChanger(true);
      }
    }
  }, [selectedExperience]);

  useEffect(() => {
    setSelectedScene(selectedExperienceScenes[0]?.name);

    return () => {
      setSelectedScene('');
    };
  }, [selectedExperienceScenes]);

  const activateDebugMode = (event: ChangeEvent<HTMLInputElement>) => {
    setDebugActive(event.target.checked);
    const iframe = document.getElementById(
      'experience-container'
    ) as HTMLIFrameElement;
    if (event.target.checked) {
      iframe.src = iframe.src + '?debug=true';
    } else {
      iframe.src = iframe.src.replace('?debug=true', '');
    }
  };

  const handlePublish = () => {
    if (
      selectedExperience.published_url &&
      selectedExperience.published_url?.length > 0
    ) {
      setViewerUnPublishExperienceModalActive(true);
    } else {
      // publishExperience();
      setPublishExperienceModalActive(true);
    }
  };

  const DelistCommunityExperience = async () => {
    setIsSubmitting(true);
    try {
      const res = await axiosClient.delete(
        `experience/delist/${organisation.organizationId}/${selectedExperience.id}`
      );
      toast({
        title: res.data.data,
        status: 'success',
      });
      navigate('/');
      setIsSubmitting(false);
    } catch (error) {
      console.log('Error ', error);
      setIsSubmitting(false);
    }
  };

  const ShareToCommunity = async () => {
    setIsSharingToCommunity(true);
    try {
      const res = await axiosClient.post(
        `experience/list/${organisation.organizationId}/${selectedExperience.id}`,
        {
          name: selectedExperience.name,
          tag_list: [],
        }
      );
      toast({
        title: res.data.data,
        status: 'success',
      });
      setIsFeaturedExperience(false);
      setIsSharingToCommunity(false);
      navigate('/community');
    } catch (error) {
      console.log('Error ', error);
      setIsSharingToCommunity(false);
    }
  };

  // const publishExperience = () => {
  //   const userOrgId = organisation.organizationId;

  //   setIsSubmitting(true);
  //   axiosClient
  //     .post(`/experience/publish/${userOrgId}/${selectedExperience.id}`)
  //     .then((res) => {
  //       const isStatusOk = res.data.status === 'OK';
  //       if (isStatusOk) {
  //         dispatchExperienceToAnalytics('publish_experience', {
  //           experience_id: selectedExperience.id,
  //           organisation_id: userOrgId,
  //           organisation_user_id: user.id,
  //           experience_name: selectedExperience.name,
  //           experience_draft_url: selectedExperience.preview_url,
  //           experience_published_url: res.data?.data?.published_url,
  //         });
  //         if (res?.data?.data?.error?.length > 0) {
  //           toast({
  //             title: `A problem occurred publishing the experience.`,
  //             status: 'error',
  //           });
  //         }
  //         // else {
  //         //   setSelectedExperience({
  //         //     ...selectedExperience,
  //         //     published_url: res.data?.data?.published_url,
  //         //   });
  //         //   toast({
  //         //     title: `Your experience was published successfully.`,
  //         //     status: 'success',
  //         //   });
  //         //   setShareCommunityExperienceModalActive(true);
  //         // }
  //       } else {
  //         if (res.data.data === 'Insufficient Permissions.') {
  //           toast({
  //             title: `Insufficient Permissions`,
  //             status: 'info',
  //           });
  //         } else {
  //           toast({
  //             title: `A problem occurred publishing the experience.`,
  //             status: 'error',
  //           });
  //         }
  //       }
  //       setIsSubmitting(false);
  //     })
  //     .catch((err: unknown) => {
  //       console.log(err);
  //       setIsSubmitting(false);
  //       toast({
  //         title: `A problem occurred publishing the experience.`,
  //         status: 'error',
  //       });
  //     });
  // };

  const handleCopy = async (text: string, toastMessage?: string) => {
    try {
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(text);
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand('copy');
        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err);
        }
        document.body.removeChild(textArea);
      }
      if (!toast.isActive('viewerLink')) {
        toast({
          id: 'viewerLink',
          title: toastMessage,
          status: 'success',
        });
      }
    } catch (err) {
      console.error('Async: Could not copy text', err);
    }
  };

  return (
    <Fragment>
      <Button
        zIndex={99}
        opacity={isFullScreen ? 1 : 0}
        visibility={isFullScreen ? 'visible' : 'hidden'}
        transition='all 0.2s'
        position='fixed'
        top='10px'
        left='0px'
        right='0px'
        margin='auto'
        textTransform='uppercase'
        width='fit-content'
        variant='outline'
        color='#08101D'
        background='white'
        border='1px solid rgba(242, 243, 245, 0.14)'
        fontFamily='Metal'
        fontSize={['14px', '14px', '15px']}
        borderRadius='30px'
        _hover={{
          bg: '#D3BB8A',
        }}
        onClick={() => setIsFullScreen(false)}
      >
        Exit Fullscreen
      </Button>
      <Box
        opacity={!isFullScreen ? 1 : 0}
        visibility={!isFullScreen ? 'visible' : 'hidden'}
        transition='all 0.2s'
        position='absolute'
        height={['65px']}
        borderBottom='1px solid rgba(242, 243, 245, 0.14)'
        background='rgba(8, 16, 29, 0.60)'
        backdropFilter='blur(20px)'
        color='#fff'
        textAlign='center'
        zIndex={50}
        width={['100%']}
        padding={['0px 20px']}
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <Box
          height={['inherit']}
          width={['auto']}
          display='flex'
          alignItems='center'
          justifyContent='center'
          gap={3}
        >
          <ArrowLeftIcon
            boxSize={[4]}
            stroke='white'
            cursor='pointer'
            onClick={() => navigate('/')}
          />
          <Box
            height={['inherit']}
            width={['auto']}
            display='flex'
            flexDirection='column'
            alignItems='flex-start'
            justifyContent='center'
          >
            <Text fontFamily='Normal' fontSize={['15px']}>
              {selectedExperience.name + ' ' + selectedExperience.description}
            </Text>
            <Text
              fontFamily='Normal'
              fontSize={['13px']}
              color='#4DFF7B'
              maxW={['150px']}
              noOfLines={1}
            >
              {selectedExperience.preview_url}
            </Text>
          </Box>

          <Button
            variant='link'
            leftIcon={<Copy stroke='currentColor' boxSize={[4]} />}
            fontFamily='Normal'
            fontSize={['12px']}
            color='#FFFFFF'
            onClick={() =>
              selectedExperience.preview_url &&
              handleCopy(selectedExperience.preview_url, 'Link Copied')
            }
          >
            Copy Link
          </Button>

          <Button
            rightIcon={
              <Switch
                id='toggle-debug'
                size='sm'
                colorScheme='blue'
                isChecked={debugActive}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  activateDebugMode(event);
                  dispatchExperienceToAnalytics('view_debug_mode', {
                    experience_id: selectedExperience.id,
                    organisation_id: organisation.organizationId,
                    organisation_user_id: user.id,
                    experience_name: selectedExperience.name,
                    experience_draft_url: selectedExperience.preview_url,
                    experience_published_url: selectedExperience.published_url,
                  });
                }}
              />
            }
            padding='0px'
            variant='ghost'
            background='transparent'
            _hover={{
              bg: 'transparent',
            }}
            _active={{
              bg: 'transparent',
            }}
            _focus={{
              bg: 'transparent',
            }}
            color='white'
            fontFamily='Normal'
            fontSize={['12px']}
          >
            Debug
          </Button>
        </Box>

        <Box
          height={['inherit']}
          width={['auto']}
          display='flex'
          alignItems='center'
          gap={3}
        >
          {selectedExperience?.community_url?.length === 0 &&
            !selectedExperience.is_community_shared && (
              <Button
                textTransform='uppercase'
                width={['auto', 'auto', 'auto']}
                variant='outline'
                color='#08101D'
                background='white'
                border='1px solid rgba(242, 243, 245, 0.14)'
                fontFamily='Metal'
                fontSize={['14px', '14px', '15px']}
                borderRadius='30px'
                _hover={{
                  bg: '#D3BB8A',
                }}
                isLoading={isSharingToCommunity}
                onClick={ShareToCommunity}
              >
                Share to community
              </Button>
            )}
          {showSceneChanger && (
            <Box display='flex' alignItems='center' gap={2}>
              <Eye stroke={'#fff'} boxSize={[5]} />
              <Text fontFamily='Normal' fontSize={['14px']}>
                View scene:
              </Text>
              <SceneDropDown
                selectedScene={selectedScene}
                setSelectedScene={setSelectedScene}
                scenes={selectedExperienceScenes}
                handleCopy={handleCopy}
              />
            </Box>
          )}

          {!isCommunityExperience &&
            selectedExperience.published_url &&
            selectedExperience.published_url?.length > 0 && (
              <Button
                textTransform='uppercase'
                width={['auto', 'auto', 'auto']}
                variant='outline'
                color='#08101D'
                background='white'
                border='1px solid rgba(242, 243, 245, 0.14)'
                fontFamily='Metal'
                fontSize={['14px', '14px', '15px']}
                borderRadius='30px'
                _hover={{
                  bg: '#D3BB8A',
                }}
                onClick={() =>
                  window.open(selectedExperience.published_url, '_blank')
                }
              >
                View Published Version
              </Button>
            )}

          {!isCommunityExperience && (
            <Button
              textTransform='uppercase'
              width={['auto', 'auto', 'auto']}
              variant='outline'
              color='#08101D'
              background='white'
              border='1px solid rgba(242, 243, 245, 0.14)'
              fontFamily='Metal'
              fontSize={['14px', '14px', '15px']}
              borderRadius='30px'
              _hover={{
                bg: '#D3BB8A',
              }}
              // isLoading={isSubmitting}
              onClick={handlePublish}
            >
              {selectedExperience.published_url &&
              selectedExperience.published_url?.length > 0
                ? 'UnPublish'
                : 'Publish'}
            </Button>
          )}

          {isCommunityExperience && (
            <Button
              textTransform='uppercase'
              width={['auto', 'auto', 'auto']}
              variant='outline'
              color='#08101D'
              background='white'
              border='1px solid rgba(242, 243, 245, 0.14)'
              fontFamily='Metal'
              fontSize={['14px', '14px', '15px']}
              borderRadius='30px'
              _hover={{
                bg: '#D3BB8A',
              }}
              isLoading={isSubmitting}
              onClick={DelistCommunityExperience}
            >
              Delist From Community
            </Button>
          )}

          <Fullscreen
            stroke='white'
            boxSize={6}
            cursor='pointer'
            onClick={() => setIsFullScreen(true)}
          />
        </Box>
      </Box>
    </Fragment>
  );
}

export default ViewerNavbar;
