import {
  Box,
  Fade,
  SimpleGrid,
  Skeleton,
  Text,
  VStack,
  Button,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import TopActionBar from '../components/common/TopActionBar';
import CommunityBarActions from '../components/barActions/CommunityBarActions';
import { Globe } from '../Icons/Globe';
import OrganisationDetails from '../components/communityProfileView/OrganisationDetails';
import configureAxios from '../axiosClient';
import { useSearchParams } from 'react-router-dom';
import { useUserStore } from '../store/user';
import { CommunityExperienceListItem } from '../interfaces';
import CommunityItem from '../components/communityView/CommunityItem';

const CommunityProfileView = () => {
  const [params] = useSearchParams('id');
  const userOrg_id = params.get('id');
  const userOrg_name = params.get('org');
  const { organisation } = useUserStore();

  const axiosClient = configureAxios();

  const [userCommunityProfileExperiences, setUserCommunityProfileExperiences] =
    useState<CommunityExperienceListItem[]>([]);
  const [userExperienceLoaded, setUserExperienceLoaded] = useState(false);
  const [page, setPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [likeBtnTriggered, setLikeBtnTriggered] = useState(true);

  const getCommunityProfileExperiences = async (pageNumber: number) => {
    setIsLoading(true);
    try {
      const res = await axiosClient.get(
        `org/${userOrg_id}/${organisation.organizationId}/profile/community-profile-experiences?page=${pageNumber}&limit=12`
      );
      if (res.status === 200) {
        const newExperiences = res.data.data.experiences?.map(
          (e: CommunityExperienceListItem) => ({
            ...e,
            is_owner: res.data.data.is_owner,
          })
        );
        setCurrentPage(res.data.data.current_page);

        if (pageNumber === 0) {
          setUserCommunityProfileExperiences(newExperiences);
          setHasMore(currentPage + 1 < res.data.data.total_pages);
          setUserExperienceLoaded(true);
        } else {
          if (currentPage + 1 !== res.data.data.total_pages) {
            setUserCommunityProfileExperiences((prev) => [
              ...prev,
              ...newExperiences,
            ]);
            setHasMore(pageNumber + 1 < res.data.data.total_pages);
            setUserExperienceLoaded(true);
          }
        }
      }
    } catch (error: unknown) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setUserExperienceLoaded(true);
    }
  };

  useEffect(() => {
    getCommunityProfileExperiences(page);
  }, [likeBtnTriggered]);

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    getCommunityProfileExperiences(nextPage);
  };

  const handleLikeBtnTriggered = () => {
    setLikeBtnTriggered((prev) => !prev);
  };

  return (
    <Fade in={true} transition={{ enter: { duration: 0.2 } }}>
      <VStack spacing={0} align='stretch' minHeight='90vh'>
        <TopActionBar
          icon={<Globe stroke='white' boxSize={[5]} />}
          title={userOrg_name || 'Organisation Name'}
          showSearchBar={false}
          searchBarPlaceholder='Search Community Experience Name...'
          rightActions={<CommunityBarActions />}
        />
        <Box padding={['40px']}>
          <OrganisationDetails userOrg_id={userOrg_id} />
        </Box>
        <Box p={['40px']} flex='1'>
          {userCommunityProfileExperiences.length > 0 ? (
            <SimpleGrid columns={[3, 3, 3, 3, 4]} spacing={[5]}>
              {userCommunityProfileExperiences.map(
                (communityExperience: CommunityExperienceListItem) => (
                  <Skeleton
                    key={communityExperience.id}
                    isLoaded={userExperienceLoaded}
                    startColor='#29303C'
                    endColor='transparent'
                    borderRadius='16px'
                  >
                    <CommunityItem
                      communityExperienceListItem={communityExperience}
                      handleLikeBtnTriggered={handleLikeBtnTriggered}
                    />
                  </Skeleton>
                )
              )}
            </SimpleGrid>
          ) : (
            <Text fontFamily='Normal' color='white' fontWeight='600'>
              No Community Experience Found
            </Text>
          )}
        </Box>
        {hasMore && (
          <Box textAlign='center' py={6}>
            <Button
              backgroundColor='transparent'
              border='1px solid #D3BB8A'
              borderRadius='25px'
              fontSize='20px'
              size='lg'
              padding={['11px 16px']}
              onClick={loadMore}
              _hover={{
                backgroundColor: 'white',
                color: 'black',
              }}
              isLoading={isLoading}
            >
              Load More
            </Button>
          </Box>
        )}
      </VStack>
    </Fade>
  );
};

export default CommunityProfileView;
