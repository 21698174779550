import { useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { StripeEmbeddedCheckoutOptions, loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from '@stripe/react-stripe-js';
import { useUserStore } from '../../store/user';
import configureAxios from '../../axiosClient';
import React from 'react';

const axiosClient = configureAxios();

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || '');

interface AddCardForm {
  onCardAdded: () => void;
}

function AddCardForm({ onCardAdded }: AddCardForm) {
  const toast = useToast({
    position: 'top',
    variant: 'top-accent',
  });
  const [clientSecret, setClientSecret] = useState('');
  const organisation = useUserStore((state) => state.organisation);
  const inactiveOrganisation = useUserStore(
    (state) => state.inactiveOrganisation
  );

  useEffect(() => {
    const userOrgId = inactiveOrganisation.organizationId
      ? inactiveOrganisation.organizationId
      : organisation.organizationId;

    axiosClient
      .get(`org/${userOrgId}/card/create`)
      .then((res) => {
        const isStatusOk = res.data.status === 'OK';
        if (isStatusOk) {
          setClientSecret(res.data.data.client_secret);
        } else {
          toast({
            title: `There was an error fetching client secret`,
            status: 'error',
          });
        }
      })
      .catch((err: unknown) => {
        console.log(err);
      });
  }, []);

  const options: StripeEmbeddedCheckoutOptions = {
    clientSecret,
    onComplete: () => {
      onCardAdded();
    },
  };

  return (
    <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
      <EmbeddedCheckout />
    </EmbeddedCheckoutProvider>
  );
}

export default AddCardForm;
