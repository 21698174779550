import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const Processes = (props: IconProps) => (
  <Icon
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M9.86523 10.6543L12.0947 12.8865'
      strokeWidth='1.6'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.86523 5.46973V10.6543'
      strokeWidth='1.6'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1.25 10C1.25 10.9209 1.39295 11.8116 1.65685 12.6445C2.77843 16.1825 6.0882 18.75 10 18.75C14.8327 18.75 18.75 14.8327 18.75 10C18.75 5.1673 14.8327 1.25 10 1.25C7.77608 1.25 5.74733 2.08019 4.2024 3.44643C4.2024 3.44643 2.86365 4.72471 2.47055 5.80231'
      strokeWidth='1.6'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.07406 5.79964H2.06055V1.78613'
      strokeWidth='1.6'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Icon>
);
