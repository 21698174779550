import React from 'react';
import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { Globe } from '../../Icons/Globe';
import ShareCommunityExperienceForm from '../forms/ShareCommunityExperienceForm';

interface ShareCommunityExperienceModalProps {
  shareCommunityExperienceModalActive: boolean;
  setShareCommunityExperienceModalActive: (state: boolean) => void;
  shareCommunityExperience: (experienceId: string) => void;
}

function ShareCommunityExperienceModal({
  shareCommunityExperienceModalActive,
  setShareCommunityExperienceModalActive,
}: ShareCommunityExperienceModalProps) {
  return (
    <Modal
      isOpen={shareCommunityExperienceModalActive}
      onClose={() => setShareCommunityExperienceModalActive(false)}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent
        minW={['500px']}
        height={['auto']}
        padding={['20px']}
        maxHeight='80vh'
        overflow='auto'
        borderRadius='16px'
        boxShadow='0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
      >
        <CloseIcon
          position='absolute'
          boxSize={[4]}
          cursor='pointer'
          color='#D6DBE4'
          _hover={{ color: 'black' }}
          transition='0.2s all'
          right={['20px']}
          top={['20px']}
          onClick={() => {
            setShareCommunityExperienceModalActive(false);
          }}
        />
        <ModalBody display='flex' flexDirection='column' alignItems='center'>
          <Box display='flex' alignItems='center' gap={2}>
            <Globe boxSize={6} />
            <Text
              color='#08101D'
              fontFamily='Metal'
              fontSize={['36px']}
              lineHeight={['36px']}
              textAlign='center'
              textTransform='uppercase'
            >
              Share
            </Text>
          </Box>
          <ShareCommunityExperienceForm
            setShareCommunityExperienceModalActive={
              setShareCommunityExperienceModalActive
            }
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ShareCommunityExperienceModal;
