import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import type {} from '@redux-devtools/extension';
import { PreLoginViews } from '../interfaces';

interface AuthState {
  isRequestPending: boolean;
  offerUserInvites: boolean;
  enteredEmail: string;
  activeLoginView: PreLoginViews;
  isLoggedIn: boolean;
  userExists: boolean;
  firstTime: boolean;
  contactedSales: boolean;
  setIsRequestPending: (state: boolean) => void;
  setOfferUserInvites: (state: boolean) => void;
  setEnteredEmail: (email: string) => void;
  setLoggedIn: (isLoggedIn: boolean) => void;
  setUserExists: (userExists: boolean) => void;
  setActiveLoginView: (view: PreLoginViews) => void;
  setFirstTime: (state: boolean) => void;
  setContactedSales: (state: boolean) => void;
  resetAuth: () => void;
  tokenActive: boolean;
  setTokenActive: (state: boolean) => void;
}

export const useAuthStore = create<AuthState>()(
  devtools(
    persist(
      (set) => ({
        isRequestPending: false,
        offerUserInvites: false,
        enteredEmail: '',
        activeLoginView: 'login',
        isLoggedIn: false,
        userExists: false,
        firstTime: false,
        contactedSales: false,
        tokenActive: false,
        setTokenActive(state) {
          set(() => ({ tokenActive: state }));
        },
        setIsRequestPending: (state: boolean) =>
          set(() => ({ isRequestPending: state })),
        setOfferUserInvites: (state: boolean) =>
          set(() => ({ offerUserInvites: state })),
        setEnteredEmail: (email: string) =>
          set(() => ({ enteredEmail: email })),
        setLoggedIn: (isLoggedIn: boolean) =>
          set(() => ({ isLoggedIn: isLoggedIn })),
        setUserExists: (userExists: boolean) =>
          set(() => ({ userExists: userExists })),
        setActiveLoginView: (view: PreLoginViews) =>
          set(() => ({ activeLoginView: view })),
        setFirstTime: (state: boolean) => set(() => ({ firstTime: state })),
        setContactedSales: (state: boolean) =>
          set(() => ({ contactedSales: state })),
        resetAuth: () => {
          set(() => ({
            isRequestPending: false,
            offerUserInvites: false,
            enteredEmail: '',
            activeLoginView: 'login',
            firstTime: false,
            isLoggedIn: false,
            userExists: false,
          }));
        },
      }),
      {
        name: 'auth-storage',
      }
    )
  )
);
