import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const UserCircle = (props: IconProps) => (
  <Icon
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <mask
      id='mask0_4592_33830'
      style={{ maskType: 'luminance' }}
      maskUnits='userSpaceOnUse'
      x='2'
      y='2'
      width='12'
      height='12'
    >
      <path
        d='M8 13.5C11.0376 13.5 13.5 11.0376 13.5 8C13.5 4.96243 11.0376 2.5 8 2.5C4.96243 2.5 2.5 4.96243 2.5 8C2.5 11.0376 4.96243 13.5 8 13.5Z'
        fill='white'
      />
    </mask>
    <g mask='url(#mask0_4592_33830)'>
      <path
        d='M9.43454 9.43488C10.1681 8.87095 10.412 8.26035 10.3119 7.39723C10.1889 6.33339 9.32951 5.47835 8.26505 5.3609C6.86007 5.20555 5.67133 6.30108 5.67133 7.67499C5.67133 8.42564 6.02677 9.09302 6.57857 9.51868C6.74014 9.64358 6.70969 9.89214 6.52141 9.97106C5.30036 10.4862 4.45215 11.6184 4.45215 12.9339V14.4259C4.45215 14.5707 4.56959 14.6881 4.71438 14.6881H11.2857C11.4305 14.6881 11.5479 14.5707 11.5479 14.4259V12.9339C11.5479 11.6184 10.6997 10.4862 9.47864 9.97106C9.34135 9.91314 9.25519 9.57276 9.43454 9.43488Z'
        stroke='white'
        strokeMiterlimit='10'
      />
    </g>
    <path
      d='M8 13.5C11.0376 13.5 13.5 11.0376 13.5 8C13.5 4.96243 11.0376 2.5 8 2.5C4.96243 2.5 2.5 4.96243 2.5 8C2.5 11.0376 4.96243 13.5 8 13.5Z'
      stroke='white'
      strokeMiterlimit='10'
    />
  </Icon>
);
