import { Box, Image, Text } from '@chakra-ui/react';
import dummyUser from '../../assets/image/DummyUser.png';
import React, { useEffect, useState } from 'react';
import { Like } from '../../Icons/Like';
import { Linkedin } from '../../Icons/Linkedin';
import configureAxios from '../../axiosClient';
import { useUserStore } from '../../store/user';
import { Instagram } from '../../Icons/Instagram';
import { Mail } from '../../Icons/Mail';
import { TwitterX } from '../../Icons/TwitterX';
import { Copy } from '../../Icons/Copy';
import { Eye } from '../../Icons/Eye';

interface OrganisationDetailProps {
  userOrg_id: string | null;
}

type communityProfileValues = {
  alias: string;
  clones: number;
  community_contact_links: string;
  community_short_bio: string;
  id: string;
  is_owner: boolean;
  likes: number;
  views: number;
};

type ContactLink = {
  platform: string;
  link: string;
};

const OrganisationDetails = ({ userOrg_id }: OrganisationDetailProps) => {
  const axiosClient = configureAxios();
  const { organisation } = useUserStore();
  const [contactLinks, setContactLinks] = useState<ContactLink[]>([]);

  const [userCommunityProfileInfo, setUserCommunityProfileInfo] =
    useState<communityProfileValues>({
      alias: '',
      clones: 0,
      community_contact_links: '',
      community_short_bio: '',
      id: '',
      is_owner: false,
      likes: 0,
      views: 0,
    });

  useEffect(() => {
    const getCommunityProfile = async () => {
      try {
        const res = await axiosClient.get(
          `org/${userOrg_id}/${organisation.organizationId}/profile/fetch-community-profile`
        );
        if (res.status === 200) {
          setUserCommunityProfileInfo(res.data.data);
          const parsedLinks =
            res.data.data.community_contact_links.length > 0
              ? JSON.parse(res.data.data.community_contact_links)
              : {};
          setContactLinks(parsedLinks);
        }
      } catch (error: unknown) {
        console.log(error);
      }
    };
    getCommunityProfile();
  }, []);

  const getIconComponent = (platform: string) => {
    switch (platform.toLowerCase()) {
      case 'linkedin':
        return <Linkedin boxSize={5} />;
      case 'instagram':
        return <Instagram boxSize={5} />;
      case 'twitter':
        return <TwitterX boxSize={5} />;
      case 'mail':
        return <Mail boxSize={5} />;
      default:
        return <Copy stroke='black' boxSize={5} />;
    }
  };
  return (
    <Box
      display='flex'
      height='112px'
      borderRadius='16px'
      bgColor='#1D2531'
      alignItems='center'
      justifyContent='space-between'
      padding={['24px']}
    >
      <Box display='flex' alignItems='center' gap={10}>
        <Box display='flex' alignItems='center' gap={5}>
          <Image src={dummyUser} objectFit='cover' width='64px' height='64px' />
          <Box
            display='flex'
            flexDir='column'
            alignItems='start'
            maxWidth={['296px']}
            gap={2}
          >
            <Text fontFamily='Normal' fontSize='16px'>
              {userCommunityProfileInfo.alias}
            </Text>
            <Text fontFamily='Normal' fontSize='11px'>
              {userCommunityProfileInfo.community_short_bio}
            </Text>
          </Box>
        </Box>
        <Box display='flex' flexDir='column' alignItems='start' gap={2}>
          <Text fontFamily='Normal' fontSize='11px'>
            Experience Overview
          </Text>
          <Box display='flex' alignItems='center' gap={5}>
            <Box
              display='flex'
              bgColor='#08101D'
              padding={['12px 12px']}
              borderRadius='30px'
              alignItems='center'
              gap={3}
            >
              <Eye stroke={'white'} boxSize={6} />
              <Text fontFamily='Normal' fontSize='12px' color='white'>
                {userCommunityProfileInfo.views}
              </Text>
            </Box>
            <Box
              display='flex'
              bgColor='#08101D'
              padding={['12px 12px']}
              borderRadius='30px'
              alignItems='center'
              gap={3}
            >
              <Like boxSize={5} />
              <Text fontFamily='Normal' fontSize='12px' color='white'>
                {userCommunityProfileInfo.likes}
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>

      {contactLinks.length > 0 && (
        <Box display='flex' flexDir='column' gap={2}>
          <Text fontFamily='Normal' fontSize='11px'>
            Get in touch
          </Text>
          <Box display='flex' alignItems='center' gap={5}>
            {contactLinks.map((link, index) => (
              <a
                href={link.link}
                target='_blank'
                rel='noopener noreferrer'
                key={index}
              >
                <Box
                  bgColor='white'
                  borderRadius='50%'
                  padding='10px'
                  width='40px'
                  height='40px'
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                >
                  {getIconComponent(link.platform)}
                </Box>
              </a>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default OrganisationDetails;
