import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useToast,
} from '@chakra-ui/react';
import { Notification } from '../../Icons/Notification';
import { BlackAddUser } from '../../Icons/BlackAddUser';
import configureAxios from '../../axiosClient';
import { formatNotificationTime } from '../../utils/helper';
import { useUserStore } from '../../store/user';

type OrganisationInviteBEValues = {
  organisation_alias: string;
  organisation: string;
  invited_by: string;
  invited_by_name: string;
  invite_id: string;
  created_at: string;
  roles: string;
};
type OrganisationInviteValues = {
  organisationAlias: string;
  organisationId: string;
  invitedBy: string;
  invitedByName: string;
  inviteId: string;
  createdAt: string;
  roles: string;
};

function NotificationDropdown() {
  const axiosClient = configureAxios();
  const toast = useToast({
    position: 'top',
    variant: 'top-accent',
  });

  const [organisationInvites, setOrganisationInvites] = useState<
    OrganisationInviteValues[] | undefined
  >();
  const [acceptNotificationInviteId, setAcceptNotificationInviteId] =
    useState('');
  const [declineNotificationInviteId, setDeclineNotificationInviteId] =
    useState('');

  const getUserOrganisationInvites = async () => {
    try {
      const res = await axiosClient.get('user/invites');
      if (res.data.status === 'OK') {
        const organisationInvites = res.data.data.map(
          (invites: OrganisationInviteBEValues) => {
            const organisationAlias = invites.organisation_alias;
            const organisationId = invites.organisation;
            const invitedByName = invites.invited_by_name;
            const inviteId = invites.invite_id;
            const createdAt = invites.created_at;
            const roles = invites.roles;
            const invitedBy = invites.invited_by;
            return {
              organisationAlias: organisationAlias,
              organisationId: organisationId,
              invitedByName: invitedByName,
              inviteId: inviteId,
              createdAt: createdAt,
              roles: roles,
              invitedBy: invitedBy,
            };
          }
        );
        setOrganisationInvites(organisationInvites);
      }
    } catch (err: unknown) {
      console.log('Error: ', err);
    }
  };

  const acceptOrganisationInvite = async (
    inviteId: string,
    organisationName: string,
    organisationId: string,
    roles: string
  ) => {
    setAcceptNotificationInviteId(inviteId);
    try {
      const res = await axiosClient.post(`user/invites/${inviteId}/accept`);
      if (res.data.status === 'OK') {
        toast({
          title: res?.data.data,
          status: 'success',
        });
        const newOrg = {
          organizationId: organisationId,
          alias: organisationName,
          roles: roles,
        };
        useUserStore.getState().setOrganisation(newOrg);
        useUserStore.getState().getOrganizations();

        getUserOrganisationInvites();
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        const message = err.message;
        if (message.includes('409')) {
          toast({
            title:
              'You have reached the maximum number of invites. Please consider leaving an organisation before accepting more invites.',
            status: 'error',
          });
          setAcceptNotificationInviteId('');
        }
      }
    }
  };

  const declineOrganisationInvite = async (inviteId: string) => {
    setDeclineNotificationInviteId(inviteId);
    try {
      const res = await axiosClient.post(`/user/invites/${inviteId}/decline`);
      console.log(res);
      setDeclineNotificationInviteId('');
      getUserOrganisationInvites();
    } catch (err: unknown) {
      setDeclineNotificationInviteId('');
      console.log(err);
    }
  };

  useEffect(() => {
    getUserOrganisationInvites();
  }, []);
  return (
    <Popover
      placement='bottom'
      returnFocusOnClose={false}
      closeOnBlur={true}
      offset={[0, 10]}
    >
      <PopoverTrigger>
        <Box position='relative'>
          <Notification />
          {organisationInvites !== undefined &&
            organisationInvites.length > 0 && (
              <Box
                position='absolute'
                top='2px'
                right='5px'
                width='12px'
                height='12px'
                borderRadius='50%'
                backgroundColor='red'
              />
            )}
        </Box>
      </PopoverTrigger>
      <PopoverContent
        borderRadius='16px'
        background='#FFF'
        boxShadow='0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
        backdropFilter='blur(12px)'
        width={['450px']}
        height={['auto']}
        // top="50px"
        left='-180px'
      >
        <PopoverBody
          height={['inherit']}
          display='flex'
          flexDirection='column'
          padding={['16px']}
        >
          <Box display='flex' justifyContent='flex-start  ' pb='7px'>
            <Text fontWeight='400' size='11px' color='#08101D'>
              Notification
            </Text>
            {/* <Text fontWeight="600" size="11px" color="#D3BB8A">Mark all as read</Text> */}
          </Box>
          {organisationInvites?.length === 0 ? (
            <Box>
              <Text mt='5px' textAlign='center' color='#7F8B9E'>
                No Notification Currently.
              </Text>
            </Box>
          ) : (
            <Box flexDir='column' gap={5}>
              {organisationInvites?.map((org_invite) => (
                <Box
                  key={org_invite.inviteId}
                  display='flex'
                  flexDirection='column'
                  mt='16px'
                  alignItems='center'
                >
                  <Box
                    display='flex'
                    width='100%'
                    alignItems='start'
                    color='#08101D'
                  >
                    <BlackAddUser boxSize={9} />
                    <Box
                      display='flex'
                      justifyContent='flex-start'
                      flexDirection='column'
                      ml='9px'
                    >
                      <Text
                        fontWeight='600'
                        width='280px'
                        display='block'
                        textOverflow='ellipsis'
                        whiteSpace='nowrap'
                        overflow='hidden'
                        title={org_invite.invitedByName}
                      >
                        {org_invite.invitedByName}{' '}
                        <span style={{ fontWeight: 'normal' }}>
                          invited you to:
                        </span>
                      </Text>
                      <Text fontWeight='600'>
                        {org_invite.organisationAlias}
                      </Text>
                    </Box>
                    <Text marginLeft='auto' color='#7F8B9E'>
                      {formatNotificationTime(org_invite.createdAt)}
                    </Text>
                  </Box>
                  <Box
                    width='100%'
                    mt='8px'
                    borderBottom='1px solid #D6DBE4'
                    pb='16px'
                  >
                    <Button
                      textTransform='uppercase'
                      w={['104px']}
                      variant='outline'
                      color='#08101D'
                      background='white'
                      border='1px solid rgba(242, 243, 245, 0.14)'
                      fontFamily='Metal'
                      fontSize={['14px', '14px', '14px']}
                      borderRadius='30px'
                      mr='16px'
                      _hover={{
                        bg: '#D3BB8A',
                      }}
                      isLoading={
                        acceptNotificationInviteId === org_invite.inviteId
                      }
                      onClick={() =>
                        acceptOrganisationInvite(
                          org_invite.inviteId,
                          org_invite.organisationAlias,
                          org_invite.organisationId,
                          org_invite.roles
                        )
                      }
                    >
                      Accept
                    </Button>
                    <Button
                      textTransform='uppercase'
                      w={['104px']}
                      variant='outline'
                      color='#08101D'
                      background='white'
                      border='1px solid rgba(242, 243, 245, 0.14)'
                      fontFamily='Metal'
                      fontSize={['14px', '14px', '14px']}
                      borderRadius='30px'
                      _hover={{
                        bg: '#D3BB8A',
                      }}
                      isLoading={
                        declineNotificationInviteId === org_invite.inviteId
                      }
                      onClick={() =>
                        declineOrganisationInvite(org_invite.inviteId)
                      }
                    >
                      Reject
                    </Button>
                  </Box>
                </Box>
              ))}
            </Box>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default NotificationDropdown;
