import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

export const Dollar = (props: IconProps) => (
  <Icon
    width='3'
    height='3'
    viewBox='0 0 7 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M3.5 1.33333V1'
      strokeWidth='0.6'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1.32812 7.87642C1.35191 8.06667 1.35983 8.12216 1.4074 8.2371C1.52234 8.49473 1.67295 8.72065 1.86717 8.89505C2.06535 9.07341 2.2992 9.20421 2.56872 9.2914C2.83824 9.3786 3.13154 9.4222 3.44862 9.4222C4.15414 9.4222 4.70111 9.25573 5.08954 8.92676C5.47796 8.59382 5.67218 8.13008 5.67218 7.52762C5.67218 7.2145 5.62065 6.95291 5.52553 6.7468C5.42644 6.5407 5.29564 6.37027 5.13313 6.23551C4.58616 5.7797 2.64799 6.2038 1.96626 5.62116C1.79979 5.47847 1.65314 5.31993 1.54612 5.10986C1.4391 4.90375 1.38361 4.6382 1.38361 4.32111C1.38361 4.09915 1.42721 3.88116 1.51441 3.67109C1.60161 3.46102 1.73241 3.27473 1.90284 3.11223C2.07327 2.94972 2.29127 2.81893 2.5489 2.7238C2.80653 2.62867 3.10776 2.57715 3.44466 2.57715C3.78156 2.57715 4.09072 2.62867 4.34439 2.73173C4.60202 2.83478 4.81209 2.9735 4.98252 3.15186C5.15295 3.33022 5.27582 3.53633 5.36302 3.77018C5.41058 3.90097 5.44625 4.02781 5.46607 4.16653'
      strokeLinecap='round'
    />
    <path
      d='M3.50391 11.0002V9.43066'
      strokeWidth='0.6'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.50397 8.13402L3.5 4.33301'
      strokeWidth='0.6'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Icon>
);
