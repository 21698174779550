import {
  Box,
  Input,
  InputGroup,
  InputRightElement,
  // Switch,
  Text,
} from '@chakra-ui/react';
import { MagnifyingGlass } from '../../Icons/MagnifyingGlass';
import React, { ReactElement } from 'react';
import { useCommunityStore } from '../../store/community';

interface TopActionBarProps {
  featureIcon?: ReactElement;
  featureTitle?: string;
  icon?: ReactElement;
  title: string;
  subtitle?: string;
  showSearchBar?: boolean;
  searchBarPlaceholder?: string;
  rightActions?: React.ReactNode;
  searchBarText?: string;
  onSearchBarTextChange?: (value: string) => void;
}

function TopActionBar({
  featureIcon,
  featureTitle,
  icon,
  title,
  subtitle,
  showSearchBar,
  rightActions,
  searchBarText,
  searchBarPlaceholder,
  onSearchBarTextChange,
}: TopActionBarProps) {
  const { isFeaturedExperiences, setIsFeaturedExperience } =
    useCommunityStore();
  return (
    <Box
      background='#1D2531'
      backdropFilter='blur(20px)'
      width={['100%']}
      height={['60px']}
      p={['0px 40px']}
      display='flex'
      justifyContent='space-between'
      alignItems='center'
    >
      <Box display='flex' alignItems='center' width={['auto']} gap={10}>
        {/* stroke='#08101D' */}
        {featureIcon && featureTitle && (
          <Box
            display='flex'
            alignItems='center'
            width={['auto']}
            gap={2}
            onClick={() => setIsFeaturedExperience(true)}
            cursor='pointer'
          >
            {featureIcon}
            <Text
              color={isFeaturedExperiences ? '#d3bb8a' : 'white'}
              textTransform='uppercase'
              fontFamily='Metal'
              fontSize={['14px']}
              _hover={{
                textDecor: !isFeaturedExperiences ? 'underline' : 'none',
              }}
            >
              {featureTitle}
            </Text>
          </Box>
        )}
        <Box
          display='flex'
          alignItems='center'
          width={['auto']}
          gap={2}
          cursor='pointer'
          onClick={() => setIsFeaturedExperience(false)}
        >
          {icon}
          <Text
            color={
              title === 'Community Experiences' && !isFeaturedExperiences
                ? '#d3bb8a'
                : 'white'
            }
            textTransform='uppercase'
            fontFamily='Metal'
            fontSize={['14px']}
            _hover={{
              textDecoration: 'underline',
            }}
          >
            {title}
          </Text>
        </Box>
        {subtitle && (
          <Text
            color='white'
            textTransform='uppercase'
            fontFamily='Metal'
            fontSize={['14px']}
            ml={['10px']}
          >
            {subtitle}
          </Text>
        )}
      </Box>

      {showSearchBar && (
        <Box>
          <InputGroup
            borderRadius='35px'
            border='1px solid rgba(242, 243, 245, 0.14)'
            background='#1D2531'
            width={['300px']}
          >
            <Input
              value={searchBarText}
              borderRadius='35px'
              placeholder={searchBarPlaceholder}
              _placeholder={{ textTransform: 'capitalize' }}
              fontFamily='Normal'
              fontSize={['12px']}
              onChange={(e) =>
                onSearchBarTextChange && onSearchBarTextChange(e.target.value)
              }
            />
            <InputRightElement>
              <MagnifyingGlass boxSize={[5]} />
            </InputRightElement>
          </InputGroup>
        </Box>
      )}

      {rightActions}
    </Box>
  );
}

export default TopActionBar;
