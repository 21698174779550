import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import { MemberItem } from '../tables/MembersTable';
import configureAxios from '../../axiosClient';
import { useUserStore } from '../../store/user';

interface DeleteOrganisationMemberProps {
  deleteOrganisationMemberModalActive: boolean;
  setDeleteOrganisationMemberModalActive: (
    state: boolean,
    member?: MemberItem
  ) => void;
  selectedMember: MemberItem;
  setMembers: React.Dispatch<React.SetStateAction<MemberItem[]>>;
  setFilteredMembers: React.Dispatch<React.SetStateAction<MemberItem[]>>;
}

function DeleteOrganisationMemberModal({
  selectedMember,
  setMembers,
  setFilteredMembers,
  deleteOrganisationMemberModalActive,
  setDeleteOrganisationMemberModalActive,
}: DeleteOrganisationMemberProps) {
  const toast = useToast({
    position: 'top',
    variant: 'top-accent',
  });
  const axiosClient = configureAxios();

  const { organisation, getOrganizations } = useUserStore();
  const org_id = organisation.organizationId;

  const [deleteOrgMemberLoader, setDeleteOrgMemberLoader] = useState(false);

  async function deleteOrgMember() {
    setDeleteOrgMemberLoader(true);
    try {
      const res = await axiosClient.post(`org/${org_id}/user/remove`, {
        user_id: selectedMember.id,
      });
      if (res.status === 200) {
        toast({
          title: res.data.data,
          status: 'success',
        });

        setMembers((prevMembers) =>
          prevMembers.filter((member) => member.id !== selectedMember.id)
        );
        setFilteredMembers((prevMembers) =>
          prevMembers.filter((member) => member.id !== selectedMember.id)
        );
        getOrganizations();
        setDeleteOrgMemberLoader(false);
        setDeleteOrganisationMemberModalActive(false, selectedMember);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setDeleteOrgMemberLoader(false);
      toast({
        title: error?.response.data.data,
        status: 'error',
      });
      console.log('Error', error);
    }
  }

  return (
    <Modal
      isOpen={deleteOrganisationMemberModalActive}
      onClose={() =>
        setDeleteOrganisationMemberModalActive(false, selectedMember)
      }
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        minW={['500px']}
        height={['auto']}
        padding={['20px']}
        borderRadius='16px'
        boxShadow='0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
      >
        <CloseIcon
          position='absolute'
          boxSize={[4]}
          cursor='pointer'
          color='#D6DBE4'
          _hover={{ color: 'black' }}
          transition='0.2s all'
          right={['20px']}
          top={['20px']}
          onClick={() =>
            setDeleteOrganisationMemberModalActive(false, selectedMember)
          }
        />
        <ModalBody
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          alignItems='center'
          gap={5}
        >
          <Text
            color='#08101D'
            fontFamily='Metal'
            fontSize={['35px']}
            lineHeight={['35px']}
            textAlign='center'
            textTransform='uppercase'
          >
            Delete
          </Text>
          <Box
            w={['100%']}
            height={['auto']}
            display='flex'
            flexDirection='column'
            justifyContent='space-between'
            alignItems='center'
            gap={5}
          >
            <Box
              display='flex'
              flexDir='column'
              textAlign='center'
              color='black'
            >
              <Text fontSize='15px' fontWeight='500'>
                Remove {selectedMember.name}?
              </Text>
              <Text fontSize='11px' fontWeight='400'>
                They will lose access to {organisation.alias}, along with all
                experiences and files associated with it.
              </Text>
            </Box>
          </Box>
          <Box display='flex' flexDir='column' justifyContent='center' gap={3}>
            <Button
              textTransform='uppercase'
              width={['80%', '80%', '300px']}
              variant='outline'
              color='#08101D'
              background='white'
              border='1px solid #D6DBE4'
              fontFamily='Metal'
              fontSize={['14px', '14px', '15px']}
              borderRadius='30px'
              _hover={{
                bg: '#D3BB8A',
                color: 'black',
              }}
              isLoading={deleteOrgMemberLoader}
              onClick={() => deleteOrgMember()}
            >
              Delete
            </Button>
            <Button
              textTransform='uppercase'
              width={['80%', '80%', '300px']}
              variant='outline'
              color='#08101D'
              background='white'
              border='1px solid #D6DBE4'
              fontFamily='Metal'
              fontSize={['14px', '14px', '15px']}
              borderRadius='30px'
              _hover={{
                bg: '#D3BB8A',
                color: 'black',
              }}
              onClick={() =>
                setDeleteOrganisationMemberModalActive(false, selectedMember)
              }
            >
              Cancel
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default DeleteOrganisationMemberModal;
