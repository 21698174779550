import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const NextArrow = (props: IconProps) => (
  <Icon
    width='10'
    height='8'
    viewBox='0 0 10 8'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M6.0683 7.02856C5.96972 7.02856 5.87831 6.9936 5.81784 6.93256C5.76683 6.88126 5.74133 6.81449 5.7419 6.73454V6.72852L5.74161 6.72251C5.74075 6.70502 5.7525 6.53452 6.40416 5.95737C6.81824 5.59028 8.60126 4.05887 8.60126 4.05887L8.32472 4.08065C7.03403 4.13968 5.85166 4.13108 4.45235 4.13108C4.24774 4.13108 4.03712 4.12994 3.8262 4.12793C3.75399 4.12793 3.59093 4.12793 3.38174 4.12736C3.00691 4.12679 2.48393 4.12621 2.07929 4.12621C1.76923 4.12621 1.58095 4.1265 1.5033 4.12736C1.47321 4.12765 1.44168 4.12822 1.40901 4.12879C1.35543 4.12965 1.29869 4.1308 1.2408 4.1308C0.967416 4.1308 0.641016 4.10845 0.542724 3.93507L0.530402 3.91358C0.492575 3.8236 0.489996 3.72531 0.522951 3.63418L0.549315 3.58088C0.608634 3.45335 0.804073 3.39404 1.16457 3.39404C1.23364 3.39404 1.30127 3.39604 1.3666 3.39776C1.41503 3.39919 1.46117 3.40034 1.50358 3.40091C1.58038 3.40177 1.76636 3.40206 2.07213 3.40206C2.47074 3.40206 2.98628 3.40149 3.36197 3.40091C3.57689 3.40091 3.82477 3.40034 3.82477 3.40034C4.03769 3.39833 4.2486 3.39719 4.4535 3.39719C5.8508 3.39719 7.03374 3.38859 8.32501 3.44762L8.60212 3.4694C8.60212 3.4694 6.82369 1.94143 6.40874 1.57434C5.7548 0.99576 5.74133 0.824966 5.7419 0.807486L5.74247 0.800322V0.793444C5.74161 0.713779 5.76683 0.647295 5.81755 0.596C5.87802 0.534961 5.96943 0.5 6.0683 0.5C6.17433 0.5 6.27004 0.539833 6.33767 0.612048L6.34111 0.615486L6.34455 0.618925C6.81796 1.06969 7.29882 1.52419 7.76391 1.9635C8.25939 2.43174 8.77177 2.91604 9.27784 3.39833L9.28214 3.40235C9.46211 3.56225 9.53088 3.71212 9.48732 3.84824L9.4856 3.85426C9.45809 3.95227 9.36066 4.05285 9.27899 4.12965C8.77205 4.61309 8.25939 5.0971 7.76391 5.56535C7.29882 6.00466 6.81796 6.45915 6.34484 6.90992L6.3414 6.91336L6.33796 6.9168C6.27004 6.98901 6.17462 7.02885 6.06887 7.02885L6.0683 7.02856Z'
      fill='white'
    />
  </Icon>
);
