import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const Private = (props: IconProps) => (
  <Icon
    width='18'
    height='20'
    viewBox='0 0 18 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.20591 5.60526C3.20591 3.06132 5.6743 1 8.72062 1C11.7669 1 14.2353 3.06132 14.2353 5.60526V8.36842H14.6765C15.6471 8.36842 16.4412 9.03158 16.4412 9.84211V16.2895C16.4412 17.5053 15.25 18.5 13.7941 18.5H3.64709C2.19121 18.5 1.00003 17.5053 1.00003 16.2895V9.84211C1.00003 9.03158 1.79415 8.36842 2.76474 8.36842H3.20591V5.60526ZM11.9008 5.60526V8.36842H5.73962V5.60526C5.73962 4.07816 6.89194 3.1875 8.72062 3.1875C10.5493 3.1875 11.9008 4.07816 11.9008 5.60526ZM8.72062 10.4408C8.28202 10.4404 7.85635 10.5648 7.51371 10.7934C7.17107 11.0221 6.93189 11.3413 6.83558 11.6987C6.73926 12.056 6.79155 12.4301 6.98383 12.7593C7.0178 12.8174 7.69121 13.3958 7.69121 13.3958V15.6229C7.69121 15.8061 7.77836 15.9819 7.93349 16.1114C8.08862 16.2409 8.56557 16.3125 8.78496 16.3125C9.00435 16.3125 9.31342 16.2409 9.46855 16.1114C9.62368 15.9819 9.71083 15.8061 9.71083 15.6229V13.3958C9.71083 13.3617 10.4135 12.8344 10.4574 12.7593C10.6497 12.4301 10.702 12.056 10.6057 11.6987C10.5093 11.3413 10.2702 11.0221 9.92753 10.7934C9.58489 10.5648 9.15922 10.4404 8.72062 10.4408Z'
      stroke='black'
      strokeWidth='1.3'
    />
  </Icon>
);
