// 1. Import the utilities
import { createMultiStyleConfigHelpers, extendTheme } from '@chakra-ui/react';
import { AlertTheme } from './AlertTheme';

const breakpoints = {
  sm: '399px',
  md: '770px',
  lg: '999px',
  xl: '1499px',
  '2xl': '1899px',
};

// This function creates a set of function that helps us create multipart component styles.
const helpers = createMultiStyleConfigHelpers(['checkbox', 'control', 'icon']);

const Checkbox = helpers.defineMultiStyleConfig({
  baseStyle: {
    control: {
      border: '1px solid',
      _checked: {
        background: '#D3BB8A',
        borderColor: '#D3BB8A',
        _hover: {
          background: '#D3BB8A',
          borderColor: '#D3BB8A',
        },
        _active: {
          background: '#D3BB8A',
          borderColor: '#D3BB8A',
        },
        _focus: {
          background: '#D3BB8A',
          borderColor: '#D3BB8A',
        },
      },
    },
    icon: {
      width: '0.6em',
    },
  },
});

const customDashTheme = extendTheme({
  breakpoints,
  colors: {
    highRed: {
      50: '#EA1A4C',
      100: '#EA1A4C',
      200: '#EA1A4C',
      300: '#EA1A4C',
      400: '#EA1A4C',
      500: '#EA1A4C',
      600: '#EA1A4C',
      700: '#EA1A4C',
      800: '#EA1A4C',
      900: '#EA1A4C',
    },
    red: {
      50: '#EA1A4C',
      100: '#EA1A4C',
      200: '#EA1A4C',
      300: '#EA1A4C',
      400: '#EA1A4C',
      500: '#EA1A4C',
      600: '#EA1A4C',
      700: '#EA1A4C',
      800: '#EA1A4C',
      900: '#EA1A4C',
    },
    lime: {
      50: '#06FF47',
      100: '#06FF47',
      200: '#06FF47',
      300: '#06FF47',
      400: '#06FF47',
      500: '#06FF47',
      600: '#06FF47',
      700: '#06FF47',
      800: '#06FF47',
      900: '#06FF47',
    },
  },
  styles: {
    global: () => ({
      body: {
        bg: '#08101D',
        color: 'white',
      },
    }),
  },
  components: {
    Checkbox,
    Table: {
      variants: {
        simple: {
          th: {
            borderColor: 'rgba(242, 243, 245, 0.14)',
            color: 'white',
          },
          td: {
            borderColor: 'rgba(242, 243, 245, 0.14)',
            color: 'white',
          },
        },
      },
    },
    Select: {
      defaultProps: {
        focusBorderColor: '#D3BB8A',
      },
    },
    Input: {
      defaultProps: {
        focusBorderColor: '#D3BB8A',
      },
    },
    Textarea: {
      defaultProps: {
        focusBorderColor: '#D3BB8A',
      },
    },
    Progress: {
      defaultProps: {
        colorScheme: 'gray',
      },
    },
    Alert: AlertTheme,
  },
});

export { customDashTheme };
