export enum SCENE_UPDATE_TYPE {
  UPDATE_SCENE = 'UPDATE SCENE PANOS',
  UPDATE_JSON_FILE = 'UPDATE GLOBAL CONFIG',
}

export enum UNSUBSCRIBE_REASON {
  TOO_MANY_EMAILS = 'too-many-emails',
  NOT_RELEVANT = 'not-relevant',
  NEVER_SIGNED_UP = 'never-signed-up',
  OTHER = 'other',
}
