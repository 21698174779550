import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

export const Toggle = (props: IconProps) => (
  <Icon
    width='8'
    height='12'
    viewBox='0 0 20 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M19 1.5L10 10.5L1 1.5'
      // stroke='white'
      strokeWidth='1.6'
      strokeLinecap='round'
    />
  </Icon>
);

export default Toggle;
