import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const RightStemArrow = (props: IconProps) => (
  <Icon
    width='21'
    height='16'
    viewBox='0 0 21 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M12.9927 15.2333C12.7627 15.2333 12.5494 15.1517 12.4083 15.0093C12.2893 14.8896 12.2298 14.7338 12.2311 14.5473V14.5332L12.2304 14.5192C12.2284 14.4784 12.2558 14.0805 13.7764 12.7339C14.7426 11.8773 18.9029 8.30403 18.9029 8.30403L18.2577 8.35485C15.2461 8.49259 12.4872 8.47253 9.22215 8.47253C8.74473 8.47253 8.25327 8.46986 7.76114 8.46518C7.59264 8.46518 7.21217 8.46518 6.72406 8.46384C5.84946 8.4625 4.62916 8.46116 3.68502 8.46116C2.96153 8.46116 2.52223 8.46183 2.34102 8.46384C2.27081 8.46451 2.19726 8.46584 2.12103 8.46718C1.996 8.46919 1.8636 8.47186 1.72853 8.47186C1.09064 8.47186 0.329038 8.41971 0.0996897 8.01517L0.0709376 7.96502C-0.0173249 7.75506 -0.0233428 7.52572 0.0535525 7.31308L0.115069 7.18871C0.25348 6.89116 0.709503 6.75275 1.55067 6.75275C1.71182 6.75275 1.86962 6.75743 2.02207 6.76144C2.13508 6.76479 2.24273 6.76746 2.34169 6.7688C2.52089 6.7708 2.95485 6.77147 3.6683 6.77147C4.5984 6.77147 5.80131 6.77013 6.67792 6.7688C7.17941 6.7688 7.7578 6.76746 7.7578 6.76746C8.25461 6.76278 8.74674 6.7601 9.22483 6.7601C12.4852 6.7601 15.2454 6.74005 18.2584 6.87779L18.9049 6.92861C18.9049 6.92861 14.7553 3.36334 13.7871 2.50679C12.2612 1.15677 12.2298 0.758255 12.2311 0.717467L12.2324 0.700751V0.684703C12.2304 0.498817 12.2893 0.343689 12.4076 0.224C12.5487 0.0815759 12.762 0 12.9927 0C13.2401 0 13.4634 0.0929431 13.6212 0.261444L13.6293 0.269468L13.6373 0.277492C14.7419 1.32929 15.8639 2.38977 16.9491 3.41482C18.1052 4.50741 19.3008 5.63743 20.4816 6.76278L20.4917 6.77214C20.9116 7.14525 21.0721 7.49496 20.9704 7.81257L20.9664 7.82661C20.9022 8.05529 20.6749 8.28999 20.4843 8.46919C19.3015 9.59721 18.1052 10.7266 16.9491 11.8191C15.8639 12.8442 14.7419 13.9047 13.638 14.9565L13.6299 14.9645L13.6219 14.9725C13.4634 15.141 13.2408 15.234 12.994 15.234L12.9927 15.2333Z' />
  </Icon>
);
