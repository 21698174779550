import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const Download = (props: IconProps) => (
  <Icon
    width='17'
    height='16'
    viewBox='0 0 17 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M2.5 14H14.5' stroke='#08101D' strokeLinecap='round' />
    <path
      d='M13.0156 7.48696L8.50258 12L3.98954 7.48696'
      stroke='#08101D'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.5 2L8.5 12'
      stroke='#08101D'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Icon>
);
