import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const Folder = (props: IconProps) => (
  <Icon
    width='20'
    height='16'
    viewBox='0 0 20 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M8.68228 6.05896L8.08066 4.0214C8.03857 3.87904 7.9086 3.78125 7.76005 3.78125H2.24279C2.09424 3.78125 1.96426 3.87904 1.92217 4.0214L1.26362 6.25207C1.25495 6.28302 1.25 6.31521 1.25 6.34615V14.8653C1.25 15.0497 1.39978 15.1995 1.58423 15.1995H15.9214C16.1059 15.1995 16.2557 15.0497 16.2557 14.8653V6.63334C16.2557 6.4489 16.1059 6.29911 15.9214 6.29911H9.00289C8.85434 6.29911 8.72436 6.20132 8.68228 6.05896Z'
      stroke='black'
      strokeWidth='1.4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18.7497 11.8866V3.65336C18.7497 3.46892 18.5999 3.31913 18.4155 3.31913H11.497C11.3484 3.31913 11.2184 3.22134 11.1763 3.07898L10.5747 1.04142C10.5326 0.899063 10.4027 0.80127 10.2541 0.80127H4.73561C4.58706 0.80127 4.45708 0.899063 4.415 1.04142L4.20703 1.74578'
      stroke='black'
      strokeWidth='1.4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Icon>
);
