import React, { HTMLProps, ReactElement } from 'react';

// Define props for the wrapper component
interface FormWrapperProps extends HTMLProps<HTMLFormElement> {
  // You can add any additional props specific to your wrapper here
  children: ReactElement;
}

// Define the wrapper component
const FormWrapper: React.FC<FormWrapperProps> = ({ children, ...rest }) => {
  return (
    <form style={{ width: '100%', height: 'auto' }} {...rest}>
      {children}
    </form>
  );
};

export default FormWrapper;
