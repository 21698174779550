import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { Adjust } from '../../Icons/Adjust';
// import { TextIcon } from '../../Icons/Text';
// import { Bin } from '../../Icons/Bin';
import React from 'react';
import { Globe } from '../../Icons/Globe';

interface CommunityExperienceItemAdjustMenuProps {
  isOpen: boolean;
  ManageExperiences: () => void;
  // deleteExperience: () => void;
  setOpen: (state: boolean) => void;
}

function CommunityExperienceItemAdjustMenu({
  isOpen,
  ManageExperiences,
  // deleteExperience,
  setOpen,
}: CommunityExperienceItemAdjustMenuProps) {
  return (
    <Menu placement='right' closeOnBlur closeOnSelect isLazy isOpen={isOpen}>
      <MenuButton
        border='#D3BB8A'
        as={IconButton}
        aria-label='View Experience'
        bg='white'
        _hover={{
          bg: '#D3BB8A',
        }}
        borderRadius='100%'
        icon={<Adjust boxSize={[5]} stroke='#08101D' />}
        variant='outline'
        onClick={() => setOpen(!isOpen)}
      />
      <MenuList
        borderRadius='16px'
        background='#FFF'
        boxShadow='0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
        backdropFilter='blur(12px)'
        padding={['16px']}
        width={['230px']}
        display='flex'
        flexDirection='column'
        alignItems='flex-start'
        position='absolute'
        gap={2}
        zIndex='999'
      >
        <Box
          width={['100%']}
          height={['25px']}
          padding={['0px 8px']}
          borderRadius='30px'
          transition='0.2s all'
          _hover={{
            bg: '#D6DBE4',
          }}
          display='flex'
          justifyContent='flex-start'
          alignItems='center'
          onClick={ManageExperiences}
        >
          <Globe boxSize={[4]} stroke='black' />
          <Text
            ml={['5px']}
            fontFamily='Normal'
            fontSize={['12px']}
            color='black'
          >
            Manage Experience
          </Text>
        </Box>
        {/* <Box
          width={['100%']}
          height={['25px']}
          padding={['0px 8px']}
          borderRadius='30px'
          transition='0.2s all'
          _hover={{
            bg: '#D6DBE4',
          }}
          display='flex'
          justifyContent='flex-start'
          alignItems='center'
          onClick={deleteExperience}
        >
          <Bin stroke='#08101D' boxSize={[4]} />
          <Text
            ml={['5px']}
            fontFamily='Normal'
            fontSize={['12px']}
            color='black'
          >
            Delete
          </Text>
        </Box> */}
      </MenuList>
    </Menu>
  );
}

export default CommunityExperienceItemAdjustMenu;
