import React, { useEffect } from 'react';
import UnsubscribeEmailModal from '../components/modals/UnsubscribeEmailModal';
import { useSearchParams } from 'react-router-dom';
import { Box } from '@chakra-ui/react';

const UnsubscribeEmail = () => {
  const [searchParams] = useSearchParams();
  const [userId, setUserId] = React.useState<string | null>(null);

  useEffect(() => {
    if (!searchParams.get('user')) {
      window.location.href = '/#/';
    } else {
      setUserId(searchParams.get('user'));
    }

    return () => {
      setUserId(null);
    };
  }, [userId]);

  return (
    <Box>
      {userId ? (
        <UnsubscribeEmailModal modalActive={true} userId={userId} />
      ) : null}
    </Box>
  );
};

export default UnsubscribeEmail;
