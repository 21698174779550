import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import configureAxios from '../../axiosClient';
import { useUserStore } from '../../store/user';
import { useNavigate } from 'react-router-dom';

interface LeaveOrganisationModalProps {
  leaveOrganisationModalActive: boolean;
  setLeaveOrganisationModalActive: (state: boolean) => void;
}

function LeaveOrganisationModal({
  leaveOrganisationModalActive,
  setLeaveOrganisationModalActive,
}: LeaveOrganisationModalProps) {
  const navigate = useNavigate();
  const toast = useToast({
    position: 'top',
    variant: 'top-accent',
  });

  const { organisation, getOrganizations } = useUserStore();
  const axiosClient = configureAxios();

  const org_id = organisation.organizationId;

  const [leaveOrgLoader, setLeaveOrgLoader] = useState(false);

  async function leaveOrganisation() {
    setLeaveOrgLoader(true);
    try {
      const res = await axiosClient.post(`org/${org_id}/leave`);
      if (res.status === 200) {
        toast({
          title: res.data.data,
          status: 'success',
        });
        getOrganizations();
        setLeaveOrgLoader(false);
        setLeaveOrganisationModalActive(false);
        setTimeout(() => {
          navigate('/');
        }, 2000);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      toast({
        title: `${err.response.data.data}`,
        status: 'error',
      });
      setLeaveOrgLoader(false);
      console.log('err ', err);
    }
  }

  return (
    <Modal
      isOpen={leaveOrganisationModalActive}
      onClose={() => setLeaveOrganisationModalActive(false)}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        minW={['500px']}
        height={['auto']}
        padding={['20px']}
        borderRadius='16px'
        boxShadow='0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
      >
        <CloseIcon
          position='absolute'
          boxSize={[4]}
          cursor='pointer'
          color='#D6DBE4'
          _hover={{ color: 'black' }}
          transition='0.2s all'
          right={['20px']}
          top={['20px']}
          onClick={() => setLeaveOrganisationModalActive(false)}
        />
        <ModalBody
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          alignItems='center'
          gap={5}
        >
          <Text
            color='#08101D'
            fontFamily='Metal'
            fontSize={['35px']}
            lineHeight={['35px']}
            textAlign='center'
            textTransform='uppercase'
          >
            Leave organization
          </Text>
          <Box
            w={['100%']}
            height={['auto']}
            display='flex'
            flexDirection='column'
            justifyContent='space-between'
            alignItems='center'
            gap={5}
          >
            <Box
              display='flex'
              flexDir='column'
              textAlign='center'
              color='black'
            >
              <Text fontSize='15px' fontWeight='500'>
                Do you want to leave {organisation.alias}?
              </Text>
              <Text fontSize='11px' fontWeight='400'>
                You will lose access to {organisation.alias}, along with all
                experiences and files associated with it.
              </Text>
            </Box>
          </Box>
          <Box display='flex' flexDir='column' justifyContent='center' gap={3}>
            <Button
              textTransform='uppercase'
              width={['80%', '80%', '300px']}
              variant='outline'
              color='#08101D'
              background='white'
              border='1px solid #D6DBE4'
              fontFamily='Metal'
              fontSize={['14px', '14px', '15px']}
              borderRadius='30px'
              _hover={{
                bg: '#D3BB8A',
                color: 'black',
              }}
              isLoading={leaveOrgLoader}
              onClick={() => leaveOrganisation()}
            >
              Leave
            </Button>
            <Button
              textTransform='uppercase'
              width={['80%', '80%', '300px']}
              variant='outline'
              color='#08101D'
              background='white'
              border='1px solid #D6DBE4'
              fontWeight='400'
              fontFamily='Metal'
              fontSize={['14px', '14px', '15px']}
              borderRadius='30px'
              _hover={{
                bg: '#D3BB8A',
                color: 'black',
              }}
              onClick={() => setLeaveOrganisationModalActive(false)}
            >
              Cancel
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default LeaveOrganisationModal;
