import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const Leave = (props: IconProps) => (
  <Icon
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M1.85051 7H9.60156'
      stroke='#08101D'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.07812 4.76462L0.87291 6.96983L3.07812 9.17505'
      stroke='#08101D'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.72605 3.65433V2.74096C5.72605 1.77712 6.50317 1 7.46701 1H11.3879C12.3518 1 13.1289 1.77712 13.1289 2.74096V11.259C13.1289 12.2229 12.3518 13 11.3879 13H7.46701C6.50317 13 5.72605 12.2229 5.72605 11.259V10.3961'
      stroke='#08101D'
      strokeMiterlimit='10'
      strokeLinecap='round'
    />
  </Icon>
);
