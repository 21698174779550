import axios, {
  AxiosInstance,
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import { useAuthStore } from './store/auth';
import { useUserStore } from './store/user';
import { useExperiencesStore } from './store/experiences';
import { usePlanStore } from './store/plan';

interface ErrorResponseData {
  status: string;
  data: string;
}

type ConfigureAxiosOptions = {
  baseURL?: string;
  withCredentials?: boolean;
};

let isRefreshing = false;
let failedQueue: Array<{
  resolve: (value?: unknown) => void;
  reject: (reason?: AxiosError) => void;
}> = [];

const processQueue = (error: AxiosError | null) => {
  // console.log('Processing queue with error:', error);
  failedQueue.forEach((prom) => {
    // console.log('Queue item:', prom);
    if (error) {
      prom.reject(error);
    } else {
      // console.log('Resolving promise in queue.');
      prom.resolve();
    }
  });
  failedQueue = [];
};

export const configureAxios = (
  options?: ConfigureAxiosOptions
): AxiosInstance => {
  const axiosClient = axios.create({
    baseURL: options?.baseURL || process.env.REACT_APP_API_URL,
    withCredentials: options?.withCredentials ?? true,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json, text/plain, */*',
    },
  });

  axiosClient.interceptors.request.use((config) => {
    // console.log(`Request ${isRetry ? 'Retry' : 'Initial'} Config:`, config); // Debugging request config
    return config;
  });

  axiosClient.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError) => {
      const originalRequest = error.config as AxiosRequestConfig & {
        _retry?: boolean;
      };

      // console.log('Error Response:', error.response);
      // console.log('Original Request Config:', originalRequest);

      const isErrorResponseDataValid = (
        data: unknown
      ): data is ErrorResponseData => {
        // Use a type assertion instead of "as any"
        return (
          typeof data === 'object' &&
          data !== null &&
          'status' in data &&
          'data' in data &&
          typeof (data as ErrorResponseData).status === 'string' &&
          typeof (data as ErrorResponseData).data === 'string'
        );
      };

      if (
        error.response?.data &&
        isErrorResponseDataValid(error.response.data) &&
        error.response.data.status === 'BAD' &&
        error.response.data.data === 'Failed to authenticate user.' &&
        !originalRequest._retry
      ) {
        if (isRefreshing) {
          // console.log('Waiting for token refresh to complete...');

          return new Promise((resolve, reject) => {
            failedQueue.push({
              resolve: () => {
                // console.log('Retrying request from queue...');
                resolve(
                  axiosClient({
                    ...originalRequest,
                    headers: {
                      ...originalRequest.headers,
                      'X-Retry': 'true', // Mark this request as a retry
                    },
                  })
                );
              },
              reject,
            });
          })
            .then((response) => {
              // console.log('Retry successful:', response);
              return response;
            })
            .catch((err) => {
              // console.log('Retry failed:', err);
              return Promise.reject(err);
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        try {
          // console.log('Refreshing token...');
          await axios.get(`${process.env.REACT_APP_API_URL}auth/refresh`, {
            withCredentials: true,
          });

          // console.log('Token refreshed successfully.');
          isRefreshing = false;
          processQueue(null);

          // console.log('Retrying original request...');
          return axiosClient({
            ...originalRequest,
            headers: {
              ...originalRequest.headers,
              'X-Retry': 'true', // Mark this request as a retry
            },
          });
        } catch (refreshError) {
          // console.error('Token refresh failed:', refreshError);
          isRefreshing = false;
          processQueue(refreshError as AxiosError);

          const { resetPlans } = usePlanStore.getState();
          const { resetExperiences } = useExperiencesStore.getState();
          const { resetUser } = useUserStore.getState();
          const { resetAuth } = useAuthStore.getState();
          resetAuth();
          resetUser();
          resetExperiences();
          resetPlans();

          return Promise.reject(refreshError);
        }
      }

      return Promise.reject(error);
    }
  );

  return axiosClient;
};

export default configureAxios;
