import {
  Box,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import React, { forwardRef } from 'react';
import Toggle from '../../Icons/Toggle';
import { Leave } from '../../Icons/Leave';
import { useUserStore } from '../../store/user';

export type MemberItem = {
  id: string;
  name: string;
  surname: string;
  email: string;
  roles: string;
};

interface ManageMembersDropdownProps {
  member: MemberItem;
  setLeaveOrganisationModalActive: (state: boolean) => void;
  setDeleteOrganisationMemberModalActive: (
    state: boolean,
    member?: MemberItem
  ) => void;
}

const Trigger = forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <div ref={ref}>
      <Toggle cursor='pointer' boxSize={3} {...props} />
    </div>
  );
});

Trigger.displayName = 'Trigger';

function ManageMembersDropdown({
  member,
  setDeleteOrganisationMemberModalActive,
  setLeaveOrganisationModalActive,
}: ManageMembersDropdownProps) {
  const { user } = useUserStore();
  const isMyUser = user.email === member.email;

  return (
    <Popover placement='bottom'>
      <PopoverTrigger>
        <Trigger />
      </PopoverTrigger>
      <PopoverContent
        borderRadius='16px'
        background='#FFF'
        boxShadow='0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
        backdropFilter='blur(12px)'
        width={['178px']}
        height={['auto']}
        // position='absolute'
        // top='30px'
      >
        <PopoverBody
          height={['inherit']}
          display='flex'
          flexDirection='column'
          justifyContent='start'
          padding={['12px 16px']}
        >
          <Box flexDir='column' gap={5}>
            <Text fontFamily='Normal' fontSize={['14px']}></Text>
            <Box
              display='flex'
              borderBottom='1px solid #D6DBE4'
              pb='12px'
              justifyContent='start'
              gap={5}
            >
              <Text
                color='black'
                fontWeight='400'
                fontSize='15px'
                textTransform='capitalize'
              >
                {member?.roles.includes('owner')
                  ? 'Owner'
                  : member?.roles.includes('admin')
                  ? 'Admin'
                  : member?.roles.includes('member') && 'Member'}
              </Text>
            </Box>
            {isMyUser && (
              <Box
                display='flex'
                pt='12px'
                cursor='pointer'
                alignItems='center'
                justifyContent='space-between'
                onClick={() => setLeaveOrganisationModalActive(true)}
                gap={5}
              >
                <Text color='black' fontWeight='400' fontSize='15px'>
                  Leave
                </Text>
                <Leave boxSize={4} />
              </Box>
            )}

            {!isMyUser && (
              <Box
                display='flex'
                pt='12px'
                cursor='pointer'
                alignItems='center'
                justifyContent='space-between'
                gap={5}
                onClick={() =>
                  setDeleteOrganisationMemberModalActive(true, member)
                }
              >
                <Text color='black' fontWeight='400' fontSize='15px'>
                  Remove
                </Text>
                <Leave boxSize={4} />
              </Box>
            )}
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default ManageMembersDropdown;
