import { Box, Button, Image, Text } from '@chakra-ui/react';
import React from 'react';
import EmperiaLogo from '../../assets/image/EmperiaLogo.jpg';
import CreateTool from '../../assets/image/EmperiaCreatorLogo.png';
import ScreensizeWarningBackground from '../../assets/image/ScreensizeWarningBackground.jpg';
import linkedin from '../../assets/image/linkedin.jpg';
import twitter_x from '../../assets/image/twitter-x.jpg';
import youtube from '../../assets/image/youtube.jpg';
import discord from '../../assets/image/discord.jpg';
import {
  YOUTUBE_LINK,
  LINKEDIN_LINK,
  X_LINK,
  DISCORD_LINK,
} from '../../utils/constants';

const ScreenSizeWarning = () => {
  return (
    <Box
      display={{ base: 'flex', lg: 'none' }}
      flexDirection='column'
      justifyContent='space-between'
      alignItems='center'
      opacity={1}
      visibility='visible'
      transition='all 0.5s'
      position='fixed'
      height='100vh'
      width='100vw'
      background='rgba(8, 16, 29, 0.6)'
      backdropFilter='blur(10px)'
      zIndex='999'
      padding={['40px 0px']}
      color='white'
    >
      <Image
        src={ScreensizeWarningBackground}
        alt='ScreenSizeWarning'
        position='absolute'
        top={0}
        left={0}
        height='100%'
        width='100%'
        objectFit='cover'
        opacity={0.5}
        zIndex='-1'
      />
      <Box display='flex' flexDir='column'>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          mt='40px'
        >
          <Image src={EmperiaLogo} alt='emperia' width={84} height={76} />
          <Image src={CreateTool} alt='creator-tools' width={174} height={38} />
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          textAlign='center'
          maxW='250px'
          mt='40px'
          fontFamily='Normal'
        >
          <Text fontWeight='600' fontSize='16px' lineHeight='18px'>
            This screen size is not supported yet.
          </Text>
          <Text fontSize='11px' lineHeight='14px'>
            Please switch to desktop or laptop to use Emperia Creator Tools.
          </Text>
        </Box>
        <Button
          bgColor='white'
          color='black'
          textDecor='uppercase'
          padding='12px 24px 12px 24px'
          borderRadius='30px'
          fontSize='14px'
          fontFamily='Metal'
          fontWeight='400'
          cursor='pointer'
          mt='40px'
          _hover={{
            bgColor: '#D3BB8A',
          }}
          onClick={() => (window.location.href = 'https://emperiavr.com/')}
        >
          Learn about the platform
        </Button>
      </Box>

      <Box
        display='flex'
        justifyContent='space-between'
        gap={10}
        cursor='pointer'
      >
        <Image
          src={linkedin}
          alt='linkedin'
          width='32px'
          height='32px'
          borderRadius='50%'
          onClick={() => (window.location.href = LINKEDIN_LINK)}
        />
        <Image
          src={twitter_x}
          alt='twitter-x'
          width='32px'
          height='32px'
          borderRadius='50%'
          onClick={() => (window.location.href = X_LINK)}
        />
        <Image
          src={youtube}
          alt='youtube'
          width='32px'
          height='32px'
          borderRadius='50%'
          onClick={() => (window.location.href = YOUTUBE_LINK)}
        />
        <Image
          src={discord}
          alt='discord'
          width='32px'
          height='32px'
          borderRadius='50%'
          onClick={() => (window.location.href = DISCORD_LINK)}
        />
      </Box>
    </Box>
  );
};

export default ScreenSizeWarning;
