import {
  Box,
  Button,
  Collapse,
  FormControl,
  FormErrorMessage,
  Input,
  Spinner,
  Text,
  useToast,
} from '@chakra-ui/react';
import { Fragment, useState } from 'react';
import { Bar } from '../../Icons/Bar';
import { useAuthStore } from '../../store/auth';
import configureAxios from '../../axiosClient';
import { SubmitHandler, useForm } from 'react-hook-form';
import FormWrapper from '../common/FormWrapper';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { usePlanStore } from '../../store/plan';
import React from 'react';
import { dispatchOrganisationToAnalytics } from '../../analytics';
import ContactModal from '../modals/ContactModal';

const axiosClient = configureAxios();

export interface Question {
  index: number;
  question: string;
  answers: Answer[];
}

interface Output {
  answers: Answer[];
  lastQuestionAnswers: Answer[];
}

type Answer = {
  text: string;
  nextQuestionIndex: number;
};

type QuestionnaireFormValues = {
  orgName: string;
};

const questionnaireEndIdentifier = 100;

const selectedPlanMap = {
  '0137': 'Pro',
  '01237': 'Pro',
  '01347': 'Pro',
  '012347': 'Pro',
  '013457': 'Studio',
  '0123457': 'Studio',
  '01234567': 'Enterprise',
  '0134567': 'Enterprise',
  '012357': 'Studio',
  '01357': 'Studio',
  '0123567': 'Enterprise',
  '013567': 'Enterprise',
};

const questions: Question[] = [
  {
    index: 0,
    question: 'What’s your occupation?',
    answers: [
      {
        text: '3d Artist',
        nextQuestionIndex: 1,
      },
      {
        text: 'Web/graphic design',
        nextQuestionIndex: 1,
      },
      {
        text: 'Engineering',
        nextQuestionIndex: 1,
      },
      {
        text: 'Marketing',
        nextQuestionIndex: 1,
      },
      {
        text: 'E-commerce manager',
        nextQuestionIndex: 1,
      },
      {
        text: 'Founder',
        nextQuestionIndex: 1,
      },
      {
        text: 'Student',
        nextQuestionIndex: 1,
      },
    ],
  },
  {
    index: 1,
    question: 'Are you familiar with Unreal Engine 5?',
    answers: [
      {
        text: 'Yes',
        nextQuestionIndex: 3,
      },
      {
        text: 'No',
        nextQuestionIndex: 2,
      },
    ],
  },
  {
    index: 2,
    question:
      'Emperia Creator Tools, built on Unreal Engine 5, require basic Unreal Engine knowledge; our team can support your virtual world building.',
    answers: [
      {
        text: 'Continue anyway',
        nextQuestionIndex: 3,
      },
      {
        text: 'Contact sales',
        nextQuestionIndex: 3,
      },
    ],
  },
  {
    index: 3,
    question: 'Who do you want to build virtual worlds for?',
    answers: [
      {
        text: 'Myself',
        nextQuestionIndex: 7,
      },
      {
        text: 'Clients',
        nextQuestionIndex: 4,
      },
      {
        text: 'Company',
        nextQuestionIndex: 5,
      },
    ],
  },
  {
    index: 4,
    question: 'How do you work with clients?',
    answers: [
      {
        text: 'As a Freelancer',
        nextQuestionIndex: 7,
      },
      {
        text: 'In an agency/ consultancy',
        nextQuestionIndex: 5,
      },
    ],
  },
  {
    index: 5,
    question: 'How many people are employed at your organization?',
    answers: [
      {
        text: '1 - 10',
        nextQuestionIndex: 7,
      },
      {
        text: '11 - 50',
        nextQuestionIndex: 7,
      },
      {
        text: '51 - 200',
        nextQuestionIndex: 6,
      },
      {
        text: '201 - 500',
        nextQuestionIndex: 6,
      },
      {
        text: '501 - 1000',
        nextQuestionIndex: 6,
      },
      {
        text: '1000 +',
        nextQuestionIndex: 6,
      },
    ],
  },
  {
    index: 6,
    question: 'Would you like someone to help you build your virtual world?',
    answers: [
      {
        text: 'Yes',
        nextQuestionIndex: 7,
      },
      {
        text: 'No',
        nextQuestionIndex: 7,
      },
    ],
  },
  {
    index: 7,
    question:
      'What do you want to use Emperia for? (Choose as many as you like)',
    answers: [
      {
        text: '3D Art',
        nextQuestionIndex: questionnaireEndIdentifier,
      },
      {
        text: 'Virtual stores',
        nextQuestionIndex: questionnaireEndIdentifier,
      },
      {
        text: 'Marketing & promotion',
        nextQuestionIndex: questionnaireEndIdentifier,
      },
      {
        text: 'Automotive',
        nextQuestionIndex: questionnaireEndIdentifier,
      },
      {
        text: 'Real estate',
        nextQuestionIndex: questionnaireEndIdentifier,
      },
      {
        text: 'Virtual event',
        nextQuestionIndex: questionnaireEndIdentifier,
      },
      {
        text: 'Portfolio',
        nextQuestionIndex: questionnaireEndIdentifier,
      },
      {
        text: 'Study',
        nextQuestionIndex: questionnaireEndIdentifier,
      },
      {
        text: 'Just for fun',
        nextQuestionIndex: questionnaireEndIdentifier,
      },
    ],
  },
];

function QuestionnaireForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<QuestionnaireFormValues>();

  const { setShowPlans, setSuggestedPlan } = usePlanStore();
  const { setLoggedIn, setActiveLoginView, setFirstTime, contactedSales } =
    useAuthStore();
  const toast = useToast({
    position: 'top',
    variant: 'top-accent',
  });

  const [showContactModal, setShowContactModal] = useState(false);
  const [selectAnswerError, setSelectAnswerError] = useState(false);
  const [activeView, setActiveView] = useState('orgName');
  const [orgName, setOrgName] = useState('');
  const [submittingQuestionnaire, setSubmittingQuestionnaire] = useState(false);
  const [prevQuestionsTrail, setPrevQuestionsTrail] = useState<Question[]>([]);
  const [activeQuestion, setActiveQuestion] = useState<Question>(questions[0]);
  const [output, setOutput] = useState<Output>({
    answers: [],
    lastQuestionAnswers: [],
  });

  function getQuestionAnswerPairs(
    questions: Question[],
    givenAnswers: Answer[]
  ) {
    const questionAnswerPairs: { question: string; answer: string }[] = [];

    givenAnswers.forEach((givenAnswer, index: number) => {
      const question = questions[index].question;
      const answer = givenAnswer.text;

      questionAnswerPairs.push({ question, answer });
    });

    return questionAnswerPairs;
  }

  const onSubmit = () => {
    setSubmittingQuestionnaire(true);

    const concatenatedIndexes = prevQuestionsTrail.reduce((acc, obj) => {
      return acc + obj.index;
    }, '');

    const suggestedPlan =
      selectedPlanMap[
        (concatenatedIndexes + '7') as keyof typeof selectedPlanMap
      ];

    const cleanedOutputAnswers = output.answers.filter((answer: Answer) => {
      return (
        answer !== undefined &&
        answer !== null &&
        answer.text !== '' &&
        Object.keys(answer).length !== 0
      );
    });

    const data = [
      ...getQuestionAnswerPairs(prevQuestionsTrail, cleanedOutputAnswers),
      {
        question:
          'What do you want to use Emperia for? (Choose as many as you like)',
        answer: output.lastQuestionAnswers.map((answer: Answer) => answer.text),
      },
    ];

    axiosClient
      .post(`org/create`, {
        suggested_plan: suggestedPlan,
        suggested_interval: 'yearly',
        organisation_name: orgName,
        data: data,
      })
      .then((res) => {
        const isStatusOk = res.data.status === 'OK';

        if (isStatusOk) {
          setSuggestedPlan(suggestedPlan, 'yearly');
          setPrevQuestionsTrail([]);
          setOutput({
            answers: [],
            lastQuestionAnswers: [],
          });

          setShowPlans(true);

          setLoggedIn(true);
          setFirstTime(true);
          setTimeout(() => {
            setActiveLoginView('login');
          }, 2000);

          dispatchOrganisationToAnalytics('create_organisation', {
            organisation_id: res.data.data.id,
            organisation_user_id: res.data.data.owner,
            organisation_occupation: data[0].answer as string,
            organisation_relationship: data[2].answer as string,
            organisation_target_audience: data[1].answer as string,
            organisation_team_size: data[3].answer as string,
            organisation_usage: data[4].answer as string,
          });
          setTimeout(() => {
            setActiveQuestion(questions[0]);
          }, 1000);
        } else {
          toast({
            title: `There was an error submitting questionnaire`,
            status: 'error',
          });
        }
        setSubmittingQuestionnaire(false);
      })
      .catch((err: unknown) => {
        console.log(err);
        setSubmittingQuestionnaire(false);
      });
  };

  const onOrgNameSubmit: SubmitHandler<QuestionnaireFormValues> = (data) => {
    setOrgName(data.orgName);
    setActiveView('questions');
  };

  return (
    <Fragment>
      <ContactModal
        postLogin={false}
        contactModalActive={showContactModal}
        setContactModalActive={(state) => setShowContactModal(state)}
      />
      <Collapse
        animateOpacity
        in={activeView === 'orgName'}
        style={{ width: '100%' }}
      >
        <FormWrapper
          onSubmit={handleSubmit(onOrgNameSubmit)}
          name='create_organisation'
        >
          <Box
            w={['100%']}
            height={['auto']}
            display='flex'
            flexDirection='column'
            justifyContent='space-between'
            alignItems='center'
            gap={5}
          >
            <Text
              fontFamily='Metal'
              fontSize={['30px', '30px', '40px']}
              lineHeight={['30px', '30px', '40px']}
              textAlign='center'
              textTransform='uppercase'
            >
              Organisation Details
            </Text>
            <FormControl isInvalid={errors.orgName ? true : false} mb='10px'>
              <Input
                id='orgName'
                placeholder='enter your organisation name'
                color='white'
                borderBottom='1px solid #D6DBE4'
                variant='flushed'
                fontFamily='Normal'
                fontSize={['14px', '14px', '14px']}
                size='md'
                _placeholder={{ color: '#7F8B9E' }}
                errorBorderColor='#EA1A4C'
                {...register('orgName', {
                  required: 'This is required',
                })}
              />
              <FormErrorMessage
                color='#EA1A4C'
                mt={['4px']}
                fontSize='13px'
                fontFamily='Normal'
              >
                {errors.orgName && errors.orgName.message}
              </FormErrorMessage>
            </FormControl>
            <Button
              textTransform='uppercase'
              width={['80%', '80%', '300px']}
              variant='outline'
              color='#7F8B9E'
              background='#1D2531'
              border='1px solid rgba(242, 243, 245, 0.14)'
              fontFamily='Metal'
              fontSize={['14px', '14px', '15px']}
              borderRadius='30px'
              _hover={{
                bg: 'white',
                color: '#08101D',
              }}
              type='submit'
            >
              Next
            </Button>
          </Box>
        </FormWrapper>
      </Collapse>
      <Collapse
        animateOpacity
        in={activeView === 'questions'}
        style={{ width: '100%' }}
      >
        {submittingQuestionnaire ? (
          <Box
            w={['100%']}
            height={['100px']}
            display='flex'
            justifyContent='center'
            alignItems='center'
            gap={2}
          >
            <Text
              textAlign='center'
              color='white'
              fontFamily='Normal'
              fontSize={['14px', '14px', '14px']}
            >
              Setting up your dashboard
            </Text>
            <Spinner size='md' />
          </Box>
        ) : (
          <Box
            w={['100%']}
            height={['auto']}
            display='flex'
            flexDirection='column'
            justifyContent='space-between'
            alignItems='center'
            gap={5}
          >
            <Text
              fontFamily='Metal'
              fontSize={['15px', '15px', '15px']}
              textAlign='center'
              textTransform='uppercase'
            >
              {activeQuestion.question}
            </Text>

            <Box
              width={['100%']}
              display='flex'
              flexDirection='column'
              alignItems='center'
            >
              <Box
                bg='#08101D'
                borderRadius='16px'
                padding={['16px']}
                width={['100%']}
                display='flex'
                flexDirection='column'
                alignItems='center'
                gap={1}
              >
                {activeQuestion.answers.map(
                  (answer: { text: string; nextQuestionIndex: number }) => (
                    <Box
                      key={answer?.text}
                      width={['100%']}
                      p={['8px 24px']}
                      textTransform='uppercase'
                      textAlign='center'
                      bg={
                        questions.length - 1 === activeQuestion.index
                          ? output?.lastQuestionAnswers.find(
                              (lastQA) => lastQA?.text === answer?.text
                            )
                            ? '#1D2531'
                            : 'transparent'
                          : output?.answers[activeQuestion.index]?.text ===
                            answer?.text
                          ? '#1D2531'
                          : 'transparent'
                      }
                      color={
                        questions.length - 1 === activeQuestion.index
                          ? output?.lastQuestionAnswers.find(
                              (lastQA) => lastQA?.text === answer?.text
                            )
                            ? 'white'
                            : '#7F8B9E'
                          : output?.answers[activeQuestion.index]?.text ===
                            answer?.text
                          ? 'white'
                          : '#7F8B9E'
                      }
                      fontFamily='Normal'
                      fontSize={['13px', '13px', '13px']}
                      fontWeight='900'
                      borderRadius='30px'
                      _hover={{
                        bg: '#1D2531',
                        color: 'white',
                      }}
                      transition='0.2s all'
                      cursor='pointer'
                      onClick={() => {
                        setSelectAnswerError(false);
                        if (activeQuestion.index <= 6) {
                          if (
                            activeQuestion?.index === 2 &&
                            answer?.text === 'Contact sales'
                          ) {
                            if (contactedSales) {
                              if (!toast.isActive('alreadyContactedSales')) {
                                toast({
                                  id: 'alreadyContactedSales',
                                  title: `You have already contacted Sales, continue`,
                                  status: 'info',
                                });
                              }
                            } else {
                              setShowContactModal(true);
                            }
                          }

                          const updateAnswers = [...output.answers];
                          updateAnswers[activeQuestion.index] = answer;
                          setOutput({
                            ...output,
                            answers: updateAnswers,
                          });
                        } else {
                          const oldLastQuestionAnswers =
                            output.lastQuestionAnswers;
                          const alreadySelected = oldLastQuestionAnswers.find(
                            (oldLastQuestionAnswer: {
                              text: string;
                              nextQuestionIndex: number;
                            }) => oldLastQuestionAnswer?.text === answer?.text
                          );
                          if (alreadySelected) {
                            const updatedLastQuestionAnswers =
                              oldLastQuestionAnswers.filter(
                                (oldLastQuestionAnswer: {
                                  text: string;
                                  nextQuestionIndex: number;
                                }) =>
                                  oldLastQuestionAnswer?.text !== answer?.text
                              );
                            setOutput({
                              ...output,
                              lastQuestionAnswers: updatedLastQuestionAnswers,
                            });
                          } else {
                            setOutput({
                              ...output,
                              lastQuestionAnswers: [
                                ...output.lastQuestionAnswers,
                                answer,
                              ],
                            });
                          }
                        }
                      }}
                    >
                      {answer?.text}
                    </Box>
                  )
                )}
              </Box>
              {selectAnswerError && (
                <Text
                  color='#EA1A4C'
                  mt={['4px']}
                  fontSize='13px'
                  fontFamily='Normal'
                >
                  Please select an answer to proceed
                </Text>
              )}
            </Box>

            {prevQuestionsTrail.length > 0 && (
              <Button
                fontSize={['12px']}
                color='white'
                fontFamily={'Normal'}
                variant='link'
                onClick={() => {
                  const updatedPrevQuestionsTrail = prevQuestionsTrail;
                  const latestLastQues = updatedPrevQuestionsTrail.pop();

                  const currentAnswers = output.answers;
                  currentAnswers.pop();

                  setOutput({
                    answers: currentAnswers,
                    lastQuestionAnswers: [],
                  });

                  setPrevQuestionsTrail(updatedPrevQuestionsTrail);
                  setActiveQuestion(latestLastQues as Question);
                }}
              >
                Previous Question
              </Button>
            )}

            <Button
              textTransform='uppercase'
              width={['80%', '80%', '250px']}
              variant='outline'
              color='#7F8B9E'
              background='#1D2531'
              border='1px solid rgba(242, 243, 245, 0.14)'
              fontFamily='Metal'
              fontSize={['15px', '15px', '15px']}
              borderRadius='30px'
              _hover={{
                bg: 'white',
                color: '#08101D',
              }}
              onClick={() => {
                if (activeQuestion.index <= 6) {
                  const selectedAnswer = output.answers[activeQuestion.index];
                  if (selectedAnswer) {
                    const nextQuestion =
                      questions[selectedAnswer.nextQuestionIndex];
                    setPrevQuestionsTrail([
                      ...prevQuestionsTrail,
                      activeQuestion,
                    ]);
                    setActiveQuestion(nextQuestion);
                  } else {
                    setSelectAnswerError(true);
                  }
                } else {
                  if (output.lastQuestionAnswers.length === 0) {
                    setSelectAnswerError(true);
                  } else {
                    onSubmit();
                  }
                }
              }}
            >
              Next
            </Button>

            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              flexDirection='column'
              gap={2}
            >
              <Box
                w={['auto']}
                height={['auto']}
                display='flex'
                justifyContent='center'
                alignItems='center'
                gap={1}
              >
                {questions.map((ques: Question, index: number) => (
                  <Bar
                    borderRadius='100%'
                    key={ques.question}
                    boxSize={[6, 6, 6]}
                    fill={activeQuestion.index === index ? 'white' : '#47505F'}
                    transition='0.2s all'
                  />
                ))}
              </Box>
              <Button
                leftIcon={
                  <ChevronLeftIcon boxSize={[4]} color='currentColor' />
                }
                color='white'
                fontSize='12px'
                variant='link'
                fontFamily='Normal'
                onClick={() => {
                  setActiveView('orgName');
                  setActiveQuestion(questions[0]);
                  setOutput({
                    answers: [],
                    lastQuestionAnswers: [],
                  });
                  setPrevQuestionsTrail([]);
                }}
              >
                Go Back
              </Button>
            </Box>
          </Box>
        )}
      </Collapse>
    </Fragment>
  );
}

export default QuestionnaireForm;
