import { Box, Divider, Text } from '@chakra-ui/react';
import { useUserStore } from '../../store/user';
import React, { useState } from 'react';
import OrganisationDropdown from '../billingsView/OrganisationDropdown';
import CreateNewOrganisationModal from '../modals/CreateNewOrganisationModal';
import { usePlanStore } from '../../store/plan';

interface OrganisationCardProps {
  membersCount: string;
}

function OrganisationCard({ membersCount }: OrganisationCardProps) {
  const { organisation } = useUserStore();
  const { currentPlan } = usePlanStore();
  const [
    createNewOrganisationModalActive,
    setCreateNewOrganisationModalActive,
  ] = useState(false);

  return (
    <Box
      borderRadius='30px'
      border='1px solid rgba(242, 243, 245, 0.14)'
      p={['30px']}
      width={['100%']}
      display='flex'
      justifyContent='space-between'
      // alignItems="flex-start"
      position='relative'
    >
      <Box display='flex' flexDirection='column'>
        <Text
          fontFamily='Metal'
          textTransform='capitalize'
          fontSize={['20px']}
          maxWidth='200px'
          overflow='hidden'
          whiteSpace='nowrap'
          textOverflow='ellipsis'
        >
          {organisation.alias}
        </Text>
        <Box display='flex' alignItems='center' gap={2}>
          <Text
            fontFamily='Metal'
            textTransform='uppercase'
            fontSize={['15px']}
          >
            {currentPlan.name.split('_')[0]}
          </Text>
          <Divider orientation='vertical' height='20px' color='#D6DBE4' />
          <Box display='flex'>
            <Text
              fontFamily='Metal'
              textTransform='capitalize'
              fontSize={['15px']}
              color='#D3BB8A'
            >
              MEMBERS
            </Text>
            <Text
              fontFamily='Metal'
              textTransform='capitalize'
              fontSize={['15px']}
              ml={['10px']}
            >
              {membersCount}
            </Text>
          </Box>
        </Box>
      </Box>
      <Box cursor='pointer'>
        <OrganisationDropdown
          setCreateNewOrganisationModalActive={
            setCreateNewOrganisationModalActive
          }
        />
      </Box>
      <CreateNewOrganisationModal
        createNewOrganisationModalActive={createNewOrganisationModalActive}
        setCreateNewOrganisationModalActive={(state) =>
          setCreateNewOrganisationModalActive(state)
        }
      />
    </Box>
  );
}

export default OrganisationCard;
