import { Button, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { Star } from '../../Icons/Star';
import { GoldQuestionMark } from '../../Icons/GoldQuestionMark';
import { BlackBin } from '../../Icons/BlackBin';
import { usePlanStore } from '../../store/plan';
import { Upload } from '../../Icons/Upload';

interface NearingUsageLimitModalProps {
  setNearingUsageLimitActive: (state: boolean) => void;
  setContactModalActive: (state: boolean) => void;
}

const NearingUsageLimit = ({
  setNearingUsageLimitActive,
  setContactModalActive,
}: NearingUsageLimitModalProps) => {
  const { setShowPlans } = usePlanStore();

  return (
    <Flex flexDir='column' textAlign='center' gap={5}>
      <Text
        fontFamily='Metal'
        textTransform='uppercase'
        fontSize='36px'
        fontWeight='400'
        color='#08101D'
      >
        Nearing usage limits
      </Text>
      <Flex flexDir='column' color='#08101D' maxWidth='415px' gap={5}>
        <Text fontWeight='400' fontSize='14px' fontFamily='Normal'>
          You have exceed 95% of your plan limits. You may be unable to create
          new experiences.
        </Text>

        <Text color='#47505F' fontFamily='Normal' fontSize='11px'>
          Data storage is constant, while bandwidth renews monthly.
        </Text>
      </Flex>
      <Flex
        flexDir='column'
        margin='auto'
        border='1px solid #D6DBE4'
        borderRadius='16px'
        padding='16px'
        gap={5}
        fontFamily='Normal'
      >
        <Flex alignItems='center' gap={2}>
          <GoldQuestionMark />
          <Text color='#D3BB8A' fontWeight='600' size='11px'>
            To avoid interruptions:
          </Text>
        </Flex>
        <Flex flexDir='column' textAlign='left' color='#08101D' gap={2}>
          <Text fontSize='14px'>Data Storage limit:</Text>
          <Flex fontSize='11px' alignItems='center' gap={1}>
            <Star />
            <Text fontWeight='800' color='#08101D'>
              Upgrade to paid plan
            </Text>
            <Text>to increase your limits</Text>
          </Flex>
          <Flex fontSize='11px' gap={1}>
            <BlackBin />
            <Text fontWeight='800'>Free up space.</Text>
            <Text>Delete old experiences.</Text>
          </Flex>
        </Flex>
        <Flex
          flexDir='column'
          fontFamily='Normal'
          textAlign='left'
          color='#08101D'
          gap={2}
        >
          <Text fontSize='14px'>Bandwidth limit:</Text>
          <Flex fontSize='11px' alignItems='center' gap={1}>
            <Star />
            <Text fontWeight='800' color='#08101D'>
              Upgrade to paid plan
            </Text>
            <Text>to increase your limits</Text>
          </Flex>
          <Flex fontSize='11px' alignItems='center' gap={1}>
            <Upload />
            <Text color='#08101D'>
              <span style={{ fontWeight: '800' }}>
                Keep experiences offline.
              </span>{' '}
              Publish again next billing month.
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex flexDir='column' justifyContent='center' gap={5}>
        <Button
          _hover={{
            bgColor: '#D3BB8A',
          }}
          color='#08101D'
          bgColor='transparent'
          border='1px solid #D6DBE4'
          borderRadius='30px'
          padding='12px 24px 12px 24px'
          textTransform='uppercase'
          fontFamily='Metal'
          leftIcon={<Star />}
          onClick={() => {
            setNearingUsageLimitActive(false);
            setShowPlans(true);
          }}
        >
          upgrade subscription
        </Button>
      </Flex>
      <Text
        fontSize='11px'
        fontFamily='Normal'
        color='#58677E'
        _hover={{
          textDecor: 'underline',
          cursor: 'pointer',
        }}
        onClick={() => {
          setNearingUsageLimitActive(false);
          setContactModalActive(true);
        }}
      >
        Need assistance? Contact sales
      </Text>
    </Flex>
  );
};

export default NearingUsageLimit;
