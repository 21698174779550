import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const Notification = (props: IconProps) => (
  <Icon
    width='34'
    height='34'
    viewBox='0 0 34 34'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M23.342 21.5091C23.8854 21.5211 24.1752 20.8598 23.7875 20.4762C23.4379 20.1305 22.9624 19.8528 22.6307 19.5271C21.2701 18.1905 21.6577 16.2084 21.6577 14.4662C21.6577 12.5441 20.2371 10.9557 18.3889 10.6899C18.0732 10.644 17.8435 10.3703 17.8435 10.0506V9.09753C17.8435 8.62 17.4499 8.23638 16.9703 8.25037H17.0303C16.5508 8.23638 16.1572 8.62 16.1572 9.09753V10.0506C16.1572 10.3683 15.9254 10.644 15.6117 10.6899C13.7635 10.9557 12.343 12.5441 12.343 14.4662C12.343 16.2064 12.7326 18.1885 11.3699 19.5271C11.0383 19.8528 10.5627 20.1305 10.2131 20.4762C9.82547 20.8598 10.1152 21.5211 10.6586 21.5091C13.0802 21.4572 20.9204 21.4572 23.342 21.5091Z'
      stroke='white'
      strokeWidth='1.4'
      strokeMiterlimit='10'
    />
    <path
      d='M19.4028 23.0635V23.497C19.4028 24.3002 18.9633 25.0415 18.254 25.4171C17.3908 25.8747 16.5297 25.8607 15.6665 25.3732C14.9972 24.9955 14.5996 24.2683 14.5996 23.499V23.0635'
      stroke='white'
      strokeWidth='1.4'
      strokeMiterlimit='10'
      strokeLinecap='round'
    />
  </Icon>
);
