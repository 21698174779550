import React from 'react';
import { motion } from 'framer-motion';
import { Box, Text } from '@chakra-ui/react';
import moment from 'moment';
import { CommunityExperienceListItem } from '../../interfaces';

interface CommunityExperienceDetailedInfoProps {
  experienceHovered: boolean;
  communityExperienceListItem: CommunityExperienceListItem;
}

const CommunityExperienceDetailedInfo = ({
  experienceHovered,
  communityExperienceListItem,
}: CommunityExperienceDetailedInfoProps) => {
  return (
    <motion.div
      initial={{ opacity: 0, height: 0 }}
      animate={{
        opacity: experienceHovered ? 1 : 0,
        height: experienceHovered ? 'auto' : 0,
      }}
      transition={{ duration: 0.2, ease: 'easeInOut' }}
      style={{ overflow: 'hidden' }}
    >
      {experienceHovered && (
        <Box mt='5px' width={['100%']} gap={2}>
          <Box display='flex' width={['inherit']} alignItems='center'>
            <Text
              color='white'
              fontWeight='600'
              fontSize='13px'
              fontFamily='Normal'
            >
              Tags:
            </Text>
            <Box display='flex' width={['inherit']} ml='4px' gap={2}>
              {communityExperienceListItem.tags.length > 0 ? (
                communityExperienceListItem.tags
                  .slice(0, 4)
                  .map((tag, index) => (
                    <Box
                      key={index}
                      display='flex'
                      width='auto'
                      border='1px solid white'
                      padding='3px 6px'
                      borderRadius='16px'
                    >
                      <Text
                        fontFamily='Normal'
                        color='white'
                        maxWidth='50px'
                        fontSize='11px'
                        display='block'
                        textOverflow='ellipsis'
                        whiteSpace='nowrap'
                        overflow='hidden'
                        title={tag}
                      >
                        {tag}
                      </Text>
                    </Box>
                  ))
              ) : (
                <Text fontFamily='Normal' color='white' fontSize='11px'>
                  --
                </Text>
              )}
            </Box>
          </Box>
          <Box display='flex' alignItems='center' gap={2} mt='5px'>
            <Text
              color='white'
              fontWeight='600'
              fontSize='13px'
              fontFamily='Normal'
            >
              Published:
            </Text>
            <Text
              as={'span'}
              color={'#ACBBD3'}
              fontFamily='Normal'
              fontSize='13px'
            >
              {communityExperienceListItem?.community_shared_at === null ||
              communityExperienceListItem?.community_shared_at === ''
                ? '--'
                : moment(
                    communityExperienceListItem?.community_shared_at
                  ).fromNow()}
            </Text>
          </Box>
        </Box>
      )}
    </motion.div>
  );
};

export default CommunityExperienceDetailedInfo;
