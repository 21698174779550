import {
  Box,
  Button,
  Collapse,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Progress,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { usePlanStore } from '../../store/plan';
import { DollarCircle } from '../../Icons/DollarCircle';
import { RightStemArrow } from '../../Icons/RightStemArrow';
import { useUserStore } from '../../store/user';
import configureAxios from '../../axiosClient';
import { getNextBillDate } from '../../utils/helper';
import { CloseIcon } from '@chakra-ui/icons';
import { SubmitHandler, useForm } from 'react-hook-form';
import FormWrapper from '../common/FormWrapper';
import { dispatchSubscriptionToAnalytics } from '../../analytics';

interface CancelContactModalProps {
  cancelPlanModalActive: boolean;
  setCancelPlanModalActive: (state: boolean) => void;
}

type Usage = {
  storage: {
    used: number;
    limit: number;
    unit_price: number;
  };
  bandwidth: {
    used: number;
    limit: number;
    unit_price: number;
  };
};

type CancelContactModalFormValues = {
  reason: string;
  improve: string;
};

const axiosClient = configureAxios();

function CancelContactModal({
  cancelPlanModalActive,
  setCancelPlanModalActive,
}: CancelContactModalProps) {
  const toast = useToast({
    position: 'top',
    variant: 'top-accent',
  });
  const { currentPlan } = usePlanStore();
  const { organisation, user } = useUserStore();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [cancelConfirmed, setCancelConfirmed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [usage, setUsage] = useState<Usage>({
    storage: {
      used: 0,
      limit: 0,
      unit_price: 0,
    },
    bandwidth: {
      used: 0,
      limit: 0,
      unit_price: 0,
    },
  });

  useEffect(() => {
    const userOrgId = organisation.organizationId;

    if (organisation?.organizationId?.length > 0)
      axiosClient
        .get(`org/${userOrgId}/usage/read`)
        .then((res) => {
          const isStatusOk = res.data.status === 'OK';
          if (res.data.status === 'LOGOUT') {
            console.log('Logged Out');
          } else if (isStatusOk) {
            setUsage(res.data.data);
          } else {
            toast({
              title: `There was an error fetching usage details`,
              status: 'error',
            });
          }
        })
        .catch((err: unknown) => {
          console.log(err);
        });
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CancelContactModalFormValues>();

  const onSubmit: SubmitHandler<CancelContactModalFormValues> = (data) => {
    setIsSubmitting(true);
    const userOrgId = organisation.organizationId;
    axiosClient
      .post(`org/${userOrgId}/email/downgrade`, {
        reason: data.reason,
        message: data.improve,
      })
      .then((res) => {
        const isStatusOk = res.data.status === 'OK';
        if (isStatusOk) {
          setFormSubmitted(true);
          toast({
            title: `Your request was sent successfully.`,
            status: 'success',
          });

          dispatchSubscriptionToAnalytics('downgrade_subscription', {
            current_plan: 'free',
            previous_plan: currentPlan.name,
            subscription_price: currentPlan?.standingCharge?.toString() || '',
            organisation_id: organisation.organizationId,
            organisation_user_id: user.id,
            subscription_interval: currentPlan?.name?.split('_')[1],
          });
        } else {
          toast({
            title: `Your request for cancellation failed.`,
            status: 'error',
          });
        }
        setIsSubmitting(false);
      })
      .catch((err: unknown) => {
        console.log(err);
        setIsSubmitting(false);
      });
  };

  const close = () => {
    setCancelPlanModalActive(false);
    setCancelConfirmed(false);
    setFormSubmitted(false);
    reset();
  };

  const storageUsed = usage.storage.used;
  const storageLimit = usage.storage.limit;
  const storageUnitPrice = usage.storage.unit_price;
  const additionalStorageUsed =
    storageUsed > storageLimit ? storageUsed - storageLimit : 0;
  const storageDivider = storageUsed / storageLimit || 0;
  const storagePercentageUsed = storageDivider * 100;
  const storagePercentageUsedFixed = storagePercentageUsed.toFixed(0);

  const bandwidthUsed = usage.bandwidth.used;
  const bandwidthLimit = usage.bandwidth.limit;
  const bandwidthUnitPrice = usage.bandwidth.unit_price;
  const additionalBandwidthUsed =
    bandwidthUsed > bandwidthLimit ? bandwidthUsed - bandwidthLimit : 0;
  const bandwidthDivider = bandwidthUsed / bandwidthLimit || 0;
  const bandwidthPercentageUsed = bandwidthDivider * 100;
  const bandwidthPercentageUsedFixed = bandwidthPercentageUsed.toFixed(0);

  const freeStorageDivider = storageUsed / 0.5 || 0;
  const freeStoragePercentageUsed = freeStorageDivider * 100;
  const freeStoragePercentageUsedFixed = freeStoragePercentageUsed.toFixed(0);

  const freeBandwidthDivider = bandwidthUsed / 5 || 0;
  const freeBandwidthPercentageUsed = freeBandwidthDivider * 100;
  const freeBandwidthPercentageUsedFixed =
    freeBandwidthPercentageUsed.toFixed(0);

  const isEnterpriseStorage = storageLimit === 0;
  const isEnterpriseBandwidth = bandwidthLimit === 0;

  return (
    <Modal
      isOpen={cancelPlanModalActive}
      onClose={close}
      isCentered
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent
        minW={['700px']}
        height={['auto']}
        margin='auto'
        maxHeight='80vh'
        overflow='auto'
        padding={['20px']}
        borderRadius='16px'
        boxShadow='0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
      >
        <CloseIcon
          position='absolute'
          boxSize={[4]}
          cursor='pointer'
          color='#D6DBE4'
          _hover={{ color: 'black' }}
          transition='0.2s all'
          right={['20px']}
          top={['20px']}
          onClick={close}
        />
        <ModalBody>
          <Collapse
            animateOpacity
            in={!cancelConfirmed && !formSubmitted}
            style={{ width: '100%' }}
          >
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='space-between'
              alignItems='center'
              gap={5}
            >
              <Text
                color='#08101D'
                fontFamily='Metal'
                fontSize={['35px']}
                lineHeight={['35px']}
                textAlign='center'
                textTransform='uppercase'
              >
                Cancel Paid Plan
              </Text>
              <Text fontSize={['12px']} fontFamily='Normal' color='#08101D'>
                {`Your monthly subscription is paid until ${getNextBillDate(
                  currentPlan.startDate,
                  currentPlan?.name?.split('_')[1]
                )}. Beyond that date, access to premium features
                such as extra storage, custom UI and domain attachment, and white-label design will be restricted. You
                can still create amazing experiences and retain access to your existing ones.`}
              </Text>

              <Box
                width={['100%']}
                display='flex'
                flexDirection='column'
                alignItems='flex-start'
                gap={2}
              >
                <Box display='flex' alignItems='center' gap={1}>
                  <DollarCircle fill='#EA1A4C' boxSize={[3]} />
                  <Text
                    fontSize={['12px']}
                    color='#EA1A4C'
                    fontWeight='900'
                    fontFamily='Bold'
                  >
                    Data Usage
                  </Text>
                </Box>

                <Text fontSize={['12px']} fontFamily='Normal' color='#08101D'>
                  {`Please note, If your current storage usage exceeds the limits of the Free Plan, you'll need to
                temporarily take all published experiences offline when transitioning at the end of your current plan's
                billing cycle. Once switched, you can republish them within the updated Free Plan limits.`}
                </Text>
                <Link
                  href={process.env.REACT_APP_DOCUMENTATION_URL}
                  target='_blank'
                >
                  <Button
                    rightIcon={
                      <RightStemArrow
                        stroke='currentColor'
                        fill='currentColor'
                        boxSize={[3]}
                        transform='rotate(-45deg)'
                      />
                    }
                    fontSize={['11px']}
                    variant='link'
                    color='#08101D'
                    fontFamily='Normal'
                    textAlign='center'
                  >
                    Documentation/ Data Usage
                  </Button>
                </Link>
              </Box>

              <Box
                width={['100%']}
                display='flex'
                gap={3}
                justifyContent='center'
                alignItems='center'
              >
                <Box
                  bg='#08101D'
                  borderRadius='30px'
                  border='1px solid rgba(242, 243, 245, 0.14)'
                  display='flex'
                  flex='1'
                  flexDirection='column'
                  justifyContent='space-between'
                  alignItems='flex-start'
                >
                  <Box
                    padding={['12px 24px']}
                    width={['100%']}
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    background='#F2F3F524'
                    borderTopRadius='30px'
                  >
                    <Text
                      fontFamily='Metal'
                      textTransform='uppercase'
                      fontSize={['14px']}
                    >
                      Current plan
                    </Text>
                  </Box>

                  <Box
                    p={['30px']}
                    width='100%'
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    alignItems='flex-start'
                    gap={4}
                  >
                    <Box display='flex' flexDirection='column'>
                      <Text
                        fontFamily='Metal'
                        fontSize={['35px']}
                        lineHeight={['35px']}
                      >
                        {currentPlan?.name?.split('_')[0]}
                      </Text>
                      <Box display='flex' alignItems='flex-end' gap={2}>
                        <Text
                          fontFamily='Metal'
                          textTransform='capitalize'
                          fontSize={['25px']}
                          lineHeight={['25px']}
                          color='white'
                        >
                          {`$${currentPlan.standingCharge}.00`}
                        </Text>
                        <Text
                          fontFamily='Metal'
                          textTransform='lowercase'
                          fontSize={['15px']}
                          color='white'
                        >
                          / month
                        </Text>
                      </Box>
                    </Box>

                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      gap={1}
                      width='100%'
                    >
                      <Box display='flex' alignItems='center' gap={2}>
                        <Text
                          fontSize={['12px']}
                          fontWeight='900'
                          fontFamily='Normal'
                        >
                          Data Storage:
                        </Text>
                        <Text
                          fontSize={['12px']}
                          color={
                            storagePercentageUsed > 100 && !isEnterpriseStorage
                              ? 'red.500'
                              : 'green.500'
                          }
                          fontWeight='900'
                          fontFamily='Bold'
                        >
                          {`${
                            storageUsed > 0
                              ? storageUsed.toFixed(2)
                              : storageUsed
                          } GB`}
                        </Text>
                        {!isEnterpriseStorage && (
                          <Text
                            fontSize={['12px']}
                            color='#58677E'
                            fontFamily='Normal'
                          >
                            {`(${storagePercentageUsedFixed}%)`}
                          </Text>
                        )}
                      </Box>
                      <Progress
                        borderRadius='30px'
                        size='sm'
                        value={storagePercentageUsed || 0}
                        colorScheme={
                          storagePercentageUsed > 100 && !isEnterpriseStorage
                            ? 'red'
                            : 'green'
                        }
                        bg='#58677E'
                      />
                      <Box display='flex' alignItems='center' gap={2}>
                        <Text
                          fontSize={['12px']}
                          color='#58677E'
                          fontFamily='Normal'
                        >
                          {isEnterpriseStorage
                            ? `of Unlimited`
                            : `of ${storageLimit}GB + ${additionalStorageUsed}GB Add-on`}
                        </Text>
                        {storageUnitPrice > 0 && (
                          <Text
                            fontSize={['12px']}
                            color='#58677E'
                            fontFamily='Normal'
                          >
                            {`($${storageUnitPrice}/GB)`}
                          </Text>
                        )}
                      </Box>
                    </Box>

                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      gap={1}
                      width='100%'
                    >
                      <Box display='flex' alignItems='center' gap={2}>
                        <Text
                          fontSize={['12px']}
                          fontWeight='900'
                          fontFamily='Normal'
                        >
                          Bandwidth:
                        </Text>
                        <Text
                          fontSize={['12px']}
                          color={
                            bandwidthPercentageUsed > 100 &&
                            !isEnterpriseBandwidth
                              ? 'red.500'
                              : 'green.500'
                          }
                          fontWeight='900'
                          fontFamily='Bold'
                        >
                          {`${
                            bandwidthUsed > 0
                              ? bandwidthUsed.toFixed(2)
                              : bandwidthUsed
                          } GB`}
                        </Text>
                        {!isEnterpriseBandwidth && (
                          <Text
                            fontSize={['12px']}
                            color='#58677E'
                            fontFamily='Normal'
                          >
                            {`(${bandwidthPercentageUsedFixed}%)`}
                          </Text>
                        )}
                      </Box>
                      <Progress
                        borderRadius='30px'
                        size='sm'
                        value={bandwidthPercentageUsed || 0}
                        colorScheme={
                          bandwidthPercentageUsed > 100 &&
                          !isEnterpriseBandwidth
                            ? 'red'
                            : 'green'
                        }
                        bg='#58677E'
                      />
                      <Box display='flex' alignItems='center' gap={2}>
                        <Text
                          fontSize={['12px']}
                          color='#58677E'
                          fontFamily='Normal'
                        >
                          {isEnterpriseBandwidth
                            ? `of Unlimited`
                            : `of ${bandwidthLimit}GB + ${additionalBandwidthUsed}GB Add-on`}
                        </Text>
                        {bandwidthUnitPrice > 0 && (
                          <Text
                            fontSize={['12px']}
                            color='#58677E'
                            fontFamily='Normal'
                          >
                            {`($${bandwidthUnitPrice}/GB)`}
                          </Text>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box
                  borderRadius='30px'
                  bg='#08101D'
                  display='flex'
                  flex='1'
                  flexDirection='column'
                  justifyContent='space-between'
                  alignItems='flex-start'
                  border='1px solid #D3BB8A'
                >
                  <Box
                    padding={['12px 24px']}
                    width={['100%']}
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    background='linear-gradient(255deg, #D3BB8A -1.47%, rgba(211, 187, 138, 0.00) 100%)'
                    borderBottom='1px solid #D3BB8A'
                    borderTopRadius='26px'
                  >
                    <Text
                      fontFamily='Metal'
                      textTransform='uppercase'
                      fontSize={['14px']}
                    >
                      New Plan
                    </Text>
                  </Box>

                  <Box
                    p={['30px']}
                    width='100%'
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    alignItems='flex-start'
                    gap={5}
                  >
                    <Box display='flex' flexDirection='column'>
                      <Text
                        fontFamily='Metal'
                        fontSize={['35px']}
                        lineHeight={['35px']}
                      >
                        Free
                      </Text>
                      <Box display='flex' alignItems='flex-end' gap={2}>
                        <Text
                          fontFamily='Metal'
                          textTransform='capitalize'
                          fontSize={['25px']}
                          lineHeight={['25px']}
                          color='white'
                        >
                          $0
                        </Text>
                        <Text
                          fontFamily='Metal'
                          textTransform='lowercase'
                          fontSize={['15px']}
                          color='white'
                        >
                          / month
                        </Text>
                      </Box>
                    </Box>

                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      gap={1}
                      width='100%'
                    >
                      <Box display='flex' alignItems='center' gap={2}>
                        <Text
                          fontSize={['12px']}
                          fontWeight='900'
                          fontFamily='Normal'
                        >
                          Data Storage:
                        </Text>
                        <Text
                          fontSize={['12px']}
                          color={
                            freeStoragePercentageUsed > 100
                              ? 'red.500'
                              : 'green.500'
                          }
                          fontWeight='900'
                          fontFamily='Bold'
                        >
                          {`${
                            storageUsed > 0
                              ? storageUsed.toFixed(2)
                              : storageUsed
                          } GB`}
                        </Text>
                        <Text
                          fontSize={['12px']}
                          color='#58677E'
                          fontFamily='Normal'
                        >
                          {`(${freeStoragePercentageUsedFixed}%)`}
                        </Text>
                      </Box>
                      <Progress
                        borderRadius='30px'
                        size='sm'
                        value={freeStoragePercentageUsed || 0}
                        colorScheme={
                          freeStoragePercentageUsed > 100 ? 'red' : 'green'
                        }
                        bg='#58677E'
                      />
                      <Box display='flex' alignItems='center' gap={2}>
                        <Text
                          fontSize={['12px']}
                          color='#58677E'
                          fontFamily='Normal'
                        >
                          of 500MB
                        </Text>
                      </Box>
                    </Box>

                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      gap={1}
                      width='100%'
                    >
                      <Box display='flex' alignItems='center' gap={2}>
                        <Text
                          fontSize={['12px']}
                          fontWeight='900'
                          fontFamily='Normal'
                        >
                          Bandwidth:
                        </Text>
                        <Text
                          fontSize={['12px']}
                          color={
                            freeBandwidthPercentageUsed > 100
                              ? 'red.500'
                              : 'green.500'
                          }
                          fontWeight='900'
                          fontFamily='Bold'
                        >
                          {`${
                            bandwidthUsed > 0
                              ? bandwidthUsed.toFixed(2)
                              : bandwidthUsed
                          } GB`}
                        </Text>
                        <Text
                          fontSize={['12px']}
                          color='#58677E'
                          fontFamily='Normal'
                        >
                          {`(${freeBandwidthPercentageUsedFixed}%)`}
                        </Text>
                      </Box>
                      <Progress
                        borderRadius='30px'
                        size='sm'
                        value={freeBandwidthPercentageUsed || 0}
                        colorScheme={
                          freeBandwidthPercentageUsed > 100 ? 'red' : 'green'
                        }
                        bg='#58677E'
                      />
                      <Box display='flex' alignItems='center' gap={2}>
                        <Text
                          fontSize={['12px']}
                          color='#58677E'
                          fontFamily='Normal'
                        >
                          of 5GB
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                justifyContent='space-between'
                alignItems='center'
                gap={2}
              >
                <Button
                  textTransform='uppercase'
                  width={['80%', '80%', '300px']}
                  variant='outline'
                  color='#08101D'
                  background='white'
                  fontFamily='Metal'
                  fontSize={['14px', '14px', '14px']}
                  borderRadius='30px'
                  border='1px solid #D6DBE4'
                  _hover={{
                    bg: '#D3BB8A',
                    borderColor: '#D3BB8A',
                  }}
                  onClick={() => {
                    setCancelConfirmed(true);
                  }}
                >
                  SWITCH TO FREE PLAN
                </Button>

                <Button
                  fontSize={['11px']}
                  variant='link'
                  fontFamily='Normal'
                  textAlign='center'
                  mt={['15px']}
                  onClick={close}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Collapse>

          <Collapse
            animateOpacity
            in={cancelConfirmed && !formSubmitted}
            style={{ width: '100%' }}
          >
            <Box
              height='auto'
              display='flex'
              flexDirection='column'
              justifyContent='space-between'
              alignItems='center'
              gap={5}
            >
              <Box>
                <Text
                  textAlign='center'
                  fontSize={['15px']}
                  color='rgba(8, 16, 29, 1)'
                  fontFamily='Normal'
                >
                  Help us to understand the Reason
                </Text>
                <Text
                  textAlign='center'
                  fontSize={['12px']}
                  color='rgba(8, 16, 29, 1)'
                  fontFamily='Normal'
                >
                  Before we switch your account
                </Text>
              </Box>

              <FormWrapper onSubmit={handleSubmit(onSubmit)}>
                <Box
                  display='flex'
                  gap={5}
                  width='100%'
                  flexWrap='wrap'
                  flexDirection='column'
                  alignItems='center'
                  justifyContent='space-between'
                >
                  <FormControl
                    isInvalid={errors.reason ? true : false}
                    isRequired
                  >
                    <FormLabel
                      color='rgba(127, 139, 158, 1)'
                      fontFamily='Normal'
                      fontSize='12px'
                      margin='0px'
                    >
                      Reason for cancelling:
                    </FormLabel>
                    <Input
                      id='reason'
                      placeholder='enter reason'
                      color='#58677E'
                      borderBottom='1px solid #D6DBE4'
                      variant='flushed'
                      fontFamily='Normal'
                      fontSize={['14px', '14px', '15px']}
                      size='md'
                      _placeholder={{ color: '#7F8B9E' }}
                      errorBorderColor='#EA1A4C'
                      {...register('reason', {
                        required: 'This is required',
                        minLength: {
                          value: 4,
                          message: 'Minimum length should be 4',
                        },
                      })}
                    />
                    <FormErrorMessage
                      color='#EA1A4C'
                      mt={['4px']}
                      fontSize='13px'
                      fontFamily='Normal'
                    >
                      {errors.reason && errors.reason.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.improve ? true : false}>
                    <FormLabel
                      color='rgba(127, 139, 158, 1)'
                      fontFamily='Normal'
                      fontSize='12px'
                    >
                      What should we improve
                    </FormLabel>
                    <Textarea
                      id='improve'
                      placeholder='enter improve'
                      minHeight='100px'
                      maxHeight='200px'
                      color='#58677E'
                      border='1px solid rgba(211, 187, 138, 1) !important'
                      _focusVisible={{
                        border: 'none',
                      }}
                      borderRadius='16px'
                      variant='outline'
                      fontFamily='Normal'
                      fontSize={['14px', '14px', '15px']}
                      errorBorderColor='#EA1A4C'
                      _placeholder={{ color: '#7F8B9E' }}
                      {...register('improve', {
                        required: false,
                      })}
                    />

                    <FormErrorMessage
                      color='#EA1A4C'
                      mt={['4px']}
                      fontSize='13px'
                      fontFamily='Normal'
                    >
                      {errors.improve && errors.improve.message}
                    </FormErrorMessage>
                  </FormControl>
                  <Button
                    textTransform='uppercase'
                    width={['80%', '80%', '300px']}
                    variant='outline'
                    color='#08101D'
                    background='white'
                    fontFamily='Metal'
                    fontSize={['14px', '14px', '14px']}
                    borderRadius='30px'
                    border='1px solid #D6DBE4'
                    _hover={{
                      bg: '#D3BB8A',
                      borderColor: '#D3BB8A',
                    }}
                    isLoading={isSubmitting}
                    type='submit'
                  >
                    Complete Cancellation
                  </Button>
                </Box>
              </FormWrapper>

              <Box
                display='flex'
                flexDirection='column'
                justifyContent='space-between'
                alignItems='center'
              >
                <Button
                  fontSize={['11px']}
                  variant='link'
                  fontFamily='Normal'
                  textAlign='center'
                  mt={['15px']}
                  onClick={() => setCancelConfirmed(false)}
                >
                  Go Back
                </Button>
              </Box>
            </Box>
          </Collapse>

          <Collapse in={formSubmitted} animateOpacity>
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              gap={5}
            >
              <Text
                fontFamily='Bold'
                fontSize='15px'
                color='rgba(6, 255, 71, 1)'
                textAlign='center'
              >
                Cancelled
              </Text>

              <Box>
                <Text
                  fontFamily='Normal'
                  fontSize='15px'
                  color='rgba(8, 16, 29, 1)'
                  textAlign='center'
                >
                  Our team will will be shortly cancelling your plan.
                </Text>
                <Text
                  fontFamily='Normal'
                  fontSize='15px'
                  color='rgba(8, 16, 29, 1)'
                  textAlign='center'
                >
                  The last day of your Plan Subscription would be:
                </Text>
                <Text
                  fontFamily='Medium'
                  fontSize='15px'
                  color='rgba(8, 16, 29, 1)'
                  textAlign='center'
                >
                  {getNextBillDate(
                    currentPlan.startDate,
                    currentPlan?.name?.split('_')[1]
                  )}
                </Text>
              </Box>
            </Box>
          </Collapse>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default CancelContactModal;
