import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import FormWrapper from '../common/FormWrapper';
import { useForm, SubmitHandler } from 'react-hook-form';
import configureAxios from '../../axiosClient';
import { usePlanStore } from '../../store/plan';
import { useUserStore } from '../../store/user';
import { Organisation } from '../../interfaces';

type CreateOrganisationFormValues = {
  organisationName: string;
  email?: string;
  role?: string;
};

interface CreateOrganisationFormProps {
  flow: 'login' | 'postLogin';
  theme?: 'white' | 'black';
  close: () => void;
}

const axiosClient = configureAxios();

function CreateOrganisationForm({
  theme = 'black',
  close,
}: CreateOrganisationFormProps) {
  const whiteTheme = theme === 'white';

  const { setShowPlans } = usePlanStore();
  const {
    setUserCreateNewOrganisationView,
    setInactiveOrganisation,
    getOrganizations,
  } = useUserStore();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<CreateOrganisationFormValues>({
    defaultValues: {
      role: 'Member',
    },
  });

  const toast = useToast({
    position: 'top',
    variant: 'top-accent',
  });

  const [createOrganisationLoading, setCreateOrganisationLoading] =
    useState(false);

  async function sendRequest(organisationName: string) {
    try {
      const response = await axiosClient.post(`org/create`, {
        organisation_name: organisationName,
      });
      const isStatusOk = response.data.status === 'OK';
      return { success: isStatusOk, response };
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error(`Error sending request:`, error.message);
        return { success: false, error };
      } else {
        console.error(`Unknown error sending request`);
        return { success: false, error: 'Unknown error' };
      }
    }
  }

  const onSubmit: SubmitHandler<CreateOrganisationFormValues> = async (
    data
  ) => {
    setCreateOrganisationLoading(true);

    if (!data.organisationName) {
      toast({
        title: 'Organisation name is required',
        status: 'error',
      });
      setCreateOrganisationLoading(false);
      return;
    }

    const result = await sendRequest(data.organisationName);

    if (result.success && result.response && result.response.data) {
      const newOrg: Organisation = {
        organizationId: result.response.data.data.id,
        alias: data.organisationName,
        roles: data.role || 'member',
      };
      setInactiveOrganisation(newOrg);
      getOrganizations();
      setUserCreateNewOrganisationView(true);
      setShowPlans(true);
      close();
    } else {
      toast({
        title: 'Failed to create organization',
        status: 'error',
      });
    }

    setCreateOrganisationLoading(false);
  };

  return (
    <FormWrapper onSubmit={handleSubmit(onSubmit)}>
      {createOrganisationLoading ? (
        <Box
          w={['100%']}
          height={['100px']}
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <Spinner size='md' color={whiteTheme ? '#08101D' : '#7F8B9E'} />
        </Box>
      ) : (
        <Box
          w={['100%']}
          height={['auto']}
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          alignItems='center'
          gap={5}
        >
          <FormControl isInvalid={errors.organisationName ? true : false}>
            <Input
              color={'#7F8B9E'}
              id='organisationName'
              type='text'
              placeholder='Your Company / Organisation Name'
              borderBottom='1px solid #D6DBE4'
              variant='flushed'
              fontFamily='Normal'
              fontSize={['14px', '14px', '14px']}
              size='md'
              _placeholder={{ color: '#7F8B9E' }}
              errorBorderColor='#EA1A4C'
              {...register('organisationName', {
                required: 'Organisation name is required',
              })}
            />
            <FormErrorMessage
              color='#EA1A4C'
              mt={['4px']}
              fontSize='13px'
              fontFamily='Normal'
            >
              {errors.organisationName && errors.organisationName.message}
            </FormErrorMessage>
          </FormControl>

          <Button
            textTransform='uppercase'
            width={['80%', '80%', '300px']}
            variant='outline'
            color={whiteTheme ? '#08101D' : '#7F8B9E'}
            background={whiteTheme ? 'white' : '#1D2531'}
            border={
              whiteTheme
                ? '1px solid #D6DBE4'
                : '1px solid rgba(242, 243, 245, 0.14)'
            }
            fontFamily='Metal'
            fontSize={['14px', '14px', '15px']}
            borderRadius='30px'
            _hover={{
              bg: 'white',
              color: '#08101D',
            }}
            isLoading={isSubmitting}
            type='submit'
          >
            Next
          </Button>
        </Box>
      )}
    </FormWrapper>
  );
}

export default CreateOrganisationForm;
