import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
  useToast,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { CloseIcon } from '@chakra-ui/icons';
import { Dollar } from '../../Icons/Dollar';
import PublishExperienceForm from '../forms/PublishExperienceForm';
import { Open } from '../../Icons/Open';
import { Copy } from '../../Icons/Copy';
import { useUserStore } from '../../store/user';
import configureAxios from '../../axiosClient';
import {
  dispatchCommunityExperienceToAnalytics,
  dispatchExperienceToAnalytics,
} from '../../analytics';
import { useExperiencesStore } from '../../store/experiences';
import { Link, useNavigate } from 'react-router-dom';
import { usePlanStore } from '../../store/plan';

interface PublishExperienceModalProps {
  publishExperienceModalActive: boolean;
  setPublishExperienceModalActive: (state: boolean) => void;
  shareCommunityExperience: (experienceId: string) => void;
}

export const PublishExperienceModal = ({
  publishExperienceModalActive,
  setPublishExperienceModalActive,
}: PublishExperienceModalProps) => {
  const toast = useToast({
    position: 'top',
    variant: 'top-accent',
  });

  const { selectedExperience, setSelectedExperience } = useExperiencesStore();
  const { currentPlan } = usePlanStore();
  const { organisation, user } = useUserStore();
  const navigate = useNavigate();

  const axiosClient = configureAxios();

  const handleCopy = async (text: string) => {
    try {
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(text);
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand('copy');
        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err);
        }
        document.body.removeChild(textArea);
      }
      if (!toast.isActive('expereinceLink')) {
        toast({
          id: 'expereinceLink',
          title: `Link Copied`,
          status: 'success',
        });
      }
    } catch (err) {
      console.error('Async: Could not copy text', err);
    }
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isReadMore, setIsReadMore] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const [selectedExperienceName, setSelectedExperienceName] = useState('');
  const [publishedLink, setPublishedLink] = useState('');
  const [stepper, setStepper] = useState(1);
  const [loadingUsage, setLoadingUsage] = useState(true);
  const [bandwidthUsed, setBandwidthUsed] = useState(0);
  const [limitUsed, setLimitUsed] = useState(0);

  const handleToggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const publishExperience = () => {
    const userOrgId = organisation.organizationId;
    setIsSubmitting(true);
    if (agreement) {
      setStepper(2);
    }
    axiosClient
      .post(`/experience/publish/${userOrgId}/${selectedExperience.id}`, {
        is_community_share: agreement,
        experience_name: agreement ? selectedExperienceName : null,
      })
      .then((res) => {
        const isStatusOk = res.data.status === 'OK';
        if (isStatusOk) {
          dispatchExperienceToAnalytics('publish_experience', {
            experience_id: selectedExperience.id,
            organisation_id: userOrgId,
            organisation_user_id: user.id,
            experience_name: selectedExperienceName,
            experience_draft_url: selectedExperience.preview_url,
            experience_published_url: res.data?.data?.published_url,
          });
          if (agreement) {
            dispatchCommunityExperienceToAnalytics(
              'publish_community_experience',
              {
                experience_id: selectedExperience.id,
                organisation_id: userOrgId,
                organisation_user_id: user.id,
                experience_name: selectedExperienceName,
                experience_draft_url: selectedExperience.preview_url,
                experience_published_url: res.data?.data?.published_url,
              }
            );
          }
          if (res?.data?.data?.error?.length > 0) {
            toast({
              title: `A problem occurred publishing the experience.`,
              status: 'error',
            });
          } else {
            setSelectedExperience({
              ...selectedExperience,
              published_url: res.data?.data?.published_url,
            });
            toast({
              title: `Your experience was published successfully.`,
              status: 'success',
            });
            if (agreement) {
              setPublishedLink(res.data?.data?.published_url);
              setStepper(3);
              setSelectedExperienceName('');
            } else {
              setPublishExperienceModalActive(false);
            }
          }
        } else {
          if (res.data.data === 'Insufficient Permissions.') {
            toast({
              title: `Insufficient Permissions`,
              status: 'info',
            });
          } else {
            toast({
              title: `A problem occurred publishing the experience.`,
              status: 'error',
            });
          }
        }
        setIsSubmitting(false);
      })
      .catch((err: unknown) => {
        console.log(err);
        setIsSubmitting(false);
        toast({
          title: `A problem occurred publishing the experience.`,
          status: 'error',
        });
      });
  };

  useEffect(() => {
    const userOrgId = organisation.organizationId;
    if (organisation.organizationId.length > 0)
      axiosClient
        .get(`org/${userOrgId}/usage/read`)
        .then((res) => {
          const isStatusOk = res.data.status === 'OK';
          if (res.data.status === 'LOGOUT') {
            console.log('Logged Out');
          } else if (isStatusOk) {
            setBandwidthUsed(res.data.data.storage.used);
            setLimitUsed(res.data.data.storage.limit);
            setLoadingUsage(false);
          } else {
            toast({
              title: `There was an error fetching usage details`,
              status: 'error',
            });
          }
        })
        .catch((err: unknown) => {
          console.log(err);
        });

    if (selectedExperience && selectedExperience.name) {
      setSelectedExperienceName(selectedExperience.name);
    }
  }, [selectedExperience]);
  return (
    <Modal
      isOpen={publishExperienceModalActive}
      onClose={() => {
        setPublishExperienceModalActive(false);
      }}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent
        minW={['500px']}
        height={['auto']}
        padding={['20px']}
        maxHeight='80vh'
        overflow='auto'
        borderRadius='16px'
        boxShadow='0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
      >
        <CloseIcon
          position='absolute'
          boxSize={[4]}
          cursor='pointer'
          color='#D6DBE4'
          _hover={{ color: 'black' }}
          transition='0.2s all'
          right={['20px']}
          top={['20px']}
          onClick={() => {
            setStepper(1);
            setAgreement(false);
            setPublishExperienceModalActive(false);
          }}
        />
        <ModalBody
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          alignItems='center'
          gap={5}
        >
          {stepper === 1 && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              transition={{ duration: 0.5 }}
            >
              <Text
                color='#08101D'
                fontFamily='Metal'
                fontSize={['35px']}
                lineHeight={['35px']}
                textAlign='center'
                textTransform='uppercase'
              >
                Publish
              </Text>
              <Box
                display='flex'
                flexDir='column'
                width={'415px'}
                justifyContent='center'
                alignItems='center'
                gap={3}
                mt='15px'
              >
                <Text fontFamily='Normal' fontSize='12px'>
                  When you publish your experience, it becomes accessible to the
                  world, generating a unique URL for sharing.
                </Text>
                <Link to='https://docs.emperiavr.com/dev-docs'>
                  <Box
                    display='flex'
                    alignItems='center'
                    cursor='pointer'
                    gap={2}
                  >
                    <Text
                      fontFamily='Normal'
                      fontSize='12px'
                      _hover={{ textDecoration: 'underline' }}
                    >
                      Documentation/ Publish An Experience
                    </Text>
                    <Open boxSize={5} />
                  </Box>
                </Link>
                <Box
                  display='flex'
                  flexDir='column'
                  border='1px solid #D6DBE4'
                  borderRadius='16px'
                  padding='16px'
                  alignItems='start'
                  sx={{
                    backdropFilter: 'blur(40px)',
                    boxShadow: '0px 4px 4px 0px #00000040',
                  }}
                  gap={2}
                >
                  <Box display='flex' alignItems='center' gap={3}>
                    <Box
                      display='flex'
                      border='1px solid #D6DBE4'
                      borderRadius='50%'
                      padding={['10px']}
                      justifyContent='center'
                    >
                      <Dollar stroke='red' boxSize={5} />
                    </Box>
                    <Text
                      fontFamily='Normal'
                      fontSize='14px'
                      fontWeight='600'
                      color='#EA1A4C'
                    >
                      Additional Fees May Accur
                    </Text>
                  </Box>
                  <Box display='flex' flexDir='column' gap={3}>
                    <Text fontFamily='Normal' fontSize='11px'>
                      {"Here's"} your current bandwidth allowed for virtual
                      experiences.
                    </Text>

                    <Text fontFamily='Normal' fontSize='11px'>
                      {isReadMore
                        ? `Here’s your current bandwidth allowed for virtual experiences. Please consider factors like high-res media, numerous products, or high user traffic that may contribute to increased usage. Exceeding this limit could result in additional fees. An email with details about additional fees will be sent to the billing contact email address. You'll need to make a payment for the excess data. If you don't settle this within 30 days of receiving the email will result in the deletion of any data exceeding the limit.`
                        : `Here’s your current bandwidth allowed for virtual experiences. Exceeding this limit could result in additional fees.`}
                      {!isReadMore && (
                        <Button
                          variant='link'
                          size='sm'
                          onClick={handleToggleReadMore}
                          ml={1}
                          color='blue.500'
                        >
                          Read More
                        </Button>
                      )}
                    </Text>

                    {isReadMore && (
                      <Button
                        variant='link'
                        size='sm'
                        onClick={handleToggleReadMore}
                        mt={2}
                        color='blue.500'
                      >
                        Show Less
                      </Button>
                    )}
                  </Box>
                  {loadingUsage ? (
                    <Box
                      width='100%'
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                      padding='16px'
                      margin='auto'
                    >
                      <Spinner color='red' />
                    </Box>
                  ) : (
                    <Box
                      display='flex'
                      border='1px solid #D6DBE4'
                      borderRadius='16px'
                      padding='16px'
                      alignItems='center'
                      justifyContent='space-between'
                      width={['100%']}
                    >
                      <Text
                        fontFamily='Normal'
                        fontSize='14px'
                        color='#EA1A4C'
                        fontWeight='600'
                      >
                        Bandwidth Used: {bandwidthUsed} GB
                      </Text>
                      <Text
                        fontFamily='Normal'
                        fontSize='11px'
                        color='#EA1A4C'
                        fontWeight='600'
                      >
                        {currentPlan?.name === 'Free'
                          ? 'Free'
                          : currentPlan?.name.split('_')[0]}{' '}
                        Plan limit: {limitUsed} MB
                      </Text>
                    </Box>
                  )}

                  <Link to='https://docs.emperiavr.com/user-guide/pricing-billing'>
                    <Box
                      display='flex'
                      alignItems='center'
                      cursor='pointer'
                      gap={2}
                    >
                      <Text
                        fontFamily='Normal'
                        fontSize='11px'
                        color='#08101D'
                        cursor='pointer'
                        _hover={{ textDecoration: 'underline' }}
                      >
                        Documentation/ Addition Fees
                      </Text>
                      <Open boxSize={5} />
                    </Box>
                  </Link>
                </Box>

                {agreement && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    transition={{ duration: 0.5 }}
                  >
                    <PublishExperienceForm
                      publishExperienceModalActive={
                        publishExperienceModalActive
                      }
                      selectedExperienceName={selectedExperienceName}
                      setSelectedExperienceName={setSelectedExperienceName}
                    />
                  </motion.div>
                )}

                <Box
                  borderRadius='16px'
                  mt='40px'
                  padding={['32px 16px 48px 16px']}
                  bgGradient='linear(to-r, rgba(211, 187, 138, 0), rgba(211, 187, 138, 1))'
                  height='110px'
                  width='415px'
                >
                  <Checkbox
                    size='lg'
                    border='black'
                    onChange={() => setAgreement(!agreement)}
                  >
                    <Text fontFamily='Normal' fontSize='12px'>
                      Publish & Share experiences with the community{' '}
                      <Text as='span' fontWeight='bold' fontSize='12px'>
                        without impacting your bandwidth or storage.
                      </Text>{' '}
                      I agree with the community{' '}
                      <Link to='https://docs.emperiavr.com/user-guide'>
                        <Text
                          as='span'
                          textDecoration='underline'
                          cursor='pointer'
                        >
                          guidelines and terms
                        </Text>
                      </Link>
                      .
                    </Text>
                  </Checkbox>
                </Box>
                <Button
                  textTransform='uppercase'
                  width={['80%', '80%', '300px']}
                  variant='outline'
                  color='#08101D'
                  background='white'
                  border='1px solid #D6DBE4'
                  fontFamily='Metal'
                  fontSize={['14px', '14px', '15px']}
                  borderRadius='30px'
                  _hover={{
                    bg: '#D3BB8A',
                  }}
                  isLoading={isSubmitting || loadingUsage}
                  onClick={() => {
                    publishExperience();
                  }}
                >
                  {agreement ? 'Publish & Share' : 'Publish'}
                </Button>
              </Box>
            </motion.div>
          )}
          {stepper === 2 && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              transition={{ duration: 0.5 }}
            >
              <Box
                display='flex'
                flexDir='column'
                justifyContent='center'
                alignItems='center'
                textAlign='center'
                gap={5}
              >
                <Text
                  color='#08101D'
                  fontFamily='Metal'
                  fontSize={['35px']}
                  lineHeight={['35px']}
                  textAlign='center'
                  textTransform='uppercase'
                >
                  Publish & Share
                </Text>
                <Spinner />

                <Box
                  display='flex'
                  flexDir='column'
                  textAlign='center'
                  alignItems='center'
                  justifyContent='center'
                >
                  <Text fontFamily='Normal' color='#08101D' fontSize='14px'>
                    Add a custom domain?
                  </Text>
                  <Link to='https://docs.emperiavr.com/dev-docs/distributing-the-experience-as-a-standalone-webpage'>
                    <Box display='flex' alignItems='center' cursor='pointer'>
                      <Text
                        fontFamily='Normal'
                        color='#08101D'
                        fontSize='11px'
                        _hover={{ textDecoration: 'underline' }}
                      >
                        Documentation
                      </Text>
                      <Open boxSize={5} />
                    </Box>
                  </Link>
                </Box>
                <Box
                  display='flex'
                  flexDir='column'
                  textAlign='center'
                  alignItems='center'
                  justifyContent='center'
                >
                  <Text fontFamily='Normal' color='#08101D' fontSize='14px'>
                    Embed on your website?
                  </Text>
                  <Link to='https://docs.emperiavr.com/dev-docs/embedding-the-experience-to-a-website'>
                    <Box display='flex' alignItems='center' cursor='pointer'>
                      <Text
                        fontFamily='Normal'
                        color='#08101D'
                        fontSize='11px'
                        _hover={{ textDecoration: 'underline' }}
                      >
                        Documentation
                      </Text>
                      <Open boxSize={5} />
                    </Box>
                  </Link>
                </Box>
              </Box>
            </motion.div>
          )}
          {stepper === 3 && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              transition={{ duration: 0.5 }}
            >
              <Box
                display='flex'
                flexDir='column'
                justifyContent='center'
                alignItems='center'
                textAlign='center'
                gap={5}
              >
                <Text
                  color='#08101D'
                  fontFamily='Metal'
                  fontSize={['35px']}
                  lineHeight={['35px']}
                  textAlign='center'
                  textTransform='uppercase'
                >
                  Thank You For Sharing
                </Text>
                <Box
                  display='flex'
                  flexDir='column'
                  textAlign='center'
                  alignItems='center'
                  justifyContent='center'
                  gap={4}
                >
                  <Text fontFamily='Normal' fontSize='11px' color='#08101D'>
                    Your experience will be live in the Community Gallery soon,
                    after we complete our review.
                  </Text>
                  <Text fontFamily='Normal' fontSize='11px' color='#08101D'>
                    Your link:
                  </Text>
                  <Box
                    display='flex'
                    border='1px solid #D6DBE4'
                    borderRadius='30px'
                    padding={['12px 24px']}
                    gap={3}
                    cursor='pointer'
                    onClick={() => handleCopy(publishedLink)}
                  >
                    <Text
                      fontFamily='Normal'
                      fontSize='13px'
                      fontWeight='500'
                      color='#05d93c'
                      maxWidth='236px'
                      display='block'
                      textOverflow='ellipsis'
                      whiteSpace='nowrap'
                      overflow='hidden'
                      title={publishedLink}
                    >
                      {publishedLink}
                    </Text>
                    <Copy
                      stroke='#08101D'
                      boxSize={5}
                      onClick={() => handleCopy(publishedLink)}
                    />
                  </Box>
                </Box>

                <Box
                  display='flex'
                  flexDir='column'
                  textAlign='center'
                  alignItems='center'
                  justifyContent='center'
                >
                  <Text fontFamily='Normal' color='#08101D' fontSize='14px'>
                    Add a custom domain?
                  </Text>
                  <Link to='https://docs.emperiavr.com/dev-docs/distributing-the-experience-as-a-standalone-webpage'>
                    <Box display='flex' alignItems='center'>
                      <Text
                        fontFamily='Normal'
                        color='#08101D'
                        fontSize='11px'
                        _hover={{ textDecoration: 'underline' }}
                      >
                        Documentation
                      </Text>
                      <Open boxSize={5} />
                    </Box>
                  </Link>
                </Box>
                <Box
                  display='flex'
                  flexDir='column'
                  textAlign='center'
                  alignItems='center'
                  justifyContent='center'
                >
                  <Text fontFamily='Normal' color='#08101D' fontSize='14px'>
                    Embed on your website?
                  </Text>
                  <Link to='https://docs.emperiavr.com/dev-docs/embedding-the-experience-to-a-website '>
                    <Box display='flex' alignItems='center'>
                      <Text
                        fontFamily='Normal'
                        color='#08101D'
                        fontSize='11px'
                        _hover={{ textDecoration: 'underline' }}
                      >
                        Documentation
                      </Text>
                      <Open boxSize={5} />
                    </Box>
                  </Link>
                </Box>
                <Button
                  textTransform='uppercase'
                  width={['80%', '80%', '300px']}
                  variant='outline'
                  color='#08101D'
                  background='white'
                  border='1px solid #D6DBE4'
                  fontFamily='Metal'
                  fontSize={['14px', '14px', '15px']}
                  borderRadius='30px'
                  _hover={{
                    bg: '#D3BB8A',
                  }}
                  onClick={() => {
                    setPublishExperienceModalActive(false);
                    setStepper(1);
                    setAgreement(false);
                    navigate(
                      `/community/community-profile?id=${organisation.organizationId}&org=${organisation.alias}`
                    );
                  }}
                >
                  Preview Community Profile
                </Button>
              </Box>
            </motion.div>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
