import { Box, Button, Text } from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Tick } from '../../Icons/Tick';
import React from 'react';

interface AccountStatusTrackerProps {
  setView: (view: string) => void;
}

function AccountStatusTracker({ setView }: AccountStatusTrackerProps) {
  return (
    <Box display='flex' justifyContent='center' alignItems='center' gap={8}>
      <Button
        leftIcon={<ChevronLeftIcon boxSize={[4]} color='currentColor' />}
        color='white'
        fontSize='12px'
        variant='link'
        fontFamily='Normal'
        onClick={() => setView('plans')}
      >
        Go Back
      </Button>

      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        gap={2}
        bg='#08101D'
        border='1px solid #F2F3F524'
        borderRadius='30px'
        padding='4px'
      >
        <Box
          display='flex'
          alignItems='center'
          gap={2}
          width='auto'
          textAlign='center'
          padding='12px 24px'
          color='#7F8B9E'
          borderRadius='30px'
          transition='0.2s'
        >
          <Text fontSize='14px' fontFamily='Metal' textTransform='uppercase'>
            Plan Selection
          </Text>
          <Tick color='currentColor' stroke='#7F8B9E' boxSize={[4]} />
        </Box>
        <Box
          display='flex'
          alignItems='center'
          gap={2}
          width='auto'
          textAlign='center'
          padding='12px 24px'
          color='color'
          bg='#1D2531'
          borderRadius='30px'
          transition='0.2s'
        >
          <Text fontSize='14px' fontFamily='Metal' textTransform='uppercase'>
            Payment
          </Text>
        </Box>
      </Box>
    </Box>
  );
}

export default AccountStatusTracker;
