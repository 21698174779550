import { Box, useToast } from '@chakra-ui/react';
import OrganisationCard from '../cards/OrganisationCard';
import CurrentPlanCard from './CurrentPlanCard';
import SuggestedPlanCard from './SuggestedPlanCard';
import React, { useEffect } from 'react';
import { usePlanStore } from '../../store/plan';
import configureAxios from '../../axiosClient';
import { useUserStore } from '../../store/user';
import { capitalize } from '../../utils/helper';

const axiosClient = configureAxios();

interface LeftInfoPanelProps {
  numberOfMembers: string;
}

function LeftInfoPanel({ numberOfMembers }: LeftInfoPanelProps) {
  const toast = useToast({
    position: 'top',
    variant: 'top-accent',
  });
  const { organisation } = useUserStore();
  const { setSuggestedPlan, setCurrentPlan } = usePlanStore();

  useEffect(() => {
    const userOrgId = organisation.organizationId;

    organisation.alias.length > 0 &&
      axiosClient
        .get(`org/${userOrgId}/plan/suggest`)
        .then((res) => {
          const isStatusOk = res.data.status === 'OK';
          if (isStatusOk) {
            const plan = res?.data?.data?.name?.split('_')[0];
            const interval = res?.data?.data?.name?.split('_')[1];
            setSuggestedPlan(capitalize(plan), capitalize(interval));
          } else {
            toast({
              title: `There was an error fetching submit status plan`,
              status: 'error',
            });
          }
        })
        .catch((err: unknown) => {
          console.log(err);
        });

    organisation.alias.length > 0 &&
      axiosClient
        .get(`org/${userOrgId}/plan/read`)
        .then((res) => {
          const isStatusOk = res.data.status === 'OK';
          if (isStatusOk) {
            setCurrentPlan(res.data.data);
          } else {
            toast({
              title: `There was an error fetching submit status plan`,
              status: 'error',
            });
          }
        })
        .catch((err: unknown) => {
          console.log(err);
        });
  }, [organisation.organizationId]);

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      gap={8}
      width={['300px']}
    >
      <OrganisationCard membersCount={numberOfMembers} />
      <CurrentPlanCard />
      <SuggestedPlanCard />
    </Box>
  );
}

export default LeftInfoPanel;
