import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const Instagram = (props: IconProps) => (
  <Icon
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M4.2026 0.333008H9.8026C11.9359 0.333008 13.6693 2.06634 13.6693 4.19967V9.79967C13.6693 10.8252 13.2619 11.8087 12.5368 12.5338C11.8116 13.259 10.8281 13.6663 9.8026 13.6663H4.2026C2.06927 13.6663 0.335938 11.933 0.335938 9.79967V4.19967C0.335938 3.17417 0.743317 2.19067 1.46846 1.46553C2.1936 0.740388 3.1771 0.333008 4.2026 0.333008ZM4.06927 1.66634C3.43275 1.66634 2.8223 1.9192 2.37221 2.36929C1.92213 2.81937 1.66927 3.42982 1.66927 4.06634V9.93301C1.66927 11.2597 2.7426 12.333 4.06927 12.333H9.93594C10.5725 12.333 11.1829 12.0802 11.633 11.6301C12.0831 11.18 12.3359 10.5695 12.3359 9.93301V4.06634C12.3359 2.73967 11.2626 1.66634 9.93594 1.66634H4.06927ZM10.5026 2.66634C10.7236 2.66634 10.9356 2.75414 11.0919 2.91042C11.2481 3.0667 11.3359 3.27866 11.3359 3.49967C11.3359 3.72069 11.2481 3.93265 11.0919 4.08893C10.9356 4.24521 10.7236 4.33301 10.5026 4.33301C10.2816 4.33301 10.0696 4.24521 9.91335 4.08893C9.75707 3.93265 9.66927 3.72069 9.66927 3.49967C9.66927 3.27866 9.75707 3.0667 9.91335 2.91042C10.0696 2.75414 10.2816 2.66634 10.5026 2.66634ZM7.0026 3.66634C7.88666 3.66634 8.73451 4.01753 9.35963 4.64265C9.98475 5.26777 10.3359 6.11562 10.3359 6.99967C10.3359 7.88373 9.98475 8.73158 9.35963 9.3567C8.73451 9.98182 7.88666 10.333 7.0026 10.333C6.11855 10.333 5.2707 9.98182 4.64558 9.3567C4.02046 8.73158 3.66927 7.88373 3.66927 6.99967C3.66927 6.11562 4.02046 5.26777 4.64558 4.64265C5.2707 4.01753 6.11855 3.66634 7.0026 3.66634ZM7.0026 4.99967C6.47217 4.99967 5.96346 5.21039 5.58839 5.58546C5.21332 5.96053 5.0026 6.46924 5.0026 6.99967C5.0026 7.53011 5.21332 8.03882 5.58839 8.41389C5.96346 8.78896 6.47217 8.99967 7.0026 8.99967C7.53304 8.99967 8.04175 8.78896 8.41682 8.41389C8.79189 8.03882 9.0026 7.53011 9.0026 6.99967C9.0026 6.46924 8.79189 5.96053 8.41682 5.58546C8.04175 5.21039 7.53304 4.99967 7.0026 4.99967Z'
      fill='#08101D'
    />
  </Icon>
);
