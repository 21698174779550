import React, { MutableRefObject, useEffect, useMemo, useRef } from 'react';
import FormWrapper from '../common/FormWrapper';
import {
  Box,
  Button,
  Collapse,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Progress,
  Select,
  Text,
  useToast,
} from '@chakra-ui/react';
import { UploadCircle } from '../../Icons/UploadCircle';
import { Folder } from '../../Icons/Folder';
import { SubmitHandler, useForm } from 'react-hook-form';
import configureAxios from '../../axiosClient';
import { ExperienceScene, UpdateSourceFormValues } from '../../interfaces';
import useHover from '../../custom-hooks/useHover';
import { useExperiencesStore } from '../../store/experiences';
import { useUserStore } from '../../store/user';
import { SCENE_UPDATE_TYPE } from '../../enums';
import { Plus } from '../../Icons/Plus';
import UpdateExperienceView from '../updateExperienceView/UpdateExperienceView';

const axiosInstance = configureAxios();

interface UpdateSourceMaterialsFormProps {
  totalProgressCompleted: number;
  updateExperience: (expName: string) => void;
  setChangeInExperience: (state: boolean) => void;
  setUpdatingExperience: (state: boolean) => void;
  getS3PresignedUrl: (
    org_id: string,
    file: File,
    exp_id?: string
  ) => Promise<{
    url: string;
    exp_id: string;
    payload_id: string;
  }>;
  sendPayloadToS3: (url: string, file: File) => Promise<boolean>;
}

const UpdateSourceMaterialForm = ({
  totalProgressCompleted,
  updateExperience,
  setChangeInExperience,
  setUpdatingExperience,
  getS3PresignedUrl,
  sendPayloadToS3,
}: UpdateSourceMaterialsFormProps) => {
  const organisation = useUserStore((state) => state.organisation);
  const selectedExperience = useExperiencesStore(
    (state) => state.selectedExperience
  );
  const { experiences } = useExperiencesStore();

  const [experienceScenes, setExperienceScenes] = React.useState<
    ExperienceScene[]
  >([]);
  const [selectedUpdateType, setSelectedUpdateType] =
    React.useState<string>('');
  const [addNewScene, setAddNewScene] = React.useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [updateSceneRef, isUpdateSceneHovered] = useHover<HTMLDivElement>();
  const [updateJsonRef, isUpdateJsonHovered] = useHover<HTMLDivElement>();

  const hiddenInputRef: MutableRefObject<HTMLInputElement | null> =
    useRef(null);

  const toast = useToast({
    position: 'top',
    variant: 'top-accent',
  });

  const {
    register,
    handleSubmit,
    reset,
    resetField,
    watch,
    formState: { errors },
  } = useForm<UpdateSourceFormValues>({
    defaultValues: useMemo(() => {
      return {
        owner: organisation.organizationId,
        experienceId: selectedExperience.id,
        payload: undefined,
      };
    }, [selectedExperience, organisation]),
  });

  const selectedExperienceId = watch('experienceId');

  const watchFile = watch('payload') as FileList;
  const payLoadFile = watchFile ? watchFile[0] : null;

  const { ref, ...fields } = register('payload', {
    required: 'Payload Is Required',
  });

  useEffect(() => {
    reset({
      owner: organisation.organizationId,
      experienceId: selectedExperience.id,
      sceneName: '',
      payload: undefined,
    });
  }, [selectedExperience, organisation.organizationId]);

  useEffect(() => {
    if (selectedExperience.id) onFetchingExperienceScenes();
  }, [selectedExperienceId, isSubmitting]);

  const onFetchingExperienceScenes = () => {
    axiosInstance
      .get(`experience/${selectedExperienceId}/scenes`)
      .then((res) => {
        if (res.data.status === 'OK') {
          const scenesData = res.data.data.map((scene: ExperienceScene) => ({
            id: scene.uuid,
            name: scene.name,
            experience: scene.experience,
            created: scene.created,
          }));
          setExperienceScenes(scenesData);
          resetField('sceneName');
        } else {
          if (!toast.isActive('sceneErrorOne')) {
            toast({
              id: 'sceneErrorOne',
              title: `No scenes exist for this experience`,
              status: 'info',
            });
          }
        }
      })
      .catch((err: unknown) => {
        console.log(err);
        if (!toast.isActive('sceneErrorTwo')) {
          toast({
            id: 'sceneErrorTwo',
            title: `No scenes exist for this experience`,
            status: 'info',
          });
        }
      });
  };

  const onUpload = () => {
    if (hiddenInputRef.current) {
      hiddenInputRef?.current?.click();
    } else {
      console.error('hiddenInputRef.current is null');
    }
  };

  const onChangeUpdateType = (type: string) => {
    setSelectedUpdateType(type);
    resetField('payload');
  };

  const onUpdateExperienceScene = (
    org_id: string,
    exp_id: string,
    scene: string,
    payload_id: string
  ) => {
    axiosInstance
      .get(
        `experience/update/scene/without-payload/${org_id}/${exp_id}/${scene}/${payload_id}`
      )
      .then((res) => {
        const isStatusOk = res.data.status === 'OK';
        setIsSubmitting(false);

        if (isStatusOk) {
          setUpdatingExperience(true);
          updateExperience(selectedExperience.name);
          setChangeInExperience(true);
          reset();
        } else {
          toast({
            title: res.data.data,
            status: 'error',
          });
        }
      })
      .catch((err) => {
        setIsSubmitting(false);
        console.log(err);
        toast({
          title: err.message,
          status: 'error',
        });
      });
  };

  const onUpdateExperienceJSON = (
    org_id: string,
    exp_id: string,
    scene: string,
    payload_id: string
  ) => {
    axiosInstance
      .get(
        `experience/update/config/without-payload/${org_id}/${exp_id}/${scene}/${payload_id}`
      )
      .then((res) => {
        const isStatusOk = res.data.status === 'OK';
        setIsSubmitting(false);

        if (isStatusOk) {
          setUpdatingExperience(true);
          updateExperience(selectedExperience.name);
          setChangeInExperience(true);
          reset();
        } else {
          toast({
            title: res.data.data,
            status: 'error',
          });
        }
      })
      .catch((err) => {
        setIsSubmitting(false);
        console.log(err);
        toast({
          title: err.message,
          status: 'error',
        });
      });
  };

  const onSubmit: SubmitHandler<UpdateSourceFormValues> = async (data) => {
    setIsSubmitting(true);

    const file = data.payload ? data.payload[0] : null;

    if (!file) {
      console.error('No file selected or payload is not an array');
      setIsSubmitting(false);
      return;
    }

    const { url, exp_id, payload_id } = await getS3PresignedUrl(
      data.owner,
      file,
      data.experienceId
    );

    if (!url && !exp_id && !payload_id) {
      setIsSubmitting(false);
      return;
    }

    const isPayloadUploaded = await sendPayloadToS3(url, file);

    if (!isPayloadUploaded) {
      setIsSubmitting(false);
      return;
    }

    const updateTypeList: {
      [key: string]: (
        org_id: string,
        exp_id: string,
        scene: string,
        payload_id: string
      ) => void;
    } = {
      [SCENE_UPDATE_TYPE.UPDATE_SCENE]: onUpdateExperienceScene,
      [SCENE_UPDATE_TYPE.UPDATE_JSON_FILE]: onUpdateExperienceJSON,
    };

    if (updateTypeList[selectedUpdateType]) {
      updateTypeList[selectedUpdateType](
        data.owner,
        data.experienceId,
        data.sceneName,
        payload_id
      );
    }
  };

  return (
    <FormWrapper onSubmit={handleSubmit(onSubmit)} name='update_source'>
      <Box
        w={['100%']}
        height={['auto']}
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        alignItems='center'
        gap={'24px'}
        mt={'20px'}
      >
        <FormControl isInvalid={errors.owner ? true : false} isRequired>
          <FormLabel
            color='#08101D'
            fontFamily='Normal'
            fontSize='14px'
            margin='0px'
            ml={'34px'}
          >
            Client / Organisation Name
          </FormLabel>
          <Box
            w={['100%']}
            mt={'5px'}
            display={'flex'}
            justifyContent={'start'}
            alignItems={'center'}
            gap={'15px'}
          >
            <Text
              fontFamily='Normal'
              color='#08101D'
              fontSize={['30px']}
              fontWeight='normal'
            >
              1.
            </Text>
            <Select
              id='owner'
              mt={['5px']}
              width={['100%']}
              placeholder='Select organisation'
              borderRadius='16px'
              border='1px solid #D3BB8A'
              color='#58677E'
              _placeholder={{ textTransform: 'capitalize' }}
              fontSize={['14px', '14px', '14px']}
              {...register('owner', {
                required: 'This is required',
              })}
              disabled
            >
              <option value={organisation.organizationId}>
                {organisation.alias}
              </option>
            </Select>
          </Box>
          <FormErrorMessage
            color='#EA1A4C'
            mt={['4px']}
            fontSize='13px'
            fontFamily='Normal'
          >
            {errors.owner && errors.owner.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.experienceId ? true : false} isRequired>
          <FormLabel
            color='#08101D'
            fontFamily='Normal'
            fontSize='14px'
            margin='0px'
            ml={'34px'}
          >
            Experience Name
          </FormLabel>
          <Box
            w={['100%']}
            mt={'5px'}
            display={'flex'}
            justifyContent={'start'}
            alignItems={'center'}
            gap={'10px'}
          >
            <Text
              fontFamily='Normal'
              color='#08101D'
              fontSize={['30px']}
              fontWeight='normal'
            >
              2.
            </Text>
            <Select
              id='experienceId'
              mt={['5px']}
              width={['100%']}
              placeholder='Select experience'
              borderRadius='16px'
              border='1px solid #D3BB8A'
              color='#58677E'
              _placeholder={{ textTransform: 'capitalize' }}
              fontSize={['14px', '14px', '14px']}
              {...register('experienceId', {
                required: 'This is required',
              })}
              disabled
            >
              {experiences?.map((experience) => (
                <option key={experience.id} value={experience.id}>
                  {experience.name}
                </option>
              ))}
            </Select>
          </Box>
          <FormErrorMessage
            color='#EA1A4C'
            mt={['4px']}
            fontSize='13px'
            fontFamily='Normal'
          >
            {errors.experienceId && errors.experienceId.message}
          </FormErrorMessage>
        </FormControl>
        <Box
          w={['100%']}
          display={'flex'}
          justifyContent={'start'}
          alignItems={'center'}
          gap={'9px'}
        >
          <Text
            fontFamily='Normal'
            color='#08101D'
            fontSize={['30px']}
            fontWeight='normal'
          >
            3.
          </Text>
          <Text
            fontFamily='Normal'
            color='#08101D'
            fontSize={['15px']}
            fontWeight='normal'
            textTransform='capitalize'
          >
            Choose Update Type:
          </Text>
        </Box>
        <Box
          w={['100%']}
          height={['auto']}
          transition={'0.2s all'}
          borderRadius='30px'
          border='1px solid #D3BB8A'
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          alignItems='center'
        >
          {/* Update Experience Scene  */}
          <UpdateExperienceView
            sceneRef={updateSceneRef}
            title='Update a Scene'
            subTitle={'Manually update a 3D scene for the experience.'}
            description={`Tip, Enable Unreal Engine's 'Auto Update Scene' for seamless, 
                          automatic 3D scene updates while in play mode.`}
            documentationLink={process.env.REACT_APP_DOCUMENTATION_URL || ''}
            sceneUpdateType={
              selectedUpdateType === SCENE_UPDATE_TYPE.UPDATE_SCENE
            }
            isHovered={isUpdateSceneHovered}
            onClick={() => onChangeUpdateType(SCENE_UPDATE_TYPE.UPDATE_SCENE)}
          />
          <Collapse
            style={{ width: '100%', padding: '0px 30px' }}
            in={selectedUpdateType === SCENE_UPDATE_TYPE.UPDATE_SCENE}
            animateOpacity
          >
            {selectedUpdateType === SCENE_UPDATE_TYPE.UPDATE_SCENE && (
              <Box w={['100%']}>
                <FormControl isInvalid={errors.owner ? true : false} isRequired>
                  <Box
                    w={['100%']}
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    gap={2}
                    my='10px'
                  >
                    <FormLabel
                      color='#08101D'
                      fontFamily='Normal'
                      fontSize='14px'
                      mb={0}
                    >
                      Scene Name
                    </FormLabel>

                    <Box
                      width={'fit-content'}
                      padding={'4px 8px 4px 8px'}
                      border={'1px solid #D6DBE4'}
                      borderRadius={'30px'}
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      gap={'4px'}
                      transition={'0.2s all'}
                      _hover={{
                        background: '#D6DBE4',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setAddNewScene(!addNewScene);
                        resetField('sceneName');
                      }}
                    >
                      {addNewScene ? (
                        <UploadCircle stroke='black' boxSize={[3.5]} />
                      ) : (
                        <Plus stroke='black' boxSize={[2]} />
                      )}
                      <Text
                        fontFamily='Normal'
                        color={'#08101D'}
                        fontSize={'11px'}
                        fontWeight='400'
                      >
                        {addNewScene ? 'Update scene' : 'Add new scene'}
                      </Text>
                    </Box>
                  </Box>
                  {addNewScene ? (
                    <Input
                      id='experienceName'
                      placeholder='Enter scene name'
                      color='#58677E'
                      border='1px solid #D6DBE4'
                      borderRadius='16px'
                      variant='flushed'
                      fontFamily='Normal'
                      fontSize={['14px', '14px', '14px']}
                      _placeholder={{ color: '#7F8B9E' }}
                      px={['14px']}
                      errorBorderColor='#EA1A4C'
                      {...register('sceneName', {
                        required: 'This is required',
                        minLength: {
                          value: 1,
                          message: 'Minimum length should be 1',
                        },
                      })}
                      isDisabled={isSubmitting}
                    />
                  ) : (
                    <Select
                      id='scene'
                      mt={['5px']}
                      width={['100%']}
                      placeholder='Select scene'
                      borderRadius='16px'
                      border='1px solid #D3BB8A'
                      color='#58677E'
                      _placeholder={{ textTransform: 'capitalize' }}
                      fontSize={['14px', '14px', '14px']}
                      {...register('sceneName', {
                        required: 'This is required',
                      })}
                      isDisabled={isSubmitting}
                    >
                      {experienceScenes?.map((scene) => (
                        <option key={scene.id} value={scene.name}>
                          {scene.name}
                        </option>
                      ))}
                    </Select>
                  )}
                  <FormErrorMessage
                    color='#EA1A4C'
                    mt={['4px']}
                    fontSize='13px'
                    fontFamily='Normal'
                  >
                    {errors.owner && errors.owner.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  isInvalid={errors.payload ? true : false}
                  mb='30px'
                >
                  <Input
                    display='none'
                    type='file'
                    accept='.zip,.rar,.7zip'
                    id='payload'
                    {...fields}
                    ref={(instance) => {
                      ref(instance); // RHF wants a reference to this input
                      hiddenInputRef.current = instance; // We also need it to manipulate the element
                    }}
                    isDisabled={isSubmitting}
                  />
                  <Box
                    width={['100%']}
                    height={['auto']}
                    display='flex'
                    flexDirection='column'
                    justifyContent='flex-start'
                    alignItems='center'
                    gap={2}
                    mt={watchFile && payLoadFile ? '15px' : '5px'}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='space-between'
                      alignItems='center'
                    >
                      {watchFile && payLoadFile && (
                        <Box
                          display='flex'
                          flexDirection='column'
                          justifyContent='space-between'
                          alignItems='center'
                        >
                          <Text
                            fontFamily='Normal'
                            color='#08101D'
                            fontWeight='900'
                            fontSize={['14px']}
                          >
                            File Selected
                          </Text>
                          <Text
                            fontFamily='Normal'
                            color='#08101D'
                            fontSize={['12px']}
                            noOfLines={1}
                            maxWidth='300px'
                          >
                            {payLoadFile?.name}
                          </Text>
                        </Box>
                      )}
                    </Box>

                    <FormErrorMessage
                      color='#EA1A4C'
                      mt={['4px']}
                      fontSize='13px'
                      fontFamily='Normal'
                      justifyContent='center'
                    >
                      {errors.payload && errors.payload.message}
                    </FormErrorMessage>
                    <Button
                      mt={['10px']}
                      leftIcon={<Folder boxSize={[4]} />}
                      textTransform='uppercase'
                      width={['200px', '200px', '200px']}
                      padding={['12px 24px']}
                      variant='outline'
                      color='#08101D'
                      background='white'
                      fontFamily='Metal'
                      fontSize={['14px', '14px', '14px']}
                      borderRadius='30px'
                      border='1px solid #D6DBE4'
                      _hover={{
                        bg: '#D3BB8A',
                        borderColor: '#D3BB8A',
                      }}
                      onClick={onUpload}
                      isDisabled={isSubmitting}
                    >
                      Select a file
                    </Button>
                  </Box>
                </FormControl>
              </Box>
            )}
          </Collapse>

          <Divider borderColor={'#D6DBE4'} />

          {/* Update Experience Configs */}
          <UpdateExperienceView
            sceneRef={updateJsonRef}
            title='Update a JSON file'
            subTitle={
              'JSON file holds information on panorama connections and functionality in the virtual experience.'
            }
            documentationLink={process.env.REACT_APP_DOCUMENTATION_URL || ''}
            sceneUpdateType={
              selectedUpdateType === SCENE_UPDATE_TYPE.UPDATE_JSON_FILE
            }
            isHovered={isUpdateJsonHovered}
            onClick={() =>
              onChangeUpdateType(SCENE_UPDATE_TYPE.UPDATE_JSON_FILE)
            }
          />
          <Collapse
            style={{ width: '100%' }}
            in={selectedUpdateType === SCENE_UPDATE_TYPE.UPDATE_JSON_FILE}
            animateOpacity
          >
            {selectedUpdateType === SCENE_UPDATE_TYPE.UPDATE_JSON_FILE && (
              <Box w={['100%']} px={['30px']}>
                <FormControl isInvalid={errors.owner ? true : false} isRequired>
                  <Box
                    w={['100%']}
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    gap={2}
                    my='10px'
                  >
                    <FormLabel
                      color='#08101D'
                      fontFamily='Normal'
                      fontSize='14px'
                      mb={0}
                    >
                      Scene Name
                    </FormLabel>
                  </Box>

                  <Select
                    id='scene'
                    mt={['5px']}
                    width={['100%']}
                    placeholder='Select scene'
                    borderRadius='16px'
                    border='1px solid #D3BB8A'
                    color='#58677E'
                    _placeholder={{ textTransform: 'capitalize' }}
                    fontSize={['14px', '14px', '14px']}
                    {...register('sceneName', {
                      required: 'This is required',
                    })}
                    isDisabled={isSubmitting}
                  >
                    {experienceScenes?.map((scene) => (
                      <option key={scene.id} value={scene.name}>
                        {scene.name}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage
                    color='#EA1A4C'
                    mt={['4px']}
                    fontSize='13px'
                    fontFamily='Normal'
                  >
                    {errors.owner && errors.owner.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={errors.payload ? true : false}
                  mb='30px'
                >
                  <Input
                    display='none'
                    type='file'
                    accept='.json'
                    id='payload'
                    {...fields}
                    ref={(instance) => {
                      ref(instance); // RHF wants a reference to this input
                      hiddenInputRef.current = instance; // We also need it to manipulate the element
                    }}
                    isDisabled={isSubmitting}
                  />
                  <Box
                    width={['100%']}
                    height={['auto']}
                    display='flex'
                    flexDirection='column'
                    justifyContent='flex-start'
                    alignItems='center'
                    gap={2}
                    mt={watchFile && payLoadFile ? '15px' : '5px'}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='space-between'
                      alignItems='center'
                    >
                      {watchFile && payLoadFile && (
                        <Box
                          display='flex'
                          flexDirection='column'
                          justifyContent='space-between'
                          alignItems='center'
                        >
                          <Text
                            fontFamily='Normal'
                            color='#08101D'
                            fontWeight='900'
                            fontSize={['14px']}
                          >
                            File Selected
                          </Text>
                          <Text
                            fontFamily='Normal'
                            color='#08101D'
                            fontSize={['12px']}
                            noOfLines={1}
                            maxWidth='300px'
                          >
                            {payLoadFile?.name}
                          </Text>
                        </Box>
                      )}
                    </Box>

                    <FormErrorMessage
                      color='#EA1A4C'
                      mt={['4px']}
                      fontSize='13px'
                      fontFamily='Normal'
                      justifyContent='center'
                    >
                      {errors.payload && errors.payload.message}
                    </FormErrorMessage>
                    <Button
                      mt={['10px']}
                      leftIcon={<Folder boxSize={[4]} />}
                      textTransform='uppercase'
                      width={['200px', '200px', '200px']}
                      padding={['12px 24px']}
                      variant='outline'
                      color='#08101D'
                      background='white'
                      fontFamily='Metal'
                      fontSize={['14px', '14px', '14px']}
                      borderRadius='30px'
                      border='1px solid #D6DBE4'
                      _hover={{
                        bg: '#D3BB8A',
                        borderColor: '#D3BB8A',
                      }}
                      onClick={onUpload}
                      isDisabled={isSubmitting}
                    >
                      Select a file
                    </Button>
                  </Box>
                </FormControl>
              </Box>
            )}
          </Collapse>
        </Box>

        {isSubmitting && (
          <>
            <Text fontFamily='Normal' color='#08101D' fontSize={['12px']}>
              {`Payload is uploading, please don't close the window.`}
            </Text>
            <Progress
              position='relative'
              mt='10px'
              w={['100%']}
              size='sm'
              hasStripe
              value={totalProgressCompleted}
              borderRadius='30px'
              colorScheme='yellow'
              height='16px'
            >
              <Box
                position='absolute'
                width='100%'
                textAlign='center'
                color='black'
                top='0px'
                fontSize='10px'
                fontWeight='bold'
                zIndex='99'
              >
                {totalProgressCompleted === 100
                  ? 'Processing...'
                  : `${totalProgressCompleted}%`}
              </Box>
            </Progress>
          </>
        )}

        <Button
          my='20px'
          textTransform='uppercase'
          width={['80%', '80%', '300px']}
          variant='outline'
          color='#08101D'
          background='white'
          fontFamily='Metal'
          fontSize={['14px', '14px', '14px']}
          borderRadius='30px'
          border='1px solid #D6DBE4'
          _hover={{
            bg: '#D3BB8A',
            borderColor: '#D3BB8A',
          }}
          isLoading={isSubmitting}
          type='submit'
        >
          Update
        </Button>
      </Box>
    </FormWrapper>
  );
};

export default UpdateSourceMaterialForm;
