import {
  Box,
  Button,
  Image,
  SimpleGrid,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import PluginDashboard from '../../assets/image/PluginDashboard.jpg';
import React, { useEffect, useState } from 'react';
import { Download } from '../../Icons/Download';
import { Play } from '../../Icons/Play';
import { Globe } from '../../Icons/Globe';
import { NextArrow } from '../../Icons/NextArrow';
import CommunityItem from './CommunityItem';
import { useNavigate } from 'react-router-dom';
import { useExperiencesStore } from '../../store/experiences';
import SampleExperienceItems from '../experiencesView/SampleExperienceItems';
import { useCommunityStore } from '../../store/community';
import { CommunityExperienceListItem } from '../../interfaces';
import { usePlanStore } from '../../store/plan';

const CommunityDashboard = () => {
  const navigate = useNavigate();
  const { showCreatorToolDashboard } = useExperiencesStore();
  const {
    queryName,
    resetQuery,
    communityExperiences,
    fetchCommunityExperiences,
    featuredExperiences,
    setIsFeaturedExperience,
  } = useCommunityStore();
  const { setShowGettingStartedModal } = usePlanStore();
  const [communityExperiencesLoaded] = useState(false);

  useEffect(() => {
    if (queryName) {
      resetQuery();
    }
    fetchCommunityExperiences('4', 0, false);
    const intervalId = setInterval(() => {
      fetchCommunityExperiences('4', 0, false);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    fetchCommunityExperiences('4', 0, true);
    const intervalId = setInterval(() => {
      fetchCommunityExperiences('4', 0, true);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Box display='flex' flexDir='column' gap={5}>
      {showCreatorToolDashboard && (
        <Box
          display='flex'
          flexDirection='column'
          position='relative'
          justifyContent='center'
          alignItems='center'
          textAlign='center'
        >
          <Image
            src={PluginDashboard}
            alt='Plugin-Dashboard'
            height='513px'
            width='100%'
            objectFit='cover'
            top={0}
            left={0}
          />
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            position='absolute'
          >
            <Text fontFamily='Metal' fontSize='36px' textTransform='uppercase'>
              New To Creator Tools?
            </Text>
            <Text fontSize='16px' fontFamily='Normal'>
              Check out the featured community experiences made by creators.
            </Text>
            <Box display='flex' mt='24px' gap={5}>
              <Button
                leftIcon={<Download stroke='currentColor' boxSize={[4]} />}
                textTransform='uppercase'
                minW={['100px', '100px', 'auto']}
                variant='outline'
                color='#08101D'
                background='white'
                border='1px solid rgba(242, 243, 245, 0.14)'
                fontFamily='Metal'
                fontSize={['14px', '14px', '14px']}
                borderRadius='30px'
                _hover={{
                  bg: '#D3BB8A',
                }}
                onClick={() => navigate('/plugins')}
              >
                Plugin
              </Button>
              <Button
                leftIcon={<Play stroke='currentColor' boxSize={[3]} />}
                textTransform='uppercase'
                minW={['100px', '100px', 'auto']}
                variant='outline'
                color='#08101D'
                background='white'
                border='1px solid rgba(242, 243, 245, 0.14)'
                fontFamily='Metal'
                fontSize={['14px', '14px', '14px']}
                borderRadius='30px'
                _hover={{
                  bg: '#D3BB8A',
                }}
                onClick={() => setShowGettingStartedModal(true)}
              >
                Tutorials
              </Button>
            </Box>
            <Box width={['inherit']} padding={['0px 5px']}>
              <SampleExperienceItems />
            </Box>
          </Box>
        </Box>
      )}

      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        p={['5px 40px 0px 40px']}
      >
        <Box display='flex' alignItems='center' gap={2}>
          <Globe stroke={'white'} boxSize={5} />
          <Text fontFamily='Metal' fontSize='14px' textTransform='uppercase'>
            Featured Experiences
          </Text>
        </Box>
        <Box display='flex' alignItems='center' gap={10}>
          {featuredExperiences.length > 0 && (
            <Box
              display='flex'
              alignItems='center'
              cursor='pointer'
              gap={2}
              onClick={() => {
                navigate('/community');
                setIsFeaturedExperience(true);
              }}
            >
              <Text fontSize='11px'>View All</Text>
              <NextArrow boxSize={5} />
            </Box>
          )}
        </Box>
      </Box>
      <Box p={['0px 40px 40px 40px']}>
        <SimpleGrid columns={[3, 3, 3, 3, 4]} spacing={[5]}>
          {featuredExperiences?.length > 0 ? (
            featuredExperiences?.map(
              (featuredExperience: CommunityExperienceListItem) => (
                <Skeleton
                  key={featuredExperience.id}
                  isLoaded={!communityExperiencesLoaded}
                  startColor='#29303C'
                  endColor='transparent'
                  borderRadius='16px'
                >
                  <CommunityItem
                    communityExperienceListItem={featuredExperience}
                  />
                </Skeleton>
              )
            )
          ) : (
            <Text fontFamily='Normal' color='white' fontWeight='600'>
              No Featured Experience Found
            </Text>
          )}
        </SimpleGrid>
      </Box>

      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        p={['5px 40px 0px 40px']}
      >
        <Box display='flex' alignItems='center' gap={2}>
          <Globe stroke={'white'} boxSize={5} />
          <Text fontFamily='Metal' fontSize='14px' textTransform='uppercase'>
            Community
          </Text>
        </Box>
        <Box display='flex' alignItems='center' gap={10}>
          {communityExperiences.length > 0 && (
            <Box
              display='flex'
              alignItems='center'
              cursor='pointer'
              gap={2}
              onClick={() => {
                navigate('/community');
                setIsFeaturedExperience(false);
              }}
            >
              <Text fontSize='11px'>View All</Text>
              <NextArrow boxSize={5} />
            </Box>
          )}
        </Box>
      </Box>
      <Box p={['0px 40px 40px 40px']}>
        <SimpleGrid columns={[3, 3, 3, 3, 4]} spacing={[5]}>
          {communityExperiences?.length > 0 ? (
            communityExperiences?.map(
              (communityExperiences: CommunityExperienceListItem) => (
                <Skeleton
                  key={communityExperiences.id}
                  isLoaded={!communityExperiencesLoaded}
                  startColor='#29303C'
                  endColor='transparent'
                  borderRadius='16px'
                >
                  <CommunityItem
                    communityExperienceListItem={communityExperiences}
                  />
                </Skeleton>
              )
            )
          ) : (
            <Text fontFamily='Normal' color='white' fontWeight='600'>
              No Community Experience Found
            </Text>
          )}
        </SimpleGrid>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        position='relative'
        justifyContent='center'
        alignItems='center'
        textAlign='center'
        mt='auto'
      >
        <Image
          src={PluginDashboard}
          alt='Plugin-Dashboard'
          height='313px'
          width='100%'
          objectFit='cover'
          top={0}
          left={0}
        />
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          position='absolute'
        >
          <Text fontSize='36px' fontFamily='Metal' textTransform='uppercase'>
            Begin Building With Plugin
          </Text>
          <Text fontSize='16px' fontWeight={500} maxWidth={['500px']}>
            Download the Creator Tools plugin to use our procedural generation
            tools and creative immersive experiences, all within Unreal Engine.
          </Text>
          <Button
            leftIcon={<Download stroke='currentColor' boxSize={[4]} />}
            textTransform='uppercase'
            minW={['100px', '100px', 'auto']}
            variant='outline'
            color='#08101D'
            background='white'
            border='1px solid rgba(242, 243, 245, 0.14)'
            fontFamily='Metal'
            fontSize={['14px', '14px', '14px']}
            borderRadius='30px'
            mt='20px'
            _hover={{
              bg: '#D3BB8A',
            }}
            onClick={() => navigate('/plugins')}
          >
            Plugin
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CommunityDashboard;
