import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const Selected = (props: IconProps) => (
  <Icon
    width='16'
    height='15'
    viewBox='0 0 16 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M1 7.5L6.6 13.5L15 1.5'
      stroke='black'
      strokeWidth='1.4'
      strokeLinecap='round'
    />
  </Icon>
);
