import { Box, IconButton } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { SidebarOption } from '../../interfaces';
import SidebarItem from './SidebarItem';
import { RightArrow } from '../../Icons/RightArrow';
import React from 'react';

interface SidebarProps {
  sideBarOpen: boolean;
  activeView: string;
  sidebarOptions: SidebarOption[];
  setActiveView: (view: string) => void;
  setSideBarOpen: (state: boolean) => void;
}

function Sidebar({
  sideBarOpen,
  activeView,
  sidebarOptions,
  setActiveView,
  setSideBarOpen,
}: SidebarProps) {
  return (
    <Box
      width={sideBarOpen ? ['220px'] : '60px'}
      transition='0.2s all'
      borderRight='1px solid rgba(242, 243, 245, 0.14)'
      p={['5px 5px 5px 5px']}
      cursor='pointer'
      display='flex'
      flexDirection='column'
      height={['calc(100vh - 65px)']}
      top={['65px']}
      left='0px'
      position='fixed'
      zIndex='10'
      bg='#08101D'
    >
      {sidebarOptions.map((sideBarOption: SidebarOption) => (
        <NavLink key={sideBarOption.key} to={sideBarOption.href}>
          <SidebarItem
            sideBarItemKey={sideBarOption.key}
            sideBarIcon={sideBarOption.sideBarIcon}
            text={sideBarOption.text}
            sideBarOpen={sideBarOpen}
            active={activeView === sideBarOption.key}
            setActiveView={() => setActiveView(sideBarOption.key)}
          />
        </NavLink>
      ))}

      <Box
        width={['100%']}
        height={['50px']}
        display='flex'
        alignItems='center'
        justifyContent='flex-end'
        mt='auto'
      >
        <IconButton
          height={['50px']}
          width={['50px']}
          bg='#1D2531'
          color='white'
          _hover={{
            bg: '#1D2531',
            color: '#D3BB8A',
          }}
          _active={{
            bg: '#1D2531',
          }}
          _focus={{
            bg: '#1D2531',
          }}
          aria-label='Toggle Sidebar'
          icon={
            <RightArrow
              stroke='currentColor'
              boxSize={[5]}
              transform={sideBarOpen ? 'rotate(180deg)' : 'rotate(0deg)'}
              transition='0.2s all'
            />
          }
          onClick={() => setSideBarOpen(!sideBarOpen)}
        />
      </Box>
    </Box>
  );
}

export default Sidebar;
