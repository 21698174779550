import {
  Box,
  Button,
  Divider,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { useUserStore } from '../../store/user';
import DummyUser from '../../assets/image/DummyUser.png';
import React from 'react';
import configureAxios from '../../axiosClient';
import Toggle from '../../Icons/Toggle';
import { AddIcon } from '@chakra-ui/icons';
import { usePlanStore } from '../../store/plan';

const axiosClient = configureAxios();

interface OrganisationDropdown {
  setCreateNewOrganisationModalActive: (state: boolean) => void;
}

function OrganisationDropdown({
  setCreateNewOrganisationModalActive,
}: OrganisationDropdown) {
  const {
    organisation,
    organisationList,
    setInactiveOrganisation,
    setOrganisation,
    setUserCreateNewOrganisationView,
  } = useUserStore();
  const { setShowPlans } = usePlanStore();

  const selectOrganisation = (org: any) => {
    axiosClient
      .put(`user/default-organisation/${org.org_id}`)
      .then((res) => {
        if (res.data.status === 'OK') {
          const newOrganisation = {
            organizationId: res.data.data.organisation,
            alias: org.alias,
            roles: org.roles,
          };
          setOrganisation(newOrganisation);
        }
      })
      .catch((err: unknown) => {
        console.log(err);
      });
  };

  const handleInActiveOrganisation = (org: any) => {
    const inActiveOrg = {
      organizationId: org.org_id,
      alias: org.alias,
      roles: org.roles,
    };
    setInactiveOrganisation(inActiveOrg);
    setUserCreateNewOrganisationView(true);
    setShowPlans(true);
  };

  return (
    <Popover placement='right'>
      <PopoverTrigger>
        <Toggle cursor='pointer' boxSize={5} stroke='white' />
      </PopoverTrigger>
      <PopoverContent
        borderRadius='16px'
        background='#FFF'
        boxShadow='0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
        backdropFilter='blur(12px)'
        width={['350px']}
        height={['auto']}
        maxHeight='450px'
        overflowY='auto'
        top='110px'
      >
        <PopoverBody
          height={['inherit']}
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          padding={['20px']}
        >
          <Box mt='16px' flexDir='column' gap={5}>
            <Text fontWeight='400' color='#08101D' mb='8px'>
              Switch Teams
            </Text>
            <Box display='flex' flexDirection='column' gap={5}>
              {organisationList?.map((org: any, index: number) => {
                return (
                  <Box
                    key={index}
                    borderRadius='16px'
                    border={
                      org.org_id === organisation.organizationId
                        ? '1px solid #D3BB8A'
                        : '1px solid #D6DBE4'
                    }
                    boxShadow='0px 2px 4px 0px rgba(0, 0, 0, 0.25)'
                    padding={['15px']}
                    cursor='pointer'
                    _hover={{
                      border: '1px solid #D3BB8A',
                    }}
                    onClick={() => {
                      if (
                        org.org_id !== organisation.organizationId &&
                        org.is_active === true
                      ) {
                        selectOrganisation(org);
                      } else if (org.is_active === false) {
                        handleInActiveOrganisation(org);
                      }
                    }}
                  >
                    <Box
                      display='flex'
                      justifyContent='flex-start'
                      alignItems='center'
                      gap={1}
                    >
                      <Image
                        src={DummyUser}
                        height={['40px']}
                        width={['40px']}
                        borderRadius='100%'
                        cursor='pointer'
                        mr={['10px']}
                      />

                      <Box width={['auto']}>
                        <Box
                          display='flex'
                          justifyContent='flex-start'
                          alignItems='center'
                          width={['inherit']}
                          gap={2}
                        >
                          <Text
                            color='#08101D'
                            fontFamily='Normal'
                            fontSize={['15px']}
                            width='100%'
                            display='block'
                            textOverflow='ellipsis'
                            whiteSpace='nowrap'
                            overflow='hidden'
                          >
                            {org.alias}
                          </Text>
                        </Box>
                        <Box
                          display='flex'
                          justifyContent='flex-start'
                          alignItems='center'
                          gap={2}
                        >
                          <Box
                            display='flex'
                            justifyContent='space-between'
                            alignItems='center'
                            gap={2}
                          >
                            <Text
                              color='#08101D'
                              fontFamily='Normal'
                              fontSize={['14px']}
                              width={['inherit']}
                            >
                              {org?.plan?.split('_')[0]}
                            </Text>
                            {!org?.is_active && (
                              <Text
                                fontSize='14px'
                                color='#FA243B'
                                fontWeight='600'
                                fontFamily='metal'
                                letterSpacing='1px'
                              >
                                Inactive
                              </Text>
                            )}
                          </Box>
                          <Divider
                            orientation='vertical'
                            height='20px'
                            color='#D6DBE4'
                            width='2px'
                          />
                          <Text
                            color='#08101D'
                            fontFamily='Normal'
                            fontSize={['14px']}
                            width={['inherit']}
                          >
                            Members {org.member_count}
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Box>
            <Button
              leftIcon={<AddIcon />}
              fontFamily='Metal'
              border='1px solid #D6DBE4'
              padding='12px 24px 12px 24px'
              width='full'
              fontSize='14px'
              lineHeight='16px'
              borderRadius='30px'
              mt='16px'
              color='#08101D'
              bgColor='transparent'
              variant='solid'
              onClick={() => setCreateNewOrganisationModalActive(true)}
            >
              Create New
            </Button>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default OrganisationDropdown;
