import {
  Box,
  IconButton,
  Image,
  Spinner,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import { ExperienceListItem } from '../../interfaces';
import { Copy } from '../../Icons/Copy';
import { Eye } from '../../Icons/Eye';
import DummyExperienceImage from '../../assets/image/DummyExperienceImage.jpeg';
import ExperienceItemAdjustMenu from './ExperienceItemAdjustMenu';
import { useState } from 'react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { dispatchExperienceToAnalytics } from '../../analytics';
import { useUserStore } from '../../store/user';
import ExperienceDetailedInfo from './ExperienceDetailedInfo';
import { Globe } from '../../Icons/Globe';
import { useCommunityStore } from '../../store/community';
import { useExperiencesStore } from '../../store/experiences';
import { Like } from '../../Icons/Like';
// import { Clone } from '../../Icons/Clone';

interface ExperienceItemProps {
  experienceListItem: ExperienceListItem;
  experienceHovered: boolean;
  renameExperience: () => void;
  deleteExperience: () => void;
  unPublishExperience: () => void;
  shareCommunityExperience: () => void;
  openEmbedModal: () => void;
  hoverExperience: () => void;
  unHoverExperience: () => void;
  updateSourceMaterials: () => void;
}

function ExperienceItem({
  experienceListItem,
  experienceHovered,
  renameExperience,
  deleteExperience,
  unPublishExperience,
  shareCommunityExperience,
  openEmbedModal,
  hoverExperience,
  unHoverExperience,
  updateSourceMaterials,
}: ExperienceItemProps) {
  const navigate = useNavigate();
  const toast = useToast({
    position: 'top',
    variant: 'top-accent',
  });
  const [isOpen, setOpen] = useState(false);
  const { organisation, user } = useUserStore();
  const { setSelectedExperience } = useExperiencesStore();
  const { setListUnListCommunityExperienceModalActive } = useCommunityStore();

  const handleCopy = async (text: string) => {
    try {
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(text);
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand('copy');
        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err);
        }
        document.body.removeChild(textArea);
      }
      if (!toast.isActive('expereinceLink')) {
        toast({
          id: 'expereinceLink',
          title: `Link Copied`,
          status: 'success',
        });
      }
    } catch (err) {
      console.error('Async: Could not copy text', err);
    }
  };

  return (
    <Box
      key={experienceListItem.id}
      height={['225px']}
      width={['100%']}
      borderRadius='16px'
      bg='#1D2531'
      position='relative'
      onMouseEnter={hoverExperience}
      onMouseLeave={() => {
        unHoverExperience();
        setOpen(false);
      }}
      cursor='pointer'
    >
      {!experienceListItem.processed && (
        <Box
          bg='rgba(8, 16, 29, 0.6)'
          position='absolute'
          height='100%'
          width='100%'
          borderRadius='16px'
          zIndex='299'
          display='flex'
          justifyContent='center'
          alignItems='center'
          gap={2}
        >
          <Text>Processing</Text>
          <Spinner size='md' color='white' />
        </Box>
      )}

      <Box
        overflow='hidden'
        width={['100%']}
        height={experienceHovered ? '100px' : '140px'}
        borderTopRadius='16px'
      >
        <Image
          src={
            experienceListItem.preview_url
              ? experienceListItem.preview_url.replace(
                  'index.html',
                  'browser_icon.jpg'
                )
              : DummyExperienceImage
          }
          objectFit='cover'
          width={['100%']}
          height={['100%']}
          transform={experienceHovered ? 'scale(1.2)' : 'scale(1)'}
          transition='0.2s all'
          cursor='pointer'
        />

        {experienceListItem.is_community_shared &&
          experienceListItem.community_url && (
            <Tooltip
              hasArrow
              label='This experience is listed in community section'
              placement='top-end'
            >
              <IconButton
                icon={<Globe stroke='black' boxSize={6} />}
                aria-label='Globe Icon'
                bgColor={'#D3BB8A'}
                position='absolute'
                top='8px'
                right='8px'
                borderRadius='50%'
                bg='transparent'
                _hover={{ bg: 'transparent' }}
                zIndex='3'
                onClick={() => {
                  setSelectedExperience(experienceListItem);
                  setListUnListCommunityExperienceModalActive(true);
                }}
              />
            </Tooltip>
          )}
      </Box>

      <Box
        opacity={experienceHovered ? 1 : 0}
        visibility={experienceHovered ? 'visible' : 'hidden'}
        backgroundColor='rgba(0,0,0,0.5)'
        transition='0.2s all'
        background='rgba(8, 16, 29, 0.60)'
        backdropFilter='blur(4px)'
        position='absolute'
        zIndex='2'
        top='0px'
        width={['100%']}
        height={experienceHovered ? '100px' : '140px'}
        borderTopRadius='16px'
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        <Box
          // height={['auto']}
          width={['90px']}
          display='flex'
          justifyContent={
            experienceListItem.processed ? 'space-between' : 'center'
          }
          alignItems='flex-end'
        >
          {experienceListItem.processed && (
            <IconButton
              bg='white'
              _hover={{
                bg: '#D3BB8A',
              }}
              borderRadius='100%'
              icon={<Eye stroke='#08101D' boxSize={[5]} />}
              aria-label='View Experience'
              onClick={() => {
                console.log('SSFDSD ', experienceListItem);
                setSelectedExperience(experienceListItem);
                navigate(`/viewer/${experienceListItem.id}`);

                dispatchExperienceToAnalytics('view_experience', {
                  experience_id: experienceListItem.id,
                  experience_name: experienceListItem.name,
                  experience_draft_url: experienceListItem.preview_url,
                  experience_published_url: experienceListItem.published_url,
                  organisation_user_id: user.id,
                  organisation_id: organisation.organizationId,
                });
              }}
            />
          )}
          <ExperienceItemAdjustMenu
            isOpen={isOpen}
            isPublished={
              !!experienceListItem.published_url &&
              experienceListItem.published_url.length > 0
            }
            isShared={
              !!experienceListItem.is_community_shared &&
              experienceListItem.is_community_shared
            }
            isCommunityUrl={experienceListItem?.community_url?.length > 0}
            renameExperience={renameExperience}
            shareCommunityExperience={shareCommunityExperience}
            unPublishExperience={unPublishExperience}
            deleteExperience={deleteExperience}
            openEmbedModal={openEmbedModal}
            setOpen={(state: boolean) => {
              setOpen(state);
            }}
            updateSourceMaterials={updateSourceMaterials}
          />
        </Box>
      </Box>

      <Box
        width={['100%']}
        // height={['auto']}
        height={['auto']}
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        alignItems='flex-start'
        padding={['12px 16px']}
      >
        {experienceListItem.is_community_shared &&
        experienceListItem.community_url !== '' ? (
          <Box display='flex' width={['100%']} justifyContent='space-between'>
            <Text fontFamily='Normal' fontSize={['14px']} noOfLines={1}>
              {experienceListItem.name}
            </Text>
            <Box display='flex' alignItems='center' gap={4}>
              <Tooltip hasArrow label='Like'>
                <Box display='flex' flexDirection='column' gap={2} mt='auto'>
                  <Like boxSize={5} />
                  <Text fontSize='11px' fontFamily='Normal' textAlign='center'>
                    {experienceListItem.like_count}
                  </Text>
                </Box>
              </Tooltip>
              {/* <Tooltip hasArrow label='Clone'>
                <Box display='flex' flexDirection='column' gap={2}>
                  <Clone boxSize={6} />
                  <Text fontSize='11px' fontFamily='Normal' textAlign='center'>
                    {experienceListItem.clone_count}
                  </Text>
                </Box>
              </Tooltip> */}
            </Box>
          </Box>
        ) : (
          <Text fontFamily='Normal' fontSize={['14px']} noOfLines={1}>
            {experienceListItem.name}
          </Text>
        )}

        <ExperienceDetailedInfo
          experienceHovered={experienceHovered}
          experienceListItem={experienceListItem}
        />
        {experienceListItem.published_url &&
        experienceListItem.published_url.length > 0 ? (
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            // marginTop='auto'
            position='relative'
          >
            <Text
              fontFamily='Normal'
              fontSize={['11px']}
              color='#4DFF7B'
              maxW='80%'
              noOfLines={1}
            >
              {experienceListItem.published_url}
            </Text>
            <Copy
              boxSize={[4]}
              stroke='white'
              _hover={{ stroke: '#4DFF7B' }}
              cursor='pointer'
              transition='0.2s all'
              onClick={() =>
                experienceListItem.published_url &&
                handleCopy(experienceListItem.published_url)
              }
            />
          </Box>
        ) : (
          <Text fontFamily='Normal' fontSize={['11px']}>
            Not Published
          </Text>
        )}
      </Box>
    </Box>
  );
}

export default ExperienceItem;
