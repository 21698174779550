import { useUserStore } from '../store/user';

const useRoleAuthorization = () => {
  const { roles } = useUserStore();

  const hasAccess = roles?.includes('admin') || roles?.includes('owner');

  return hasAccess;
};

export default useRoleAuthorization;
