import { Box, Button, IconButton, Text } from '@chakra-ui/react';
import { Bin } from '../../Icons/Bin';
import React from 'react';

interface BillingCardItemProps {
  isDefault: boolean;
  name: string;
  last4: string;
  expMonth: number;
  expYear: number;
  updateDefaultCard: () => void;
  deleteCard: () => void;
}

function BillingCardItem({
  isDefault,
  name,
  last4,
  expMonth,
  expYear,
  updateDefaultCard,
  deleteCard,
}: BillingCardItemProps) {
  return (
    <Box
      width={['inherit']}
      height={['70px']}
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      borderRadius='16px'
      border='1px solid #D3BB8A'
      background={isDefault ? 'rgba(29, 37, 49, 0.05)' : 'transparent'}
      padding={['12px 16px']}
    >
      <Box
        width={['320px']}
        height={['50px']}
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        alignItems='flex-start'
      >
        <Box>
          <Text
            color='#08101D'
            fontSize={['15px']}
            fontFamily='Normal'
            fontWeight='700'
            as='span'
          >
            {name}
          </Text>
          <Text
            color='#08101D'
            fontSize={['15px']}
            fontFamily='Normal'
            fontWeight='700'
            as='span'
            m={['0px 10px']}
          >
            {`**** ${last4}`}
          </Text>
          {isDefault && (
            <Text
              color='#08101D'
              fontSize={['12px']}
              fontFamily='Normal'
              fontWeight='700'
              as='span'
            >
              Default
            </Text>
          )}
        </Box>
        <Box
          width={['230px']}
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <Text color='#58677E' fontSize={['12px']} fontFamily='Normal'>
            {`Expires ${expMonth} / ${expYear}`}
          </Text>
          {/* <Button
        rightIcon={<Adjust boxSize={[4]} stroke="#58677E" />}
        variant="link"
        color="#58677E"
        fontSize={['12px']}
        fontFamily="Normal"
      >
        Billing Address
      </Button> */}
        </Box>
      </Box>
      <Box display='flex' alignItems='center'>
        {!isDefault && (
          <Button
            textTransform='uppercase'
            width={['150px', '150px', '150px']}
            variant='outline'
            color='#58677E'
            background='#1D25310D'
            fontFamily='Metal'
            fontSize={['14px', '14px', '15px']}
            borderRadius='30px'
            border='unset'
            _hover={{
              color: '#08101D',
            }}
            onClick={updateDefaultCard}
          >
            set as default
          </Button>
        )}
        <IconButton
          bg='white'
          _hover={{
            bg: '#D3BB8A',
          }}
          borderRadius='100px'
          height={['35px']}
          minW={['35px']}
          icon={<Bin boxSize={[4]} stroke='black' />}
          aria-label='Delete Card'
          onClick={deleteCard}
        />
      </Box>
    </Box>
  );
}

export default BillingCardItem;
