import React, { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import Toggle from '../../Icons/Toggle';
import { useCommunityStore } from '../../store/community';
import configureAxios from '../../axiosClient';
import { CloseIcon } from '@chakra-ui/icons';

function CommunityTagsDropdown() {
  const { tags, setTags } = useCommunityStore();
  const [suggestedTags, setSuggestedTags] = useState<string[]>([]);
  const [tagInputValue, setTagInputValue] = useState('');

  const axiosClient = configureAxios();

  const getTags = async () => {
    try {
      const res = await axiosClient.get('experience/tags');
      setSuggestedTags(res.data.data);
    } catch (error) {
      console.error('Error fetching tags:', error);
    }
  };

  const handleTags = async (inputTag: string) => {
    setTagInputValue(inputTag);
    if (inputTag.trim()) {
      try {
        const res = await axiosClient.get(
          `experience/tags?tag=${encodeURIComponent(inputTag.trim())}`
        );
        setSuggestedTags(res.data.data);
      } catch (error) {
        console.error('Error fetching tags:', error);
      }
    } else {
      setSuggestedTags([]);
    }
  };

  const addTag = (newTag: string) => {
    const tag = newTag.trim().replace(/\s+/g, '');
    if (tag && !tags.includes(tag)) {
      setSuggestedTags((prev) => [...prev, tag]);
      setTags([...tags, tag]);
    }
  };

  const handleTagToggle = (tag: string) => {
    setTags(
      tags.includes(tag) ? tags.filter((t) => t !== tag) : [...tags, tag]
    );
  };

  useEffect(() => {
    getTags();
  }, []);

  return (
    <Popover
      placement='bottom'
      returnFocusOnClose={false}
      closeOnBlur={true}
      isLazy
    >
      <PopoverTrigger>
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          bg='#1D2531'
          width='280px'
          padding='8px 16px 8px 16px'
          borderRadius='16px'
          cursor='pointer'
        >
          <Text fontFamily='Normal' fontSize='14px'>
            Search By Tags
          </Text>
          <Toggle stroke='white' boxSize={4} />
        </Box>
      </PopoverTrigger>
      <PopoverContent
        borderRadius='16px'
        background='#FFF'
        boxShadow='0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
        backdropFilter='blur(12px)'
        width='280px'
        maxHeight='450px'
        overflowY='auto'
      >
        <PopoverBody
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          padding='20px'
          gap={4}
        >
          {Array.isArray(tags) && tags.length > 0 && (
            <SimpleGrid columns={3} spacing={3} mt='5px'>
              {tags.map((tag, index) => (
                <Tooltip label={tag} key={index}>
                  <Box
                    display='flex'
                    width='auto'
                    flexDirection='row'
                    justifyContent='space-between'
                    alignItems='center'
                    border='1px solid #D6DBE4'
                    borderRadius='30px'
                    p={'8px'}
                    cursor='pointer'
                    _hover={{
                      backgroundColor: '#D3BB8A',
                      fontWeight: '600',
                    }}
                    onClick={() => handleTagToggle(tag)}
                  >
                    <Text
                      color='#08101D'
                      fontSize='12px'
                      fontFamily='Normal'
                      display='block'
                      maxWidth={['100px']}
                      textOverflow='ellipsis'
                      whiteSpace='nowrap'
                      overflow='hidden'
                      title={tag}
                    >
                      {tag}
                    </Text>
                    <CloseIcon color='black' boxSize={2} />
                  </Box>
                </Tooltip>
              ))}
            </SimpleGrid>
          )}
          <Box display='flex' flexDirection='column' gap={4}>
            <Input
              color='#08101D'
              id='tags'
              type='text'
              mt='15px'
              placeholder='Search Tag'
              borderBottom='1px solid #D6DBE4'
              variant='flushed'
              fontFamily='Normal'
              fontSize='14px'
              size='sm'
              value={tagInputValue}
              _placeholder={{ color: '#7F8B9E' }}
              errorBorderColor='#EA1A4C'
              onChange={(e) => handleTags(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === ',' || e.key === 'Enter') {
                  e.preventDefault();
                  addTag(tagInputValue);
                  setTagInputValue('');
                }
              }}
            />
            {suggestedTags.map((suggestedTag, index) => (
              <Box
                key={index}
                display='flex'
                gap={4}
                alignItems='center'
                cursor='pointer'
                p='5px'
              >
                <Checkbox
                  isChecked={tags.includes(suggestedTag)}
                  onChange={() => {
                    handleTagToggle(suggestedTag);
                  }}
                  style={{ border: 'black' }}
                >
                  <Text
                    fontSize='14px'
                    color='#08101D'
                    fontFamily='Normal'
                    display='block'
                    maxWidth={['150px']}
                    textOverflow='ellipsis'
                    whiteSpace='nowrap'
                    overflow='hidden'
                    title={suggestedTag}
                  >
                    {suggestedTag}
                  </Text>
                </Checkbox>
              </Box>
            ))}
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default CommunityTagsDropdown;
