import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const Bar = (props: IconProps) => (
  <Icon
    width='30'
    height='6'
    viewBox='0 0 30 6'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect width='30' height='6' rx='3' />
  </Icon>
);
