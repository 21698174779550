import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import React from 'react';

interface InfoPopoverProps {
  position?: 'top' | 'right' | 'bottom' | 'left';
  trigger: React.ReactNode;
  content: React.ReactNode;
  showArrow?: boolean;
}

const InfoPopover = React.forwardRef<HTMLDivElement, InfoPopoverProps>(
  ({ position, trigger, content, showArrow = false }, ref) => {
    return (
      <Popover placement={position}>
        <PopoverTrigger>{trigger}</PopoverTrigger>
        <PopoverContent ref={ref} w={'fit-content'} borderRadius={'16px'}>
          {showArrow && <PopoverArrow />}
          <PopoverBody>{content}</PopoverBody>
        </PopoverContent>
      </Popover>
    );
  }
);

InfoPopover.displayName = 'InfoPopover';

export default InfoPopover;
