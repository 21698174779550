import { Box, Button, IconButton, Link, Text } from '@chakra-ui/react';
import { RightStemArrow } from '../../Icons/RightStemArrow';
import { Plus } from '../../Icons/Plus';
import React from 'react';

interface CreateExperienceCardProps {
  createExperience: () => void;
}

function CreateExperienceCard({ createExperience }: CreateExperienceCardProps) {
  return (
    <Box
      height={['auto']}
      width={['100%']}
      borderRadius='16px'
      bg='#1D2531'
      position='relative'
      border='1px solid #D3BB8A'
    >
      <Box
        bg='#08101D'
        overflow='hidden'
        width={['100%']}
        height={['140px']}
        borderTopRadius='16px'
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        <IconButton
          bg='white'
          _hover={{
            bg: '#D3BB8A',
          }}
          borderRadius='100%'
          icon={<Plus boxSize={[5]} stroke='#1D2531' />}
          aria-label='View Experience'
          onClick={createExperience}
        />
      </Box>

      <Box
        width={['100%']}
        height={['auto']}
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='flex-start'
        padding={['12px 16px']}
      >
        <Text fontFamily='Normal' fontSize={['14px']}>
          Create New Experience
        </Text>
        <Box
          width='100%'
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <Text
            fontFamily='Normal'
            fontSize={['11px']}
            maxW='80%'
            noOfLines={1}
          >
            Don’t know how?
          </Text>
          <Link href={process.env.REACT_APP_DOCUMENTATION_URL} target='_blank'>
            <Button
              fontWeight='100'
              fontFamily='Normal'
              variant='link'
              fontSize={['11px']}
              color='white'
              rightIcon={
                <RightStemArrow
                  transform='rotate(-45deg)'
                  boxSize={[3]}
                  fill='currentColor'
                  stroke='currentColor'
                />
              }
            >
              Documentation
            </Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

export default CreateExperienceCard;
