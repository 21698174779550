import {
  Box,
  Button,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useExperiencesStore } from '../../store/experiences';
import DummyExperienceImage from '../../assets/image/DummyExperienceImage.jpeg';
import configureAxios from '../../axiosClient';
import { useUserStore } from '../../store/user';
import React, { useState } from 'react';
import { CloseIcon } from '@chakra-ui/icons';
// import { dispatchExperienceToAnalytics } from '../../analytics';

const axiosInstance = configureAxios();

interface UnPublishExperienceModalProps {
  unPublishExperienceModalActive: boolean;
  setUnPublishExperienceModalActive: (state: boolean) => void;
  unPublishExperience: (experienceId: string) => void;
  fetchExperiences?: () => void;
}

function UnPublishExperienceModal({
  unPublishExperienceModalActive,
  setUnPublishExperienceModalActive,
  unPublishExperience,
  fetchExperiences,
}: UnPublishExperienceModalProps) {
  const toast = useToast({
    position: 'top',
    variant: 'top-accent',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { organisation } = useUserStore();
  const selectedExperience = useExperiencesStore(
    (state) => state.selectedExperience
  );
  const setSelectedExperience = useExperiencesStore(
    (state) => state.setSelectedExperience
  );

  const unPublishExperienceList = (experienceId: string) => {
    const userOrgId = organisation.organizationId;
    setIsSubmitting(true);
    axiosInstance
      .delete(`experience/unpublish/${userOrgId}/${selectedExperience.id}`)
      .then((res) => {
        const isStatusOk = res.data.status === 'OK';
        if (isStatusOk) {
          fetchExperiences && fetchExperiences();
          unPublishExperience(experienceId);
          toast({
            title: `Experience unpublished successfully`,
            status: 'success',
          });
          setSelectedExperience({
            ...selectedExperience,
            published_url: res.data.data.published_url,
          });
        } else {
          toast({
            title: `There was an error unpublishing experience`,
            status: 'error',
          });
        }
        setIsSubmitting(false);
      })
      .catch((err: unknown) => {
        console.log(err);
        setIsSubmitting(false);
        toast({
          title: `There was an error unpublishing experience`,
          status: 'error',
        });
      });
  };

  return (
    <Modal
      isOpen={unPublishExperienceModalActive}
      onClose={() => setUnPublishExperienceModalActive(false)}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        minW={['500px']}
        height={['500px']}
        padding={['20px']}
        borderRadius='16px'
        boxShadow='0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
      >
        <CloseIcon
          position='absolute'
          boxSize={[4]}
          cursor='pointer'
          color='#D6DBE4'
          _hover={{ color: 'black' }}
          transition='0.2s all'
          right={['20px']}
          top={['20px']}
          onClick={() => setUnPublishExperienceModalActive(false)}
        />
        <ModalBody
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          alignItems='center'
        >
          <Text
            color='#08101D'
            fontFamily='Metal'
            fontSize={['35px']}
            lineHeight={['35px']}
            textAlign='center'
            textTransform='uppercase'
          >
            Unpublish
          </Text>
          <Box>
            <Box>
              <Text
                color='#08101D'
                fontFamily='Normal'
                fontSize={['15px']}
                fontWeight='600'
                textAlign='center'
                textTransform='capitalize'
              >
                Are you sure you want to Unpublish this experience?
              </Text>
              <Text
                color='#08101D'
                fontFamily='Normal'
                fontSize={['12px']}
                textAlign='center'
              >
                Once you click Unpublish users wont be able to visit your
                experience.
              </Text>
              <Text
                color='#08101D'
                fontFamily='Normal'
                fontSize={['12px']}
                textAlign='center'
              >
                {"Don't"} worry, you can make it live again at any time from the
                Experience Viewer.
              </Text>
            </Box>
            <Box
              height={['220px']}
              width={['280px']}
              borderRadius='16px'
              bg='#1D2531'
              position='relative'
              margin='0 auto'
              mt={['15px']}
            >
              <Image
                src={
                  selectedExperience.preview_url
                    ? selectedExperience.preview_url.replace(
                        'index.html',
                        'browser_icon.jpg'
                      )
                    : DummyExperienceImage
                }
                objectFit='cover'
                width={['100%']}
                height={['140px']}
                borderTopRadius='16px'
                cursor='pointer'
              />
              <Box
                width={['100%']}
                height={['80px']}
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='flex-start'
                padding={['0px 20px']}
              >
                <Text fontFamily='Normal' color='white' fontSize={['15px']}>
                  {selectedExperience?.name}
                </Text>

                {selectedExperience.published_url &&
                selectedExperience.published_url?.length > 0 ? (
                  <Box
                    width='100%'
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <Text
                      fontFamily='Normal'
                      fontSize={['11px']}
                      color='#4DFF7B'
                      maxW='100%'
                      noOfLines={1}
                    >
                      {selectedExperience?.published_url}
                    </Text>
                  </Box>
                ) : (
                  <Text fontFamily='Normal' fontSize={['11px']}>
                    Not Published
                  </Text>
                )}
              </Box>
            </Box>
          </Box>

          <Box
            display='flex'
            flexDirection='column'
            justifyContent='space-between'
            alignItems='center'
          >
            <Button
              textTransform='uppercase'
              width={['80%', '80%', '300px']}
              variant='outline'
              color='#08101D'
              background='white'
              fontFamily='Metal'
              fontSize={['14px', '14px', '15px']}
              borderRadius='30px'
              border='1px solid #D6DBE4'
              _hover={{
                bg: '#D3BB8A',
                borderColor: '#D3BB8A',
              }}
              isLoading={isSubmitting}
              onClick={() => unPublishExperienceList(selectedExperience?.id)}
            >
              Unpublish
            </Button>
            <Button
              variant='link'
              fontFamily='Normal'
              textAlign='center'
              mt={['15px']}
              fontSize={['11px']}
              onClick={() => setUnPublishExperienceModalActive(false)}
            >
              Cancel
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default UnPublishExperienceModal;
