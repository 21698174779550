import { Box } from '@chakra-ui/react';
import MembersTable from '../tables/MembersTable';
import BillingBarActions from '../barActions/BillingBarActions';
import { useUserStore } from '../../store/user';
import TopActionBar from '../common/TopActionBar';
import UserInvitesModal from '../modals/UserInvitesModal';
import React, { useState } from 'react';
import LeaveOrganisationModal from '../modals/LeaveOrganisationModal';
import DeleteOrganisationMemberModal from '../modals/DeleteOrganisationMemberModal';

type MemberItem = {
  id: string;
  name: string;
  surname: string;
  email: string;
  roles: string;
};

interface MembersProps {
  searchBarText: string;
  members: MemberItem[];
  filteredMembers: MemberItem[];
  searchMembers: (searchString: string) => void;
  setMembers: React.Dispatch<React.SetStateAction<MemberItem[]>>;
  setFilteredMembers: React.Dispatch<React.SetStateAction<MemberItem[]>>;
  // setFilteredMembers: (members: MemberItem[]) => void;
}

function Members({
  searchBarText,
  members,
  setMembers,
  filteredMembers,
  setFilteredMembers,
  searchMembers,
}: MembersProps) {
  const organisation = useUserStore((state) => state.organisation);
  const [userInvitesModalActive, setUserInvitesModalActive] = useState(false);
  const [leaveOrganisationModalActive, setLeaveOrganisationModalActive] =
    useState(false);
  const [
    deleteOrganisationMemberModalActive,
    setDeleteOrganisationMemberModalActive,
  ] = useState(false);
  const [selectedMember, setSelectedMember] = useState<MemberItem | null>(null);

  const handleSetDeleteOrganisationMemberModalActive = (
    state: boolean,
    member?: MemberItem
  ) => {
    setDeleteOrganisationMemberModalActive(state);
    setSelectedMember(member || null);
  };

  return (
    <Box flex='1' flexDirection='column'>
      <UserInvitesModal
        userInvitesModalActive={userInvitesModalActive}
        setUserInvitesModalActive={(state) => setUserInvitesModalActive(state)}
      />
      <TopActionBar
        title={organisation.alias}
        subtitle={`Members: ${members.length.toString()}`}
        searchBarPlaceholder='Search Name...'
        showSearchBar
        searchBarText={searchBarText}
        onSearchBarTextChange={(value) => searchMembers(value)}
        rightActions={
          <BillingBarActions
            openUserInvitesModal={() => setUserInvitesModalActive(true)}
          />
        }
      />
      <LeaveOrganisationModal
        leaveOrganisationModalActive={leaveOrganisationModalActive}
        setLeaveOrganisationModalActive={setLeaveOrganisationModalActive}
      />
      {selectedMember && (
        <DeleteOrganisationMemberModal
          deleteOrganisationMemberModalActive={
            deleteOrganisationMemberModalActive
          }
          setDeleteOrganisationMemberModalActive={
            handleSetDeleteOrganisationMemberModalActive
          }
          selectedMember={selectedMember}
          setMembers={setMembers}
          setFilteredMembers={setFilteredMembers}
        />
      )}
      <Box p={['40px']} width={['100%']}>
        <MembersTable
          members={filteredMembers}
          setDeleteOrganisationMemberModalActive={
            handleSetDeleteOrganisationMemberModalActive
          }
          setLeaveOrganisationModalActive={(state) =>
            setLeaveOrganisationModalActive(state)
          }
          openUserInvitesModal={() => setUserInvitesModalActive(true)}
        />
      </Box>
    </Box>
  );
}

export default Members;
