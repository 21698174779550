import React from 'react';
import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { Globe } from '../../Icons/Globe';
import { CloseIcon } from '@chakra-ui/icons';
import ListUnlistCommunityExperienceForm from '../forms/ListUnlistCommunityExperienceForm';

interface ListUnListCommunityExperienceModalProps {
  listUnlistCommunityExperienceModalActive: boolean;
  setListUnListCommunityExperienceModalActive: (state: boolean) => void;
}

function ListUnListCommunityExperienceModal({
  listUnlistCommunityExperienceModalActive,
  setListUnListCommunityExperienceModalActive,
}: ListUnListCommunityExperienceModalProps) {
  return (
    <Modal
      isOpen={listUnlistCommunityExperienceModalActive}
      onClose={() => {
        setListUnListCommunityExperienceModalActive(false);
      }}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent
        minW={['500px']}
        height={['auto']}
        padding={['20px']}
        maxHeight='80vh'
        overflow='auto'
        borderRadius='16px'
        boxShadow='0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
      >
        <CloseIcon
          position='absolute'
          boxSize={[4]}
          cursor='pointer'
          color='#D6DBE4'
          _hover={{ color: 'black' }}
          transition='0.2s all'
          right={['20px']}
          top={['20px']}
          onClick={() => {
            setListUnListCommunityExperienceModalActive(false);
          }}
        />
        <ModalBody display='flex' flexDirection='column' alignItems='center'>
          <Box display='flex' alignItems='center' gap={2}>
            <Globe boxSize={6} />
            <Text
              color='#08101D'
              fontFamily='Metal'
              fontSize={['36px']}
              lineHeight={['36px']}
              textAlign='center'
              textTransform='uppercase'
            >
              Manage
            </Text>
          </Box>
          <ListUnlistCommunityExperienceForm
            setListUnListCommunityExperienceModalActive={
              setListUnListCommunityExperienceModalActive
            }
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ListUnListCommunityExperienceModal;
