import { Box, Collapse, Fade, useToast } from '@chakra-ui/react';
import BillingDetailsModal from '../components/modals/BillingDetailsModal';
import OrganisationInfo from '../components/billingsView/OrganisationInfo';
import Billing from '../components/billingsView/Billing';
import Members from '../components/billingsView/Members';
import BillingTabItem from '../components/billingsView/BillingTabItem';
import UserProfile from '../components/billingsView/UserProfile';
import { useEffect, useState } from 'react';
import { useUserStore } from '../store/user';
import configureAxios from '../axiosClient';
import React from 'react';
import CommunityProfileModal from '../components/modals/UpdateCommunityProfileModal';

const axiosClient = configureAxios();

export type MemberItem = {
  id: string;
  name: string;
  surname: string;
  email: string;
  roles: string;
};

function BillingView() {
  const toast = useToast({
    position: 'top',
    variant: 'top-accent',
  });
  const [members, setMembers] = useState<MemberItem[]>([]);
  const [searchBarText, setSearchBarText] = useState('');
  const [filteredMembers, setFilteredMembers] = useState<MemberItem[]>([]);
  const organisation = useUserStore((state) => state.organisation);
  const [activeTab, setActiveTab] = useState<string>('orgInfo');
  const [billingModalActive, setBillingModalActive] = useState(false);

  useEffect(() => {
    const userOrgId = organisation.organizationId;

    axiosClient
      .get(`org/${userOrgId}/user`)
      .then((res) => {
        const isStatusOk = res.data.status === 'OK';
        if (isStatusOk) {
          setFilteredMembers(res.data.data);
          setMembers(res.data.data);
        } else {
          toast({
            title: `There was an error fetching users`,
            status: 'error',
          });
        }
      })
      .catch((err: unknown) => {
        console.log(err);
      });
  }, [organisation.organizationId]);

  const searchMembers = (value: string) => {
    setSearchBarText(value);
    debouncedFilter(value);
  };

  const filterMembers = (memberName: string) => {
    const filteredMembers = [...members].filter((member: MemberItem) =>
      member.name.toLowerCase().includes(memberName.toLowerCase())
    );
    setFilteredMembers(filteredMembers);
  };

  function debounce<T extends (...args: string[]) => void>(
    func: T,
    delay: number
  ): (...args: Parameters<T>) => void {
    let timeoutId: ReturnType<typeof setTimeout>;

    return function (this: ThisParameterType<T>, ...args: Parameters<T>): void {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  const debouncedFilter = debounce(filterMembers, 500);

  const tabContent = {
    orgInfo: <OrganisationInfo numberOfMembers={members.length.toString()} />,
    members: (
      <Members
        searchBarText={searchBarText}
        members={members}
        setMembers={setMembers}
        filteredMembers={filteredMembers}
        setFilteredMembers={setFilteredMembers}
        searchMembers={(searchString) => searchMembers(searchString)}
        // setFilteredMembers={(members) => setFilteredMembers(members)}
      />
    ),
    billing: (
      <Billing
        setBillingModalActive={(state) => setBillingModalActive(state)}
        numberOfMembers={members.length.toString()}
      />
    ),
  };

  return (
    <Fade in={true} transition={{ enter: { duration: 0.2 } }}>
      <Box display='flex' flexDirection='column' height='100%'>
        <BillingDetailsModal
          billingModalActive={billingModalActive}
          setBillingModalActive={(state) => setBillingModalActive(state)}
        />

        <Box
          width={['100%']}
          height={['90px']}
          p={['0px 60px']}
          display='flex'
          justifyContent='flex-start'
          alignItems='center'
          borderBottom='1px solid rgba(242, 243, 245, 0.14)'
        >
          <UserProfile />

          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            width={['500px']}
            ml={['50px']}
          >
            <BillingTabItem
              text='Organization info'
              active={activeTab === 'orgInfo'}
              setActive={() => setActiveTab('orgInfo')}
            />
            <BillingTabItem
              text='Manage Members'
              active={activeTab === 'members'}
              setActive={() => setActiveTab('members')}
            />
            <BillingTabItem
              text='Billing'
              active={activeTab === 'billing'}
              setActive={() => setActiveTab('billing')}
            />
          </Box>
        </Box>

        <Collapse
          animateOpacity
          in={activeTab === 'orgInfo'}
          style={{ width: '100%' }}
        >
          {tabContent['orgInfo']}
        </Collapse>
        <Collapse animateOpacity in={activeTab === 'members'}>
          {tabContent['members']}
        </Collapse>
        <Collapse animateOpacity in={activeTab === 'billing'}>
          {tabContent['billing']}
        </Collapse>

        <CommunityProfileModal />
      </Box>
    </Fade>
  );
}

export default BillingView;
