import { Box, Collapse, Image } from '@chakra-ui/react';
import EmperiaCreatorLogo from '../assets/image/EmperiaCreatorLogo.png';
import Login from '../components/authView/Login';
import Otp from '../components/authView/Otp';
import CreateAccount from '../components/authView/CreateAccount';
import Questionnaire from '../components/authView/Questionnaire';
import { useAuthStore } from '../store/auth';
import React from 'react';

function Auth() {
  const activeLoginView = useAuthStore((state) => state.activeLoginView);
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  return (
    <Box
      w='100%'
      h='100vh'
      display='flex'
      justifyContent='center'
      alignItems='center'
      flexDirection='column'
      padding='48px'
    >
      <Box
        h='auto'
        w='auto'
        display='flex'
        flexDirection='column'
        alignItems='center'
        gap={[5, 5, 5, 5, 5]}
      >
        <Image
          src={EmperiaCreatorLogo}
          w={['120px']}
          alt='Emperia Creator Tools'
        />

        <Collapse animateOpacity in={activeLoginView === 'login'}>
          <Login />
        </Collapse>

        <Collapse
          animateOpacity
          in={activeLoginView === 'enterCode' && !isLoggedIn}
          transition={{ enter: { delay: 0.5 } }}
        >
          <Otp />
        </Collapse>

        <Collapse
          animateOpacity
          in={activeLoginView === 'createAccount'}
          transition={{ enter: { delay: 0.5 } }}
        >
          <CreateAccount />
        </Collapse>

        <Collapse
          animateOpacity
          in={activeLoginView === 'questionnaire'}
          transition={{ enter: { delay: 0.5 } }}
        >
          <Questionnaire />
        </Collapse>
      </Box>
    </Box>
  );
}

export default Auth;
