import { Box, useToast, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import configureAxios from '../axiosClient';
import { useUserStore } from '../store/user';
import { useExperiencesStore } from '../store/experiences';
import { ExperienceListItem } from '../interfaces';

const axiosClient = configureAxios();

function Viewer() {
  const toast = useToast({
    position: 'top',
    variant: 'top-accent',
  });
  const navigate = useNavigate();
  const { experienceId } = useParams();
  const { organisation } = useUserStore();
  const {
    selectedExperience,
    setSelectedExperience,
    setSelectedExperienceScenes,
  } = useExperiencesStore();
  const [experienceLoading, setExperienceLoading] = useState<boolean>(true);

  useEffect(() => {
    const userOrgId = organisation.organizationId;

    axiosClient
      .get(`org/${userOrgId}/experiences/${experienceId}`)
      .then((res) => {
        if (res.data.status === 'OK') {
          const experience = res.data.data;
          setSelectedExperience(experience);

          window.emperia.init({
            id: 'experience-root',
            experience_url: experience.preview_url.replace(
              'index',
              'experience'
            ),
            ui_url: process.env.REACT_APP_STANDARD_UI_URL as string,
            attach_ui: true,
            debug: false,
            organization_id: '587accae-22f4-41e4-86c6-fa83d20e7f65',
          });

          setExperienceLoading(false);
        } else {
          navigate('/');
          if (!toast.isActive('experienceErrorOne')) {
            toast({
              id: 'experienceErrorOne',
              title: `Experience does not exist`,
              status: 'info',
            });
          }
        }
      })
      .catch((err: unknown) => {
        console.log(err);
        navigate('/');
        if (!toast.isActive('experienceErrorTwo')) {
          toast({
            id: 'experienceErrorTwo',
            title: `Experience does not exist`,
            status: 'info',
          });
        }
      });

    return () => {
      const uiElementToRemove = document.getElementById('ui-root');
      if (uiElementToRemove) {
        document.body.removeChild(uiElementToRemove);
      }
    };
  }, []);

  useEffect(() => {
    selectedExperience?.id?.length > 0 &&
      axiosClient
        .get(`/experience/${selectedExperience.id}/scenes`)
        .then((res) => {
          if (res.data.status === 'OK') {
            setSelectedExperienceScenes(
              res.data.data?.sort(
                (a: ExperienceListItem, b: ExperienceListItem) =>
                  a.created &&
                  b.created &&
                  new Date(a.created).getTime() - new Date(b.created).getTime()
              )
            );
          }
        })
        .catch((err: unknown) => {
          console.log(err);
        });
  }, [selectedExperience]);

  return (
    <Box
      w='100%'
      h='100vh'
      display='flex'
      justifyContent='center'
      alignItems='center'
      position='fixed'
      bg='#08101D'
      color='white'
    >
      <Box
        height='100%'
        width='100%'
        position='absolute'
        top='0px'
        left='0px'
        zIndex='1'
        bg='#08101D'
        opacity={experienceLoading ? 1 : 0}
        visibility={experienceLoading ? 'visible' : 'hidden'}
        transition='all 0.2s'
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        <Text fontSize='20px'>Loading your experience</Text>
      </Box>
      <Box id='experience-root' height='100%' width='100%' />
    </Box>
  );
}

export default Viewer;
