import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Image,
  Input,
  Text,
  useToast,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { motion } from 'framer-motion';
import { CloseIcon } from '@chakra-ui/icons';
import DummyExperienceImage from '../../assets/image/DummyExperienceImage.jpeg';
import { useExperiencesStore } from '../../store/experiences';
import configureAxios from '../../axiosClient';
import { useUserStore } from '../../store/user';
import { useCommunityStore } from '../../store/community';
import { dispatchExperienceToAnalytics } from '../../analytics';
import { Link } from 'react-router-dom';

interface ShareCommunityExperienceFormProps {
  setShareCommunityExperienceModalActive?: (state: boolean) => void;
}

interface FormData {
  acceptTerms: boolean;
  experienceName: string;
  tags: string[];
}

const ShareCommunityExperienceForm = ({
  setShareCommunityExperienceModalActive,
}: ShareCommunityExperienceFormProps) => {
  const toast = useToast({
    position: 'top',
    variant: 'top-accent',
  });

  const { organisation, user } = useUserStore();
  const { selectedExperience } = useExperiencesStore();
  const {
    listedUnlistedCommunityExperience,
    setListedUnlistedCommunityExperienceProcessing,
    setUnlistingProcessActive,
  } = useCommunityStore();

  const axiosClient = configureAxios();

  const organizationId = organisation.organizationId;

  const [isLoading, setIsLoading] = useState(false);
  const [suggestedTags, setSuggestedTags] = useState<string[]>([]);
  const [tagInputValue, setTagInputValue] = useState('');
  const [showDetailsForm, setShowDetailsForm] = useState(false);

  console.log('Selected Experience: ', selectedExperience);
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      acceptTerms: false,
      experienceName: selectedExperience.name,
      tags: [],
    },
  });

  const acceptTerms = watch('acceptTerms');
  const tags = watch('tags');

  const onSubmit = async (data: FormData) => {
    if (!acceptTerms) {
      return;
    }

    if (!showDetailsForm) {
      setShowDetailsForm(true);
      return;
    }

    setIsLoading(true);
    setUnlistingProcessActive(false);
    setListedUnlistedCommunityExperienceProcessing(true);

    try {
      const res = await axiosClient.post(
        `experience/list/${organizationId}/${selectedExperience.id}`,
        {
          name: data.experienceName,
          tag_list: data.tags,
        }
      );
      setIsLoading(false);

      toast({
        title: res.data.data,
        status: 'success',
      });
      dispatchExperienceToAnalytics('publish_community_experience', {
        experience_id: selectedExperience.id,
        organisation_id: organizationId,
        organisation_user_id: user.id,
        experience_name: selectedExperience.name,
        experience_draft_url: selectedExperience.preview_url,
        experience_published_url: res.data?.data?.published_url,
      });
      setShareCommunityExperienceModalActive &&
        setShareCommunityExperienceModalActive(false);
    } catch (error) {
      console.log('Error: ', error);
      setIsLoading(false);
      setListedUnlistedCommunityExperienceProcessing(false);
      setShareCommunityExperienceModalActive &&
        setShareCommunityExperienceModalActive(false);
    }
  };

  const handleTags = async (inputTag: string) => {
    setTagInputValue(inputTag);
    if (inputTag.trim()) {
      try {
        const res = await axiosClient.get(
          `experience/tags?tag=${inputTag.trim()}`
        );
        setSuggestedTags(res.data.data);
      } catch (error) {
        console.log('Error fetching tags:', error);
      }
    } else {
      setSuggestedTags([]);
    }
  };

  const addTag = (tag: string) => {
    const newTag = tag.trim().replace(/\s+/g, '');
    if (newTag && !tags.includes(newTag)) {
      setValue('tags', [...tags, newTag]);
      setTagInputValue('');
      setSuggestedTags([]);
    }
  };

  const removeTag = (index: number) => {
    setValue(
      'tags',
      tags.filter((_, i) => i !== index)
    );
  };

  const toggleTag = (tag: string) => {
    if (tags.includes(tag)) {
      setValue(
        'tags',
        tags.filter((t) => t !== tag)
      );
    } else {
      setValue('tags', [...tags, tag]);
      setTagInputValue('');
      setSuggestedTags([]);
    }
  };

  return (
    <Box maxWidth={['415px']} mt='40px'>
      {!showDetailsForm && (
        <Box
          display='flex'
          flexDirection='column'
          textAlign='center'
          color='black'
          gap={2}
        >
          <Text
            fontFamily='Normal'
            fontWeight={600}
            fontSize='16px'
            lineHeight='18px'
          >
            Share experiences with the community without impacting your
            bandwidth or storage.
          </Text>
          <Text fontFamily='Normal' fontSize='11px' lineHeight='14px'>
            Public and available to view by anyone
          </Text>
        </Box>
      )}
      {/* <Box mt='40px'>
        <Image
          src={DummyExperienceImage}
          alt='dummy-experience'
          width='415px'
          height='200px'
          borderRadius='16px'
        />
      </Box> */}
      <Box
        height={['220px']}
        width={['415px']}
        borderRadius='16px'
        bg='#1D2531'
        position='relative'
        margin='0 auto'
        mt={['15px']}
      >
        <Image
          src={
            selectedExperience.preview_url
              ? selectedExperience.preview_url.replace(
                  'index.html',
                  'browser_icon.jpg'
                )
              : DummyExperienceImage
          }
          objectFit='cover'
          width={['415px']}
          height={['140px']}
          borderTopRadius='16px'
          cursor='pointer'
        />
        <Box
          width={['100%']}
          height={['80px']}
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='flex-start'
          padding={['0px 20px']}
        >
          <Text fontFamily='Normal' color='white' fontSize={['15px']}>
            {listedUnlistedCommunityExperience.name.length > 0
              ? listedUnlistedCommunityExperience.name
              : selectedExperience.name}
          </Text>

          {selectedExperience.published_url &&
          selectedExperience.published_url?.length > 0 ? (
            <Box
              width='100%'
              display='flex'
              justifyContent='space-between'
              alignItems='center'
            >
              <Text
                fontFamily='Normal'
                fontSize={['11px']}
                color='#4DFF7B'
                maxW='100%'
                noOfLines={1}
              >
                {selectedExperience?.published_url}
              </Text>
            </Box>
          ) : (
            <Text fontFamily='Normal' fontSize={['11px']}>
              Not Published
            </Text>
          )}
        </Box>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        {!showDetailsForm ? (
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='start'
            alignItems='center'
            mt='29px'
          >
            <Controller
              name='acceptTerms'
              control={control}
              rules={{
                required: 'Accept the community terms and guidelines',
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Checkbox
                  isChecked={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  ref={ref}
                  size='md'
                  mt={['15px']}
                  borderRadius='100px'
                  colorScheme='lime'
                >
                  <Text fontSize={['12px']} fontFamily='Normal' color='#08101D'>
                    I accept the community{' '}
                    <Link to='https://docs.emperiavr.com/user-guide'>
                      <span style={{ textDecoration: 'underline' }}>
                        guidelines and terms
                      </span>
                      .
                    </Link>
                  </Text>
                </Checkbox>
              )}
            />
            {errors.acceptTerms && (
              <Text color='red' mt='10px' fontSize='12px'>
                {errors.acceptTerms.message}
              </Text>
            )}
          </Box>
        ) : (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            transition={{ duration: 0.5 }}
          >
            <Box
              display='flex'
              justifyContent='center'
              flexDirection='column'
              mt='20px'
              width={['100%']}
            >
              <Controller
                name='experienceName'
                control={control}
                rules={{ required: 'Experience name is required' }}
                render={({ field }) => (
                  <Input
                    {...field}
                    color={'#08101D'}
                    id='experience-name'
                    type='text'
                    placeholder='Experience Name'
                    borderBottom='1px solid #D6DBE4'
                    variant='flushed'
                    fontFamily='Normal'
                    fontSize={['14px', '14px', '14px']}
                    size='md'
                    _placeholder={{ color: '#7F8B9E' }}
                    errorBorderColor='#EA1A4C'
                    mb='5px'
                    isDisabled={isLoading}
                  />
                )}
              />
              {errors.experienceName && (
                <Text color='red' fontSize='12px'>
                  {errors.experienceName.message}
                </Text>
              )}

              {tags && tags.length > 0 && (
                <Box
                  display='flex'
                  flexWrap='wrap'
                  maxWidth='400px'
                  gap={3}
                  mt='15px'
                >
                  {tags.map((tag, index) => (
                    <Box
                      key={index}
                      display='flex'
                      flexDirection='row'
                      maxWidth={['100px']}
                      justifyContent='space-between'
                      alignItems='center'
                      border='1px solid #D6DBE4'
                      borderRadius='30px'
                      gap={3}
                      p={'10px'}
                    >
                      <Text
                        width='auto'
                        color='#08101D'
                        fontFamily='Normal'
                        fontSize={['14px']}
                        display='block'
                        textOverflow='ellipsis'
                        whiteSpace='nowrap'
                        overflow='hidden'
                        title={tag}
                      >
                        {tag}
                      </Text>
                      <CloseIcon
                        boxSize={3}
                        color='black'
                        cursor='pointer'
                        onClick={() => removeTag(index)}
                      />
                    </Box>
                  ))}
                </Box>
              )}

              <Controller
                name='tags'
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    value={tagInputValue}
                    color={'#08101D'}
                    id='tags'
                    type='text'
                    mt='15px'
                    placeholder='Add tags...'
                    borderBottom='1px solid #D6DBE4'
                    variant='flushed'
                    fontFamily='Normal'
                    fontSize={['14px', '14px', '14px']}
                    size='md'
                    _placeholder={{ color: '#7F8B9E' }}
                    errorBorderColor='#EA1A4C'
                    onChange={(e) => {
                      handleTags(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === ',' || e.key === 'Enter') {
                        e.preventDefault();
                        addTag(tagInputValue);
                        setTagInputValue('');
                      }
                    }}
                    isDisabled={isLoading}
                  />
                )}
              />

              {suggestedTags.length > 0 && (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='flex-end'
                  width='200px'
                  height={['auto']}
                  maxHeight='200px'
                  overflowY='auto'
                  borderRadius='16px'
                  padding='20px'
                  backdropFilter='blur(40px)'
                  border='1px solid gold'
                  boxShadow='0px 4px 4px 0px #00000040'
                  m='15px 0px'
                  gap={5}
                >
                  {suggestedTags.map((tag, index) => (
                    <Box display='flex' key={index} gap={5} alignItems='center'>
                      <Checkbox
                        borderRadius='16px'
                        color='gold'
                        isChecked={tags && tags.includes(tag)}
                        onChange={() => toggleTag(tag)}
                      >
                        <Text
                          color='#08101D'
                          fontSize='16px'
                          fontFamily='Normal'
                          display='block'
                          maxWidth={['120px']}
                          textOverflow='ellipsis'
                          whiteSpace='nowrap'
                          overflow='hidden'
                          title={tag}
                        >
                          {tag}
                        </Text>
                      </Checkbox>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          </motion.div>
        )}

        <Box
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          alignItems='center'
          mt='40px'
        >
          <Button
            type='submit'
            textTransform='uppercase'
            width={['80%', '80%', '300px']}
            variant='outline'
            color='#08101D'
            background='white'
            fontFamily='Metal'
            fontSize={['14px', '14px', '15px']}
            borderRadius='30px'
            border='1px solid #D6DBE4'
            textDecoration='capitalize'
            _hover={{
              bg: '#D3BB8A',
              borderColor: '#D3BB8A',
            }}
            isLoading={isLoading}
          >
            {showDetailsForm ? 'Share' : 'Continue'}
          </Button>
          <Button
            variant='link'
            fontFamily='Normal'
            textAlign='center'
            mt={['15px']}
            fontSize={['11px']}
            onClick={() => {
              setShareCommunityExperienceModalActive &&
                setShareCommunityExperienceModalActive(false);
            }}
          >
            Cancel
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default ShareCommunityExperienceForm;
