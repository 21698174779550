import { Box, Button, Text } from '@chakra-ui/react';
import { Tick } from '../../Icons/Tick';
import { Plus } from '../../Icons/Plus';
import React from 'react';

interface Plans {
  [key: string]: Plan;
}

type Plan = {
  topOptions: TopOption;
  preSection: PreSection;
  sectionOne?: SectionOption;
  sectionTwo?: SectionOption;
  sectionThree?: SectionOption;
  btnText?: string;
};

type TopOption = {
  key: string;
  options: Option[];
};

type SectionOption = {
  key: string;
  options: string[];
};

type PreSection = {
  key: string;
};

type Option = {
  text: string;
  textAdded: string;
  textAdditional?: string;
  textAdditionalAdded?: string;
};

interface PlanCardProps {
  current?: boolean;
  suggested?: boolean;
  isDisabled: boolean;
  planType: string;
  targetPeople?: string;
  charges: string;
  planDuration: string;
  btnText?: string;
  onClick?: () => void;
  additionalClick?: () => void;
}

const options: Plans = {
  Free: {
    topOptions: {
      key: 'Top Options',
      options: [
        {
          text: 'Upload/storage',
          textAdded: '500 MB',
        },
        {
          text: 'Bandwidth',
          textAdded: '5 GB',
        },
      ],
    },
    preSection: {
      key: '',
    },
    sectionOne: {
      key: 'Experience Creation',
      options: [
        'Single Sign On',
        'Pre-built interactions',
        'Template scenes',
        'Procedural Generation Tools',
      ],
    },
  },
  Pro: {
    topOptions: {
      key: 'Top Options',
      options: [
        {
          text: 'Upload/storage',
          textAdded: '5 GB',
          textAdditional: 'Additional Storage',
          textAdditionalAdded: '$0.50/GB',
        },
        {
          text: 'Bandwidth',
          textAdded: '50 GB',
          textAdditional: 'Additional Storage',
          textAdditionalAdded: '$1/GB',
        },
      ],
    },
    preSection: {
      key: 'All in Free +',
    },
    sectionOne: {
      key: 'Experience Creation:',
      options: ['Custom domains', 'White-label', '3D navigation (coming soon)'],
    },
  },
  Studio: {
    topOptions: {
      key: 'Top Options',
      options: [
        {
          text: 'Upload/storage',
          textAdded: '10 GB',
          textAdditional: 'Additional Storage',
          textAdditionalAdded: '$0.70/GB',
        },
        {
          text: 'Bandwidth',
          textAdded: '200 GB',
          textAdditional: 'Additional Storage',
          textAdditionalAdded: '$1.50/GB',
        },
      ],
    },
    preSection: {
      key: 'All in Pro +',
    },
    sectionOne: {
      key: 'Experience Creation:',
      options: ['Custom UI'],
    },
    sectionTwo: {
      key: 'UX & Interaction:',
      options: [
        'Custom logic & interactions',
        'API integrations',
        'Token Gated Rooms',
        'Gamification features',
        'Connectivity tools (coming soon)',
      ],
    },
    sectionThree: {
      key: 'Analytics & Management:',
      options: ['Analytics', 'Commerce tools'],
    },
    btnText: 'Need more data? contact us',
  },
  Enterprise: {
    topOptions: {
      key: 'Top Options',
      options: [
        {
          text: 'Upload/storage',
          textAdded: 'Unlimited',
        },
        // {
        //   text: 'Bandwidth',
        //   textAdded: '500 GB',
        //   textAdditional: 'Additional Storage',
        //   textAdditionalAdded: '$3.50/GB',
        // },
      ],
    },
    preSection: {
      key: 'All in Studio +',
    },
    sectionOne: {
      key: 'Analytics & Management:',
      options: [
        'Data export',
        'Markets',
        'Role controls {RBAC}',
        'Security',
        'Dedicated success manager',
        'Self-hosted infrastructure',
        'Managed SaaS',
        'Services availability',
      ],
    },
  },
};

function PlanCard({
  current = false,
  suggested = false,
  isDisabled,
  planType,
  targetPeople,
  charges,
  planDuration,
  btnText,
  onClick,
  additionalClick,
}: PlanCardProps) {
  return (
    <Box
      width={['340px']}
      display='flex'
      justifyContent='center'
      alignItems='flex-start'
    >
      <Box
        width={['100%']}
        borderRadius='30px'
        border={
          current ? '1px solid #D3BB8A' : '1px solid rgba(242, 243, 245, 0.14)'
        }
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        alignItems='center'
      >
        {current && (
          <Box
            height={['40px']}
            width={['inherit']}
            display='flex'
            justifyContent='center'
            alignItems='center'
            background='linear-gradient(255deg, #1D2531 -1.47%, rgba(211, 187, 138, 0.00) 100%)'
            borderTopRadius='28px'
          >
            <Text
              fontFamily='Metal'
              textTransform='uppercase'
              color={current ? '#D3BB8A' : 'white'}
              fontSize={['14px']}
            >
              Current Plan
            </Text>
          </Box>
        )}

        <Box
          p={['30px']}
          height={['210px']}
          width={['inherit']}
          display='flex'
          flexDirection='column'
          alignItems='flex-start'
          background='#1D2531'
          borderTopRadius={current ? 'unset' : '28px'}
          position='relative'
        >
          {suggested && !current && (
            <Box
              width={['-webkit-fit-content']}
              bg='#D3BB8A'
              color='#08101D'
              textTransform='uppercase'
              fontFamily='Metal'
              fontSize={['14px']}
              borderRadius={['5px']}
              padding={['5px 10px']}
              position='absolute'
              top={['-20px']}
              right={['0px']}
              left={['0px']}
              margin='0 auto'
            >
              Suggested
            </Box>
          )}

          <Box
            width={['inherit']}
            display='flex'
            flexDirection='column'
            gap={2}
          >
            <Text
              fontFamily='Metal'
              textTransform='uppercase'
              fontSize={['35px']}
              lineHeight={['35px']}
              color='white'
            >
              {planType}
            </Text>

            {charges?.length > 0 && (
              <Box display='flex' alignItems='flex-end' gap={2}>
                <Text
                  fontFamily='Metal'
                  textTransform='capitalize'
                  fontSize={['25px']}
                  lineHeight={['25px']}
                  color='white'
                >
                  {charges}
                </Text>
                <Text
                  fontFamily='Metal'
                  textTransform='lowercase'
                  fontSize={['15px']}
                  color='white'
                >
                  {planDuration}
                </Text>
              </Box>
            )}
            <Text
              fontFamily='Metal'
              textTransform='lowercase'
              fontSize={['12px']}
              color='white'
            >
              {targetPeople}
            </Text>
          </Box>

          {btnText && btnText?.length > 0 && (
            <Button
              mt={['auto']}
              textTransform='uppercase'
              width='100%'
              variant='outline'
              color='white'
              background='#08101D'
              border='1px solid white'
              fontFamily='Metal'
              fontSize={['14px', '14px', '15px']}
              borderRadius='30px'
              whiteSpace='break-spaces'
              _hover={{
                bg: 'white',
                color: '#08101D',
                _disabled: {
                  bg: '#08101D',
                  color: 'white',
                },
              }}
              isDisabled={current || isDisabled}
              onClick={onClick}
            >
              {current ? 'Current Plan' : btnText}
            </Button>
          )}
        </Box>

        <Box
          p={['30px']}
          width={['inherit']}
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          alignItems='flex-start'
          gap={5}
        >
          <Box
            width={['inherit']}
            display='flex'
            flexDirection='column'
            alignItems='flex-start'
            gap={5}
          >
            {options[planType as keyof typeof options].topOptions.options?.map(
              (option: Option) =>
                option.text.length > 0 && (
                  <Box
                    key={option.text}
                    mt={['5px']}
                    display='flex'
                    flexDirection='column'
                    alignItems='flex-start'
                  >
                    <Box
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                      gap={2}
                    >
                      <Text
                        fontFamily='Normal'
                        fontSize={['12px']}
                        textTransform='capitalize'
                      >
                        {option.text}
                      </Text>
                      <Text
                        fontFamily='Normal'
                        fontSize={['12px']}
                        color='#D3BB8A'
                        textTransform='capitalize'
                      >
                        {option.textAdded}
                      </Text>
                      {option.textAdditional && (
                        <Plus stroke='white' boxSize={[2]} />
                      )}
                    </Box>
                    {option.textAdditional && (
                      <Box
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        gap={2}
                      >
                        <Text
                          fontFamily='Normal'
                          fontSize={['12px']}
                          textTransform='capitalize'
                        >
                          {option.textAdditional}
                        </Text>
                        <Text
                          fontFamily='Normal'
                          fontSize={['12px']}
                          color='#D3BB8A'
                          textTransform='capitalize'
                        >
                          {option.textAdditionalAdded}
                        </Text>
                      </Box>
                    )}
                  </Box>
                )
            )}
          </Box>

          {options[planType as keyof typeof options].preSection.key && (
            <Text fontSize='12px' fontFamily='Normal' fontWeight='900'>
              {options[planType as keyof typeof options].preSection.key}
            </Text>
          )}

          {options[planType as keyof typeof options].sectionOne?.key && (
            <Box>
              <Text fontSize='12px' fontFamily='Normal' fontWeight='900'>
                {options[planType as keyof typeof options].sectionOne?.key}
              </Text>
              <Box
                width={['inherit']}
                display='flex'
                flexDirection='column'
                justifyContent='space-between'
                alignItems='flex-start'
              >
                {options[
                  planType as keyof typeof options
                ].sectionOne?.options.map((option: string) => (
                  <Box
                    key={option}
                    mt={['5px']}
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    gap={2}
                  >
                    <Tick stroke='#7F8B9E' boxSize={[3]} />
                    <Text
                      fontFamily='Normal'
                      fontSize={['12px']}
                      textTransform='capitalize'
                    >
                      {option}
                    </Text>
                  </Box>
                ))}
              </Box>
            </Box>
          )}

          {options[planType as keyof typeof options].sectionTwo && (
            <Box>
              <Text fontSize='12px' fontFamily='Normal' fontWeight='900'>
                {options[planType as keyof typeof options].sectionTwo?.key}
              </Text>
              <Box
                width={['inherit']}
                display='flex'
                flexDirection='column'
                justifyContent='space-between'
                alignItems='flex-start'
              >
                {options[
                  planType as keyof typeof options
                ].sectionTwo?.options.map((option: string) => (
                  <Box
                    key={option}
                    mt={['5px']}
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    gap={2}
                  >
                    <Tick stroke='#7F8B9E' boxSize={[3]} />
                    <Text
                      fontFamily='Normal'
                      fontSize={['12px']}
                      textTransform='capitalize'
                    >
                      {option}
                    </Text>
                  </Box>
                ))}
              </Box>
            </Box>
          )}

          {options[planType as keyof typeof options].sectionThree && (
            <Box>
              <Text fontSize='12px' fontFamily='Normal' fontWeight='900'>
                {options[planType as keyof typeof options].sectionThree?.key}
              </Text>
              <Box
                width={['inherit']}
                display='flex'
                flexDirection='column'
                justifyContent='space-between'
                alignItems='flex-start'
              >
                {options[
                  planType as keyof typeof options
                ].sectionThree?.options.map((option: string) => (
                  <Box
                    key={option}
                    mt={['5px']}
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    gap={2}
                  >
                    <Tick stroke='#7F8B9E' boxSize={[3]} />
                    <Text
                      fontFamily='Normal'
                      fontSize={['12px']}
                      textTransform='capitalize'
                    >
                      {option}
                    </Text>
                  </Box>
                ))}
              </Box>
            </Box>
          )}

          {options[planType as keyof typeof options].btnText && (
            <Button
              textTransform='uppercase'
              width='auto'
              variant='outline'
              color='white'
              background='#1D2531'
              border='none'
              fontFamily='Metal'
              fontSize={['14px', '14px', '15px']}
              borderRadius='30px'
              padding={['12px 24px']}
              whiteSpace='break-spaces'
              _hover={{
                bg: 'white',
                color: '#08101D',
              }}
              onClick={additionalClick}
            >
              {options[planType as keyof typeof options].btnText}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default PlanCard;
