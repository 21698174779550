import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Box,
  Image,
  // useToast,
  IconButton,
} from '@chakra-ui/react';
// import configureAxios from '../../axiosClient';
import DummyUser from '../../assets/image/DummyUser.png';
import { Plus } from '../../Icons/Plus';
import React from 'react';
import ManageMembersDropdown from '../manageMembers/ManageMembersDropdown';

// const axiosClient = configureAxios();

export type MemberItem = {
  id: string;
  name: string;
  surname: string;
  email: string;
  roles: string;
};

interface MembersTableProps {
  members: MemberItem[];
  // setMembers: (members: MemberItem[]) => void;
  openUserInvitesModal: () => void;
  setLeaveOrganisationModalActive: (state: boolean) => void;
  setDeleteOrganisationMemberModalActive: (
    state: boolean,
    member?: MemberItem
  ) => void;
}

function MembersTable({
  members,
  openUserInvitesModal,
  setLeaveOrganisationModalActive,
  setDeleteOrganisationMemberModalActive,
}: MembersTableProps) {
  return (
    <TableContainer>
      <Table variant='simple' size='lg'>
        <Thead height={['auto']}>
          <Tr>
            <Th borderBottom='2px solid #D3BB8A' padding={['16px']}>
              <Text
                fontFamily='Normal'
                textTransform='capitalize'
                fontSize={['15px']}
                color='#D3BB8A'
              >
                Name
              </Text>
            </Th>
            <Th borderBottom='2px solid #D3BB8A' padding={['16px']}>
              <Text
                fontFamily='Normal'
                textTransform='capitalize'
                fontSize={['15px']}
              >
                email
              </Text>
            </Th>
            <Th borderBottom='2px solid #D3BB8A' padding={['16px']}>
              <Text
                fontFamily='Normal'
                textTransform='capitalize'
                fontSize={['15px']}
              >
                team role
              </Text>
            </Th>
          </Tr>
        </Thead>

        <Tbody>
          {members.map((member: MemberItem) => (
            <Tr key={member.id} padding={['16px']} height={['60px']}>
              <Td padding={['16px']}>
                <Box display='flex' gap={1} alignItems='center'>
                  <Image
                    src={DummyUser}
                    height={['35px']}
                    width={['35px']}
                    borderRadius='100%'
                    mr={['10px']}
                  />
                  <Text fontSize={['14px']} fontFamily='Semi'>
                    {member.name}
                  </Text>
                </Box>
              </Td>
              <Td padding={['16px']}>
                <Text fontFamily='Normal' fontSize={['14px']}>
                  {member.email}
                </Text>
              </Td>
              <Td padding={['16px']}>
                <Box display='flex' alignItems='start' gap={[5]}>
                  <Text fontFamily='Normal' fontSize={['14px']}>
                    {member?.roles.includes('owner')
                      ? 'Owner'
                      : member?.roles.includes('admin')
                      ? 'Admin'
                      : member?.roles.includes('member') && 'Member'}
                  </Text>
                  <ManageMembersDropdown
                    member={member}
                    setDeleteOrganisationMemberModalActive={
                      setDeleteOrganisationMemberModalActive
                    }
                    setLeaveOrganisationModalActive={
                      setLeaveOrganisationModalActive
                    }
                  />
                </Box>
              </Td>
            </Tr>
          ))}
          {members.length === 0 && (
            <Tr height={['100px']}>
              <Td padding={['16px']}>
                <Box display='flex' gap={2} alignItems='center'>
                  <IconButton
                    bg='white'
                    _hover={{
                      bg: '#D3BB8A',
                    }}
                    borderRadius='100%'
                    icon={<Plus boxSize={[5]} stroke='#1D2531' />}
                    aria-label='View Experience'
                    onClick={openUserInvitesModal}
                  />
                  <Text fontSize={['14px']} fontFamily='Semi'>
                    Invite Members
                  </Text>
                </Box>
              </Td>
              <Td padding={['16px']}>
                <Text fontFamily='Normal' fontSize={['14px']}></Text>
              </Td>
              <Td padding={['16px']}>
                <Text fontFamily='Normal' fontSize={['14px']}></Text>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

export default MembersTable;
