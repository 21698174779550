import { Box, Text, Td, Tr } from '@chakra-ui/react';
import { Process } from '../../interfaces';
import React from 'react';
import { formatDate, formatDuration } from '../../utils/helper';

interface ProcessItemProps {
  process: Process;
}

function ProcessItem({ process }: ProcessItemProps) {
  return (
    <Tr key={process?.id} padding={['16px']}>
      <Td padding={['16px']}>
        <Box
          display='flex'
          justifyContent='flex-start'
          alignItems='center'
          width={['300px', '300px', '300px', '400px', '400px']}
        >
          <Box height={['inherit']} ml={['10px']} width={['100%']}>
            <Text fontSize={['14px']} fontFamily='Normal' noOfLines={2}>
              {process?.details.experience_name}
            </Text>
          </Box>
        </Box>
      </Td>
      <Td padding={['16px']}>
        <Text fontFamily='Normal' fontSize={['14px']}>
          {process?.label}
        </Text>
      </Td>
      <Td padding={['16px']}>
        <Text fontFamily='Normal' fontSize={['14px']}>
          {process?.state}
        </Text>
      </Td>
      <Td padding={['16px']}>
        <Text fontFamily='Normal' fontSize={['14px']}>
          {formatDate(process?.created)}
        </Text>
      </Td>
      <Td padding={['16px']}>
        <Text fontFamily='Normal' fontSize={['14px']}>
          {formatDuration(process?.time_elapsed)}
        </Text>
      </Td>
      <Td padding={['16px']}>
        <Text fontFamily='Normal' fontSize={['14px']}>
          {process?.user?.name}
        </Text>
      </Td>
    </Tr>
  );
}

export default ProcessItem;
