import { CloseIcon } from '@chakra-ui/icons';
import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react';
import React from 'react';
import LimitReached from '../common/LimitReached';

interface LimitReachedModalProps {
  limitReachedModalActive: boolean;
  setLimitReachedModalActive: (state: boolean) => void;
  setContactModalActive: (state: boolean) => void;
}

function LimitReachedModal({
  limitReachedModalActive,
  setLimitReachedModalActive,
  setContactModalActive,
}: LimitReachedModalProps) {
  return (
    <Modal
      isOpen={limitReachedModalActive}
      onClose={() => {
        setLimitReachedModalActive(false);
      }}
      size={'lg'}
      isCentered
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent
        // minW={['700px']}
        height={['auto']}
        margin='auto'
        maxHeight='80vh'
        overflow='auto'
        padding={['20px']}
        borderRadius='16px'
        boxShadow='0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
      >
        <CloseIcon
          position='absolute'
          boxSize={[4]}
          cursor='pointer'
          color='#D6DBE4'
          _hover={{ color: 'black' }}
          transition='0.2s all'
          right={['20px']}
          top={['20px']}
          onClick={() => {
            setLimitReachedModalActive(false);
          }}
        />
        <ModalBody
          height='auto'
          width='auto'
          gap={5}
          display='flex'
          flexDirection='column'
          alignItems='center'
        >
          <LimitReached
            setContactModalActive={setContactModalActive}
            setLimitReachedModalActive={setLimitReachedModalActive}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default LimitReachedModal;
